import { Component, OnInit, Input, ViewChild, EventEmitter, Output, AfterViewInit, Optional, OnDestroy } from '@angular/core';
import { WorkflowStep, WorkflowStepType } from '../../../../../resources/workflow';
import { OutsideProcessSpecification, Station } from '../../../../../resources/station';
import { StationService } from '../../../../../services/station.service';
import { OrderDetailService } from '../../../order-detail.service';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Product } from '../../../../../resources/product';
import { Order } from '../../../../../resources/order';
import { startWith, distinctUntilChanged, pairwise, filter, withLatestFrom } from 'rxjs/operators';
import { PurchasingRfqRequest } from '../../../../../../common/resources/purchasing-rfq-request';

const eqSet = (xs: Set<any>, ys: Set<any>) =>
    xs.size === ys.size &&
    [...xs].every((x) => ys.has(x));

@Component({
  selector: 'product-workflow-step-form',
  templateUrl: './product-workflow-step-form.component.html',
  styleUrls: ['./product-workflow-step-form.component.less']
})
export class ProductWorkflowStepFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() immutable = false;
  @Input() record: Order;
  @Input() product: Product;
  @Input() step: WorkflowStep;
  @Input() editing: boolean = false;
  @Input() unsavedSpecs: OutsideProcessSpecification[] = [];
  @Output() newOPSpec = new EventEmitter<OutsideProcessSpecification>();
  @Output() rfqRequestCreated = new EventEmitter<PurchasingRfqRequest>();

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  constructor(private stationService: StationService) { }

  @ViewChild('form') form: NgForm;
  @Output() edited = new EventEmitter<WorkflowStep>();
  @Output() fieldChange = new EventEmitter<{ field: string, oldValue: any, newValue: any }>();
  private formSubscription: Subscription;
  private formSubs: Subscription[] = [];
  ngAfterViewInit() {
    this.formSubscription = this.form?.valueChanges.subscribe(() => {
      this.edited.emit(this.step);
    });
    // Hook up to change tracking
    setTimeout(() => {
      for (const field in this.form.controls) {
        const sub = this.form.controls[field].valueChanges
          .pipe(
            startWith(this.form.controls[field].value),
            distinctUntilChanged((oldValue, newValue) => {
              if (field === 'outsideProcessSpecifications') return eqSet(new Set(oldValue), new Set(newValue));
              return oldValue === newValue;
            }),
            pairwise(),
            filter(() => this.editing),
            filter(([_, newValue]) => newValue !== undefined),
          ).subscribe(([oldValue, newValue]) => {
            this.fieldChange.emit({ field, oldValue, newValue });
          })
        ;
        this.formSubs.push(sub);
      }
    });
  }

  ngOnDestroy(): void {
    this.formSubscription && this.formSubscription.unsubscribe();
    for (const sub of this.formSubs) {
      if (sub) sub.unsubscribe();
    }
  }

  public get canEditWorkflowStep() {
    if (!this.editing) return false;
    else if (!this.product.productRepairPlan) return true;
    else return this.step.stepType !== WorkflowStepType.Standard
  }

  public get firstQuantity(): number {
    return Product.getFirstQuantity(this.product);
  }

  public get firstPartInspectionCost() {
    if (!this.step.firstPartInspectionTime || !this.step.firstPartInspectionRate) return 0;
    if (!this.step.hasFirstPartInspection) return 0;
    return ((this.step.firstPartInspectionTime / 60) * this.step.firstPartInspectionRate);
  }

  public get fullInspectionCost() {
    if (!this.step.inspectionTime || !this.step.inspectionRate) return 0;
    if (!this.step.hasInspection) return 0;
    let inspectionTimes: number;
    if (!this.step.inspectionIsBatched || isNaN(this.step.inspectionBatchSize) || this.step.inspectionBatchSize < 1) inspectionTimes = this.firstQuantity;
    else inspectionTimes = Math.ceil(this.firstQuantity / this.step.inspectionBatchSize);
    return ((this.step.inspectionTime / 60) * this.step.inspectionRate) * inspectionTimes;
  }

  public setOutsourcedStepPrice(quote: MaterialBid) {
    let newPrice: number;
    if (this.step.runIsPerPart)
    {
        newPrice = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    }
    else
    {
        newPrice = quote.totalBid ? (quote.totalBid) : (quote.perItemBid * quote.qty);
    }
    this.fieldChange.emit({
      field: 'runPrice',
      oldValue: this.step.runPrice,
      newValue: newPrice,
    });
    if (!this.immutable) this.step.runPrice = newPrice;
  }

  public async onCreateSpec(specName: string) {
    const newSpec = <OutsideProcessSpecification>{
      outsideProcessSpecificationId: UtilityService.newGuid(),
      name: specName,
      stationId: this.step.stationId
    };
    this.newOPSpec.emit(newSpec);
    const newSpecs =  [...this.step.outsideProcessSpecifications, newSpec.outsideProcessSpecificationId];
    if (!this.immutable) this.step.outsideProcessSpecifications = newSpecs;
    else this.fieldChange.emit({
      field: 'outsideProcessSpecifications',
      oldValue: this.step.outsideProcessSpecifications,
      newValue: newSpecs,
    });
  }


  public stationList: Station[]
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  @Output() deleteStep = new EventEmitter<void>();
  @Output() addQuote = new EventEmitter<MaterialBid>();

}
