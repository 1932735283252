<div class="checklist-table-outer" *ngIf="internalState">
    <table class="table table-sm table-striped table-borderless table-hover thead-light mb-0">
        <tr class="large">
            <th>Item</th>
            <th *ngFor="let value of answers" class="text-center">{{ value }}</th>
            <th>Examples/Notes</th>
        </tr>
        <tr *ngFor="let question of questions">
            <td class="font-weight-bold question-text">{{question.text}}</td>
            <td *ngFor="let value of answers" class="text-center">
                <mat-radio-button [name]="question.contractReviewQuestionId"
                    [checked]="internalState[question.contractReviewQuestionId] === value"
                    (click)="update(question.contractReviewQuestionId, value)"
                    [value]="value"></mat-radio-button>
            </td>
            <td class="text-muted font-italic small">
                {{ question.examples ? question.examples : '—' }}
            </td>
        </tr>
    </table>
</div>