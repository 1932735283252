import { PricingBreakdownItem, PricingBreakdownSubitem, doAltText } from "../../../../util/pricing";
import { PurchasingRfqRequest } from "../../common/resources/purchasing-rfq-request";
import { UtilityService } from "../../common/services/utility.service";
import { InventoryItem } from "../../inventory/resources/inventory-item";
import { MachineAssignment } from "../../planning/resources/machine-assignment";
import { ShippingTicket } from "../../shipping/resources/shipping-ticket";
import { Paint } from "./paint";
import { SchedulingTicket } from "./schedulingTicket";
import { Station } from "./station";

export interface Workflow {
  workflowId: string;
  name: string;

  workflowSteps: WorkflowStep[];
}


export enum WorkflowStepType {
  Standard = 0,
  Repair = 1,
  Amend = 2
}

export enum WorkflowStepInventoryItemType {
  INPUT = 0,
  OUTPUT = 1,
  TOOLING = 2,
  INSPECTION_TOOLING = 3
}
export enum WorkflowStepPlanningStatus {
  UNPLANNED = 0,
  NEEDS_CHECK = 1,
  PLANNED = 2
}
export interface WorkflowStepInventoryItem
{
  workflowStepInventoryItemId: string;
  workflowStepId: string;
  inventoryItemId: string;
  inventoryItem: InventoryItem;

  type: WorkflowStepInventoryItemType;
  quantity: number;
}

export interface WorkflowStepBreakdownOptions {
  // programmingOutput: 'combined' | 'separate',
  inspectionOutput: 'combined' | 'separate',
}

export class WorkflowStep {
  workflowStepId: string;
  stepOrder: number;
  workflowId: string;
  planningStatus: WorkflowStepPlanningStatus;
  description: string;
  stationId: string;
  runTime?: number;
  runPrice?: number;
  productionBatchSize?: number;
  runIsPerPart?: boolean;
  outsourceMarkup?: number;
  outsourceIsFinal?: boolean;
  processStarted?: Date;
  processCompleted?: Date;
  paintId?: string;
  paintedArea?: number;
  paintCoats?: number;
  paintCost?: number;
  paintPrice?: number;
  paintMinPrice?: number;
  stepType: WorkflowStepType;
  perPieceSetupTime?: number;
  specification: string;
  outsideProcessSpecifications: string[];

  inspectionIsCMM: boolean;
  hasFirstPartInspection: boolean;
  firstPartInspectionTime: number;
  firstPartInspectionRate: number;
  inspectionIsBatched: boolean;
  inspectionBatchSize: number;
  inspectionNotes: string;

  hasInspection: boolean;
  inspectionTime: number;
  inspectionRate: number;

  hasProgramming: boolean;
  programmingTime: number;
  programmingRate: number;

  outgoingShippingTicketId?: string;
  incomingShippingTicketId?: string;

  selectedQuote?: string;
  isStandalone: boolean;

  isAdministrative: boolean;
  isAssembly: boolean;
  predecessorStepId?: string;
  startOffsetHours: number;

  hasSetup: boolean;
  setupTime: number;

  paint: Paint;
  isFromCompare?: boolean = false;

  outgoingShippingTicket?: ShippingTicket;
  incomingShippingTicket?: ShippingTicket;

  workflowStepInventoryItems: WorkflowStepInventoryItem[];
  machineAssignments: MachineAssignment[];
  schedulingTicket: SchedulingTicket;

  purchasingRfqRequestId?: string;
  purchasingRfqRequest?: PurchasingRfqRequest;

  public static calculateCost(step: WorkflowStep): number {
    if (step.paint) {
      let paintCost: number;
      const paintedArea = step.paintedArea * step.paintCoats;
      const calculatedPaintCost = (step.runPrice * paintedArea) + (step.paintCost * paintedArea);
      if (step.paintMinPrice > calculatedPaintCost) paintCost = step.paintMinPrice;
      else paintCost = calculatedPaintCost;

      return ((step.runIsPerPart || false) ? (1 / 60.0) : 1.0) * paintCost;
    }

    let finalCost: number;
    if (step.outsourceMarkup) {
      finalCost = (step.runPrice || 0) * (1 + ((step.outsourceMarkup || 0) / 100));
    } else {
      const oneTimeSetup = step.hasSetup ? ((step.setupTime * step.runPrice)) : 0;
      const perPartSetup = (step.hasSetup && step.runIsPerPart) ? ((step.perPieceSetupTime * (step.runPrice / 60))) : 0;
      const programmingCost = step.hasProgramming ? ((step.programmingTime || 0) * (step.programmingRate || 0)) : 0;
      
      const runTime = step.runIsPerPart ? ((step.runTime || 0) / 60): (step.runTime || 0);
      const mainCost = (step.runPrice || 0) * runTime;


      const firstPartInspectionCost = step.hasFirstPartInspection ? (((step.firstPartInspectionTime || 0) / 60) * (step.firstPartInspectionRate || 0)) : 0;
      
      finalCost = oneTimeSetup + perPartSetup + firstPartInspectionCost + programmingCost + mainCost;
    }

    return parseFloat(finalCost.toFixed(2))
  }

  public static getProgrammingCost(step: WorkflowStep) {
    return step.hasProgramming ? ((step.programmingTime || 0) * (step.programmingRate || 0)) : 0;
  }

  public static getBatchedInspectionCost(step: WorkflowStep, qty: number): number {
    if (step.hasInspection && step.inspectionIsBatched) {
      const batchCount = Math.ceil(qty / (step.inspectionBatchSize || 1));
      const batchedInspectionCost = ((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0);
      return batchedInspectionCost * batchCount;
    } else return 0;
  }

  public static calculateCostForQty(step: WorkflowStep, qty: number): number {
    if (step.paint) {
      let paintCost: number;
      const paintedArea = step.paintedArea * step.paintCoats;
      const calculatedPaintCost = (step.runPrice * paintedArea) + (step.paintCost * paintedArea);
      if (step.paintMinPrice > calculatedPaintCost) paintCost = step.paintMinPrice;
      else paintCost = calculatedPaintCost;

      return ((step.runIsPerPart || false) ? (1 / 60.0) : 1.0) * paintCost * (step.runIsPerPart ? qty : 1);
    }

    let finalCost = 0;
    if (step.outsourceMarkup) {
      finalCost = (step.runPrice || 0) * (1 + ((step.outsourceMarkup || 0) / 100)) * (step.runIsPerPart ? qty : 1);
    } else {
      const runTime = step.runIsPerPart ? ((step.runTime || 0) / 60): (step.runTime || 0);
      const perRunCost = (step.runPrice || 0) * runTime;
      // One-time stuff
      const oneTimeSetupCost = step.hasSetup ? ((step.setupTime * step.runPrice)) : 0;
      finalCost += oneTimeSetupCost;
      // const programmingCost = this.getProgrammingCost(step);
      // finalCost += programmingCost;
      const firstPartInspectionCost = step.hasFirstPartInspection ? (((step.firstPartInspectionTime || 0) / 60) * (step.firstPartInspectionRate || 0)) : 0;
      finalCost += firstPartInspectionCost;
      if (!step.runIsPerPart) finalCost += perRunCost;
      // Per-qty stuff
      let perQtyCost = 0;
      const perPartSetupCost = (step.hasSetup && step.runIsPerPart) ? ((step.perPieceSetupTime * (step.runPrice / 60))) : 0;
      perQtyCost += perPartSetupCost
      if (step.runIsPerPart) perQtyCost += perRunCost;
      const unbatchedInspectionCost = (step.hasInspection) ? (((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0)) : 0;
      if (!step.inspectionIsBatched) perQtyCost += (step.hasInspection) ? (((step.inspectionTime || 0) / 60) * (step.inspectionRate || 0)) : 0;
      finalCost += perQtyCost * (step.runIsPerPart ? qty : 1);
      const batchedInspectionCost = this.getBatchedInspectionCost(step, qty);
      finalCost += batchedInspectionCost;
    }
    return parseFloat(finalCost.toFixed(2))
  }

  public static calculatePerItemCost(step: WorkflowStep, qty: number): number {
    return WorkflowStep.calculateCostForQty(step, qty) / qty;
  }

  public static newEmptyStep(station: Station) {
    const newStep = <WorkflowStep>{
      workflowStepId: UtilityService.emptyGuid,
      stationId: station.stationId,
      runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
      runIsPerPart: station.perPart,
      isStandalone: station.isStandalone || false,
      isAdministrative: station.isAdministrative,
      isAssembly: station.isAssembly,
      hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
      setupTime: station.defaultSetupTime,
      outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
      outsideProcessSpecifications: [],
      startOffsetHours: 0,
    };

    if (station.isPainting) {
      newStep.runTime = 1;
      newStep.runPrice = 0.065;
      newStep.paintCost = 0.125;
      newStep.paintMinPrice = 350;
    }
    return newStep;
  }

  public static getPaintStepBreakdown(step: WorkflowStep, quantity: number, name: string): PricingBreakdownItem {
    let calculation = '';
    const subitems: PricingBreakdownSubitem[] = [];

    // Define step ids
    const paint_area_id = 'PAINTED_AREA';
    const labor_cost_id = 'LABOR_COST';
    const paint_cost_id = 'PAINT_COST';
    const combined_cost_id = 'COMBINED_COST';
    const check_per_part_id = 'PER_PART';
    const quantity_mult_id = 'QUANTITY_MUL';
    const check_minimum_id = 'MINIMUM';

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id);

    // 1. Calculate painted area
    const unitPaintedArea = step.paintedArea * step.paintCoats; // actual calc

    calculation = doAltText(`${step.paintedArea} in&sup2;`, 'Painted area');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${step.paintCoats}`, 'Paint coats');

    subitems.push({
      id: paint_area_id,
      name: 'Calculate painted area per unit',
      value: unitPaintedArea,
      displayValue: `${unitPaintedArea} in&sup2;`,
    });

    // 2. Calculate labor cost
    const runPrice = step.runPrice ?? 0;
    const unitLaborCost = runPrice * getStep(paint_area_id).value; // actual calc

    calculation = doAltText(`$${runPrice.toFixed(2)}`, 'Labor cost per in&sup2;');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_area_id).displayValue, 'Unit painted area');

    subitems.push({
      id: labor_cost_id,
      name: 'Calculate labor cost per unit',
      value: unitLaborCost,
      displayValue: `$${unitLaborCost.toFixed(2)}`,
      calculation,
    });

    // 3. Calculate paint cost
    const paintCost = step.paintCost ?? 0;
    const unitPaintCost = paintCost * getStep(paint_area_id).value; // actual calc

    calculation = doAltText(`$${paintCost.toFixed(2)}`, 'Paint cost per in&sup2;');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_area_id).displayValue, 'Unit painted area');

    subitems.push({
      id: paint_cost_id,
      name: 'Calculate paint cost per unit',
      value: unitPaintCost,
      displayValue: `$${unitPaintCost.toFixed(2)}`,
      calculation,
    });

    // 4. Calculate paint cost
    const unitTotalCost = getStep(labor_cost_id).value * getStep(paint_cost_id).value; // actual calc

    calculation = doAltText(getStep(labor_cost_id).displayValue, 'Labor cost per unit');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(getStep(paint_cost_id).displayValue, 'Paint cost per unit');

    subitems.push({
      id: combined_cost_id,
      name: 'Combine costs',
      value: unitTotalCost,
      displayValue: `$${unitTotalCost.toFixed(2)}`,
      calculation,
    });

    // 5. Check per-part
    subitems.push({
      id: check_per_part_id,
      name: 'Check if cost is per-part',
      displayValue: step.runIsPerPart ? 'Yes' : 'No',
    });

    // 6. Multiply by adjusted quantity
    const adjustedQuantity = step.runIsPerPart ? quantity : 1; // actual calc
    const prelimTotalCost = getStep(combined_cost_id).value * adjustedQuantity; // actual calc

    calculation = doAltText(getStep(combined_cost_id).displayValue, 'Combined cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${adjustedQuantity}`, step.runIsPerPart ? 'Per-part quantity' : 'Non-per-part quantity');

    subitems.push({
      id: quantity_mult_id,
      name: 'Calculate preliminary total cost',
      value: prelimTotalCost,
      displayValue: `$${prelimTotalCost.toFixed(2)}`,
      calculation,
    });

    // 7. Check minimum price
    const paintMinPrice = step.paintMinPrice ?? 0;
    const actualTotalCost = Math.max(prelimTotalCost, paintMinPrice); // actual calc

    calculation = 'Maximum of either ';
    calculation += doAltText(getStep(quantity_mult_id).displayValue, 'Preliminary total cost');
    calculation += ' or ';
    calculation += doAltText(`$${paintMinPrice.toFixed(2)}`, 'Minimum paint price');

    subitems.push({
      id: check_minimum_id,
      name: 'Replace with minimum price if necessary',
      value: actualTotalCost,
      displayValue: `${actualTotalCost.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = getStep(check_minimum_id).value;

    const itemId = step.workflowStepId;

    // Structure into breakdown item
    return { name, price, subitems, itemId };
  }

  public static getLaborStepBreakdown(step: WorkflowStep, quantity: number, name: string,
    options: WorkflowStepBreakdownOptions = {
    inspectionOutput: "combined",
    // programmingOutput: "combined"
  }): PricingBreakdownItem[] {
    let calculation = '';
    const subitems: PricingBreakdownSubitem [] = [];

    let output: PricingBreakdownItem[] = [];

    // Define step ids
    const per_part_id = 'PER_PART';
    const per_run_cost_id = 'PER_RUN_COST';
    const per_run_qty_mult_id = 'PER_RUN_QTY';
    const has_setup_id = 'HAS_SETUP';
    const per_part_setup_id = 'PER_PART_SETUP';
    const setup_qty_mult_id = 'SETUP_QTY';
    const single_setup_id = 'SINGLE_SETUP'
    const setup_total_id = 'SETUP_TOTAL';
    const has_inspection_id = 'HAS_INSPECTION';
    const has_batch_inspection_id = 'HAS_BATCHED_INSPECTION';
    const unbatched_cost_id = 'UNBATCHED_COST';
    const unbatched_qty_mult_id = 'UNBATCHED_QTY';
    const batched_count_id = 'BATCHED_COUNT';
    const batch_cost_id = 'BATCH_COST';
    const batches_cost_id = 'BATCHES_COST';
    const has_first_inspection_id = 'HAS_FIRST';
    const first_inspection_cost_id = 'FIRST_INSPECT_COST';
    const total_inspection_cost_id = 'TOTAL_INSPECTION';
    const grand_total_id = 'GRAND_TOTAL';

    let inspectionSubitems: PricingBreakdownSubitem[];
    // if combined, set it to the same array as everything else
    if (options.inspectionOutput === 'combined') inspectionSubitems = subitems;
    // otherwise make a new empty array
    else if (options.inspectionOutput === 'separate') inspectionSubitems = [];

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id) ?? inspectionSubitems.find(i => i.id === id);

    // 1. Is run per-part?
    const perPartCoefficient = step.runIsPerPart ? 1 : 0; // actual calc

    subitems.push({
      id: per_part_id,
      name: 'Is the cost for each part?',
      value: perPartCoefficient,
      displayValue: perPartCoefficient === 1 ? 'Yes' : 'No',
    });

    // 2. Calculate cost per run
    const runTime = getStep(per_part_id).value === 1 ? (step.runTime ?? 0) / 60 : (step.runTime ?? 0);
    const runPrice = step.runPrice ?? 0;
    const costPerRun = runTime * runPrice; // actual calc

    calculation = doAltText(`${runTime.toFixed(3)} hours`, 'Run time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Run price');

    subitems.push({
      id: per_run_cost_id,
      name: 'Calculate cost per run',
      value: costPerRun,
      displayValue: `$${costPerRun.toFixed(2)}`,
      calculation,
    });

    // 3. Multiply cost per run by adjusted quantity
    const costPerRunQty = getStep(per_part_id).value === 1 ? quantity : 1
    const fullRunCost = getStep(per_run_cost_id).value * costPerRunQty; // actual calc

    calculation = doAltText(`${getStep(per_run_cost_id).displayValue}`, 'Run price');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${costPerRunQty}`, getStep(per_part_id).value === 1 ? 'Per-part quantity' : 'Not per-part, so single run');

    subitems.push({
      id: per_run_qty_mult_id,
      name: '<b>Get total run cost</b>',
      value: fullRunCost,
      displayValue: `$${fullRunCost.toFixed(2)}`,
      calculation,
    });

    // 4. Part has setup
    const setupCoefficient = step.hasSetup ? 1 : 0; // actual calc

    subitems.push({
      id: has_setup_id,
      name: 'Part has setup',
      value: setupCoefficient,
      displayValue: setupCoefficient === 1 ? 'Yes' : 'No',
    });

    // 5. Calculate per-part setup cost
    const perPieceSetupTime = (step.perPieceSetupTime ?? 0) / 60;
    const perPartSetupCost = getStep(has_setup_id).value * getStep(per_part_id).value * perPieceSetupTime * runPrice; // actual calc

    calculation = doAltText(`${getStep(has_setup_id).value}`, '1 if run has setup');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(per_part_id).value}`, '1 if run is per-part');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${perPieceSetupTime.toFixed(3)} hours`, 'Per-piece setup time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Run price');

    subitems.push({
      id: per_part_setup_id,
      name: 'Calculate the per-part setup costs',
      value: perPartSetupCost,
      displayValue: `$${perPartSetupCost.toFixed(2)}`,
      calculation,
    });

    // 6. Multiply per-part setup by quantity
    const allPartsSetupCost = getStep(per_part_setup_id).value * quantity; // actual calc

    calculation = doAltText(`${getStep(per_part_setup_id).displayValue}`, 'Per part setup cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantity}`, 'Quantity');

    subitems.push({
      id: setup_qty_mult_id,
      name: 'Get per-part setup cost for all parts',
      value: allPartsSetupCost,
      displayValue: `$${allPartsSetupCost.toFixed(2)}`,
      calculation,
    });

    // 7. Get single setup cost
    const setupTime = step.setupTime ?? 0;
    const singleSetupCost = getStep(has_setup_id).value * setupTime * runPrice; // actual calc

    calculation = doAltText(`${getStep(has_setup_id).value}`, '1 if part has setup');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${setupTime.toFixed(3)} hours`, 'Setup time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${runPrice.toFixed(2)}/hr`, 'Setup time');

    subitems.push({
      id: single_setup_id,
      name: 'Get per-part setup cost for all parts',
      value: singleSetupCost,
      displayValue: `$${singleSetupCost.toFixed(2)}`,
      calculation,
    });

    // 8. Get total setup cost
    const totalSetup = getStep(setup_qty_mult_id).value + getStep(single_setup_id).value; // actual calc

    calculation = doAltText(`${getStep(setup_qty_mult_id).displayValue}`, 'Per-part setup total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(single_setup_id).displayValue}`, 'Single setup total');

    subitems.push({
      id: setup_total_id,
      name: '<b>Get total setup costs</b>',
      value: totalSetup,
      displayValue: `$${totalSetup.toFixed(2)}`,
      calculation,
    });

    // 9. Check for inspection
    const hasInspection = step.hasInspection ? 1 : 0; // actual calc


    inspectionSubitems.push({
      id: has_inspection_id,
      name: 'Step has inspection?',
      value: hasInspection,
      displayValue: hasInspection === 1 ? 'Yes' : 'No',
    });

    // 10. Is inspection batched?
    const isBatched = step.inspectionIsBatched ? 1 : 0; // actual calc

    inspectionSubitems.push({
      id: has_batch_inspection_id,
      name: 'Step\'s inspection is batched?',
      value: isBatched,
      displayValue: isBatched === 1 ? 'Yes' : 'No',
    });

    // 11. Get unbatched inspection cost
    const inspectionTime = (step.inspectionTime ?? 0) / 60;
    const inspectionRate = step.inspectionRate ?? 0;
    const reverseBatchInspectionCoefficient = getStep(has_batch_inspection_id).value === 1 ? 0 : 1;
    const unbatchedCostPerPart = getStep(has_inspection_id).value * reverseBatchInspectionCoefficient * inspectionTime * inspectionRate; // actual calc

    calculation = doAltText(`${getStep(has_inspection_id).value}`, '1 if part has inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${reverseBatchInspectionCoefficient}`, '1 if inspection is not batched');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${inspectionTime.toFixed(3)} hours`, 'Inspection time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${inspectionRate.toFixed(2)}/hr`, 'Inspection rate');

    inspectionSubitems.push({
      id: unbatched_cost_id,
      name: 'Get per-part cost for unbatched inspection',
      value: unbatchedCostPerPart,
      displayValue: `$${unbatchedCostPerPart.toFixed(2)}`,
      calculation,
    });

    // 12. Get unbatched inspection cost
    const totalUnbatchedInspectionCost = getStep(unbatched_cost_id).value * quantity; // actual calc

    calculation = doAltText(`${getStep(unbatched_cost_id).displayValue}`, 'Unbatched inspection per-part cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantity}`, 'Quantity');

    inspectionSubitems.push({
      id: unbatched_qty_mult_id,
      name: 'Get total cost for all unbatched inspections',
      value: totalUnbatchedInspectionCost,
      displayValue: `$${totalUnbatchedInspectionCost.toFixed(2)}`,
      calculation,
    });

    // 13. Get inspection batch count
    const inspectionBatchSize = step.inspectionBatchSize || 1;
    const inspectionBatchCount = Math.ceil(quantity / inspectionBatchSize); // actual calc

    calculation = doAltText(`${quantity}`, 'Quantity');
    calculation += ' <b>&divide;</b> ';
    calculation += doAltText(`${inspectionBatchSize}`, 'Inspection batch size');
    calculation += ' (rounded up)';

    inspectionSubitems.push({
      id: batched_count_id,
      name: 'Get number of batches for batched inspection',
      value: inspectionBatchCount,
      displayValue: `${inspectionBatchCount}`,
      calculation,
    });

    // 14. Get inspection cost per batch
    const inspectionCostPerBatch = inspectionTime * inspectionRate; // actual calc

    calculation = doAltText(`${inspectionTime.toFixed(3)} hours`, 'Inspection time');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${inspectionRate.toFixed(2)}/hr`, 'Inspection cost');

    inspectionSubitems.push({
      id: batch_cost_id,
      name: 'Get cost per inspection batch',
      value: inspectionCostPerBatch,
      displayValue: `$${inspectionCostPerBatch.toFixed(2)}`,
      calculation,
    });

    // 15. Get batched inspection cost overall
    const inspectionBatchesCost = getStep(has_inspection_id).value * getStep(has_batch_inspection_id).value * getStep(batched_count_id).value * getStep(batch_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(has_inspection_id).value}`, '1 if step has inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(has_batch_inspection_id).value}`, '1 if step has batched inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(batched_count_id).value} batches`, 'Batch count');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${getStep(batch_cost_id).displayValue} per batch`, 'Per-batch cost');

    inspectionSubitems.push({
      id: batches_cost_id,
      name: 'Get cost for inspecting all batches',
      value: inspectionBatchesCost,
      displayValue: `$${inspectionBatchesCost.toFixed(2)}`,
      calculation,
    });

    // 16. Check first part inspection
    const firstPartInspectionCoefficient = step.hasFirstPartInspection ? 1 : 0; // actual calc

    inspectionSubitems.push({
      id: has_first_inspection_id,
      name: 'Part has first part inspection',
      value: firstPartInspectionCoefficient,
      displayValue: firstPartInspectionCoefficient === 1 ? 'Yes' : 'No',
    });

    // 17. Get first part inspection cost
    const firstPartInspectionTime = (step.firstPartInspectionTime ?? 0) / 60;
    const firstPartInspectionRate = step.firstPartInspectionRate ?? 0;
    const firstPartInspectionCost = getStep(has_first_inspection_id).value * firstPartInspectionTime * firstPartInspectionRate; // actual calc

    calculation = doAltText(`${getStep(has_first_inspection_id).value}`, '1 if step has first part inspection');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${firstPartInspectionTime.toFixed(3)} hours`, 'First part inspection hours');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`$${firstPartInspectionRate.toFixed(2)}/hr`, 'First part inspection rate');

    inspectionSubitems.push({
      id: first_inspection_cost_id,
      name: 'Get total cost for first part inspection',
      value: firstPartInspectionCost,
      displayValue: `$${firstPartInspectionCost.toFixed(2)}`,
      calculation,
    });

    // 18. Get total inspection cost
    const totalInspectionCost = getStep(unbatched_qty_mult_id).value + getStep(batches_cost_id).value + getStep(first_inspection_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(unbatched_qty_mult_id).displayValue}`, 'Unbatched inspection total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(batches_cost_id).displayValue}`, 'Batched inspection total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(first_inspection_cost_id).displayValue}`, 'First part inspection total');

    inspectionSubitems.push({
      id: total_inspection_cost_id,
      name: '<b>Get total inspection cost</b>',
      value: totalInspectionCost,
      displayValue: `$${totalInspectionCost.toFixed(2)}`,
      calculation,
    });

    if (options.inspectionOutput === 'separate' && hasInspection && getStep(total_inspection_cost_id).value > 0) {
      output.push({
        itemId: `${step.workflowStepId}--inspection`,
        name: `Inspection for ${name}`,
        price: getStep(total_inspection_cost_id).value,
        subitems: inspectionSubitems
      });
    }

    // 19. Get grand combined total
    const grandCombinedTotal = getStep(per_run_qty_mult_id).value + getStep(setup_total_id).value + getStep(total_inspection_cost_id).value; // actual calc

    calculation = doAltText(`${getStep(per_run_qty_mult_id).displayValue}`, 'Run total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(setup_total_id).displayValue}`, 'Setup total');
    calculation += ' <b>+</b> ';
    calculation += doAltText(`${getStep(total_inspection_cost_id).displayValue}`, 'Inspection total');

    subitems.push({
      id: grand_total_id,
      name: 'Combine run, setup, and inspection costs',
      value: grandCombinedTotal,
      displayValue: `$${grandCombinedTotal.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = getStep(grand_total_id).value;

    const itemId = step.workflowStepId;

    // Structure into breakdown item
    const main = { name, price, subitems, itemId };

    output.unshift(main);
    
    return output;
  }

  public static getOutsideProcessBreakdown(step: WorkflowStep, quantity: number, name: string): PricingBreakdownItem {
    let priceSoFar = 0;
    let calculation = '';
    const subitems: PricingBreakdownSubitem[] = [];

    // Define step ids
    const run_price_id = 'RUN_PRICE';
    const run_per_part_id = 'RUN_PER_PART';
    const quantity_mult_id = 'QUANTITY_MULT';
    const markup_pct_id = 'MARKUP_PCT';
    const markup_calc_id = 'CALC_MARKUP';
    const apply_markup_id = 'APPLY_MARKUP';

    // Shorter step getter
    const getStep = (id: string) => subitems.find(i => i.id === id);

    // 1. Base run price
    priceSoFar = step.runPrice || 0; // actual calc
    subitems.push({
      id: run_price_id,
      name: 'Base run price',
      value: priceSoFar,
      displayValue: `$${priceSoFar.toFixed(2)}`,
    });

    // 2. Check if recurring
    subitems.push({
      id: run_per_part_id,
      name: 'Price is per part?',
      displayValue: step.runIsPerPart ? 'Yes' : 'No'
    });

    // 3. Quantity multiply
    const quantityMultiplier = step.runIsPerPart ? quantity : 1;
    priceSoFar *= quantityMultiplier; // actual calc

    calculation = doAltText(getStep(run_price_id).displayValue, 'Base quoted price');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${quantityMultiplier}`, `${step.runIsPerPart ? 'Per-part' : 'Non-per-part'} quantity`);

    subitems.push({
      id: quantity_mult_id,
      name: 'Multiply by quantity',
      value: priceSoFar,
      displayValue: `$${priceSoFar.toFixed(2)}`,
      calculation,
    });

    // 4. Calculate markup
    const markupPercent = step.outsourceMarkup ?? 0.0;
    const markupDollars = priceSoFar * (markupPercent / 100.0); // actual calc

    calculation = doAltText(getStep(quantity_mult_id).displayValue, 'Base cost');
    calculation += ' <b>&times;</b> ';
    calculation += doAltText(`${markupPercent}%`, `Total markup`);

    subitems.push({
      id: markup_pct_id,
      name: 'Markup Rate',
      value: markupPercent,
      displayValue: `${markupPercent}%`,
      calculation,
    });

    subitems.push({
      id: markup_calc_id,
      name: 'Markup',
      value: markupDollars,
      displayValue: `$${markupDollars.toFixed(2)}`,
      calculation,
    });

    // 5. Add markup
    priceSoFar += getStep(markup_calc_id).value; // actual calc

    calculation = doAltText(getStep(quantity_mult_id).displayValue, 'Base cost');
    calculation += ' <b>+</b> ';
    calculation += doAltText(getStep(markup_calc_id).displayValue, 'Total markup');

    subitems.push({
      id: apply_markup_id,
      name: 'Apply markup',
      value: priceSoFar,
      displayValue: `$${priceSoFar.toFixed(2)}`,
      calculation,
    });

    // Finalize price
    const price = priceSoFar;

    const itemId = step.workflowStepId;

    return {name, price, subitems, itemId};
  }

  public static getBreakdown(step: WorkflowStep, quantity: number, name: string, options: WorkflowStepBreakdownOptions): PricingBreakdownItem[] {
    if (step.paint) return [WorkflowStep.getPaintStepBreakdown(step, quantity, name)];
    else if (step.outsourceMarkup) return [WorkflowStep.getOutsideProcessBreakdown(step, quantity, name)];
    else if (!step.outsourceMarkup) return WorkflowStep.getLaborStepBreakdown(step, quantity, name, options);
  }

  public static getPriceFromBreakdown(step: WorkflowStep, quantity: number): number {
    const { price } = WorkflowStep.getBreakdown[0](step, quantity, '');
    return price;
  }

}
