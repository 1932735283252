import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MicroTicket, MicroTicketStatus } from '../../resources/microticket';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/resources/user';
import { MicroTicketService } from '../../services/microticket.service';
import { Observable, combineLatest, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { UtilityService } from '../../../common/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from '../../../common/services/document.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'microticket-card',
  templateUrl: './microticket-card.component.html',
  styleUrls: ['./microticket-card.component.less']
})
export class MicroticketCardComponent implements OnInit {

  @Input() ticket: MicroTicket;
  @Input() subItemTitle?: string;
  @Input() subItemName?: string;
  @Output() subItemNavigate = new EventEmitter<void>();

  @Input() userNames: Observable<Pick<User, 'userId' | 'fullName'>[]> = of([]);

  @Input() existingDocuments: Observable<VirtualDocument[]> | null = null;

  constructor(private userSvc: UserService, private dialog: MatDialog) { }

  public filteredDocuments: Observable<VirtualDocument[]>;
  public docFilterControl = new FormControl<string>('');

  ngOnInit(): void {
    if (this.ticket && this.ticket.comments) {
      this.ticket.comments = this.ticket.comments.sort((a, b) => 
        (new Date(b.createdDate).getTime()) - (new Date(a.createdDate).getTime())
      )
    }
    if (this.existingDocuments) this.filteredDocuments = combineLatest([this.existingDocuments, this.docFilterControl.valueChanges.pipe(startWith(''))]).pipe(map(([docs, filter]) => docs.filter(
      doc => {
        if (!filter?.trim()) return true;
        return doc.name.toLowerCase().includes(filter.toLowerCase());
      })))
  }

  public isAssignedToMe() {
    return this.userSvc.userData?.userId === this.ticket?.assignedUserId;
  }

  getUserName(user: User) {
    if (!user) return 'Unknown User';
    if (this.userSvc.userData?.userId === user.userId) return 'you';
    else return user.fullName;
  }

  @Output() edit = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @Output() jumpToSubitem = new EventEmitter<void>();
  @Output() markInProgress = new EventEmitter<void>();
  @Output() markComplete = new EventEmitter<void>();
  @Output() reopen = new EventEmitter<void>();
  @Output() newComment = new EventEmitter<string>();

  public getStatusText(status: MicroTicketStatus) {
    return MicroTicketService.getStatusText(status);
  }

  public getStatusColorClass(status: MicroTicketStatus) {
    return MicroTicketService.getStatusColorClass(status);
  }

  public isAddingComment = false;
  public newCommentText = '';

  public newCommentCancel(event) {
    event.stopPropagation();
    this.isAddingComment = false;
    this.newCommentText = '';
  }

  @ViewChild('commentsPanel') commentsPanel: MatExpansionPanel;
  public newCommentSubmit(event) {
    event.stopPropagation();
    this.isAddingComment = false;
    this.newComment.emit(this.newCommentText);
    this.newCommentText = '';
    if (this.commentsPanel) this.commentsPanel.open();
  }

  public getCCUserName(id: string) {
    return this.userNames.pipe(
      map((users) => users.find(u => u.userId === id)),
      map((user) => user?.fullName ?? 'Unknown User')
    )
  }

  @Output() addFiles = new EventEmitter<VirtualDocument[]>();

  @ViewChild('documentsPanel') documentsPanel: MatExpansionPanel;
  @ViewChild('newFileUploadDialog') newFileUploadDialog: TemplateRef<any>; 
  public async uploadFilesToTicket() {
    const dialog = this.dialog.open<any, any, VirtualDocument[] | null>(this.newFileUploadDialog, {
      disableClose: true,
      minHeight: '500px',
      minWidth: '50vw',
      data: {
        documents: []
      }
    });
    const documents = await dialog.afterClosed().toPromise();
    if (!documents) return;
    this.addFiles.emit(documents);
    if (this.documentsPanel) this.documentsPanel.open();
  }

  @ViewChild('existingFileLinkDialog ') existingFileLinkDialog: TemplateRef<any>; 
  public async linkFilesToTicket() {
    const dialog = this.dialog.open<any, any, VirtualDocument[] | null>(this.existingFileLinkDialog , {
      disableClose: true,
      minHeight: '500px',
      minWidth: '50vw',
      data: {
        documents: []
      }
    });
    const documents = await dialog.afterClosed().toPromise();
    if (!documents) return;
    this.addFiles.emit(documents);
    if (this.documentsPanel) this.documentsPanel.open();
  }
  
  public openDocument(doc: VirtualDocument) {
    window.open(`/document/${doc.documentId}` + ('/?inline=true'), "_blank")
  }

  public addLinkedDoc(data: { documents: VirtualDocument[] }, doc: VirtualDocument) {
    data.documents = [...data.documents, doc];
    this.docFilterControl.setValue('');
  }
  public removeLinkedDoc(data: { documents: VirtualDocument[] }, doc: VirtualDocument) {
    data.documents = data.documents.filter(d => d.documentId !== doc.documentId);
  }
}
