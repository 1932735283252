import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../services/message.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/errorHandler.service'
import { httpService } from './http.service';
import { SearchResult } from '../resources/search-result';
import { Address } from '../resources/address';
import { PurchasingRfqRequest, PurchasingRfqRequestStatus } from '../resources/purchasing-rfq-request';
import { MessageType } from '../resources/message';
import { Vendor } from '../../supplier/resources/vendor';
import { VirtualDocument } from '../resources/virtual-document';
import { MaterialBid } from '../../purchasing/resources/materialBid';

export type PurchasingRfqRequestItemType = 'material' | 'outsourced' | 'hardware';

@Injectable({
  providedIn: 'root',
})
export class PurchasingRfqRequestService extends httpService {
  private apiBase: string = 'api/';
  private apiUrl: string;

  constructor(errorHandler: ErrorHandlerService, private messages: MessageService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    super(errorHandler, messages);
    this.serviceName = "Address";

    this.apiUrl = this.baseUrl + this.apiBase;
  }

  public createRequest(type: PurchasingRfqRequestItemType, itemId: string, quantityRequested: number, dueDate?: Date, sourceEstimateId?: string, sourcePurchaseOrderId?: string): Observable<PurchasingRfqRequest> {
    return this.http.post<PurchasingRfqRequest>(this.baseUrl + 'api/purchasingRfqRequest/create', {
        type,
        itemId,
        quantityRequested,
        dueDate,
        sourceEstimateId,
        sourcePurchaseOrderId
    }).pipe(
        tap(_ => this.messages.add("Quoting Request Submitted Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<PurchasingRfqRequest>("Create Quoting Request", null))
      );

  }

  public search(searchString?: string, page?: number, sortBy?: string, sortDirection?: string): Observable<SearchResult<PurchasingRfqRequest>> {
    return this.http.get<SearchResult<PurchasingRfqRequest>>(this.baseUrl + 'api/purchasingRfqRequest/search', { params: { pageSize: 14, searchText: searchString, pageIndex: (page || 0).toString(), orderBy: sortBy || "name", direction: sortDirection || "asc" } }).pipe(
      catchError(this.handleError<any>("Get Procurement Search Results", null))
    );
  }

  public get(id: string): Observable<PurchasingRfqRequest> {
    return this.http.get<SearchResult<PurchasingRfqRequest>>(this.baseUrl + 'api/purchasingRfqRequest/' + id).pipe(
      catchError(this.handleError<any>("Get Procurement Item", null))
    );
  }

  public getRFQDocument(item: PurchasingRfqRequest, vendor: Vendor): Observable<VirtualDocument> {
    return this.http.get<Document>(this.baseUrl + `api/purchasingRfqRequest/print/${item.purchasingRfqRequestId}/${vendor.vendorId}`).pipe(
      catchError(this.handleError<any>("Get RFQ Document", null))
    );
  }

  public acceptQuote(item: PurchasingRfqRequest, quote: MaterialBid): Observable<void> {
    return this.http.post<void>(this.baseUrl + `api/purchasingRfqRequest/acceptQuote/${item.purchasingRfqRequestId}/${quote.materialBidId}`, null).pipe(
        tap(_ => this.messages.add("Quote Accepted Successfully", MessageType.SUCCESS, true)),
        catchError(this.handleError<void>("Accept Quote", null))
      );
  }

  public updateStatus(item: PurchasingRfqRequest, status: PurchasingRfqRequestStatus): Observable<void> {
    return this.http.post<void>(this.baseUrl + `api/purchasingRfqRequest/updateStatus/${item.purchasingRfqRequestId}`, status).pipe(
        catchError(this.handleError<void>("Update Quoting Request Status", null))
      );

  }

}
