<div class="position-relative rounded" style="
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div id="filter-bar">
    <mat-form-field appearance="outline" class="mat-input-no-message dense-field" id="search-field" #searchField>
      <mat-label>Text filter</mat-label>
      <mat-icon matPrefix class="text-muted">search</mat-icon>
      <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchChange()" #searchInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-input-no-message" id="daterange-field" #rangeField>
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="since" (keyup.enter)="onSearchChange()" placeholder="Start date">
        <input matEndDate [(ngModel)]="until" (keyup.enter)="onSearchChange()" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button
      (click)="onSearchChange()"
      mat-flat-button
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading"
    >
      Apply
    </button>
    <button
      mat-flat-button
      (click)="clearSearch()"
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading || (searchText === '' && since === null && until === null)"
    >
        Clear
      </button>
  </div>

  <div id="aggregate-total">
    Total: {{ $filteredSum | async | currency }}
  </div>

  <mat-table
    #dataTable
    [dataSource]="dataSource"
    matSort
    class="w-100 flex-grow-1"
    [class.loading]="loading"
  >
    <mat-header-row *matHeaderRowDef="rowDisplayedColumns"></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: ['groupHeader']; when: rowIsHeader"
      class="oor-row"
    ></mat-row>

    <mat-row
      *matRowDef="let row; columns: rowDisplayedColumns;"
      [routerLink]="'/floor/upcoming/' + row.productId"
      class="oor-row oospr-data-row"
    ></mat-row>

    <div class="mat-row" *matNoDataRow>
      <div class="mat-cell"
        [attr.colspan]="rowDisplayedColumns.length"
        class="text-muted small font-italic text-center"
        *ngIf="!loading"
      >
        No open orders matching the parameters were found.
      </div>
    </div>

    <ng-container matColumnDef="groupHeader">
      <mat-cell *matCellDef="let row" class="oospr-header-row">
        <div>
          <b>{{ row.groupName }}</b>
        </div><div>
          <b>{{ row.lines }}</b> orders
        </div><div>
          <b>{{ row.total | currency }}</b>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customer">
      <mat-header-cell *matHeaderCellDef mat-sort-header="customer" style="flex-basis: 5%"> Customer </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%">
        <!-- header only -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderDate">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%"> Order Date </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%">
        {{ row.orderDate | date:'dd-MMM-yy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderNumber">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 18%"> WO # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 18%">
        {{ row.workOrderNumber }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partNumber">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 10%; padding-left: 1em;"> Part # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 10%; padding-left: 1em;">
        {{ row.partNumber }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="revision">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Rev </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
        {{ row.revision }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="purchaseOrderNumber">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 10%"> PO # </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 10%">
        {{ row.purchaseOrderNumber }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Qty </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
        {{ row.quantity }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unitPrice">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Unit Price </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
        {{ row.unitPrice | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%"> Due </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%">
        {{ row.dueDate | date:'dd-MMM-yy' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extPrice">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 5%; padding-left: 1em;"> Unit Price </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 5%; padding-left: 1em;">
        {{ row.extPrice | currency }}
      </mat-cell>
    </ng-container>

  </mat-table>

  <mat-paginator
    [pageSizeOptions]="[15]"
    [length]="dataLength"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    style="position: sticky; bottom: -1px;"
  ></mat-paginator>
</div>