import { Address } from "../../common/resources/address";
import { Customer } from "../../customer/resources/customer";
import { WorkOrder } from "../../planning/resources/work-order";
import { Order, QuoteLineItem } from "./order";
import { Product } from "./product";
import { Station } from "./station";


export enum CustomerPurchaseOrderType {
    STANDARD = 0,
    BLANKET = 1,
    LTA = 2,
    RELEASE = 3
}

export interface CustomerPurchaseOrder {
    customerPurchaseOrderId: string;
    purchaseOrderNumber: string;

    customerId: string;
    customer: Customer;

    quoteId: string;
    order: Order;
    quote: Order;

    shipToAddressId: string;
    shipToAddress: Address;

    lineItems: CustomerPurchaseOrderLineItem[]

    documentId: string;

    type: CustomerPurchaseOrderType;

    releases: CustomerPurchaseOrder[];

    releaseForPOId?: string;
    releaseForPO?: CustomerPurchaseOrder;

}

export interface CustomerPurchaseOrderLineItem {
    customerPurchaseOrderLineItemId: string

    customerPurchaseOrderId: string
    customerPurchaseOrder: CustomerPurchaseOrder;

    lineItemNumber: string

    productId?: string;
    product: Product;

    stationId?: string;
    station: Station;

    quantity: number;
    unitPrice: number;
    extPrice: number;

    dueDate: Date;

    workOrderId?: string;
    workOrder: WorkOrder;

    quoteLineItemId?: string;
    quoteLineItem: QuoteLineItem;

    originalLineItemId?: string;
}