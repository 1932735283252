import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';

type ApiData = {
  datePlaced: Date;
  category: string;
  price: number;
}

type RowData = {
  category: string;
  categoryTotal: number;
  categoryPercent: number;
}

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'purchasing-categories-report',
  templateUrl: './purchasing-categories-report.component.html',
  styleUrls: ['./purchasing-categories-report.component.less'],
})

export class PurchasingCategoriesReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  private allPurchases: ApiData[];

  // The current content of the filters
  public since: Date = null;
  public until: Date = null;

  // Header row total
  public $filteredSum = new BehaviorSubject(0);

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Purchasing per category');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<RowData>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'categoryPercent', start: 'desc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getPurchasingCategories().subscribe(data => {
      this.allPurchases = data;
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: ApiData[]): RowData[] {
    const rows: RowData[] = [];
    let grandTotalPrice = 0;

    for (const data of apiData) {
      data.datePlaced = new Date(data.datePlaced);
      grandTotalPrice += data.price;

      // If there's no row for this customer, create one
      const row = rows.find(r => r.category === data.category);
      if (row === undefined) {
        rows.push({
          category: data.category,
          categoryTotal: data.price,
          categoryPercent: 0,
        });
      }

      // If there is a row, add to the sales
      else {
        row.categoryTotal += data.price;
      }
    }

    this.$filteredSum.next(grandTotalPrice);

    // All in place, now calculate shares
    for (const row of rows) {
      row.categoryPercent = row.categoryTotal / grandTotalPrice;
    }

    return rows;
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    return pageData;
  }

  // Search data
  public searchText = '';
  search = (data: RowData, filterJson: string) => {
    return data.categoryTotal > 0;
  }

  updateRowsWithFilter(filter: SearchFilter = null) {
    filter ??= {
      text: '',
      since: this.minDate,
      until: this.maxDate,
    };

    let grandTotal = 0;
    for (const row of this.dataSource.data) {
      row.categoryTotal = this.allPurchases
        .filter(d => d.category === row.category && d.category.toLowerCase().includes(filter.text.toLowerCase()))
        .filter(d => d.datePlaced >= filter.since && d.datePlaced <= filter.until)
        .reduce((sum, d) => sum += d.price, 0);
      grandTotal += row.categoryTotal;
    }

    for (const row of this.dataSource.data) {
      row.categoryPercent = row.categoryTotal / grandTotal;
    }

    this.$filteredSum.next(grandTotal);
  }

  onSearchChange() {
    const filter: SearchFilter = {
      text: this.searchText,
      since: this.since ?? this.minDate,
      until: this.until ?? this.maxDate,
    };
    this.updateRowsWithFilter(filter)
    this.dataSource.filter = JSON.stringify(filter);
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'category',
      'categoryTotal',
      'categoryPercent',
    ];
  }

}
