import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Product, ProductDocument, ProductQuantity } from '../../../resources/product';
import { EstimatingTaskStatus, Order, OrderProduct } from '../../../resources/order';
import { OrderDetailService, fetchLatest } from '../order-detail.service';
import { VirtualDocument } from '../../../../common/resources/virtual-document';
import { TaskStatus } from '../../../../common/resources/estimatingtask';
import { ProductHierarchySortComponent } from '../../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component';
import { AddProductDocumentChange, DeleteProductDocumentChange, UpdateChange } from '@cots/common/autosaving/change';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID, ESTIMATING_CONTRACT_REVIEW_QUESTIONS } from './product-estimating-review/product-estimating-review.component';

@Component({
  selector: 'product-detail-new',
  templateUrl: './product-detail-new.component.html',
  styleUrls: ['./product-detail-new.component.less']
})
export class ProductDetailNewComponent implements AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService
  ) { }

  public get editing() { return this.service.editing }

  private generateFragment(tab: number = null) {
    // TODO: Fix this
    // if (!this.product) return '';
    // switch (tab ?? this.selectedTab) {
    //   case 0:
    //   case 1:
    //     return this.service.generateSubItemNavigationId(this.product.productId, "product");
    //   case 2:
    //     return this.service.generateSubItemNavigationId(this.product.productId, "material");
    //   case 3:
    //     return this.service.generateSubItemNavigationId(this.product.productId, "workflow", this.service.selectedWorkflowStepId);
    //   case 4:
    //     return this.service.generateSubItemNavigationId(this.product.productId, "hardware", this.service.selectedPurchasedItemId);
    // }
  }

  @ViewChild('hierarchySort') hierarchySort: ProductHierarchySortComponent;
  ngAfterViewInit(): void {
    // Update the fragment
    // TODO: Fix this
    // window.location.hash = this.generateFragment();
  }

  ngOnDestroy(): void {
    window.location.hash = '';
  }

  @Input() selectedTab = 0;
  @Output() selectedTabChange = new EventEmitter<number>();
  public onSelectedTabChange(tab: number) {
    // TODO: Fix this
    // window.location.hash = this.generateFragment(tab);
    this.selectedTabChange.emit(tab);
  }

  public convertDocuments(docs: ProductDocument[]): VirtualDocument[] {
    return docs.map(d => d.document);
  }

  public async onDocumentsAdded(docs: VirtualDocument[]) {
    const productId = await fetchLatest(this.service.selectedProductIdSubject);
    const changes: AddProductDocumentChange[] = docs.map(c => ({
             changeType: 'ADD_PRODUCT_DOCUMENT',
      entity: null,
      data: {
        productId,
        documentId: c.documentId,
        documentData: c
      }
    }));
    this.service.recordChanges(...changes);
  }

  public async onDocumentDeleted(doc: VirtualDocument) {
    const productId = await fetchLatest(this.service.selectedProductIdSubject);
    const change: DeleteProductDocumentChange = {
             changeType: 'DELETE_PRODUCT_DOCUMENT',
      entity: null,
      data: {
        productId,
        documentId: doc.documentId,
        documentData: doc
      }
    };
    this.service.recordChanges(change);
  }

  public onDocumentTagsUpdated({ document, newTags }: { document: VirtualDocument, newTags: string[] }) {
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Document',
      data: {
        itemId: document.documentId,
        field: 'tags',
        oldValue: document.tags,
        newValue: newTags,
      }
    }
    this.service.recordChanges(change);
  }

  public getSimpleTaskStatus(product: Product, taskType: 'material' | 'workflow' | 'hardware') {
    return this.service.getEstimatingTaskStatus(product, taskType);
  }

  public getSubassembliesStatus(productId: string, allProducts: Product[]) {
    const descendants = this.service.getAllDescendants(productId, allProducts);
    const allStatuses = descendants.flatMap(p => [p.materialStatus, p.workflowStatus, p.hardwareStatus]);
    if (allStatuses.every(s => EstimatingTaskStatus.countsAsDone(s))) return TaskStatus.DONE;
    if (allStatuses.some(s => EstimatingTaskStatus.countsAsInProgress(s))) return TaskStatus.IN_PROGRESS;
    else return TaskStatus.NOT_STARTED;
  }

  
  public getMicroTaskStatusClass(status: TaskStatus) {
    return EstimatingTaskStatus.getStatusColorClass(status);
  }

  public getProductQuantityData(productId: string): Observable<{ totalQty: number, quantitiesMap: ProductQuantity[ ]}> {
    return this.service.autosaver.getPostChanges('productMap').pipe(
      map((productsMap) => {
        const product = productsMap[productId];

        // Find top-level quantity
        let parent = product;
        while (parent?.parentAssemblyId !== null)
          parent = productsMap[parent.parentAssemblyId];

        const topQty = this.service.getFirstQuantity(parent);

        return {
          totalQty: this.service.getTotalQuantity(Object.values(productsMap), productId, topQty),
          quantitiesMap: product.parentAssemblyId ? [] : (product.quantitiesMap ?? []),
        }
      }),
    )
  }

  public estimateReviewTotal = ESTIMATING_CONTRACT_REVIEW_QUESTIONS.length;
  public countAnsweredEstimateReviewQuestions(order: Order, productId: string) {
    const validAnswers = Object.values(order.estimateReviewAnswers?.[productId]?.[ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID] ?? {})
      .filter(ans => !!ans);
    return validAnswers.length;
  }
}
