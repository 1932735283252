<microticket-list
    *ngIf="service.postChangesOrder$ | async as record"
    [relatedTicketId]="record.salesProcessId"
    [relatedTicketName]="service.getNameForMicrotickets(record)"
    [relatedTicketType]="'SalesProcess'"
    [filterKey]="service.getMicroticketFilterKey(record)"
    [subItems]="partNumbers"
    [existingDocuments]="documents"
    [subItemName]="'Associated Part/Section'"
    [subItemDisplayWith]="partNumberDisplayWith.bind(this)"
    [subItemCompareWith]="partNumberCompareWith.bind(this)"
    (loadingChange)="service.loading = $event"
    (jumpToSubitem)="jumpToSubitem($event)"
    [subject]="service.sharedMicroticketSubject"
></microticket-list>
