<div class="p-3 w-100 h-100-t pt-6 position-relative">
  <div class="mat-card-label">Documents</div>
  <div class="mat-card-outline rounded w-100 h-100-t d-flex flex-wrap" style="gap: 1.5rem"
  
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="$event.preventDefault()"
  (drop)="onDrop($event)"
  >
    <div class="drop-overlay" *ngIf="dragging">
      <mat-icon svgIcon="file-multiple" class="text-muted" style="height: 56px; width: 48px; font-size: 48px; line-height: 56px;"></mat-icon>
      <div class="text-muted font-italic mat-small-header mt-2">
        <span>Drop files to upload.</span>
      </div>
    </div>
    <button *ngIf="editing" mat-fab class="extended-fab-button extended-fab-small button-text bg-success" color="primary" style="position: absolute; top: 1em; right: 1em; z-index: 10"
      (click)="browse()"
    >
      <mat-icon svgIcon="file-plus-outline"></mat-icon>
      Upload File
    </button>
    <mat-table class="w-100" [dataSource]="documents" [trackBy]="trackByFn">
      <ng-container matColumnDef="icon">
        <mat-header-cell *matHeaderCellDef class="icon-cell">&nbsp;</mat-header-cell>
        <mat-cell *matCellDef="let doc" class="icon-cell cursor-pointer" (click)="openDocument(doc)">
          <mat-icon [svgIcon]="getFileIcon(doc)"></mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="filename">
        <mat-header-cell *matHeaderCellDef>File</mat-header-cell>
        <mat-cell *matCellDef="let doc" class="doc-filename-cell" (click)="openDocument(doc)">
          <span>{{ doc.name }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="filetype">
        <mat-header-cell *matHeaderCellDef class="type-cell">Type</mat-header-cell>
        <mat-cell *matCellDef="let doc" class="type-cell">
          {{ getFriendlyFiletype(doc) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
        <mat-cell *matCellDef="let doc">
          <mat-form-field appearance="outline" class="w-100 dense-field" floatLabel="always">
            <mat-chip-list class="tag-chip-list" #chipList [required]="required">
              <mat-chip
                *ngFor="let tag of doc.tags; index as i"
                [removable]="editing"
                (removed)="removeTag(doc, i)"
                selected
                class="tiny-chip"
                >
                {{tag}}
                <button matChipRemove *ngIf="editing">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-chip>
              <input
                [class.d-none]="!editing"
                [readonly]="!editing"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addTag(doc, $event)"
                >
            </mat-chip-list>
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef class="delete-cell">&nbsp;</mat-header-cell>
        <mat-cell *matCellDef="let doc" class="delete-cell">
          <button mat-icon-button  (click)="deleteDocument(doc)" *ngIf="editing"><mat-icon>close</mat-icon></button>
        </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="showTags ? ['icon', 'filename', 'filetype', 'tags', 'delete']: ['icon', 'filename', 'filetype', 'delete']" class="mb-3"></mat-header-row>
      <mat-row *matRowDef="let row; columns: showTags ? ['icon', 'filename', 'filetype', 'tags', 'delete']: ['icon', 'filename', 'filetype', 'delete']"></mat-row>
    </mat-table>
  </div>
  <input type="file" multiple class="d-none" #fileInput (change)="onBrowseDone(fileInput.files)" />
</div>