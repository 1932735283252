import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { UtilityService } from '../../../common/services/utility.service';
import { SearchList } from '../../../common/components/search-list/search-list';
import { UserService } from '../../../common/services/user.service';
import { QualityTicket, QualityTicketType } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { FilterParam, SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { User } from '../../../common/resources/user';
import { OrderStatus } from '../../../order/resources/order';

@Component({
  selector: 'quality-ticket-list',
  templateUrl: './quality-ticket-list.component.html',
  styleUrls: ['./quality-ticket-list.component.less']
})
export class QualityTicketListComponent {

  constructor(navService: NavigationService, private service: QualityService, private router: Router, private userSvc: UserService) {
    navService.setPageTitle("Quality");
  }

  public filterTypes: QualityTicketType[] = [QualityTicketType.ContractReview, QualityTicketType.ReceivedShippingTicket, QualityTicketType.SupplierCertExpiration, QualityTicketType.RMAEvaluation, QualityTicketType.RMAApproval];

  public search({ searchText, page, sort, forAllUsers, filters }: SearchData) {
    return this.service.search(forAllUsers, searchText, page.pageIndex, sort.active, sort.direction, filters);
  }

  onSelect(record: QualityTicket): void {
    // This would crash anyway
    if (record.ticketType == QualityTicketType.ContractReview && record.orderSegment == null)
      return;

    this.router.navigate(['/quality/', this.getTypeUrl(record.ticketType), record.qualityTicketId]);
  }

  weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  getTypeColorClass(ticketType: QualityTicketType): string {
    if (ticketType === QualityTicketType.ContractReview) {
      return 'bg-info text-white';
    } else if (ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'bg-success text-white';
    } else if (ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'bg-danger text-white';
    } else if (ticketType === QualityTicketType.RMAEvaluation) {
      return 'bg-warning text-dark';
    } else if (ticketType === QualityTicketType.RMAApproval) {
      return 'bg-info text-white';
    }
  }

  getTypeIcon(ticketType: QualityTicketType): string {
    if (ticketType === QualityTicketType.ContractReview) {
      return 'receipt_long';
    } else if (ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'local_shipping';
    } else if (ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'approval';
    } else if (ticketType === QualityTicketType.RMAEvaluation) {
      return 'assignment_returned';
    } else if (ticketType === QualityTicketType.RMAApproval) {
      return 'assignment_returned';
    }
  }

  getTypeText(ticketType: QualityTicketType): string {
    if (ticketType === QualityTicketType.ContractReview) {
      return 'Contract Review';
    } else if (ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'Received Shipment';
    } else if (ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'Supplier Cert Expiring';
    } else if (ticketType === QualityTicketType.RMAEvaluation) {
      return 'RMA Disposition';
    } else if (ticketType === QualityTicketType.RMAApproval) {
      return 'RMA Request';
    }
  }


  getTypeUrl(ticketType: QualityTicketType) {
    if (ticketType === QualityTicketType.ContractReview) {
      return 'contract';
    } else if (ticketType === QualityTicketType.ReceivedShippingTicket) {
      return 'received';
    } else if (ticketType === QualityTicketType.SupplierCertExpiration) {
      return 'cert-expire';
    } else if (ticketType === QualityTicketType.RMAEvaluation) {
      return 'rma';
    } else if (ticketType === QualityTicketType.RMAApproval) {
      return 'rma-approve';
    }
  }

  public getSubticketNumber(ticket: QualityTicket): string
  {
    if (ticket.ticketType === QualityTicketType.ContractReview) {
      let poNumber = (ticket.orderSegment == null ? '<removed>' : ticket.orderSegment.orderPurchaseOrder ? ticket.orderSegment.orderPurchaseOrder.purchaseOrderNumber : ticket.orderSegment.orderNumber);
      let aqnNumber = ticket?.orderSegment.orderPurchaseOrder?.quote?.orderNumber ?? '<removed>';
      return `${poNumber} (${aqnNumber})`;
    } 
    else if (ticket.ticketType === QualityTicketType.ReceivedShippingTicket) return ShippingService.getTicketNumber(ticket.shippingTicketPlaceLocation.shippingTicket);
    else if (ticket.ticketType === QualityTicketType.SupplierCertExpiration) return `${ticket.vendor.name} - ${ticket.vendorCertificate?.certificateName}`;
    else if (ticket.ticketType === QualityTicketType.RMAEvaluation) return ticket.rmaTicket.requestNumber ?? ticket.rmaTicket.rmaNumber;
    else if (ticket.ticketType === QualityTicketType.RMAApproval) return ticket.rmaTicket.rmaNumber ?? ticket.rmaTicket.requestNumber;
    else return "";
  }

  public hasPermission(moduleName: string): boolean {
    return this.userSvc.canAccess(moduleName);
  }

  public userIsManager(user: User): boolean {
    // TODO: Make this manager
    return this.userSvc.canAccess('QAUser', user);
  }

  public readonly availableStatuses = [OrderStatus.HISTORY, OrderStatus.UNASSIGNED, OrderStatus.IN_PROCESS, OrderStatus.REJECTED, OrderStatus.APPROVED] as const;
  public readonly ticketTypes =  Object.values(QualityTicketType).filter(k => typeof k === "number") as number[];

  public readonly filterParams: FilterParam[] = [
    {
      categoryName: 'status',
      categoryTitle: 'Status',
      options: this.availableStatuses.map(s => ({
        class: OrderStatus.getStatusColorClassChip(s),
        title: OrderStatus.getStatusText(s),
        value: s
      }))
    },
    {
      categoryName: 'type',
      categoryTitle: 'Ticket Type',
      options: this.ticketTypes.map(s => ({
        class: this.getTypeColorClass(s),
        title: this.getTypeText(s),
        value: s
      }))
    },
  ];

  public readonly defaultStatuses = [OrderStatus.UNASSIGNED, OrderStatus.IN_PROCESS] as const;

  public readonly defaultFilters = { status: this.defaultStatuses, type: [] }

  getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public getHighlightClass(due: string, now: any) {
    if (!due) {
      return '';
    }

    const target = new Date(due);
    if (!now) now = new Date();
    else now = new Date(now);
    const daysBetween = (+target - +now) / (24*60*60*1000);

    if (daysBetween < 1) {
      return 'bg-danger text-white';
    } else if (daysBetween < 3) {
      return 'bg-warning';
    }

    return '';
  }

}
