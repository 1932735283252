import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, Inject, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Customer, CustomerContact } from '../../../customer/resources/customer';
import { Order, OrderDocument, OrderProduct, OrderSegmentProductReviewStatus, OrderStatus } from '../../resources/order';
import { OrderService } from '../../services/order.service';
import { Product } from '../../resources/product';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { OrderDetailService, fetchLatest } from './order-detail.service';
import { RFQDoneConfirmationDialog, RejectReasoningDialog, StatusConfirmationDialog } from '../order-detail/order-detail.component';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { ProductHierarchySortComponent } from '../../../planning/components/product-hierarchy-sort/product-hierarchy-sort.component';
import { distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { StationService } from '../../services/station.service';
import { humanFileSize } from '../../../../../util/humanFileSize';
import { ProductTimingService } from '../../services/product-timing.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { MatSidenav } from '@angular/material/sidenav';
import { AddOrderDocumentChange, DeleteOrderDocumentChange, UpdateChange } from '@cots/common/autosaving/change';

@Component({
  selector: 'order-detail-new',
  templateUrl: './order-detail-new.component.html',
  styleUrls: ['./order-detail-new.component.less'],
  providers: [OrderDetailService, ProductTimingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailNewComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private orderService: OrderService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavigationService,
    private utilitySvc: UtilityService,
    private userSvc: UserService,
    public service: OrderDetailService,
    private stationService: StationService,
    private cdr: ChangeDetectorRef
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  public get id() {
    return this.service.id;
  }
  public set id(val: string) {
    this.service.id = val;
  }
  public get selectedTab() {
    return this.service.mainTab;
  }
  public set selectedTab(val: number) {
    this.service.mainTab = val;
  } 
  public get productSelectedTab() {
    return this.service.productTab;
  }
  public set productSelectedTab(val: number) {
    this.service.productTab = val;
  } 

  public set loading(item: boolean) { this.service.loading$.next(item) }

  public get editing() { return this.service.editing }
  public set editing(value: boolean) { this.service.editing = value }

  @Output() soloClose = new EventEmitter<{ product: Product, quotes: { [key: string]: MaterialBid[] } }>();
  private async getDetail() {
    this.service.resetOrder(null);
    if (this.soloOrder && this.soloProduct) {
      this.service.initSolo(this.soloProduct, this.soloOrder, this.soloClose);
      this.navService.pushBreadcrumb(`${this.soloProduct.partNumber} Rev. ${this.soloProduct.revision}`);
    }
    else if (this.id == "new") {
      this.editing = true;
      const u = await this.userSvc.userReplaySubject.pipe(take(1)).toPromise();
      if (!u || !u.userId) return;
      this.service.resetOrder(<Order>{
        orderSegmentId: UtilityService.emptyGuid,
        status: 0,
        discriminator: "RFQ",
        assignedTo: this.userSvc.userData.userId, //If you're creating it, you don't need to assign it to yourself
        assignedUser: this.userSvc.userData,
        documents: [],
        products: [],
        createdDate: new Date(),
        creatorId: this.userSvc.userData.userId,
        creator: this.userSvc.userData,
      }, true);
      this.service.autosaver.resetDomain('quoteMap', {});
      this.service.autosaver.resetDomain('productMap', {});
      this.cdr.detectChanges();
    } else {
      this.loading = true;
      this.stationService.getStationList();
      const detail = await this.orderService.getDetail(this.id).toPromise();
      const products = await this.orderService.getAllProductsFlat(detail.orderSegmentId).toPromise();
      this.service.resetProducts(products);
      this.service.resetOrder(detail);
      this.service.initStartingQuotes(detail);

      this.loading = false;

      await this.service.initLeadTimes();
      this.route.fragment.pipe(take(1)).subscribe(fragment => {
        if (fragment) this.service.navigateToSubItem(fragment);
      });
    }
    fetchLatest(this.service.postChangesOrder$.pipe(filter(o => !!o))).then(r => this.service.setPageTitle(r));
  }


  public isEditable(record: Order): boolean {
    if (record.discriminator === 'RFQ' && record.status === OrderStatus.AWAITING_REVIEW && this.service.canApproveRFQ(record)) return true;
    return (
      !(record.discriminator === 'RFQ' && record.status === OrderStatus.AWAITING_REVIEW) &&
      (record == null || record.discriminator != "SalesOrder") &&
      OrderStatus.isEditable(record.status)
    );
  }

  async ngOnInit() {
    this.getDetail();
  }

  private tabGroup: MatTabGroup;
  @ViewChild('tabGroup', { static: false }) set tabGroupSetter(content: MatTabGroup) {
    if (content) {
      this.tabGroup = content;
      this.service.mainTabBodyElement = (this.tabGroup?._elementRef?.nativeElement as HTMLElement)?.querySelector('.mat-tab-body-content');
    }
  }
  public onTabGroupClick(event: MouseEvent) {
    const tgt = event.target as HTMLElement;
    const el: HTMLElement = this.tabGroup._elementRef.nativeElement;
    const headerEl = el.querySelector(':scope > .mat-tab-header')
    if (headerEl.contains(tgt) &&
    (tgt.classList.contains('mat-tab-label') ||
    tgt.classList.contains('mat-tab-label-content'))
    ) {
      this.service.setSelectedProductId(null);
    }
  }

  public canApproveEstimate() {
    return this.userSvc.canAccess('EstimateManager');
  }

  public async sendRFQToApproval() {
    const validated = await this.service.validate();
    if (!validated) return;
    let record = await fetchLatest(this.service.postChangesOrder$);
    const dialogRef = this.utilitySvc.dialogService.open(
      RFQDoneConfirmationDialog,
      { disableClose: true }
    );
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    let statusNote = result.note;
    let status: number;
    if (!result.ready) {
      status = OrderStatus.IN_PROCESS;
    }
    else {
      status = OrderStatus.AWAITING_REVIEW;
    }
    const noteChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'statusNote',
        oldValue: record.statusNote,
        newValue: statusNote,
      },
    };
    const statusChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'status',
        oldValue: record.status,
        newValue: status,
      },
    };
    this.service.recordChanges(noteChange, statusChange);
  }
  public async sendEstimateToApproval() {
    const validated = await this.service.validate();
    if (!validated) return;
    let record = await fetchLatest(this.service.postChangesOrder$);
    let allProducts = await fetchLatest(this.service.allProducts$);
    const unresolvedReviews = record.products.filter(p => p.reviewStatus === OrderSegmentProductReviewStatus.Incomplete);
    const dialogRef = this.utilitySvc.dialogService.open(
      StatusConfirmationDialog,
      { disableClose: true, data: { unresolvedReviews, incomplete: this.service.getAllIncompleteSimpleTasksByProduct(allProducts) }  }
    );
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    let statusNote = result.note;
    let status: number;
    if (!result.ready) {
      status = OrderStatus.IN_PROCESS;
    }
    else {
      status = OrderStatus.AWAITING_REVIEW;
    }
    const noteChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'statusNote',
        oldValue: record.statusNote,
        newValue: statusNote,
      },
    };
    const statusChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'status',
        oldValue: record.status,
        newValue: status,
      },
    };
    this.service.recordChanges(noteChange, statusChange);

  }

  public convertDocuments(docs: OrderDocument[]): VirtualDocument[] {
    return docs.map(d => d.document);
  }

  public onDocumentsAdded(docs: VirtualDocument[]) {
    const changes: AddOrderDocumentChange[] = docs.map(c => ({
      changeType: 'ADD_ORDER_DOCUMENT',
      entity: null,
      data: {
        documentId: c.documentId,
        documentData: c
      }
    }));
    this.service.recordChanges(...changes);
  }

  public onDocumentDeleted(doc: VirtualDocument) {
    const change: DeleteOrderDocumentChange = {
      changeType: 'DELETE_ORDER_DOCUMENT',
      entity: null,
      data: {
        documentId: doc.documentId,
        documentData: doc
      }
    };
    this.service.recordChanges(change);
  }

  public onDocumentTagsUpdated(data: { document: VirtualDocument, newTags: string[] }) {
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Document',
      data: {
        itemId: data.document.documentId,
        field: 'tags',
        oldValue: data.document.tags,
        newValue: data.newTags,
      }
    }
    this.service.recordChanges(change);
  }

  public async goToOldUI() {
    if (this.editing) {
      const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Any unsaved changes will be lost.');
      if (!r) return;
    }
    this.route.data.pipe(take(1)).subscribe(data => {
      const { discriminator } = data;
      const localStorageKey = `order-detail-${discriminator}-new-ui`;
      localStorage.setItem(localStorageKey, JSON.stringify(false));
      this.router.navigate(['/', discriminator, 'oldUi', this.id]);
    })
  }

  @HostBinding('attr.tabindex')
  public tabindex = 0;

  public async viewPdf() {
    this.service.startQuotePreview();
  }
  public focusPdf() {
    if (this.service.quotePreviewWindow)
      this.service.quotePreviewWindow.focus();
  }

  ngOnDestroy(): void {
    this.service.closeQuotePreviewWindow();
  }

  public async rejectOrder(record: Order) {
    await this.service.untilSaved();
    this.utilitySvc.showConfirmation(
      "Reject This Order?",
      "<p>Are you sure you want to reject this order?</p><p class='text-muted'>Rejecting an order sends it back for additional information, clairification, etc.</p>",
      (r) => {
        if (r) {
          const dialogRef = this.utilitySvc.dialogService.open(
            RejectReasoningDialog,
            { disableClose: true }
          );
          dialogRef.afterClosed().subscribe((result) => {
            this.loading = true;
            this.orderService
              .rejectOrder(record, result.note)
              .subscribe((_) => {
                this.getDetail();
                this.loading = false;
              });
          });
        }
      }
    );
  }

  public async approveEstimate(record: Order) {
    const r = await this.utilitySvc.showConfirmationPromise(
      "Approve This Estimate?",
      "<p>Please confirm that you want to approve this estimate in its current state.</p>"
    );
    if (!r) return; 
    this.loading = true;
    await this.service.untilSaved();
    const lineItems = await this.service.generateLineItems();
    this.orderService.approveEstimateClientside(record, lineItems).subscribe((_) => {
      this.getDetail();
      this.loading = false;
    });
  }

  public canBeClosed(record: Order): boolean {
    if (this.service.solo) return false;
    switch (record.status) {
      case OrderStatus.CLOSED:
      case OrderStatus.HISTORY:
      case OrderStatus.FULFILLED:
      case OrderStatus.APPROVED:
        return false;
      default: return true;
    }
  }

  @ViewChild("closeOrderDialogTemplate", { static: true }) closeOrderDialogTemplate: TemplateRef<any>;
  public async closeOrder(record: Order) {
    let discriminator: string;
    switch (record.discriminator) {
      case 'RMAEstimate':
        discriminator = 'Estimate';
        break;
      case 'SalesOrder':
        discriminator = 'Order'
        break;
      default:
        discriminator = record.discriminator;
        break;
    }
    const dialogRef = this.utilitySvc.dialogService.open(this.closeOrderDialogTemplate, {
      data: {
        reason: ""
      }
    });
    const r = await dialogRef.afterClosed().toPromise();
    if (!r) return;
    this.loading = true;
    await this.service.untilSaved();
    this.orderService
      .closeOrder(record, r.reason)
      .subscribe((_) => {
        this.loading = false;
        this.route.data.pipe(take(1)).subscribe(data => {
          const { discriminator } = data;
          this.router.navigate(['/', discriminator]);
        })
      });
  }

  @ViewChild('form') form: NgForm;
  ngAfterViewInit(): void {
    this.service.sidenav = this.sidenav;
  }

  public async approveRFQ(record: Order) {
    const validated = await this.service.validate();
    if (!validated) return;
    const r = await this.utilitySvc.showConfirmationPromise('Approve RFQ?', 'It will be sent to Estimating.');
    if (!r) return;
    else {
      this.loading = true;
      await this.service.untilSaved();
      await this.orderService.quoteToEstimate(record).toPromise();
      this.router.navigate(["/rfqApproval"]);
    }
  }
  @ViewChild('rejectRfqTemplate') rejectRfqTemplate: TemplateRef<any>;
  public async rejectRFQ(record: Order) {
    const dialogRef = this.utilitySvc.dialogService.open(
      this.rejectRfqTemplate,
      { disableClose: true, data: { note: '' } }
    );
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    record.statusNote = result;
    record.status = OrderStatus.REJECTED;
    let statusNote = result.note;
    let status: number;
    if (!result.ready) {
      status = OrderStatus.IN_PROCESS;
    }
    else {
      status = OrderStatus.AWAITING_REVIEW;
    }
    const noteChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'statusNote',
        oldValue: record.statusNote,
        newValue: statusNote,
      },
    };
    const statusChange: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'status',
        oldValue: record.status,
        newValue: status,
      },
    };
    this.service.recordChanges(noteChange, statusChange);
    await this.service.untilNextSave();
    this.router.navigate(["/rfqApproval"]);
  }

  public allProductsReviewed(record: Order) {
    return record?.products?.every(p => p.reviewStatus > 0);
  }

  public async cloneRFQ(record: Order) {
    const r = await this.utilitySvc.showConfirmationPromise(`Clone ${record.orderNumber}?`, 'A new RFQ will be created with the same part numbers.');
    if (!r) return;
    this.loading = true;
    await this.service.untilSaved();
    const id = await this.orderService.cloneRFQ(record.orderSegmentId).toPromise();
    this.loading = false;
    this.router.navigate(["/rfqs/", id]);
  }

  @Input() soloProduct: Product = null;
  @Input() soloOrder: Order = null;


  @ViewChild(MatSidenav) sidenav: MatSidenav;

  public startEditing() {
    this.service.startEditing();
    this.cdr.detectChanges();
  }

}
