import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../../../order-detail.service';
import { CdkDragDrop, CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { Station } from '../../../../../resources/station';
import { Workflow, WorkflowStep } from '../../../../../resources/workflow';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { StationService } from '../../../../../services/station.service';
import { Subscription } from 'rxjs';
import { Product } from '../../../../../resources/product';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'product-workflow-workflow-list',
  templateUrl: './product-workflow-workflow-list.component.html',
  styleUrls: ['./product-workflow-workflow-list.component.less']
})
export class ProductWorkflowWorkflowListComponent implements OnInit {

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return { name: 'Unknown Station' } as Station;

    const station = this.stationList.find(r => r.stationId == item.stationId);
    if (!station)
      return { name: 'Unknown Station' } as Station;
    else return station;
  }

  public getName(step: WorkflowStep) {
    return this.getStation(step)?.name ?? 'Unknown Station';
  }

  @Input() product: Product;
  @Input() editing = false;

  @Input() selectedStepId: string;
  @Output() selectedStepIdChange = new EventEmitter<string>();

  constructor(private stationService: StationService) { }

  public stationList: Station[];
  async ngOnInit() {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }

  @Output() stationDraggedIn = new EventEmitter<{ index: number, station: Station }>();
  @Output() sort = new EventEmitter<{ previousIndex: number, currentIndex: number}>();

  drop(e: CdkDragDrop<any, any>) {
    // coming in from the station list
    if (e.previousContainer.id === "workflowStationListCdkDrop") {
      const station = e.item.data as Station;
      this.stationDraggedIn.emit({
        index: e.currentIndex,
        station
      });
    // sorting inside workflow list
    } else {
      this.sort.emit(e);
    }
  }

  @Input() quantityGetter: (p: Product) => number;

  public calculateCost(product: Product, item: WorkflowStep): number {
    return WorkflowStep.calculateCostForQty(item, this.quantityGetter(product)) / this.quantityGetter(product);
  }

  public calculatePercentage(product: Product, item: WorkflowStep): number {
    let out = this.calculateCost(product, item) / product.workflow.workflowSteps.reduce((p, c) => { return p += this.calculateCost(product, c); }, 0);
    if (!out) return 0;
    else return out;
  }

}
