import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@cots/common/services/navigation.service';

@Component({
  selector: 'report-home',
  templateUrl: './report-home.component.html',
  styleUrls: ['./report-home.component.less']
})
export class ReportHomeComponent implements OnInit {

  constructor(
      navService: NavigationService,
    ) {
      navService.setPageTitle("Reports");
    }

  ngOnInit() {
  }

}
