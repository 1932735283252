function generateCsvFromData(data: any[][], headers: string[]) {
    if (data.length === 0) {
        console.log('Empty data in CSV generation');
        return '';
    }

    if (data[0].length !== headers.length) {
        throw new Error("Header/data length mismatch in CSV generation");
    }

    const csvPrefix = "data:text/csv;charset=utf-8;base64,";

    const csvHeaders = headers.map(h => `"${h.replaceAll(/"/g, '\\"')}"`).join(',');

    let csvContent = "\n";

    for (let row = 0; row < data.length; row++) {

        const columns = [];

        for (let col = 0; col < data[0].length; col++) {
            let item = data[row][col];

            if (item === undefined || item === null)
                item = '';

            else if (item instanceof Date)
                item = item.toISOString();

            else if (item instanceof String)
                item = item.replaceAll(/"/g, '\\"');

            columns.push(`"${item}"`);
        }

        csvContent += columns.join(',');
        csvContent += "\n";
    }

    return csvPrefix + base64encode(csvHeaders + csvContent);
}

export function exportCsv(title: string, data: any[], headers: string[]) {
    const encodedUri = generateCsvFromData(data, headers);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);

    const e = document.body.appendChild(link);

    link.click();
    e.remove();
}

// Taken from https://www.webtoolkit.info/javascript_base64.html
// Need this in order to support unicode characters. btoa() is ascii-only
function base64encode(input: string) {
    let output = "";
    let chr1: number, chr2: number, chr3: number, enc1: number, enc2: number, enc3: number, enc4: number;
    let i = 0;
    let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    input = base64ProcessUnicode(input);

    while (i < input.length) {

        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output = output +
        keyStr.charAt(enc1) + keyStr.charAt(enc2) +
        keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }


    return output;
}

function base64ProcessUnicode(string: string) {
    string = string.replace(/\r\n/g,"\n");
    let utftext = "";

    for (let n = 0; n < string.length; n++) {

        let c = string.charCodeAt(n);

        if (c < 128) {
            utftext += String.fromCharCode(c);
        }
        else if((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }

    return utftext;
}