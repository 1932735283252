import { Component, Input, OnInit } from '@angular/core';
import { EstimatingTaskStatus, Order, OrderStatus } from '../../resources/order';
import { Subscription } from 'rxjs';
import { FilterParam, SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';
import { User } from '../../../common/resources/user';
import { ProductStandardHistory, getQuoteHistoryChipClass, getQuoteHistoryText } from '../../resources/product';
import { TaskStatus } from '../../../common/resources/estimatingtask';

@Component({
  selector: 'ordersegment-list-new',
  templateUrl: './ordersegment-list-new.component.html',
  styleUrls: ['./ordersegment-list-new.component.less']
})
export class OrdersegmentListNewComponent implements OnInit {

  constructor(private service: OrderService, private router: Router) { }

  ngOnInit(): void {
  }

  @Input() displayedColumns: string[] = ['customer', 'orderNumber', 'customerRfqnumber', 'assigned', 'received', 'required', 'status'];
  @Input() orderNumberTitle = 'Order';
  @Input() showAdd = false;
  @Input() showManagerToggle = false;
  @Input() alwaysManager = false;
  @Input() path: string;
  @Input() discriminator: string;
  @Input() localStorageId: string;
  @Input() isManager: (u: User) => boolean = (_) => false;
  @Input() filterParams: FilterParam;
  @Input() defaultFilters: { [key: string]: any[] };

  public search({ searchText, page, sort, forAllUsers, fieldSearches, filters, groupBy }: SearchData) {
    return this.service.search(this.discriminator, forAllUsers ?? false, searchText, page.pageIndex, sort.active, sort.direction, page.pageSize, fieldSearches, filters, groupBy);
  }

  onSelect(record: Order): void {
    this.router.navigate([this.path, record.orderSegmentId]);
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getStatusText(status: number): string {
    if (status === OrderStatus.TENT_APPROVED) return "Tent. Approved";
    if (status === OrderStatus.AWAITING_REVIEW) return "Aw. Review";
    if (status === OrderStatus.AWAIT_ESTIMATE) return "Aw. Estimate";
    return OrderStatus.getStatusText(status);
  }

  public toNumber(str: string) {
    return parseInt(str);
  }

  public getQuoteHistoryText(h: ProductStandardHistory) {
    return getQuoteHistoryText(h);
  }

  public getQuoteHistoryChipClass(h: ProductStandardHistory) {
    return getQuoteHistoryChipClass(h);
  }

  public getProgress(o: Order) {
    // TODO
  }

  public possibleProgressStatuses = Object.values(TaskStatus).filter(x => typeof x === 'number');
  public getProgressColorClass(status: TaskStatus) {
    return EstimatingTaskStatus.getStatusColorClass(status);
  }
  public getProgressStatusText(status: number) {
    return EstimatingTaskStatus.getStatusText(status);
  }
}
