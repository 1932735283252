import { Component, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { UtilityService } from "../../../common/services/utility.service";
import { NavigationService } from "../../../common/services/navigation.service";
import { Order, OrderProduct, OrderStatus } from "../../../order/resources/order";
import { OrderService } from "../../../order/services/order.service";
import {
  Customer,
  CustomerContact,
} from "../../../customer/resources/customer";
import { User } from "../../../common/resources/user";
import { Product } from "../../../order/resources/product";
import { MatSidenav } from "@angular/material/sidenav";
import { VirtualDocument } from "../../../common/resources/virtual-document";
import { UserService } from "../../../common/services/user.service";
import { QuoteInfoComponent } from "../quote-info/quote-info.component";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { MatStep, MatStepper } from "@angular/material/stepper";
import $ from "jquery";
import { EstimatePricingComponent } from "../estimate-pricing/estimate-pricing.component";
import { CopyPartDialogComponent } from "../copy-part-dialog/copy-part-dialog.component";
import { take } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.less"],
})
export class OrderDetailComponent implements OnInit {
  private selectedPart: Product;
  public id: string;
  public editCustomerId: string = "new";
  public record: Order;
  public editing: boolean = false;
  public contactsView: boolean = false;
  public saving: boolean = false;
  public showEditor: string = null;
  public selectedTab: number = 0;
  public assigningEmployee: User = null;
  public totalCost?: number = null;
  public nameHint?: string = null;
  public modifiedByUser: User = null;
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild("quoteInfo") quoteInfo: QuoteInfoComponent;
  @ViewChild("estimatePricing") estimatePricing: EstimatePricingComponent;
  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("pricingStep") pricingStep: MatStep;
  @ViewChild("closeOrderDialogTemplate", { static: true }) closeOrderDialogTemplate: TemplateRef<any>;

  constructor(
    private orderService: OrderService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavigationService,
    private utilitySvc: UtilityService,
    private userSvc: UserService,
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
  }

  public get emptyGuid(): string {
    return UtilityService.emptyGuid;
  }

  public products: Product[];
  private getDetail(): void {
    this.record = null;
    this.totalCost = null;
    if (this.id == "new" || this.id == UtilityService.emptyGuid) {
      this.editing = true;

      this.userSvc.user.subscribe((u) => {
        if (!u || !u.userId) return;

        this.record = <Order>{
          orderSegmentId: UtilityService.emptyGuid,
          discriminator: "RFQ",
          assignedTo: this.userSvc.userData.userId, //If you're creating it, you don't need to assign it to yourself
          assignedUser: this.userSvc.userData,
          documents: [],
          products: [],
          customer: <Customer>{
            customerId: UtilityService.emptyGuid,
            salesPerson: <User>{},
          },
          customerContact: <CustomerContact>{},
        };
        this.products = [];

        this.setPageTitle();
        this.totalCost = 0;
      });
    } else {
      this.orderService.getDetail(this.id).subscribe((detail) => {
        this.cleanUpRecord(detail);
        this.orderService.getProductTree(detail.orderSegmentId).subscribe(p => {
          this.products = p;
        });
      });
    }
  }

  private cleanUpRecord(data: Order): void {
    if (data == null) return;

    //Clean Up...
    data.customer = data.customer || <Customer>{ salesPerson: <User>{} };
    data.customer.salesPerson = data.customer.salesPerson || <User>{};
    data.customerContact = data.customerContact || <CustomerContact>{};

    this.record = data;
    this.getLastUpdatedBy();


    this.setPageTitle();
    this.calculateCost();
  }

  private calculateCost(): void {
    this.totalCost = null;

    this.orderService
      .getCostForOrder(this.record.orderSegmentId)
      .subscribe((cost) => {
        this.totalCost = cost;
      });
  }

  public showAddCustomer(newName: string, goToContacts: boolean): void {
    this.nameHint = newName;
    this.showEditor = "customer";
    if (!goToContacts) this.editCustomerId = UtilityService.emptyGuid;
    else {
      this.editCustomerId = this.record.customerId;
    }
    this.navService.pushBreadcrumb("Add Customer");
    this.sidenav.toggle();
  }

  public closeSideNav(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();
  }

  public setNewCustomer(cust: Customer): void {
    if (cust.customerId != UtilityService.emptyGuid)
      this.record.customer = cust;
  }

  private setPageTitle(): void {
    this.navService.setPageTitle(this.record.discriminator + " Detail");
    this.navService.pushBreadcrumb(
      this.record.orderNumber || "New " + this.record.discriminator
    );
  }

  public toggleEditing(): void {
    if (
      this.id == "new" ||
      this.id == UtilityService.emptyGuid ||
      !this.isEditable(this.record.status)
    ) {
      this.route.data.pipe(take(1)).subscribe(data => {
        const { discriminator } = data;
        this.router.navigate(['/', discriminator]);
      })
    }

    if (
      !this.editing &&
      (this.record.status == OrderStatus.AWAITING_REVIEW ||
        this.record.status == OrderStatus.TENT_APPROVED)
    ) {
      var statusText =
        this.record.status == OrderStatus.AWAITING_REVIEW
          ? "Awaiting Review"
          : "Tentatively Approved";
      this.utilitySvc.showConfirmation(
        statusText,
        `<p>This order is ${statusText.toLowerCase()}. Are you sure you want to continue?</p><p class='text-muted'>Changes to this order can reset its status.</p>`,
        (r) => {
          if (r) {
            this.getDetail();
            this.editing = !this.editing;
          }
        }
      );

      return;
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  public formatPhone(event: KeyboardEvent): void {
    (<HTMLInputElement>event.target).value = UtilityService.formatPhone(
      (<HTMLInputElement>event.target).value
    );
  }

  public selectProduct(product: Product): void {
    if (this.record.customer == null || this.record.customerId == null) {
      this.utilitySvc.showAlert(
        "Please Select a Customer",
        "<p>Please select a customer before continuing.</p>"
      );
      return;
    }

    this.selectedPart = product;
    this.showEditor = "part";
    this.navService.pushBreadcrumb(
      this.selectedPart.partNumber + " Rev " + this.selectedPart.revision
    );
    this.sidenav.toggle();
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation(
      "Are you Sure?",
      "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>",
      (r) => {
        if (r) {
          this.saving = true;
          this.orderService
            .removeDocument(this.record, document)
            .subscribe((r) => {
              this.saving = false;
              this.getDetail();
            });
        }
      }
    );
  }

  public deletePart(product: Product): void {
    this.utilitySvc.showConfirmation(`Remove Part ${product.partNumber} Rev. ${product.revision}?`, "<p>Are you sure you want to remove this part from the order?</p>", r => {
      if (r) {
        this.saving = true;
        this.orderService.removePartFromOrder(this.record, product).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }

    });
  }

  public deletePartChild({ parent, child }: { parent: Product, child: Product }): void {
    this.utilitySvc.showConfirmation(`Remove Part ${child.partNumber} Rev. ${child.revision}?`, "<p>Are you sure you want to remove this part from the order?</p>", r => {
      if (r) {
        this.saving = true;
        this.orderService.removeSubComponent(parent, child).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    });
  }

  public addPart(): void {
    if (this.record.customer == null || this.record.customerId == null) {
      this.utilitySvc.showAlert(
        "Please Select a Customer",
        "<p>Please select a customer before continuing.</p>"
      );
      return;
    }

    this.showEditor = "addProduct";
    this.navService.pushBreadcrumb("Add a Part");
    this.sidenav.toggle();
  }

  public async copyPart() {
    if (this.record.customer == null || this.record.customerId == null) {
      this.utilitySvc.showAlert(
        "Please Select a Customer",
        "<p>Please select a customer before continuing.</p>"
      );
      return;
    }

    const dialogRef = this.utilitySvc.dialogService.open(CopyPartDialogComponent, {
      disableClose: true,
      minWidth: 500,
      data: { orderId: this.record.orderSegmentId }
    });

    const result: Product | null = await dialogRef.afterClosed().toPromise();
    
    if (!result) return;
    this.saving = true;
    this.orderService.save(this.record).subscribe((detail) => {
      this.cleanUpRecord(detail);
      // product.dateRequired = this.record.requiredDate;
      this.saving = false;

      this.orderService
        .addPartToOrder(result, this.record)
        .subscribe((orderProduct) => {
          this.record.products.push(orderProduct);
          this.products = [...this.products, orderProduct.product];
          this.selectProduct(orderProduct.product);

          setTimeout((_) => {
            if (this.id != this.record.orderSegmentId) {
              this.id = this.record.orderSegmentId;
              this.router.navigate(["/order", this.record.orderSegmentId]);
            }
          }, 10);
        });
    });

  }

  public addNewPart(product: Product): void {
    this.saving = true;

    this.orderService.save(this.record).subscribe((detail) => {
      this.cleanUpRecord(detail);
      // product.dateRequired = this.record.requiredDate;

      this.orderService
        .addPartToOrder(product, this.record)
        .subscribe((orderProduct) => {
          this.record.products.push(orderProduct);
          this.products = [...this.products, orderProduct.product];
          this.saving = false;
          this.selectProduct(orderProduct.product);

          setTimeout((_) => {
            if (this.id != this.record.orderSegmentId) {
              this.id = this.record.orderSegmentId;
              this.router.navigate(["/order", this.record.orderSegmentId]);
            }
          }, 10);
        });
    });
  }

  public approveOrder(): void {
    this.utilitySvc.showConfirmation(
      "Approve This Order?",
      "<p>Please confirm that you want to approve this order in its current state.</p>",
      (r) => {
        if (r) {
          this.saving = true;
          this.orderService.approveOrder(this.record).subscribe((_) => {
            this.getDetail();
            this.saving = false;
          });
        }
      }
    );
  }

  public markAsSent(): void {
    this.utilitySvc.showConfirmation(
      "Mark Quote as Issued?",
      "<p>This cannot be undone. Please confirm that you have sent the quote to the customer.</p>",
      (r) => {
        if (r) {
          this.saving = true;
          this.orderService.markAsIssued(this.record.orderSegmentId).subscribe(() => {
            this.record.status = 8;
            this.saving = false;
          });
        }
      }
    );
  }

  public async returnToEstimating() {
    const r = await this.utilitySvc.showConfirmationPromise("Return to Estimating?",
      "<p>The estimate will be sent back to Estimating's queue. It will be revised and go through the approval process again.</p>"
    );
    if (!r) return;
    const dialogRef = this.utilitySvc.dialogService.open(
      RejectReasoningDialog,
      { disableClose: true }
    );
    this.saving = true;
    const result = await dialogRef.afterClosed().toPromise();
    this.orderService
      .returnQuoteToEstimating(this.record.orderSegmentId, result.note)
      .subscribe((_) => {
        this.getDetail();
        this.saving = false;
        this.router.navigate(["/quoting"]);
      });
  }

  public rejectOrder(): void {
    this.utilitySvc.showConfirmation(
      "Reject This Order?",
      "<p>Are you sure you want to reject this order?</p><p class='text-muted'>Rejecting an order sends it back for additional information, clairification, etc.</p>",
      (r) => {
        if (r) {
          const dialogRef = this.utilitySvc.dialogService.open(
            RejectReasoningDialog,
            { disableClose: true }
          );

          this.saving = true;
          dialogRef.afterClosed().subscribe((result) => {
            this.orderService
              .rejectOrder(this.record, result.note)
              .subscribe((_) => {
                this.getDetail();
                this.saving = false;
              });
          });
        }
      }
    );
  }

  public openDocumentWindow(): void {
    if (this.record.customer == null || this.record.customerId == null) {
      this.utilitySvc.showAlert(
        "Please Select a Customer",
        "<p>Please select a customer before continuing.</p>"
      );
      return;
    }

    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.sidenav.toggle();
  }

  public addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.orderService.save(this.record).subscribe((detail) => {
      this.record = detail;

      this.orderService.addDocuments(this.record, documents).subscribe((_) => {
        this.saving = false;

        if (this.id == this.record.orderSegmentId) {
          this.getDetail();
        } else {
          this.id = this.record.orderSegmentId;
          this.router.navigate(["/quoting", this.record.orderSegmentId]);
          this.getDetail();
        }
      });
    });
  }

  public validateDeliveryTimes(): string {
    const firstInvalid = Object.entries(this.record.shippingAssignments).find(([productId, assignments]) => {
      const orderQty = this.record.products.find(p => p.productProductId === productId).product.orderQuantity;
      if (!orderQty) return false;
      const totalAssignments = assignments.reduce((acc, x) => acc + x.quantity, 0);
      return (totalAssignments > orderQty) || assignments.some(a => !a.shipDate || !a.quantity);
    });
    if (firstInvalid)
      return firstInvalid[0]; // [0 is id]
    else return null;
  }

  public saveChanges(finishEditingWhenDone?: boolean): void {
    finishEditingWhenDone = finishEditingWhenDone || false;

    if (this.record.customer == null || this.record.customerId == null) {
      this.utilitySvc.showAlert(
        "Please Select a Customer",
        "<p>Please select a customer before continuing.</p>"
      );
      return;
    }

    if (this.record.discriminator != 'Estimate' && this.record.estimateNumber) {
      const invalidId = this.validateDeliveryTimes();
      if (invalidId) {
        const invalidProduct = this.record.products.map(p => p.product).find(p => p.productId === invalidId);
        this.stepper.selected = this.pricingStep;
        setTimeout(() => {
          ($(`#pricing-select-${invalidId}`).get(0) as HTMLDivElement).scrollIntoView({
            behavior: "auto",
            block: "start",
            inline: "nearest"
          });
        }, 50)
        this.utilitySvc.showAlert(
          `Invalid Delivery Time Assignment`,
          `<p>Please correct delivery times for <b>${invalidProduct.partNumber} Rev. ${invalidProduct.revision}</b> before continuing.</p>`
        );
        return;
      }
    }

    if (finishEditingWhenDone) {
      if (
        this.record.discriminator === "RFQ" &&
        !this.record.estimateNumber
      ) {
        const dialogRef = this.utilitySvc.dialogService.open(
          RFQDoneConfirmationDialog,
          { disableClose: true }
        );

        dialogRef.afterClosed().subscribe((result) => {
          //console.log(result);
          if (result != null) {
            this.record.statusNote = result.note;
            if (!result.ready) this.record.status = OrderStatus.IN_PROCESS;
            else this.record.status = this.orderStatus.AWAITING_REVIEW;

            this.saving = true;
            if (this.quoteInfo) this.quoteInfo.save();
            if (this.estimatePricing) this.estimatePricing.save();
            this.orderService.save(this.record).subscribe((detail) => {
              var redirect = this.record.orderSegmentId != detail.orderSegmentId;
              this.record = detail;
              if (redirect) {
                this.router.navigate(["/quoting", this.record.orderSegmentId]);
              }
              // if (result.ready) {
              //   this.orderService.quoteToEstimate(this.record).subscribe(_estimate => {
              //     if (redirect) {
              //       this.router.navigate(["/quoting", this.record.orderSegmentId]);
              //     }
              //     this.saving = false;
              //     this.editing = false;
              //     this.getDetail();
              //   });
              // } else {
              //   this.saving = false;
              // }

            });
          }
        });

      } else if (this.record.discriminator === "Quote") {
        this.saving = true;
        if (this.quoteInfo) this.quoteInfo.save();
        if (this.estimatePricing) this.estimatePricing.save();
        this.orderService.save(this.record).subscribe((detail) => {
          if (detail == null) {
            return;
          }

          this.record = detail;

          if (this.id == detail.orderSegmentId) {
            this.getDetail();
          } else {
            this.id = this.record.orderSegmentId;
            this.router.navigate(["/quoting", detail.orderSegmentId]);
            this.getDetail();
          }

          this.saving = false;
          this.editing = false;
        });
      }
      else {
        const dialogRef = this.utilitySvc.dialogService.open(
          StatusConfirmationDialog,
          { disableClose: true, data: { incomplete: [] } }
        );

        dialogRef.afterClosed().subscribe((result) => {
          if (result != null) {
            this.record.statusNote = result.note;

            if (!result.ready) {
              this.record.status = OrderStatus.IN_PROCESS;
            } else {
              switch (this.record.discriminator) {
                case "RFQ":
                case "Estimate":
                case "RMAEstimate":
                  this.record.status = OrderStatus.AWAITING_REVIEW;
                  break;
                case "SalesOrder":
                case "Quote":
                case "Quality":
                  //???
                  break;
              }
            }

            this.saving = true;
            if (this.quoteInfo) this.quoteInfo.save();
            if (this.estimatePricing) this.estimatePricing.save();
            this.orderService.save(this.record).subscribe((detail) => {
              if (detail == null) {
                return;
              }

              this.record = detail;

              if (this.id == detail.orderSegmentId) {
                this.getDetail();
              } else {
                this.id = this.record.orderSegmentId;
                this.router.navigate(["/quoting", detail.orderSegmentId]);
                this.getDetail();
              }

              this.saving = false;
              this.editing = false;
            });
          }
        });
      }
    } else {
      this.saving = true;
      this.orderService.save(this.record).subscribe((detail) => {
        if (detail == null) {
          return;
        }

        this.record = detail;

        if (this.id == detail.orderSegmentId) {
          this.getDetail();
        } else {
          this.id = this.record.orderSegmentId;
          this.router.navigate(["/quoting", detail.orderSegmentId]);
          this.getDetail();
        }

        this.saving = false;
      });
    }
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClass(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  public orderStatus = OrderStatus

  public isEditable(status: number): boolean {
    return (
      (this.record == null || this.record.discriminator != "SalesOrder") &&
      OrderStatus.isEditable(status)
    );
  }

  public get canBeClosed(): boolean {
    if (this.record.discriminator === "SalesOrder") return false;
    switch (this.record.status) {
      case OrderStatus.CLOSED:
      case OrderStatus.HISTORY:
      case OrderStatus.APPROVED:
        return false;
      default: return true;
    }
  }

  public onAcceptance() {
    const invalidId = this.validateDeliveryTimes();
    if (invalidId) {
      const invalidProduct = this.record.products.map(p => p.product).find(p => p.productId === invalidId);
      this.stepper.selected = this.pricingStep;
      setTimeout(() => {
        ($(`#pricing-select-${invalidId}`).get(0) as HTMLDivElement).scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest"
        });
      }, 50)
      this.utilitySvc.showAlert(
        `Invalid Delivery Time Assignment`,
        `<p>Please correct delivery times for <b>${invalidProduct.partNumber} Rev. ${invalidProduct.revision}</b> before continuing.</p>`
      );
      return;
    }
    this.showEditor = 'acceptance';
    this.sidenav.toggle();
  }

  public async closeOrder() {
    const dialogRef = this.utilitySvc.dialogService.open(this.closeOrderDialogTemplate, {
      data: {
        reason: ""
      }
    });
    const r = await dialogRef.afterClosed().toPromise();
    if (!r) return;
    this.saving = true;
    this.orderService
      .closeOrder(this.record, r.reason)
      .subscribe((_) => {
        // this.getDetail();
        this.saving = false;
        this.route.data.pipe(take(1)).subscribe(data => {
          const { discriminator } = data;
          this.router.navigate(['/', discriminator]);
        })
      });
  }

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess(this.record.discriminator + "Manager") ||
      this.userSvc.canAccess("Developer")
    );
  }

  public saveAssignment(): void {
    if (!this.record) return;

    this.saving = true;
    this.orderService
      .assign(this.record.orderSegmentId, this.assigningEmployee)
      .subscribe((_) => {
        this.saving = false;
        if (this.assigningEmployee.userId === this.userSvc.userData.userId) this.getDetail();
        else {
          // redirect to list if assigning to someone else
          this.route.data.pipe(take(1)).subscribe(data => {
            const { discriminator } = data;
            this.router.navigate(['/', discriminator]);
          })
        }
      });
  }

  public getLastUpdatedBy(): void {
    if (this.record && this.record.modifiedBy) {
      this.userSvc.getUser(this.record.modifiedBy).subscribe((r) => {
        this.modifiedByUser = r;
      });
    }
  }

  public setSelectedTab(e: StepperSelectionEvent): void {
    this.selectedTab = e.selectedIndex;
  }

  public onProductUpdate(product: Product) {
    if (this.quoteInfo)
      this.quoteInfo.updateProducts();
    if (this.estimatePricing)
      this.estimatePricing.onExternalChange();
  }

  public showRMA() {
    this.showEditor = "rma";
    this.sidenav.toggle();
  }

  public onPOAccepted() {
    this.getDetail();
    this.stepper.selectedIndex = 2;
  }

  ngOnInit(): void {
    this.getDetail();
  }

  public async goToNewUi() {
    if (this.editing) {
      const r = await this.utilitySvc.showConfirmationPromise('Are you sure?', 'Any unsaved changes will be lost.');
      if (!r) return;
    }
    this.route.data.pipe(take(1)).subscribe(data => {
      const { discriminator } = data;
      const localStorageKey = `order-detail-${discriminator}-new-ui`;
      localStorage.setItem(localStorageKey, JSON.stringify(true));
      this.router.navigate(['/', discriminator, 'newUi', this.id]);
    })
  }
}

@Component({
  selector: "status-confirmation-dialog",
  templateUrl: "status-confirmation.dialog.html",
})
export class StatusConfirmationDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    incomplete?: { productName: string, missing: string }[],
    unresolvedReviews?: OrderProduct[]
  }) 
  { }
}

@Component({
  selector: "estimate-confirmation-dialog",
  templateUrl: "estimate-confirmation.dialog.html",
})
export class RFQDoneConfirmationDialog { }

@Component({
  selector: "reject-reasoning-dialog",
  templateUrl: "reject-reasoning.dialog.html",
})
export class RejectReasoningDialog { }
