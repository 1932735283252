import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Host, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { Order } from '../../../../../resources/order';
import { Contact } from '../../../../../../common/resources/contact';
import { UtilityService } from '../../../../../../common/services/utility.service';
import { VendorContact } from '../../../../../../supplier/resources/vendor';
import { MatDialog } from '@angular/material/dialog';
import { VendorService } from '../../../../../../supplier/services/vendor.service';
import { VirtualDocument } from '../../../../../../common/resources/virtual-document';
import { DocumentService } from '../../../../../../common/services/document.service';

@Component({
  selector: 'product-quoting-new-quote',
  templateUrl: './product-quoting-new-quote.component.html',
  styleUrls: ['./product-quoting-new-quote.component.less'],
})
export class ProductQuotingNewQuoteComponent implements OnInit {
  @Input() order: Order;
  @Input() record: MaterialBid;

  constructor(private dialog: MatDialog, private vendorService: VendorService, private utilityService: UtilityService, private documentService: DocumentService, private cdr: ChangeDetectorRef) { }
  @Input() uploadFunction: (files: File[]) => Promise<VirtualDocument[]> = (files) => Promise.all(files.map(f => this.documentService.upload(f as any).toPromise()));

  ngOnInit(): void {
  }

  public getTypeId(): string {
    return (this.record.material && this.record.material.materialGroupId) || this.record.stationId || 'purchased';
  }

  public getTypeName(): string {
    return (this.record.material && this.record.material.materialGroup.groupName) || (this.record.station && this.record.station.name) || 'Hardware';
  }

  public get contacts(): Contact[] {
    return this.record?.vendor?.vendorContacts?.map(vc => vc.contact) ?? [];
  }

  public bidType: 'total' | 'perItem' = 'total'

  public saving = false;
  @ViewChild('newVendorContactDialogTemplate', { static: true }) newVendorContactDialogTemplate: TemplateRef<any>;
  public async newVendorContact(name: string) {
    const dialogRef = this.dialog.open<Contact, any, Contact>(this.newVendorContactDialogTemplate, {
      disableClose: false,
      minWidth: 500,
      data: {
        contactId: UtilityService.emptyGuid,
        name,
        title: '',
        phoneNumber: '', phoneExtension: '',
        cellNumber: '', fax: '',
        email: '', personalEmail: ''
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;
    this.saving = true;
    const createdContact = await this.vendorService.saveContact(this.record.vendor, <VendorContact>{
      vendorContactId: UtilityService.emptyGuid,
      useForQuoting: true,
      contact: result,
    }).toPromise();
    this.record.vendor.vendorContacts = [...this.record.vendor.vendorContacts, createdContact];
    this.record.contact = createdContact.contact;
    this.record.contactId = createdContact.contactId;
    this.saving = false;

  }

  public get isValid() {
    return this.record?.vendor &&
      (this.record?.perItemBid || this.record?.totalBid) &&
      this.record?.leadTimeDays
  }

  @Output() done = new EventEmitter<MaterialBid>();
  @Output() doCancelAdding = new EventEmitter<void>();


  public dragging = false;
  private enterTarget: EventTarget | null;
  public onDragEnter(event: DragEvent) {
    this.enterTarget = event.target;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = true;
  }

  public onDragLeave(event: DragEvent) {
    if (this.enterTarget === event.target) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
    }
  }

  public async onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragging = false;
    const files = Array.from(event.dataTransfer.files ?? []);
    if (files.length === 0) return;
    this.addDocuments(files);
  }

  private checkEmptyFiles(files: File[]) {
    if (files.some(f => f.size === 0)) {
      this.utilityService.showAlert("Empty Files Detected", "<p>Some of the files you selected are empty and cannot be uploaded.</p><p>This is usually caused by trying to drag-and-drop files directly from a .zip folder. Please extract them first if so.</p><p>If this isn't what you're doing, please inspect the files; they may be corrupted.</p>")
      return true;
    } else {
      return false;
    }
  }

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  public browse() {
    this.fileInput.nativeElement.click();
  }
  onBrowseDone(filelist: FileList) {
    const files = Array.from(filelist);
    this.addDocuments(files);
  }

  public savingDocuments = false;
  async addDocuments(files: File[]) {
    if (this.savingDocuments) return;
    if (this.checkEmptyFiles(files)) return;
    this.savingDocuments = true;
    this.cdr.detectChanges();
    const vdocs = await this.uploadFunction(files);
    // this.documentsChange.emit([...this.documents, ...vdocs]);
    if (!this.record.materialBidDocuments) this.record.materialBidDocuments = [];
    this.record.materialBidDocuments.push(...vdocs.map(vd => ({
      materialBidId: this.record.materialBidId,
      documentId: vd.documentId,
      document: vd,
    })));
    this.savingDocuments = false;
    this.cdr.detectChanges();
    this.fileInput.nativeElement.value = null;
  }
}
