<div class="row m-0 h-100-t" ngForm #form="ngForm" *ngLet="service.selectedProduct$ | async as product">
  <ng-container *ngIf="service.postChangesOrder$ | async as record">
  <div class="col-12 d-flex py-2 border-bottom" *ngIf="service.shouldShowIndicators(record)">
    <div class="ml-auto">
      <estimating-progress-status label="Hardware Status" [status]="product.hardwareStatus" (statusChange)="service.setEstimatingTaskStatus(product.productId, 'hardware', $event)" [disabled]="!editing"></estimating-progress-status>
    </div>
  </div>
  </ng-container>
  <div class="col-4 h-100-t p-0 d-flex flex-column mat-elevation-z6">
    <div style="font-size: 1.25rem" class="font-weight-normal p-3 border-bottom position-relative">
      Purchased Items
      <button mat-fab *ngIf="editing" class="bg-success text-white" style="position: absolute; bottom: -2em; right: 2em; z-index: 10"
        (click)="createNew(product)"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-list class="pt-0 pt-4 flex-grow-1 position-relative" style="height: 0; overflow-y: scroll;" *ngLet="service.selectedPurchasedItemIdSubject | async as selectedItemId">
      <mat-list-item *ngFor="let item of product.purchasedItems"
        class="border-bottom bg-white list-item cursor-pointer"
        [class.bg-primary]="selectedItemId === item.productPurchasedItemId"
        [class.text-white]="selectedItemId === item.productPurchasedItemId" 
        (click)="service.selectedPurchasedItemIdSubject.next(item.productPurchasedItemId)">
        <div class="w-100 text-ellipsis d-flex align-items-center">
          <span class="badge mr-2 bg-dark text-white">{{ item.quantity }}<span *ngIf="!item.isNonRecurring">/part</span></span>
          <span *ngIf="item.purchasedItem" class="small text-ellipsis">{{ item.purchasedItem?.description }}</span>
          <span *ngIf="!item.purchasedItem" class="small font-italic text-ellipsis">(No Item Selected)</span>
          <span class="ml-auto small text-muted">
            ({{ getCost(product, item) | currency }}/p)
          </span>
          <button mat-icon-button (click)="deleteItem(item)" class="ml-1">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <div *ngIf="product.purchasedItems.length === 0"
        class="w-100 text-muted text-center position-absolute small p-4" style="pointer-events: none;">
        This product has no purchased items.
      </div>
    </mat-list>
  </div>
  <div class="col-8 p-3 pt-5">
    <product-purchased-items-form *ngIf="selectedPurchasedItem$ | async as item" [product]="product" [item]="item" (changeItem)="changeItem($event)"></product-purchased-items-form>
  </div>
</div>

<ng-template #newItemDialog let-data>
  <h2 matDialogTitle>{{ data.isNew ? 'Add Purchased Item' : 'Change Purchased Item' }}</h2>
  <mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <purchased-item-search [(ngModel)]="data.item"></purchased-item-search>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!data.item" [matDialogClose]="data.item">{{ data.isNew ? 'Add Item' : 'Set Item' }}</button>
  </mat-dialog-actions>
</ng-template>
