import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaterialBid, MaterialBidDocument } from '../../../../../purchasing/resources/materialBid';
import { OrderDetailService } from '../../order-detail.service';
import { VirtualDocument } from '../../../../../common/resources/virtual-document';
import { AddMaterialBidDocumentChange, DeleteMaterialBidDocumentChange } from '@cots/common/autosaving/change';

@Component({
  selector: 'product-quote-detail-new',
  templateUrl: './product-quote-detail-new.component.html',
  styleUrls: ['./product-quote-detail-new.component.less']
})
export class ProductQuoteDetailNewComponent implements OnInit {

  @Input() quote: MaterialBid;
  @Input() editing: boolean;

  public onNewDocs(documents: VirtualDocument[]) {
    const changes: AddMaterialBidDocumentChange[] = documents.map(doc => ({
      changeType: 'ADD_MATERIAL_BID_DOCUMENT',
      entity: null,
      data: {
        materialBidId: this.quote.materialBidId,
        documentId: doc.documentId,
        documentData: doc,
      },
    }));
    this.service.recordChanges(...changes);
  }

  public onDocsDeleted(documents: VirtualDocument[]) {
    const changes: DeleteMaterialBidDocumentChange[] = documents.map(doc => ({
      changeType: 'DELETE_MATERIAL_BID_DOCUMENT',
      entity: null,
      data: {
        materialBidId: this.quote.materialBidId,
        documentId: doc.documentId,
        documentData: doc,
      },
    }));
    this.service.recordChanges(...changes);
  }

  constructor(public service: OrderDetailService) { }

  ngOnInit(): void {
  }

}
