import { Component, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductPurchasedItem } from '../../../../resources/product';
import { WorkflowStep } from '../../../../resources/workflow';
import { Material } from '../../../../resources/material';
import { PurchasingRfqRequest } from '../../../../../common/resources/purchasing-rfq-request';
import { StationService } from '../../../../services/station.service';
import { MatDialog } from '@angular/material/dialog';
import { PurchasingRfqRequestItemType, PurchasingRfqRequestService } from '../../../../../common/services/purchasing-rfq-request.service';
import { Order } from '../../../../resources/order';
import { OrderDetailService, fetchLatest } from '../../order-detail.service';

@Component({
  selector: 'item-rfq-request-status',
  templateUrl: './item-rfq-request-status.component.html',
  styleUrls: ['./item-rfq-request-status.component.less']
})
export class ItemRfqRequestStatusComponent implements OnInit {

  @Input('record') record: Order;
  @Input('product') product: Product;
  @Input('productPurchasedItem') productPurchasedItem: ProductPurchasedItem;
  @Input('workflowStep') workflowStep: WorkflowStep;
  @Input('material') material: Material;
  @Input('purchasingRfqRequest') purchasingRfqRequest: PurchasingRfqRequest;

  public loading = false;

  public getStatusText = PurchasingRfqRequest.getStatusText;
  public getStatusChipClass = PurchasingRfqRequest.getStatusChipClass;

  constructor(
    private service: PurchasingRfqRequestService,
    @Optional() public detailService: OrderDetailService,
    private stationSvc: StationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
  }

  public getItemName() {
    if (this.material) return this.material.materialName;
    else if (this.productPurchasedItem) return this.productPurchasedItem.purchasedItem.description;
    else if (this.workflowStep) return this.stationSvc.stationList.find(s => s.stationId === this.workflowStep.stationId)?.name ?? 'Unknown Station';
    else throw new Error('Tried to get name for unknown item');
  }

  private getItemType(): PurchasingRfqRequestItemType {
    if (this.material) return "material";
    else if (this.productPurchasedItem) return "hardware";
    else if (this.workflowStep) return "outsourced";
    else throw new Error('Tried to get type stringfor unknown item');
  }

  private getItemId(): string {
    if (this.material) return this.product.productId;
    else if (this.productPurchasedItem) return this.productPurchasedItem.productPurchasedItemId;
    else if (this.workflowStep) return this.workflowStep.workflowStepId;
    else throw new Error('Tried to get type stringfor unknown item');
  }

  private async getTotalQuantity(): Promise<number> {
    // TODO make this work in planning
    if (!this.detailService) return 0;
    const productTotalQuantity = this.detailService.getTotalQuantity(
      await fetchLatest(this.detailService.allProducts$),
      this.product.productId,
      this.detailService.getFirstQuantity(this.product),
    );
    if (this.material) {
      const materialLotVolume = Product.getVolume(this.product, this.material);
      const blankVolume = Product.getBlankVolume(this.product, this.material);
      const blanksPerMaterialLot = this.product.partsPerMaterialOverride ? 
        this.product.partsPerMaterial :
        Math.floor(materialLotVolume / blankVolume)
      ;
      const lotsNeeded = Math.ceil(productTotalQuantity / blanksPerMaterialLot);
      return lotsNeeded;
    }
    else if (this.productPurchasedItem) {
      if (this.productPurchasedItem.isNonRecurring) return this.productPurchasedItem.quantity;
      else return this.productPurchasedItem.quantity * productTotalQuantity;
    }
    else if (this.workflowStep) {
      if (this.workflowStep.runIsPerPart) return productTotalQuantity;
      else return 1;
    }
  }

  @ViewChild('matSendRequestDialogTemplate') matSendRequestDialogTemplate: TemplateRef<any>;
  @Output() requestCreated = new EventEmitter<PurchasingRfqRequest>();
  public async submitRequest() {
    const dialogRef = this.dialog.open<any, any, { quantityRequested: number, dueDate?: Date }>(this.matSendRequestDialogTemplate, {
      minWidth: '500px',
      data: {
        dueDate: null,
        quantityRequested: await this.getTotalQuantity(),
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;

    this.loading = true;
    if (this.detailService) await this.detailService.untilSaved();
    const request = await this.service.createRequest(
      this.getItemType(),
      this.getItemId(),
      result.quantityRequested,
      result.dueDate,
      this.record.orderSegmentId,
    ).toPromise();

    this.loading = false;
    this.requestCreated.emit(request);
  }

  public async viewQuote() {
    const quote = await fetchLatest(this.detailService.getQuoteObservable(this.purchasingRfqRequest.selectedQuoteId));
    this.detailService.openSidenav('quoteDetail', { itemId: '', quote });
  }

}
