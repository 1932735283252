<div class="h-100 d-flex flex-column">
<div class="d-flex align-items-center p-3 w-100 border border-bottom">
  <h3>Assembly</h3>
  <div class="ml-auto"></div>
  <button mat-stroked-button class="ml-auto" [disabled]="loading" *ngIf="!sorting && service.editing"
    (click)="sorting = true">
    <mat-icon matPrefix>edit</mat-icon>
    Edit Hierarchy
  </button>
  <button mat-stroked-button class="ml-2 mat-square-button" [disabled]="loading" *ngIf="!sorting"
    (click)="expandAll()">
    <mat-icon matPrefix svgIcon="expand-all"></mat-icon>
  </button>
  <button mat-stroked-button [disabled]="loading" *ngIf="sorting" (click)="sorting = false">
    <mat-icon matPrefix>check</mat-icon>
    Done Editing
  </button>
</div>
<div [matMenuTriggerFor]="contextMenu" #menuTrigger="matMenuTrigger" style="position: fixed;" [style]="{
    left: menuPosition.x + 'px',
    top: menuPosition.y + 'px'
  }">
</div>
<mat-menu #contextMenu>
  <button mat-menu-item *ngLet="topProduct$ | async as topProduct" (click)="menuAddChild(menuItem ?? null, topProduct)">
    <mat-icon>add_box</mat-icon>
    <span *ngIf="menuItem">
      Add Blank Child to <b>{{menuItem.name}}</b>
    </span>
    <span *ngIf="!menuItem">
      Add Blank Child to <b>{{topProduct.partNumber}} Rev. {{topProduct.revision}}</b>
    </span>
  </button>
  <button mat-menu-item *ngLet="topProduct$ | async as topProduct" (click)="menuCloneChild(menuItem ?? null, topProduct)">
    <mat-icon>add_box</mat-icon>
    <span *ngIf="menuItem">
      Clone Child to <b>{{menuItem.name}}</b>
    </span>
    <span *ngIf="!menuItem">
      Clone Child to <b>{{topProduct.partNumber}} Rev. {{topProduct.revision}}</b>
    </span>
  </button>
  <button mat-menu-item *ngIf="menuItem && menuItem.parentAssemblyId" (click)="menuDuplicateChild(menuItem)">
    <mat-icon>content_copy</mat-icon>
    Duplicate <b>{{menuItem.name}}</b>
  </button>
  <button mat-menu-item *ngIf="menuItem && menuItem.parentAssemblyId" (click)="menuDelete(menuItem)">
    <mat-icon class="text-danger">delete</mat-icon>
    Delete <b>{{menuItem.name}}</b>
  </button>
</mat-menu>
<mat-tree [dataSource]="data" [treeControl]="treeControl" class="tree"
  *ngLet="{ selectedProductId: service.selectedProductIdSubject | async, cachedData: data | async } as asyncData" cdkDropList
  (cdkDropListSorted)="dragSorted($event, asyncData.cachedData)" (cdkDropListDropped)="drop($event, asyncData.cachedData)" (contextmenu)="openMenu($event, null)">
  <mat-tree-node *matTreeNodeDef="let node; when: isAdding" matTreeNodePadding (contextmenu)="$event.stopPropagation()"
    (focusout)="cancelAdding($event, node)" tabindex="0" id="adding-text-field-container">
    <div class="px-1 w-100 d-flex align-items-center">
      <button mat-icon-button disabled *ngIf="!node.loading"></button>
      <button mat-icon-button disabled *ngIf="node.loading">
        <i class="fas fa-circle-notch fa-spin fa-fw text-primary mr-2" *ngIf="node.loading"></i>
      </button>
      <input class="adding-text-field" style="padding-left: 13px;" [(ngModel)]="addingName"
        (keydown.enter)="saveAdding(addingName, asyncData.cachedData)" type="text" id="adding-text-field">
      <button mat-flat-button class="ml-2" (click)="saveAdding(addingName, asyncData.cachedData)" [disabled]="!addingName">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: isLoading" matTreeNodePadding
    [ngClass]="shouldRender(node, data | async) ? 'd-flex' : 'd-none'"
    disabled>
    <mat-icon class="text-muted drag-handle" [class.invisible]="true">
      drag_handle
    </mat-icon>
    <div class="loading-spinner-container">
      <i class="fa fa-spin fa-circle-notch text-primary"></i>
    </div>
    <div class="text-muted">
      {{node.name}}
    </div>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
    [ngClass]="shouldRender(node, data | async) ? 'd-flex' : 'd-none'"
    [class.node-selected]="node.productId === asyncData.selectedProductId" [class.node-clickable]="!sorting" matRipple
    [matRippleDisabled]="sorting" matRippleColor="rgba(0,0,0,.04)" cdkDrag [cdkDragData]="node"
    [cdkDragDisabled]="!sorting || node.level === 0" (cdkDragStarted)="dragStarted($event, asyncData.cachedData)"
    (cdkDragMoved)="dragMoved($event, asyncData.cachedData)" (contextmenu)="openMenu($event, node)"
    (click)="selectProduct(node)">
    <div [ngStyle]="{ 'padding-left': ((node.level * 40) + 13) + 'px' }" *cdkDragPlaceholder>
      <div class="mat-tree-node rounded bg-primary"></div>
    </div>
    <mat-icon class="text-muted drag-handle" cdkDragHandle [class.invisible]="!sorting || node.level === 0" (mousedown)="sorting && beforeDragStarted(node)">
      drag_handle
    </mat-icon>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
      [style.visibility]="node.hasChildren ? 'visible' : 'hidden'">
      <mat-icon class="mat-icon-rtl-mirror">
        {{!treeControl.isExpanded(node) ? 'chevron_right' : 'expand_more'}}
      </mat-icon>
    </button>
    <div>
      {{node.name}}
    </div>
    <ng-template *ngIf="postNameTemplate" [ngTemplateOutlet]="postNameTemplate" [ngTemplateOutletContext]="{ $implicit: node, expanded: treeControl.isExpanded(node) }"></ng-template>
  </mat-tree-node>
</mat-tree>
</div>

<ng-template #cloneChildDialog let-data>
  <h2 matDialogTitle>Clone Child</h2>
  <mat-dialog-content>
    <existing-product-select class="d-block w-100" [allowNew]="false" [allowNewRev]="false" (selected)="data.value = $event"></existing-product-select>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!data.value" [matDialogClose]="data.value">Clone</button>
  </mat-dialog-actions>
</ng-template>
