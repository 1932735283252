import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges, TemplateRef, AfterViewInit, Optional } from '@angular/core';
import { Product, ProductMaterialDimension, ProductPurchasedItem } from '../../../order/resources/product';
import { MatSidenav } from '@angular/material/sidenav';
import { WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { Station } from '../../../order/resources/station';
import { NgForm, NgModel } from '@angular/forms';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { NavigationService } from '../../../common/services/navigation.service';
import { OrderService } from '../../../order/services/order.service';
import { StationService } from '../../../order/services/station.service';
import { UtilityService } from '../../../common/services/utility.service';
import { ChartComponent, ChartDataSet } from '../../../common/components/chart/chart.component';
import { Material } from '../../../order/resources/material';
import { Building } from '../../../floor/resources/building';
import { WorkOrder } from '../../../planning/resources/work-order';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { UserService } from '../../../common/services/user.service';
import { Paint } from '../../resources/paint';
import { MaterialSelectComponent } from '../material-select/material-select.component';
import { PurchasedItemListComponent } from '../purchased-item-list/purchased-item-list.component';
import { PurchasedItem } from '../../resources/purchased-item';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { Customer } from '../../../customer/resources/customer';
import { StationListComponent } from '../station-list/station-list.component';
import { ShippingTicket } from '../../../shipping/resources/shipping-ticket';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { WorkOrderShippingAssignment } from '../../../planning/services/work-order.service';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ProductHistoryComponent } from '../product-history/product-history.component';
import { CopyPartDialogComponent } from '../copy-part-dialog/copy-part-dialog.component';
import { Vendor } from '../../../supplier/resources/vendor';
import { Order, OrderSegmentProductReviewStatus } from '../../resources/order';
import { TaskStatus } from '../../../common/resources/estimatingtask';

@Component({
  selector: 'product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.less']
})
export class ProductDetailComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() product: Product;
  @Input() sidenav: MatSidenav;
  @Input() orderId: string;
  @Input() order?: Order;
  @Input() customer: Customer | null;
  @Input() canEdit: boolean = false;
  @Input() standAlone: boolean = false;
  @Input() workOrder: WorkOrder = null;
  @Input() editing: boolean = false;
  @Output() productChange: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() productUpdate: EventEmitter<Product> = new EventEmitter<Product>();
  @Input() shippingAssignments: WorkOrderShippingAssignment[] = null;
  @Output() shippingAssignmentsChange: EventEmitter<WorkOrderShippingAssignment[]> = new EventEmitter<WorkOrderShippingAssignment[]>();
  @Input() department?: string;
  @Input() shouldPromptHistory = true;
  @Input() rapidEdit = false;
  public selectedPart: Product;
  public childrenLoaded: boolean = false;
  public showEditor: string = null;
  @ViewChild('workflowCostChart') workflowCostChart: ChartComponent;
  public selectedWorkflowStep: WorkflowStep;
  public selectedPurchasedItem: ProductPurchasedItem;
  public newQuoteData: MaterialBid = null;
  public selectedTab: number = 0;
  public detailedQuoteId: string = null;
  public totalCost?: number = null;
  public previewPart: Product = null;
  @ViewChild('insetnav', { static: true }) insetnav: MatSidenav;
  @ViewChild('partNumber') partNumber: NgModel;
  @ViewChild('partRevision') partRevision: NgModel;
  public newMaterialType: 'new' | 'search' = 'new';
  @ViewChild('materialSelect') materialSelect: MaterialSelectComponent;
  @ViewChild('stationList') stationList: StationListComponent;
  public saving: boolean = false;
  public loading: boolean = false;
  private workflowDirty: boolean = false;
  public similarPartOverlayOpen: boolean = false;
  private similarItemsApplied: boolean = false;

  constructor(
    private navService: NavigationService, private orderSvc: OrderService, private matBidSvc: MaterialBidService, private stationSvc: StationService, private utilitySvc: UtilityService,
    private usrService: UserService, private dialog: MatDialog,
  ) { }

  public selectProduct(product: Product): void {
    this.selectedPart = product;
    this.showEditor = "part";
    this.navService.pushBreadcrumb(this.selectedPart.partNumber + ' Rev ' + this.selectedPart.revision);
    this.insetnav.toggle();
  }

  public deletePart(product: Product): void {
    this.utilitySvc.showConfirmation(`Remove Part ${product.partNumber} Rev. ${product.revision}?`, "<p>Are you sure you want to remove this part from the order?</p>", r => {
      if (r) {
        this.saving = true;
        this.orderSvc.removeSubComponent(this.product, product).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    });
  }

  public deletePartChild({ parent, child }: { parent: Product, child: Product }): void {
    this.utilitySvc.showConfirmation(`Remove Part ${child.partNumber} Rev. ${child.revision}?`, "<p>Are you sure you want to remove this part from the order?</p>", r => {
      if (r) {
        this.saving = true;
        this.orderSvc.removeSubComponent(parent, child).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    });
  }

  public isPurchasingUser(): boolean {
    return this.usrService.canAccess("PurchasingUser");
  }

  public isDeveloper(): boolean {
    return this.usrService.canAccess("Developer");
  }

  public showQuoteDetail(quoteId: string): void {
    if (quoteId == null) {
      this.utilitySvc.showAlert("No Quote was Found", "<p>Something seems to have gone wrong - we couldn't find a quote for the item you requested.</p><p class='text-muted'>If you continue to receive this error, please alert a management team member.</p>");
      return;
    }

    this.detailedQuoteId = quoteId;
    this.showEditor = "quotedetail";
    this.insetnav.open();
  }

  public setStep(item: WorkflowStep): void {
    if (this.workflowDirty) {
      this.utilitySvc.showConfirmation("Save First?", "<p>Would you like to save the item you're working on first?</p><p class='text-muted'>If you choose to cancel, any changes you made will be lost. Choose 'Okay' to save.</p>", (r => {
        if (r) {
          this.saving = true;
          if (this.isRepair) {
            this.orderSvc.saveRepairWorkflowStep(this.product.productRepairPlan, this.selectedWorkflowStep).subscribe(result => {
              this.selectedWorkflowStep.workflowId = result.workflowId;
              this.selectedWorkflowStep.workflowStepId = result.workflowStepId;

              this.selectedWorkflowStep = item;
              this.onToggleFirstPartInspection(true);
              this.saving = false;
            });
          } else {
            this.orderSvc.saveWorkflowStep(this.product, this.selectedWorkflowStep).subscribe(result => {
              this.selectedWorkflowStep.workflowId = result.workflowId;
              this.selectedWorkflowStep.workflowStepId = result.workflowStepId;

              this.selectedWorkflowStep = item;
              this.onToggleFirstPartInspection(true);
              this.saving = false;
            });
          }
        }
        else {
          this.selectedWorkflowStep = item;
          this.onToggleFirstPartInspection(true);
        }
      }));
    }
    else {
      this.selectedWorkflowStep = item;
    }

    this.workflowDirty = false;
  }

  public getStation(stationId: string): Station {
    if (!this.stationSvc.loaded) return null;
    return this.stationSvc.stationList.find(s => s.stationId == stationId);
  }

  public sidebarClosing(): void {
    this.showEditor = null;
    this.navService.popBreadCrumb();

    this.getDetail();
  }

  public sidebarOpening(): void {
    if (this.showEditor != 'previewPart') {
      this.similarPartOverlayOpen = false;
    }
  }

  public async cancelEditing() {
    if (this.editing) {
      if (this.rapidEdit) {
        const r = await this.utilitySvc.showConfirmationPromise('Really close?', 'All changes will be lost.');
        if (!r) return;
        else {
          this.sidenav.close();
          return;
        }
      }
      this.toggleEditing();
      return;
    }

    if (this.sidenav)
      this.sidenav.close();
  }

  public weeksLeft(date: string, abs: boolean): number {
    if (date == null) return null;

    return UtilityService.getWeeksRemaining(new Date(date), abs);
  }

  public addPart(): void {
    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered before continuing.</p>");
      return;
    }

    this.showEditor = "addProduct";
    this.navService.pushBreadcrumb("Add Part");
    this.insetnav.toggle();
  }

  public async copyPart() {
    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered before continuing.</p>");
      return;
    }

    const dialogRef = this.utilitySvc.dialogService.open(CopyPartDialogComponent, {
      disableClose: true,
      minWidth: 500,
      data: { orderId: this.orderId }
    });

    const result: Product | null = await dialogRef.afterClosed().toPromise();
    
    if (!result) return;
    this.saving = true;
    this.orderSvc.savePart(this.product).subscribe(_ => {
      //Reset the ID - this effectively copies the part
      result.productId = UtilityService.newGuid();

      this.orderSvc.addSubComponent(this.product.productId, result).subscribe(_ => {
        this.saving = false;
        this.getDetail();
      });
    });

  }

  public addNewPart(product: Product): void {
    this.saving = true;
    this.orderSvc.savePart(this.product).subscribe(_ => {
      this.orderSvc.addSubComponent(this.product.productId, product).subscribe(_ => {
        this.saving = false;
        this.getDetail();
      });
    });

  }

  public getBlankDimension(mtdId: string) {
    const dimensions = this.product.blankDimensions || [];
    const out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setBlankDimension(mtdId: string, value: number) {
    const dimensions = this.product.blankDimensions || [];
    const dim = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      dimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
  }

  public getFinishedDimension(mtdId: string) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setFinishedDimension(mtdId: string, value: number) {
    const dimensions = this.product.finishedDimensions || [];
    const dim = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      dimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
  }

  public get rawWeight(): number {
    if (this.newMaterialType === 'new' || !this.editing) {
      if (!this.materialSelect || !this.materialSelect.calculatedVolume || !this.materialSelect.materialDensity) return null;
      return this.materialSelect && this.materialSelect.calculatedVolume * this.materialSelect.materialDensity
    } else {
      return Material.getVolume(this.product.material) * this.product.material.density;
    }
  }

  public get blankWeight(): number {
    return Product.getBlankWeight(this.product);
  }

  public get finishedWeight(): number {
    return Product.getFinishedWeight(this.product);
  }

  public get materialRemoved(): number {
    return Product.getMaterialRemoved(this.product);
  }

  public get piecesFromLot(): number {
    if (Product.getBlankVolume(this.product) === 0) return 0;
    return Math.ceil(Product.getVolume(this.product) / Product.getBlankVolume(this.product))
  }

  public get piecesFromLotAfterOverride(): number {
    return this.product.partsPerMaterialOverride ? this.product.partsPerMaterial : this.piecesFromLot;
  }

  public get materialMarkupFactor(): number {
    if (!this.product.materialMarkup) return 1;
    return (1 + ((this.product.materialMarkup || 0.0) / 100.0));
  }

  public get materialCost(): number {
    return Product.getMaterialCost(this.product, this.firstQuantity);
  }

  public togglePartsPerMaterialOverride(e) {
    e.preventDefault();
    this.product.partsPerMaterialOverride = !this.product.partsPerMaterialOverride;
  }

  public initializeWorkingDimensions() {
    if (!this.product.material) {
      this.product.blankDimensions = [];
      this.product.finishedDimensions = [];
      return;
    } else {
      const defaultDimensions = this.product.material.materialType.materialTypeDimensions.map<ProductMaterialDimension>(mtd => {
        const dim = this.product.material.materialDimensions.find(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)
        if (!dim) return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: 0 };
        else return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: dim.value };
      })
      this.product.blankDimensions = defaultDimensions;
      this.product.finishedDimensions = defaultDimensions;
    }
  }

  public get complexity() {
    if (this.product.complexity) return this.product.complexity
    return Math.round((this.materialRemoved || 0) / 10);
  }

  public set complexity(val) {
    Product.overrideComplexity(this.product, val)
  }

  public resetComplexity() {
    Product.resetComplexity(this.product)
  }

  public toggleEditing(): void {
    if (this.product.productId == "new" || this.product.productId == UtilityService.emptyGuid) {
      if (this.sidenav)
        this.sidenav.close();
    }

    this.getDetail();
    this.editing = !this.editing;
  }

  public forceVendor: Vendor | null;
  public async showQuickQuote(material: Material, workflowStep: WorkflowStep, purchasedItem: PurchasedItem): Promise<void> {

    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered.</p>");
      return;
    }

    this.saving = true;

    if (purchasedItem && this.selectedPurchasedItem && (this.selectedPurchasedItem.productPurchasedItemId === UtilityService.emptyGuid || this.purchasedItemDirty) && this.purchasedItemForm.valid) {
      const r = await this.orderSvc.saveProductPurchasedItem(this.selectedPurchasedItem).toPromise();
      this.selectedPurchasedItem = r;
      this.resetPurchasedItemForm(r);
    }

    this.orderSvc.savePart(this.product).subscribe(_ => {
      this.saving = false;

      const station = workflowStep ? this.getStation(workflowStep.stationId) : null;

      this.newQuoteData = <MaterialBid>{
        orderId: this.orderId,
        productId: this.product.productId,
        materialBidId: UtilityService.emptyGuid,
        material: material,
        materialId: material ? material.materialId : null,
        station: station,
        stationId: station ? station.stationId : null,
        purchasedItem: purchasedItem,
        purchasedItemId: purchasedItem ? purchasedItem.purchasedItemId : null,
        asked: new Date(),
        answered: new Date(),
        isVerbal: false
      };
      this.forceVendor = null;

      this.showEditor = "quickquote";
      this.navService.pushBreadcrumb("Add Quick Quote");
      this.insetnav.toggle();
    });
  }

  public async copyOldQuote(oldQuote: MaterialBid): Promise<void> {

    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered.</p>");
      return;
    }

    this.saving = true;

    this.orderSvc.savePart(this.product).subscribe(_ => {

      this.matBidSvc.get(oldQuote.materialBidId).subscribe(q => {
        this.saving = false;
        this.newQuoteData = <MaterialBid>{
          ...q,
          materialBidDocuments: [],
          orderId: this.orderId,
          productId: this.product.productId,
          materialBidId: UtilityService.emptyGuid,
          asked: new Date(),
          answered: new Date(),
          accepted: null,
          acceptedBy: null
        };
        this.forceVendor = q.vendor;

        this.showEditor = "quickquote";
        this.navService.pushBreadcrumb("Refresh Old Quote");
        this.insetnav.toggle();
        });

    });
  }

  public openDocumentWindow(): void {
    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered before continuing.</p>");
      return;
    }

    this.showEditor = "document";
    this.navService.pushBreadcrumb("Add Documents");
    this.insetnav.toggle();
  }

  public getRequiredColor(date: string): string {
    return UtilityService.getDateRequiredColor(date);
  }

  @ViewChild('purchasedItemForm') purchasedItemForm: NgForm;

  private oldPurchasedItem: ProductPurchasedItem;

  public resetPurchasedItemForm(item: ProductPurchasedItem | null) {
    if (item && this.purchasedItemForm) {
      this.purchasedItemForm.form.reset(item);
      this.purchasedItemForm.form.markAsUntouched();
      this.purchasedItemForm.form.markAsPristine();
    };
  }


  @ViewChild(PurchasedItemListComponent) purchasedItemList: PurchasedItemListComponent;


  public setSelectedPurchasedItem(item: ProductPurchasedItem): void {
    if (this.selectedPurchasedItem && this.selectedPurchasedItem.productPurchasedItemId === UtilityService.emptyGuid) {
      this.utilitySvc.showConfirmation("Continue?", "Exiting a newly-created line item without saving it will delete it.", (r) => {
        if (r) {
          this.product.purchasedItems = this.product.purchasedItems.filter(x => x.productPurchasedItemId !== UtilityService.emptyGuid);
          this.selectedPurchasedItem = item;
          this.resetPurchasedItemForm(item);
          this.saving = false;
        } else {
          this.purchasedItemList.selectedItem = this.selectedPurchasedItem;
        }
      });
      return;
    }
    if (this.purchasedItemDirty) {
      this.utilitySvc.showConfirmation("Save First?", "<p>Would you like to save the item you're working on first?</p><p class='text-muted'>If you choose to cancel, any changes you made will be lost. Choose 'Okay' to save.</p>", (r => {
        if (r) {
          this.saving = true;
          this.orderSvc.saveProductPurchasedItem(this.selectedPurchasedItem).subscribe(result => {
            this.selectedPurchasedItem.productId = result.productId;
            this.selectedPurchasedItem.purchasedItemId = result.purchasedItemId;

            this.selectedPurchasedItem = item;
            this.resetPurchasedItemForm(item);
            this.saving = false;
          });
        }
        else {
          Object.assign(this.selectedPurchasedItem, this.oldPurchasedItem);
          this.selectedPurchasedItem = item;
          this.resetPurchasedItemForm(item);
        }
      }));
    }
    else {
      this.selectedPurchasedItem = item;
      this.resetPurchasedItemForm(item);
    }

    this.oldPurchasedItem = JSON.parse(JSON.stringify(item));

  }

  public get purchasedItemDirty() {
    return this.selectedPurchasedItem && this.purchasedItemForm && this.purchasedItemForm.dirty && this.purchasedItemForm.touched;
  }

  public setWorkflowDirty(): void {
    this.workflowDirty = true;
    this.chartData = this.getWorkflowPricingData();
  }

  public get firstQuantity(): number {
    if (this.product.quantityAsChild) return this.product.quantityAsChild;
    if (this.product.orderQuantity) return this.product.orderQuantity;
    if (this.product.quantitiesMap == null) return null;

    var quantities = this.product.quantitiesMap;

    if (quantities.length == 0) return null;

    return quantities[0].value;
  }

  public saveWorkflowQuoteSelection(bid: MaterialBid): void {
    let runPrice: number = 0;
    if (this.selectedWorkflowStep.runIsPerPart) {
      runPrice = bid.totalBid ? (bid.totalBid / this.firstQuantity) : bid.perItemBid;
    }
    else {
      runPrice = bid.totalBid ? (bid.totalBid) : (bid.perItemBid * bid.qty);
    }
    this.selectedWorkflowStep.runPrice = runPrice;
    this.matBidSvc.accept(bid).subscribe(_ =>
      this.saveSelectedWorkflowStep()
    );
  }

  public savePurchasedItemQuoteSelection(bid: MaterialBid): void {
    let costPer: number = 0;
    if (this.selectedPurchasedItem.isAmortized) {
      costPer = bid.totalBid ? (bid.totalBid / this.firstQuantity) : bid.perItemBid;
    } else {
      costPer = bid.totalBid ? (bid.totalBid / bid.qty) : bid.perItemBid;
    }
    this.selectedPurchasedItem.costPer = costPer;
    this.matBidSvc.accept(bid).subscribe(_ =>
      this.saveSelectedPurchasedItem()
    );
  }

  public saveMaterialQuoteSelection(bid: MaterialBid): void {
    this.product.materialLotCost = bid.totalBid ? (bid.totalBid / bid.qty) : bid.perItemBid;
    this.saving = true;
    this.matBidSvc.accept(bid).subscribe(_ =>
      this.saveChanges(false)
    );
  }

  public async deleteQuote(bid: MaterialBid, type: 'material' | 'workflow' | 'purchased') {
    const c = await this.utilitySvc.showConfirmationPromise('Are you sure?', `Do you really want to delete this quote from <b>${bid.vendor.name}</b>? This cannot be undone.`);
    if (!c) return;
    this.saving = true;
    // make sure we don't lose any changes
    const p = await this.orderSvc.savePart(this.product).toPromise();
    this.product = p;
    await this.matBidSvc.delete(bid).toPromise();
    // need to deselect quote if it's the selected one for the current type
    switch (type) {
      case 'material':
        if (this.product.selectedMaterialQuote === bid.materialBidId) {
          this.product.selectedMaterialQuote = null;
          this.product.materialLotCost = null;
          await this.orderSvc.savePart(this.product).toPromise();
        }
        break;
      case 'purchased':
        if (this.selectedPurchasedItem.selectedQuote === bid.materialBidId) {
          this.selectedPurchasedItem.selectedQuote = null;
          this.selectedPurchasedItem.costPer = null;
          await this.orderSvc.saveProductPurchasedItem(this.selectedPurchasedItem).toPromise();
        }
        break;
      case 'workflow':
        if (this.selectedWorkflowStep.selectedQuote === bid.materialBidId) {
          this.selectedWorkflowStep.selectedQuote = null;
          this.selectedWorkflowStep.runPrice = 0;
          if (this.isRepair) await this.orderSvc.saveRepairWorkflowStep(this.product.productRepairPlan, this.selectedWorkflowStep).toPromise();
          else await this.orderSvc.saveWorkflowStep(this.product, this.selectedWorkflowStep).toPromise();
        }
        break;
    }
    this.saving = false;
    this.getDetail();
  }

  public getPaintSize(paint: Paint): string {
    if (!paint) return '';
    return Paint.PaintVolumes[paint.purchaseVolume];
  }

  public async removePurchasedItem(item: ProductPurchasedItem) {
    this.saving = true;
    if (item.productPurchasedItemId !== UtilityService.emptyGuid) await this.orderSvc.removeProductPurchasedItem(item).toPromise();
    this.selectedPurchasedItem = null;
    this.purchasedItemList.selectedItem = null;
    this.saving = false;
  }

  public removeWorkflowStep(item: WorkflowStep): void {
    this.saving = true;
    this.orderSvc.removeWorkflowStep(item.workflowStepId).subscribe(_ => {
      this.saving = false;
    });
  }

  public saveSelectedWorkflowStep(): void {
    this.saving = true;
    if (this.isRepair) {
      this.orderSvc.saveRepairWorkflowStep(this.product.productRepairPlan, this.selectedWorkflowStep).subscribe(result => {
        this.selectedWorkflowStep = result;
        this.workflowDirty = false;
        this.saving = false;
        this.chartData = this.getWorkflowPricingData();
      });
    } else {
      this.orderSvc.saveWorkflowStep(this.product, this.selectedWorkflowStep).subscribe(result => {
        this.selectedWorkflowStep = result;
        this.workflowDirty = false;
        this.saving = false;
        this.chartData = this.getWorkflowPricingData();
      });
    }
  }

  public saveSelectedPurchasedItem(): void {
    this.saving = true;
    this.orderSvc.saveProductPurchasedItem(this.selectedPurchasedItem).subscribe(result => {
      this.selectedPurchasedItem = result;
      this.resetPurchasedItemForm(result);
      this.saving = false;
    });
  }

  public addToWorkflow(station: Station): void {
    if (this.editing) {
      var newStep: WorkflowStep = <WorkflowStep>{
        workflowStepId: UtilityService.emptyGuid,
        stepType: this.isRepair ? WorkflowStepType.Repair : WorkflowStepType.Standard,
        stationId: station.stationId,
        runPrice: station.isOutsourceStep ? null : station.stdCostPerHour,
        runIsPerPart: station.perPart,
        isAdministrative: station.isAdministrative,
        isAssembly: station.isAssembly,
        isStandalone: station.isStandalone || false,
        hasSetup: station.hasSetup ? true : (station.isOutsourceStep ? false : true),
        setupTime: station.defaultSetupTime,
        outsourceMarkup: station.isOutsourceStep ? UtilityService.defaultMarkup : null,
        workflowId: this.product.workflowWorkflowId
      };

      if (station.isPainting) {
        newStep.runTime = 1;
        newStep.runPrice = 0.065;
        newStep.paintCost = 0.125;
        newStep.paintMinPrice = 350;
      }

      this.saving = true;
      if (this.isRepair) {
        this.orderSvc.saveRepairWorkflowStep(this.product.productRepairPlan, newStep).subscribe(result => {
          this.product.productRepairPlan.workflow.workflowSteps.push(result);
          this.saving = false;
        });
      } else {
        this.orderSvc.saveWorkflowStep(this.product, newStep).subscribe(result => {
          this.product.workflow.workflowSteps.push(result);
          this.saving = false;
        });
      }
    }
  }

  public addPurchasedItem(item: ProductPurchasedItem): void {

    if (this.isRepair) item.productRepairPlanId = this.product.productRepairPlan.productRepairPlanId;
    else item.productId = this.product.productId;
    item.productPurchasedItemId = UtilityService.emptyGuid;

    // don't want to save until a purchased item is selected
    // if (this.editing) {
    //   this.saving = true;
    //   this.orderSvc.saveProductPurchasedItem(item).subscribe(x => {
    //     item.productId = x.productId;
    //     item.purchasedItemId = x.purchasedItemId;

    //     this.saving = false;
    //   });
    // }
  }

  public assignPaint(paint: Paint): void {
    this.setWorkflowDirty();
    if (paint) {
      this.selectedWorkflowStep.paintId = paint.paintId;
      this.selectedWorkflowStep.paint = paint;
    }
  }

  public addPaint(_name: string): void {
    this.showEditor = "paintEditor";
    this.navService.pushBreadcrumb("Add Paints");
    this.insetnav.toggle();
  }

  public async saveChanges(close: boolean): Promise<void> {
    if ((this.partNumber && this.partNumber.invalid) || (this.partRevision && this.partRevision.invalid)) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered.</p>");
      return;
    }

    // Check that the material-select has been properly filled out
    if (this.newMaterialType === 'new' && !this.materialSelect.materialFullyInput && this.materialSelect.dirty) {
      this.utilitySvc.showAlert("Material Data Not Filled Out", "<p>Please make sure all fields on the material are selected before saving.</p>");
      return;
    }
    if (this.workflowDirty) {
      this.saveSelectedWorkflowStep();
    }
    if (this.purchasedItemDirty) {
      this.saveSelectedPurchasedItem();
    }

    this.saving = true;

    if (this.newMaterialType === 'new' && this.materialSelect.dirty) {
      const newMaterial = await this.materialSelect.onSave();
      // reset dimensions
      this.initializeWorkingDimensions();
      this.product.material = newMaterial;
      this.product.materialId = newMaterial.materialId;
    }

    var _ = await this.orderSvc.savePart(this.product).toPromise();

    if (this.similarItemsApplied) {
      await this.saveSimilarPartChanges();
    }

    this.doAfterSave(close);
  }

  private async saveSimilarPartChanges() {
    //Save off the workflow and purchased items that were added
    if (this.product.workflow && this.product.workflow.workflowSteps) {
      var steps: WorkflowStep[] = this.product.workflow.workflowSteps.filter(step => step.isFromCompare);

      var _w = await this.orderSvc.saveWorkflowSteps(this.product, steps).toPromise();
    }

    if (this.product.purchasedItems) {
      var items: ProductPurchasedItem[] = this.product.purchasedItems.filter(item => item.isFromCompare);

      var _p = await this.orderSvc.saveProductPurchasedItems(this.product, items).toPromise();
    }
  }

  private async doAfterSave(close: boolean) {
    this.orderWorkflow(this.product.workflow && this.product.workflow.workflowSteps || []);

    this.saving = this.similarItemsApplied = false;

    var prod = this.product;
    this.productUpdate.emit(this.product);

    if (!this.rapidEdit) {
      this.getDetail();
    }

    if (close && this.shouldPromptHistory) {
      if (prod.parentAssemblyId) {
        const parent = await this.orderSvc.getTopParent(prod).toPromise();
        await this.promptRevision(parent);
      } else {
        await this.promptRevision();
      }
    }

    if (close && this.sidenav) {
      const r = this.rapidEdit ? true : await this.utilitySvc.showConfirmationPromise("Are You Finished?", "<p>If you are ready to return to the previous screen, click 'Okay'. Click 'Cancel' to stay here and make further changes.</p>");
      if (r) this.sidenav.close();
    }
  }

  @ViewChild('partHistoryDialog', { static: true }) partHistoryDialog: TemplateRef<any>;
  @ViewChild('historyComponent') historyComponent: ProductHistoryComponent;
  public async promptRevision(parent?: Product) {
    const ref = this.dialog.open(this.partHistoryDialog, {
      disableClose: true
    });
    const r = await ref.afterClosed().toPromise();
    if (r === null) return;
    const { note } = r;
    this.saving = true;
    await this.orderSvc.saveProductHistory(parent ? parent : this.product, {
      timestamp: new Date(),
      note: parent ? `Subassembly ${this.product.partNumber} - ${note}` : note,
      department: this.department || 'Unknown'
    }).toPromise();
    if (this.historyComponent) this.historyComponent.ngOnInit();
    this.saving = false;
  }

  public getWorkflowPricingData(): ChartDataSet[] {
    var workflowData: ChartDataSet = <ChartDataSet>{ label: "Workflow Cost Breakdown", data: [], dataPointLabels: [], backgroundColor: [] };
    const qty = this.getQuantity();
    this.product.workflow.workflowSteps.forEach((s, i) => {
      let cost = WorkflowStep.calculatePerItemCost(s, qty);
      const mainColor = ChartComponent.availableColors[i % ChartComponent.availableColors.length];
      // break out programming
      if (s.hasProgramming) {
        const pc = ((s.programmingTime || 0) * (s.programmingRate || 0)) / qty;
        cost -= pc;
        workflowData.dataPointLabels.push(`Programming - ${(this.getStation(s.stationId) || { name: "" }).name}`);
        workflowData.data.push(pc);
        workflowData.backgroundColor.push(ChartComponent.shade(0.50, mainColor, false, true));
      }
      // break out inspection
      let ic: number = 0;
      if (s.hasInspection || s.hasFirstPartInspection) {
        const firstPartInspectionCost = s.hasFirstPartInspection ? (((s.firstPartInspectionTime || 0) / 60) * (s.firstPartInspectionRate || 0)) : 0;
        ic += firstPartInspectionCost / qty;
        const inspectionCost = s.hasInspection ? (((s.inspectionTime || 0) / 60) * (s.inspectionRate || 0)) : 0;
        let inspectionTimes: number;
        if (!s.inspectionIsBatched || isNaN(s.inspectionBatchSize) || s.inspectionBatchSize < 1) inspectionTimes = qty;
        else inspectionTimes = Math.ceil(qty / s.inspectionBatchSize);
        const inspectionCostTimes = inspectionCost * inspectionTimes;
        ic += inspectionCostTimes / qty;
        cost -= ic;
      }
      workflowData.dataPointLabels.push((this.getStation(s.stationId) || { name: "" }).name);
      workflowData.data.push(cost);
      workflowData.backgroundColor.push(mainColor);
      if (s.hasInspection || s.hasFirstPartInspection) {
        workflowData.dataPointLabels.push(`Inspection - ${(this.getStation(s.stationId) || { name: "" }).name}`);
        workflowData.data.push(ic);
        workflowData.backgroundColor.push(ChartComponent.shade(-0.25, mainColor, false, true));
      }
    });

    return [workflowData];
  }

  public setMaterial(m: Material): void {
    this.product.materialId = m.materialId;
    this.product.material = m;
  }

  public addMaterial(): void {
    if (this.partNumber.invalid || this.partRevision.invalid) {
      this.partRevision.control.markAsTouched();
      this.partNumber.control.markAsTouched();

      this.utilitySvc.showAlert("Part Number and Revision Required", "<p>Please make sure a part number and revision are entered before continuing.</p>");
      return;
    }

    this.showEditor = "materials";
    this.navService.pushBreadcrumb("Add Materials");
    this.insetnav.toggle();
  }

  public deleteDocument(document: VirtualDocument): void {
    this.utilitySvc.showConfirmation("Are you Sure?", "<p>Are you sure you want to remove this document?</p><p class='text-muted font-weight-bold'>This cannot be undone.</p>", (r => {
      if (r) {
        this.saving = true;
        this.orderSvc.removeDocumentFromPart(this.product, document).subscribe(_ => {
          this.saving = false;
          this.getDetail();
        });
      }
    }));
  }

  addDocuments(documents: VirtualDocument[]): void {
    this.saving = true;

    this.orderSvc.savePart(this.product).subscribe(_ => {

      this.orderSvc.addDocumentsToPart(this.product, documents).subscribe(
        _ => {
          this.saving = false;
          this.getDetail();
        }
      );

    });
  }

  public getShippingAssignment(step: WorkflowStep): WorkOrderShippingAssignment {
    if (step == null) return null;

    return this.shippingAssignments.find(a => a != null && a.workflowStepId == step.workflowStepId);
  }

  public setShippingAssignmentOutgoing(step: WorkflowStep, building: Building): void {

    if (building == null || step == null)
      return;

    var assignment = this.getShippingAssignment(step);

    if (assignment == null) {
      assignment = <WorkOrderShippingAssignment>{
        workflowStepId: step.workflowStepId,
        sendingBuildingId: building.buildingId,
        receivingBuildingId: null
      };

      this.shippingAssignments.push(assignment);
    }
    else {
      assignment.sendingBuildingId = building.buildingId;
    }

    this.shippingAssignmentsChange.emit(this.shippingAssignments);
  }

  public setShippingAssignmentIncoming(step: WorkflowStep, building: Building): void {

    if (building == null || step == null)
      return;

    var assignment = this.getShippingAssignment(step);

    if (assignment == null) {
      assignment = <WorkOrderShippingAssignment>{
        workflowStepId: step.workflowStepId,
        sendingBuildingId: null,
        receivingBuildingId: building.buildingId
      };

      this.shippingAssignments.push(assignment);
    }
    else {
      assignment.receivingBuildingId = building.buildingId;
    }

    this.shippingAssignmentsChange.emit(this.shippingAssignments);
  }

  public chartData: ChartDataSet[];

  @ViewChild('stepper') stepper: MatStepper;
  private async getDetail() {
    var id = this.product ? this.product.productId : UtilityService.emptyGuid;

    this.totalCost = null;
    if (!this.rapidEdit) this.product = null;
    this.selectedPurchasedItem = null;
    this.selectedWorkflowStep = null;
    this.purchasedItemForm && this.purchasedItemForm.form.markAsPristine();
    this.workflowDirty = false;

    if (id == "new" || id == UtilityService.emptyGuid) {
      this.editing = true;
      this.product = <Product>{
        productId: UtilityService.emptyGuid,
        childAssemblies: []
      };
      this.childrenLoaded = true;
      this.chartData = this.getWorkflowPricingData();
    }
    else {

      if (this.rapidEdit) {
        this.editing = true;
      } else {
        this.product = await this.orderSvc.getProduct(id).toPromise();
        this.childrenLoaded = true;
      }
      if (this.product && (!this.product.blankDimensions || !this.product.finishedDimensions)) {
        this.initializeWorkingDimensions();
      }
      this.calculateCost();
      this.updateLeadTime();
      this.chartData = this.getWorkflowPricingData();

      if (!this.product.workflow) {
        this.saving = true;
        this.orderSvc.createEmptyWorkflow().subscribe(workflow => {
          this.product.workflow = workflow
          this.product.workflowWorkflowId = workflow.workflowId
          this.saveChanges(false);
        })
      }
    }    

  }

  public getStandaloneTotal(): number {
    return this.product.workflow.workflowSteps
      .filter(s => s.isStandalone)
      .reduce((total, item) => WorkflowStep.calculateCost(item) + total, 0);
  }

  public showProductPreview(productId: string): void {

    if (this.showEditor == 'previewPart') {
      this.insetnav.close();
    }

    this.loading = true;

    this.orderSvc.getProduct(productId).subscribe(product => {
      this.previewPart = product;
      this.showEditor = 'previewPart';
      this.navService.pushBreadcrumb(`Preview of ${product.partNumber} Rev ${product.revision}`);
      this.insetnav.toggle();
      this.loading = false;
    });
  }

  public shouldShowSimilarProducts(): boolean {
    return this.editing && (this.showEditor == null || this.showEditor == 'previewPart') && this.selectedTab != 0 && this.product && !(this.product.productRepairPlan);
  }

  public assignSimilarProduct(productId: string): void {
    if (this.showEditor == 'previewPart') {
      this.insetnav.close();
    }

    this.loading = true;

    this.orderSvc.getProduct(productId).subscribe(product => {
      this.loading = false;

      this.product.selectedSimilarPart = productId;
      this.compare(this.product, product);
    });
  }

  private highlightDifferences(obj1: any, obj2: any): void {

    var keys: string[] = Object.keys(obj2);
    for (var key of keys) {
      if (obj2[key] != obj1[key] && !key.endsWith("id")) {
        obj1['compareTo_' + key] = obj2[key];
      }
    }

  }

  private compare(product1: Product, product2: Product): void {
    //TODO: compare workflow/materials/purchased items and flag/add
    if (product1.materialId == null && product2.materialId != null) {
      product1.material = product2.material;
      product1.material.isFromCompare = true;
      product1.materialId = product1.material.materialId = product2.materialId;
    }
    else if (product1.materialId != null && product1.materialId != product2.materialId) {
      //TODO: show a difference
    }

    if (product1.workflow && product1.workflow.workflowSteps && product2.workflow && product2.workflow.workflowSteps) {
      product1.workflow.workflowSteps.forEach(step => {
        var match = product2.workflow.workflowSteps.filter(s => s.stationId == step.stationId);
        if (match.length > 0) {
          //remove it from the copy
          product2.workflow.workflowSteps = product2.workflow.workflowSteps.filter(s => s.workflowStepId != match[0].workflowStepId);

          this.highlightDifferences(step, match[0]);
        }
      });

      product2.workflow.workflowSteps.forEach(step => {
        //If it's still here, we need to add it to product1
        step.isFromCompare = true;
        step.workflowStepId = UtilityService.emptyGuid;
        product1.workflow.workflowSteps.push(step);

        this.similarItemsApplied = true;
      });
    }

    if (product1.purchasedItems && product2.purchasedItems) {
      product1.purchasedItems.forEach(item => {
        var match = product2.purchasedItems.filter(p => p.productPurchasedItemId == item.productPurchasedItemId);
        if (match.length > 0) {
          //remove it from the copy
          product2.purchasedItems = product2.purchasedItems.filter(p => p.purchasedItemId != match[0].purchasedItemId);

          this.highlightDifferences(item, match[0]);
        }
      });

      product2.purchasedItems.forEach(item => {
        //If it's still here, we need to add it to product1
        item.isFromCompare = true;
        item.productPurchasedItemId = UtilityService.emptyGuid;
        product1.purchasedItems.push(item);

        this.similarItemsApplied = true;
      });
    }
  }

  public getLaborTotal(): number {
    var qty = this.getQuantity();

    return this.product.workflow.workflowSteps
      .filter(s => !s.isStandalone)
      .reduce((total, item) => WorkflowStep.calculatePerItemCost(item, qty) + total, 0);
  }

  public getQuantity(): number {

    if (this.product.parentAssemblyId != null) {
      return this.product.quantityAsChild || 1;
    } else {
      if (this.product.quantitiesMap.length > 0) return this.product.quantitiesMap[0].value;
      else return 1;
    }
  }

  private calculateCost(): void {
    this.totalCost = null;

    this.orderSvc.getCostForAssembly(this.product.productId, this.getQuantity()).subscribe(cost => {
      this.totalCost = cost;
    });

  }

  public setSelectedTab(e: StepperSelectionEvent): void {
    this.selectedTab = e.selectedIndex;
    if (e.selectedIndex === 0 && this.product) {
      this.updateLeadTime();
    }
  }

  public orderWorkflow(steps: WorkflowStep[]): void {
    if (this.editing) {
      steps.forEach((v, i) => v.stepOrder = i);
      this.orderSvc.orderWorkflowSteps(steps.map(s => s.workflowStepId)).subscribe(_ => { });
    }
  }

  public workflowStepAdded(step: WorkflowStep): void {
    this.saving = true;
    if (!this.isRepair) {
      this.orderSvc.saveWorkflowStep(this.product, step).subscribe(result => {
        this.selectedWorkflowStep = result;

        var oldIndex = this.product.workflow.workflowSteps.findIndex(s => s.workflowStepId == step.workflowStepId);
        if (oldIndex >= 0) this.product.workflow.workflowSteps[oldIndex] = result;

        this.saving = false;
        this.orderWorkflow(this.product.workflow && this.product.workflow.workflowSteps || []);
      });
    } else {
      this.orderSvc.saveRepairWorkflowStep(this.product.productRepairPlan, step).subscribe(result => {
        this.selectedWorkflowStep = result;

        var oldIndex = this.product.productRepairPlan.workflow.workflowSteps.findIndex(s => s.workflowStepId == step.workflowStepId);
        if (oldIndex >= 0) this.product.productRepairPlan.workflow.workflowSteps[oldIndex] = result;

        this.saving = false;
        this.orderWorkflow(this.product.productRepairPlan.workflow && this.product.productRepairPlan.workflow.workflowSteps || []);
      });
    }
  }

  leadTime: number;
  updatingLeadTime = false;
  public updateLeadTime() {
    this.updatingLeadTime = true;
    this.orderSvc.getProductLeadTime(this.product).subscribe(time => {
      this.leadTime = time;
      this.updatingLeadTime = false;
    });
  }

  ngOnInit(): void {
    this.getDetail();
  }

  ngAfterViewInit(): void {
    // if (this.rapidEdit) this.stepper.selectedIndex = 2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product && !changes.product.firstChange)
      this.getDetail();
  }

  public get isRepair(): boolean {
    return !!this.product.productRepairPlan;
  }

  public get canEditWorkflowStep(): boolean {
    if (this.isRepair) {
      return this.editing && this.selectedWorkflowStep.stepType !== WorkflowStepType.Standard;
    } else {
      return this.editing;
    }
  }

  public get canEditPurchasedItem(): boolean {
    if (this.isRepair) {
      return this.editing && this.selectedPurchasedItem.itemRepairType !== WorkflowStepType.Standard;
    } else {
      return this.editing;
    }
  }

  public onSubproductUpdate(_: Product) {
    this.productUpdate.emit(this.product);
  }

  public onPurchasedItemChosen() {
    if (this.selectedPurchasedItem) {
      this.selectedPurchasedItem.purchasedItemId = this.selectedPurchasedItem.purchasedItem && this.selectedPurchasedItem.purchasedItem.purchasedItemId;
    }
  }

  public async openStationEditor() {
    var okayToContinue: boolean = true;
    if (this.workflowDirty || this.similarItemsApplied) {
      okayToContinue = await this.utilitySvc.showConfirmationPromise("Pending Changes", "<p>You have pending changes that will be destroyed if you continue to the Station Editor.</p><p>Would you like to continue anyway?</p>");
    }

    if (okayToContinue) {
      this.showEditor = "stations";
      this.insetnav.toggle();
    }
  }

  public async onNewStation() {
    await this.stationList.reloadStations();
  }

  public getShippingTicketNumber(ticket: ShippingTicket) {
    return ShippingService.getTicketNumber(ticket);
  }

  public onToggleProgramming(e: boolean) {
    this.setWorkflowDirty();
    // set defaults
    if (e === true) {
      if (!this.selectedWorkflowStep.programmingTime)
        this.selectedWorkflowStep.programmingTime = 1;
      if (!this.selectedWorkflowStep.programmingRate)
        this.selectedWorkflowStep.programmingRate = 125;
    }
  }

  public onToggleFirstPartInspection(e: boolean) {
    this.setWorkflowDirty();
    // set defaults
    if (e === true) {
      if (!this.selectedWorkflowStep.firstPartInspectionTime)
        this.selectedWorkflowStep.firstPartInspectionTime = 60;
      if (!this.selectedWorkflowStep.firstPartInspectionRate)
        this.selectedWorkflowStep.firstPartInspectionRate = 125;
    }
  }

  public onToggleInspection(e: boolean) {
    this.setWorkflowDirty();
    // set defaults
    if (e === true) {
      if (!this.selectedWorkflowStep.inspectionBatchSize)
        this.selectedWorkflowStep.inspectionBatchSize = 1;
      if (!this.selectedWorkflowStep.inspectionTime)
        this.selectedWorkflowStep.inspectionTime = 60;
      if (!this.selectedWorkflowStep.inspectionRate)
        this.selectedWorkflowStep.inspectionRate = 125;
    }
  }

  public getFirstPartInspectionCost() {
    if (!this.selectedWorkflowStep || !this.selectedWorkflowStep.firstPartInspectionTime || !this.selectedWorkflowStep.firstPartInspectionRate) return 0;
    if (!this.selectedWorkflowStep.hasFirstPartInspection) return 0;
    return ((this.selectedWorkflowStep.firstPartInspectionTime / 60) * this.selectedWorkflowStep.firstPartInspectionRate);
  }

  public getFullInspectionCost() {
    if (!this.selectedWorkflowStep || !this.selectedWorkflowStep.inspectionTime || !this.selectedWorkflowStep.inspectionRate) return 0;
    if (!this.selectedWorkflowStep.hasInspection) return 0;
    let inspectionTimes: number;
    if (!this.selectedWorkflowStep.inspectionIsBatched || isNaN(this.selectedWorkflowStep.inspectionBatchSize) || this.selectedWorkflowStep.inspectionBatchSize < 1) inspectionTimes = this.firstQuantity;
    else inspectionTimes = Math.ceil(this.firstQuantity / this.selectedWorkflowStep.inspectionBatchSize);
    return ((this.selectedWorkflowStep.inspectionTime / 60) * this.selectedWorkflowStep.inspectionRate) * inspectionTimes;
  }

  public chartOptions = {
    responsive: true, aspectRatio: 2, legend: { position: 'left', labels: {
      filter: function(item, chart) {
          // Logic to remove a particular legend item goes here
          return !(item.text.includes('Programming -') || item.text.includes('Inspection -'));
      }
  }}
}
  public async makeCanonical() {
    const r = await this.utilitySvc.showConfirmationPromise('Make this product canonical?', 'This will make this copy of the product the one to be cloned when adding it to a new RFQ.');
    if (!r) return;
    this.saving = true;
    this.product.productStandard = await this.orderSvc.makeProductCanonical(this.product).toPromise();
    this.saving = false;
  }

  public async onCreateSpec(specName: string) {
    console.log(specName);
    this.saving = true;
    const spec = await this.stationSvc.createSpec(this.selectedWorkflowStep.stationId, specName).toPromise();
    this.selectedWorkflowStep.outsideProcessSpecifications = [...this.selectedWorkflowStep.outsideProcessSpecifications, spec.outsideProcessSpecificationId];
    this.saving = false;
  }

  public get incompleteAssemblyInfo() {
    const top = this.order?.products?.find(p => p.productProductId === this.product.topParentAssemblyId);
    if (!top) return null;
    if (top.reviewStatus !== 1 && top.reviewStatus !== 3) return null;
    else return { note: top.reviewStatusNote, status: top.reviewStatus };
  }

  public async markAsCorrected() {
    this.loading = true;
    const top = this.order?.products?.find(p => p.productProductId === this.product.topParentAssemblyId);
    try {
      await this.orderSvc.markProductAsCorrected(this.orderId, top.productProductId).toPromise();
      top.reviewStatus = OrderSegmentProductReviewStatus.Corrected;
    } catch (_) { } finally {
      this.loading = false;
    }
  }

  public matProgress = TaskStatus.NOT_STARTED;
  public workflowProgress = TaskStatus.NOT_STARTED;
  public hardwareProgress = TaskStatus.NOT_STARTED;

  // public 

  public getTaskStatus(type: 'material' | 'workflow' | 'hardware') {
  }

  public async setTaskStatus(type: 'material' | 'workflow' | 'hardware', status: TaskStatus) {
  }

}
