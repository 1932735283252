import { moveItemInArray } from "@angular/cdk/drag-drop";
import { MaterialBid } from "../../../purchasing/resources/materialBid";
import { Order, OrderProduct, OrderSegmentProductReviewStatus, OrderSegmentProductType } from "../../resources/order";
import { Product, ProductPurchasedItem, ProductStandard, ProductStandardHistory } from "../../resources/product";
import { WorkflowStep } from "../../resources/workflow";
import { AddProductDocumentChange, BatchImportResultData, Change, ChangeResult, ChangeType, CloneProductSubLevelResultData, CloneProductTopLevelResultData, CreateChange, CreateOrderSegmentResultData, DeleteChange, DeleteProductDocumentChange, RecordedChange, UpdateChange, assertUnreachable } from "../../../common/autosaving/change";

export type QuoteMap = { [itemId: string]: MaterialBid[] };
export type ProductMap = { [productId: string]: Product };


function applyUpdate<T>(data: UpdateChange['data'], item: T) {
    item[data.field] = data.newValue;
    if (data.relatedEntityField) item[data.relatedEntityField] = data.newRelatedEntity;
}

export function applyOrderSegmentChanges(originalOrder: Order, changes: Change[]) {
    // Deep copy
    const order = (originalOrder);
    // Get relevant CRUD changes
    for (const change of changes) {
        const { changeType, data } = change;
        switch (changeType) {
            // None of these should apply
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
            case "CLONE_PRODUCT_SUB_LEVEL":
            case "SORT_WORKFLOW_STEP":
            case "MAKE_PRODUCT_CANONICAL":
            case "CREATE":
            case "DELETE":
                break;
            case "UPDATE":
                const { entity } = change;
                switch (entity) {
                    case "Product":
                    case "WorkflowStep":
                    case "ProductPurchasedItem":
                    case "PurchasedItem":
                    case "MaterialBid":
                    case "Contact":
                    case "CustomerContact":
                    case "OutsideProcessSpecification":
                        break;
                    case "Document":
                        const matchingDocs = order.documents.filter(od => od.documentDocumentId === data.itemId);
                        for (const orderDoc of matchingDocs) {
                            applyUpdate(data, orderDoc.document);
                        }
                        break;
                    case "OrderSegment":
                        applyUpdate(data, order);
                        break;
                    default:
                        assertUnreachable(entity);
                }
                break;
            case "ADD_ORDER_DOCUMENT":
                order.documents = [...order.documents ?? [], {
                    orderSegmentOrderSegmentId: order.orderSegmentId,
                    documentDocumentId: data.documentId,
                    document: data.documentData
                }];
                break;
            case "DELETE_ORDER_DOCUMENT":
                order.documents = (order.documents ?? []).filter(od => od.documentDocumentId !== data.documentId);
                break;
            case "ADD_PRODUCT_DOCUMENT":
            case "DELETE_PRODUCT_DOCUMENT":
            case "ADD_MATERIAL_BID_DOCUMENT":
            case "DELETE_MATERIAL_BID_DOCUMENT":
                break;
            case "REVIEW_ORDER_PRODUCT":
                const product = order.products.find(p => p.productProductId === data.productId);
                product.reviewStatus = data.reviewStatus;
                product.reviewStatusNote = data.reviewStatusNote;
                break;
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
                order.products.push({
                    orderSegmentOrderSegmentId: order.orderSegmentId,
                    productProductId: data.productId,
                    reviewStatus: OrderSegmentProductReviewStatus.NotReviewed,
                    reviewStatusNote: '',
                    history: ProductStandardHistory.NEW,
                    type: OrderSegmentProductType.NEW,
                    sort: data.sort,
                });
                break;
            case "CLONE_PRODUCT_TOP_LEVEL":
                // We only need to add a dummy object to show in the parts list, the real one will be added by applying change results
                const dummyOrderProduct: OrderProduct & { isLoading: boolean } = {
                    orderSegmentOrderSegmentId: order.orderSegmentId,
                    productProductId: data.intoProductId,
                    reviewStatus: OrderSegmentProductReviewStatus.NotReviewed,
                    reviewStatusNote: '',
                    isLoading: true,
                    product: <Product>data.tempData,
                    sort: data.sort,
                };
                order.products.push(dummyOrderProduct);
                break;
            case "BATCH_IMPORT":
                const dummyOrderProducts: (OrderProduct & { isLoading: boolean })[] = data.items.map((d) => ({
                    orderSegmentOrderSegmentId: order.orderSegmentId,
                    productProductId: d.intoProductId,
                    reviewStatus: OrderSegmentProductReviewStatus.NotReviewed,
                    reviewStatusNote: '',
                    isLoading: true,
                    product: <Product>{ partNumber: d.partNumber, revision: d.revision },
                    sort: d.sort,
                }));
                order.products.push(...dummyOrderProducts);
                break;
            case "SORT_ORDER_PRODUCT":
                const { oldIndex, newIndex } = change.data;
                moveItemInArray(order.products, oldIndex, newIndex);
                order.products.forEach((osp, i) => osp.sort = i);
                break;
            case "DELETE_ORDER_PRODUCT":
                const index = order.products.findIndex(p => p.productProductId === data.productId);
                // if actions after first delete happen before a save, we might still have a mutated map that this change was run over
                // therefore, silently exit when we can't find the item to delete
                if (index === -1) break;
                order.products.splice(index, 1);
                break;
            default:
                assertUnreachable(changeType);
        }
    }
    
    return order;
}

function findProductForStep(map: ProductMap, step: WorkflowStep): Product {
    return Object.values(map).find(prod => prod.workflowWorkflowId === step.workflowId);
}

function applyCreateForProducts(map: ProductMap, change: CreateChange) {
    const { entity, data } = change;
    switch (entity) {
        case 'OutsideProcessSpecification':
        case 'OrderSegment':
        case 'Contact':
        case 'CustomerContact':
        case 'PurchasedItem':
            // Irrelevant to products
            break;
        case 'MaterialBid':
            // I /think/ also irrelevant
            break;
        case 'Product':
            // This is handled by bespoke actions
            break;
        case "Document": 
            // No create here
            break;
        case "WorkflowStep":
            {
                const step = data.value as WorkflowStep;
                const product = findProductForStep(map, step);
                let exists = product.workflow.workflowSteps.some(s => s.workflowStepId === data.itemId);
                if (exists) break;
                product.workflow.workflowSteps.push(step);
                step.stepOrder = product.workflow.workflowSteps.length - 1;
                break;
            }
        case "ProductPurchasedItem":
            {
                const item = data.value as ProductPurchasedItem;
                const product = map[item.productId];
                let exists = product.purchasedItems.some(s => s.productPurchasedItemId === data.itemId);
                if (exists) break;
                product.purchasedItems.push(item);
                break;
            }
        default:
            assertUnreachable(entity);
    }
}

function applyUpdateForProducts(map: ProductMap, change: UpdateChange) {
    const { entity, data } = change;
    switch (entity) {
        case 'OutsideProcessSpecification':
        case 'OrderSegment':
        case 'Contact':
        case 'CustomerContact':
        case "PurchasedItem":
            // Irrelevant to products
            break;
        case 'MaterialBid':
            // I /think/ also irrelevant
            break;
        case "Document": 
            // Could be multiple copies of the same document
            const matchingDocs = Object.values(map).flatMap(p => p.documents).filter(pd => pd.documentDocumentId === data.itemId);
            for (const productDoc of matchingDocs) {
                applyUpdate(data, productDoc.document);
            }
            break;
        case 'Product':
            if (data.itemId === null) {
                console.error('change with null itemId', change);
                return;
            }
            const product = map[data.itemId];
            if (!product) {
                console.error(`Could not find product with ID ${data.itemId}`);
                break;
            }
            applyUpdate(data, product);
            break;
        case "WorkflowStep":
            const step = Object.values(map).flatMap(p => p.workflow.workflowSteps).find(s => s.workflowStepId === data.itemId);
            applyUpdate(data, step);
            break;
        case "ProductPurchasedItem":
            const item = Object.values(map).flatMap(p => p.purchasedItems).find(i => i.productPurchasedItemId === data.itemId);
            applyUpdate(data, item);
            break;
        default:
            assertUnreachable(entity);
    }
}

function applyDeleteForProducts(map: ProductMap, change: DeleteChange) {
    const { entity, data } = change;
    switch (entity) {
        case 'OutsideProcessSpecification':
        case 'OrderSegment':
        case 'Contact':
        case 'CustomerContact':
        case 'PurchasedItem':
            // Irrelevant to products
            break;
        case 'MaterialBid':
            // I /think/ also irrelevant
            break;
        case 'Document':
            // Not deleted directly
            break;
        case 'Product':
            // We need to delete every descendant as well
            const allProducts = Object.values(map);
            const toBeDeletedIds = [data.itemId];
            let currentChildrenIds: string[] = toBeDeletedIds;
            do {
                currentChildrenIds = allProducts.filter(p => currentChildrenIds.includes(p.parentAssemblyId)).map(p => p.productId);
                toBeDeletedIds.push(...currentChildrenIds);
            } while (currentChildrenIds.length > 0);
            for (const id of toBeDeletedIds) {
                delete map[id];
            }
            break;
        case "WorkflowStep":
            {
                const product = Object.values(map).find(prod => prod.workflow.workflowSteps.findIndex(s => s.workflowStepId === data.itemId) !== -1);
                const index = product?.workflow.workflowSteps.findIndex(s => s.workflowStepId === data.itemId);
                // if actions after first delete happen before a save, we might still have a mutated map that this change was run over
                // therefore, silently exit when we can't find the item to delete
                if (index !== undefined) {
                    product.workflow.workflowSteps.splice(index, 1);
                    // correct stepOrder
                    product.workflow.workflowSteps.forEach((step, i) => step.stepOrder = i);
                }
                break;
            }
        case "ProductPurchasedItem":
            {
                const product = Object.values(map).find(prod => prod.purchasedItems.findIndex(s => s.productPurchasedItemId === data.itemId) !== -1);
                const index = product?.purchasedItems.findIndex(s => s.productPurchasedItemId === data.itemId);
                // if actions after first delete happen before a save, we might still have a mutated map that this change was run over
                // therefore, silently exit when we can't find the item to delete
                if (index !== undefined) product.purchasedItems.splice(index, 1);
                break;
            }
        default:
            assertUnreachable(entity);
    }
}


function updateProductDocuments(product: Product, change: AddProductDocumentChange | DeleteProductDocumentChange) {
    const { changeType, data } = change;
    switch (changeType) {
        case "ADD_PRODUCT_DOCUMENT":
            product.documents = [...product.documents ?? [], {
                productProductId: product.productId,
                documentDocumentId: data.documentId,
                document: data.documentData
            }];
            break;
        case "DELETE_PRODUCT_DOCUMENT":
            product.documents = (product.documents ?? []).filter(od => od.documentDocumentId !== data.documentId);
            break;
    }

}

function sortWorkflow(product: Product, oldIndex: number, newIndex: number) {
    moveItemInArray(product.workflow.workflowSteps, oldIndex, newIndex);
    product.workflow.workflowSteps.forEach((s, i) => s.stepOrder = i);
}

function setupEmptyProduct(productId: string, partNumber: string, revision?: string) {
    const prod = Product.newEmptyProduct();
    prod.partNumber = partNumber;
    if (revision) prod.revision = revision;
    prod.productId = productId;
    prod.workflow.workflowId = prod.productId;
    prod.workflowWorkflowId = prod.productId;
    return prod;
}

export function applyProductsMapChanges(oldMap: ProductMap, changes: Change[]) {
    // Deep copy
    const newMap= oldMap;
    for (const change of changes) {
        const { changeType, data } = change;
        switch (changeType) {
            case "ADD_ORDER_DOCUMENT":
            case "DELETE_ORDER_DOCUMENT":
            case "ADD_MATERIAL_BID_DOCUMENT":
            case "DELETE_MATERIAL_BID_DOCUMENT":
            case "REVIEW_ORDER_PRODUCT":
            case "SORT_ORDER_PRODUCT":
            case "DELETE_ORDER_PRODUCT":
                // Irrelevant
                break;                
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
                const blankTopProd = setupEmptyProduct(data.productId, data.partNumber, data.revision);
                blankTopProd.topParentAssemblyId = blankTopProd.productId;
                newMap[data.productId] = blankTopProd;
                break;
            case "CLONE_PRODUCT_TOP_LEVEL":
            case "BATCH_IMPORT":
                // We don't add anything to the product map until we get the real data back from the server
                break;
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
                const blankSubProd = setupEmptyProduct(data.productId, data.partNumber, data.revision);
                blankSubProd.parentAssemblyId = data.parentAssemblyId;
                blankSubProd.topParentAssemblyId = data.topParentAssemblyId;
                newMap[data.productId] = blankSubProd;
                break;
            case "CLONE_PRODUCT_SUB_LEVEL":
                // We don't add anything to the map that's not loaded. We'll directly observe the change list in the hierarchy tree to render loading items.
                break;
            case "CREATE":
                applyCreateForProducts(newMap, change);
                break;
            case "UPDATE":
                applyUpdateForProducts(newMap, change);
                break;
            case "DELETE":
                applyDeleteForProducts(newMap, change);
                break;
            case "SORT_WORKFLOW_STEP":
                const { productId, oldIndex, newIndex } = change.data;
                sortWorkflow(newMap[productId], oldIndex, newIndex);
                break;
            case "DELETE_PRODUCT_DOCUMENT":
            case "ADD_PRODUCT_DOCUMENT":
                {
                    const prod = newMap[data.productId];
                    updateProductDocuments(prod, change);
                    break;
                }
            case "MAKE_PRODUCT_CANONICAL":
                {
                    const prod = newMap[data.productId];
                    // This is missing some fields, but it shouldn't matter with how this specific instance is used by the UI
                    // (it basically just checks if it's there)
                    prod.productStandard = <ProductStandard>{
                        productId: data.productId,
                        partNumber: prod.partNumber,
                        revision: prod.revision,
                    };
                    break;
                }
            default:
                assertUnreachable(changeType);
        }
    }

    return newMap;
}

export function applyQuotesMapChanges(oldMap: QuoteMap, changes: Change[]) {
    const newMap= (oldMap);
    for (const change of changes) {
        const { changeType, data } = change;
        switch (changeType) {
            case "ADD_ORDER_DOCUMENT":
            case "DELETE_ORDER_DOCUMENT":
            case "REVIEW_ORDER_PRODUCT":
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
            case "CLONE_PRODUCT_TOP_LEVEL":
            case "BATCH_IMPORT":
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
            case "CLONE_PRODUCT_SUB_LEVEL":
            case "SORT_ORDER_PRODUCT":
            case "SORT_WORKFLOW_STEP":
            case "DELETE_PRODUCT_DOCUMENT":
            case "ADD_PRODUCT_DOCUMENT":
            case "MAKE_PRODUCT_CANONICAL":
            case "DELETE_ORDER_PRODUCT":
                // Irrelevant
                break;
            case "DELETE":
                // Quotes are never deleted
                break;
            case "ADD_MATERIAL_BID_DOCUMENT":
                {
                    let quote = Object.values(newMap).flat().find((q: MaterialBid) => q.materialBidId === data.materialBidId) as MaterialBid;
                    quote.materialBidDocuments = [...quote.materialBidDocuments ?? [], {
                        materialBidId: data.materialBidId,
                        documentId: data.documentId,
                        document: data.documentData,
                    }];
                    break;
                }
            case "DELETE_MATERIAL_BID_DOCUMENT":
                {
                    let quote = Object.values(newMap).flat().find((q: MaterialBid) => q.materialBidId === data.materialBidId) as MaterialBid;
                    quote.materialBidDocuments = (quote.materialBidDocuments ?? []).filter(mbd => mbd.documentId !== data.documentId);
                }
                break;
            case "CREATE":
                const quoteData = (change.data.value as MaterialBid);
                const filterId = quoteData.materialId ?? quoteData.purchasedItemId ?? quoteData.stationId;
                if (!filterId) {
                    console.error('Cannot create quote with null item ID!');
                    break;
                }
                if (!newMap[filterId]) newMap[filterId] = [];
                newMap[filterId].push(quoteData);
                break;
            case "UPDATE":
                let quote = Object.values(newMap).flat().find((q: MaterialBid) => q.materialBidId === data.itemId) as MaterialBid;
                applyUpdate(change.data, quote);
                break;
            default:
                assertUnreachable(changeType);
        }
    }
    return newMap;
}


export function changeIsRedundant(oldChange: Change, newChange: Change) {
        if (oldChange.changeType !== newChange.changeType) return false;
        const { changeType } = newChange;
        switch (changeType) {
            case "CREATE":
            case "DELETE":
            case "ADD_ORDER_DOCUMENT":
            case "DELETE_ORDER_DOCUMENT":
            case "ADD_PRODUCT_DOCUMENT":
            case "DELETE_PRODUCT_DOCUMENT":
            case "ADD_MATERIAL_BID_DOCUMENT":
            case "DELETE_MATERIAL_BID_DOCUMENT":
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT": 
            case "CLONE_PRODUCT_TOP_LEVEL":
            case "BATCH_IMPORT":
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
            case "CLONE_PRODUCT_SUB_LEVEL":
            case "DELETE_ORDER_PRODUCT":
                // None of these should ever be redundant
                return false;
            case "SORT_ORDER_PRODUCT":
            case "SORT_WORKFLOW_STEP": // These can't be redundant because the effects of the sort depend on previous sorts
                return false;
            case "UPDATE":
                {
                    const oldData = (oldChange as UpdateChange).data;
                    return oldData.itemId === newChange.data.itemId && oldChange.entity === newChange.entity && oldData.field === newChange.data.field;
                }
            case "REVIEW_ORDER_PRODUCT":
            case "MAKE_PRODUCT_CANONICAL":
                {
                    const oldData = oldChange.data as { productId: string };
                    return oldData.productId === newChange.data.productId;
                }
            default:
                assertUnreachable(changeType);
        }

}

function replaceDummyOrderProduct(order: Order, orderSegmentProduct: OrderProduct) {
    const dummyIndex = order.products.findIndex(osp => osp.productProductId === orderSegmentProduct.productProductId);
    const dummy = order.products[dummyIndex];
    // Copy the sort
    orderSegmentProduct.sort = dummy.sort;
    order.products.splice(dummyIndex, 1, orderSegmentProduct);
}

export function applyChangeResultsToOrder(order: Order, results: ChangeResult[], changes: RecordedChange[]) {
    const newOrder= order;
    for (const result of results) {
        const change = changes.find(c => c.changeId === result.changeId);
        if (!change) { console.error(`No change with id ${result.changeId} provided`); continue; }
        const { changeType } = change;
        switch (changeType) {
            // None of these should apply
            case "SORT_ORDER_PRODUCT":
            case "SORT_WORKFLOW_STEP":
            case "MAKE_PRODUCT_CANONICAL":
            case "UPDATE":
            case "DELETE":
            case "ADD_ORDER_DOCUMENT":
            case "DELETE_ORDER_DOCUMENT":
            case "ADD_PRODUCT_DOCUMENT":
            case "DELETE_PRODUCT_DOCUMENT":
            case "DELETE_ORDER_PRODUCT":
            case "ADD_MATERIAL_BID_DOCUMENT":
            case "DELETE_MATERIAL_BID_DOCUMENT":
            case "REVIEW_ORDER_PRODUCT":
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
            case "CLONE_PRODUCT_SUB_LEVEL":
                break;
            case "CREATE":
                if (change.entity === 'OrderSegment') {
                    const { salesProcessId, orderNumber, createdDate } = result.data as CreateOrderSegmentResultData;
                    newOrder.salesProcessId = salesProcessId;
                    newOrder.orderNumber = orderNumber;
                    newOrder.createdDate = createdDate;
                }
                break;
            case "CLONE_PRODUCT_TOP_LEVEL":
                const { orderSegmentProduct } = result.data as CloneProductTopLevelResultData;
                orderSegmentProduct.product = (result.data as CloneProductTopLevelResultData).products.find(p => p.productId === orderSegmentProduct.productProductId);
                replaceDummyOrderProduct(order, orderSegmentProduct);
                break;
            case "BATCH_IMPORT":
                const { orderSegmentProducts } = result.data as BatchImportResultData;
                for (const osp of orderSegmentProducts) {
                    replaceDummyOrderProduct(order, osp);
                }
                break;
            default:
                assertUnreachable(changeType);
        }
    }
    return newOrder;
}

export function applyChangeResultsToProductMap(oldMap: ProductMap, results: ChangeResult[], changes: RecordedChange[]) {
    const newMap = oldMap;
    for (const result of results ) {
        const change = changes.find(c => c.changeId === result.changeId);
        if (!change) { console.error(`No change with id ${result.changeId} provided`); continue; }
        const { changeType } = change;
        switch (changeType) {
            case "ADD_ORDER_DOCUMENT":
            case "DELETE_ORDER_DOCUMENT":
            case "ADD_PRODUCT_DOCUMENT":
            case "DELETE_PRODUCT_DOCUMENT":
            case "ADD_MATERIAL_BID_DOCUMENT":
            case "DELETE_MATERIAL_BID_DOCUMENT":
            case "REVIEW_ORDER_PRODUCT":
            case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
            case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
            case "DELETE_ORDER_PRODUCT":
            case "SORT_ORDER_PRODUCT":
            case "SORT_WORKFLOW_STEP":
            case "MAKE_PRODUCT_CANONICAL":
            case "DELETE":
            case "CREATE":
            case "UPDATE":
                // These have no serverside effects
                break;
            case "CLONE_PRODUCT_TOP_LEVEL":
            case "BATCH_IMPORT":
            case "CLONE_PRODUCT_SUB_LEVEL":
                {
                    const { products } = result.data as CloneProductTopLevelResultData | BatchImportResultData | CloneProductSubLevelResultData;
                    for (const prod of products) {
                        prod.workflow.workflowSteps.sort((a, b) => a.stepOrder - b.stepOrder);
                        newMap[prod.productId] = prod;
                    }
                    break;
                }
            default:
                assertUnreachable(changeType);
        }
    }
    return newMap;
}

const QUOTE_RELEVANT_CHANGES: ChangeType[] = ['ADD_MATERIAL_BID_DOCUMENT', 'DELETE_MATERIAL_BID_DOCUMENT'];
export function changeIsForQuotesMap(change: RecordedChange): boolean {
    return QUOTE_RELEVANT_CHANGES.includes(change.changeType) || change.entity === 'MaterialBid';
}
