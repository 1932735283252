import { Component, Inject, Injector, OnChanges, Optional, Self, forwardRef } from "@angular/core";
import { User } from "../../resources/user";
import { UserService } from "../../services/user.service";
import { GenericSearchComponent } from "../generic-search/generic-search.component";
import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, SimpleChanges } from "@angular/core";
import { Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NgForm } from "@angular/forms";

@Component({
  selector: 'employee-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  styleUrls: ['../../../common/components/generic-search/generic-search.component.less', './employee-search.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeSearchComponent),
      multi: true
    }
  ]
})
export class EmployeeSearchComponent extends GenericSearchComponent<User> implements ControlValueAccessor {


  @Input() declare dense: boolean;
  @Input() placeholder: string;
  @Input() declare label: string;
  @Input() floatLabel: string;
  @Input() readonly = false;

  public get myLabel() {
    if (this.label) return this.label;
    else return 'User';
  }

  constructor(private userSvc: UserService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.userSvc.search(searchText).pipe(
      map(r => r.results)
    )
  }

  public canAdd(): boolean { return false }

  public get addItemText(): string {
     return '';
  }

  public get noItemsText(): string {
    return 'No matching users found.';
  }

  public getSearchField(c: User): string {
    return c && c.fullName;
  }

  value: User;
  public untouched = true;

}