import { Component, EventEmitter, OnInit, Output, ViewChild, } from '@angular/core';
import { NgModel } from '@angular/forms';
import { tap, throttleTime } from 'rxjs/operators';
import { MaterialGroup } from '../../../order/resources/material';
import { MaterialService } from '../../../order/services/material.service';

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

@Component({
  selector: 'material-group-list',
  templateUrl: './material-group-list.component.html',
  styleUrls: ['./material-group-list.component.less']
})
/** station-list component*/
export class MaterialGroupListComponent implements OnInit {
  public materialList: MaterialGroup[] = null;

  public searching = false;
  canScroll = true;
  currentPageIndex = 0;

  public filter = '';

  @Output() selected: EventEmitter<MaterialGroup> = new EventEmitter<MaterialGroup>();

  constructor(private materialService: MaterialService) { }

  private loadMaterials(filter = ''): void {
    this.materialService.getMaterialGroups(filter).subscribe(( {results, resultCount}) => {
      this.materialList = results;
      this.currentPageIndex = 0;

      this.canScroll = this.materialList.length < resultCount;
      this.searching = false;
    });
  }

  public async onListScroll(e: Event) {

    const panel = e.target as HTMLUListElement;
    const maxScrollPosition = panel.scrollHeight - panel.clientHeight;
    const scrollThreshold = maxScrollPosition - 80;
    if (panel.scrollTop > scrollThreshold && !this.searching && this.canScroll) {
      this.currentPageIndex += 1;
      this.searching = true;
      const { results, resultCount } = await this.materialService.getMaterialGroups(this.filter, this.currentPageIndex, 30).toPromise();
      this.searching = false;

      this.materialList = this.materialList.concat(results);

      this.canScroll = this.materialList.length < resultCount;
    }
  }

  public select(material: MaterialGroup): void {
    this.selected.emit(material);
  }

  public getHighlightName(str: string): string {
    if (!this.filter) { return str; }
    const split = this.filter.split(' ');
    let out = str;
    for (const keyword of split) {
      let kw = keyword.trim();
      if (!kw) continue;
      var re = new RegExp(escapeRegExp(keyword), 'gi');
      out = out.replace(re, "<b>$&</b>");
    }
    return out;
  }

  ngOnInit(): void {
    this.loadMaterials();
    this.materialService.updated.asObservable().subscribe(_ => this.loadMaterials());
  }

  @ViewChild('filterModel', { static: true }) filterModel: NgModel;
  ngAfterViewInit() {
    if (!this.filterModel || !this.filterModel.valueChanges) return;
    this.filterModel.valueChanges.pipe(
      throttleTime(250),
      tap(() => this.searching = true)
    ).subscribe((f) => this.loadMaterials(f));
  }
}
