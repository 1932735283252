import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatStep } from '@angular/material/stepper';
import { UtilityService } from '../../../common/services/utility.service';
import { Building } from '../../../floor/resources/building';
import { InventoryTreeSelectComponent } from '../../../inventory/components/inventory-tree-select/inventory-tree-select.component';
import { InventoryLocation } from '../../../inventory/resources/inventory-location';
import { ShippingTicket } from '../../resources/shipping-ticket';
import { ShippingService } from '../../services/shipping.service';
import { InventoryTreePickerComponent } from '../inventory-tree-picker/inventory-tree-picker.component';
import { PurchasingService } from '../../../purchasing/services/purchasing.service';
import { Subject, Observable } from 'rxjs';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { DocumentService } from '../../../common/services/document.service';


@Component({
  selector: 'shipping-check-in',
  templateUrl: './shipping-check-in.component.html',
  styleUrls: ['./shipping-check-in.component.less']
})
export class ShippingCheckInComponent implements OnInit {
  public subSidenavMode: string;
  @ViewChild('subSidenav', { static: true }) subSidenav: MatSidenav;

  @Input() sidenav: MatSidenav;
  @Input() ticket: ShippingTicket;
  @Input() building: Building;
  @Output() done = new EventEmitter<void>();

  public saving = false;

  public uploadedPhotos: VirtualDocument[] = [];
  public documents: { document: VirtualDocument }[] = [];
  private savedPhotos: VirtualDocument[] = [];

  constructor(private fb: FormBuilder, private utilityService: UtilityService, private shippingService: ShippingService, private purchasingService: PurchasingService, private documentService: DocumentService) {
    this.checklistForm = this.fb.group({
      item: new FormControl<boolean>(false, (c) => c.value === false ? { item: true } : null),
      origin: new FormControl<boolean>(false, (c) => c.value === false ? { origin: true } : null),
      poNumber: new FormControl<boolean>(false, (c) => c.value === false ? { poNumber: true } : null),
      woNumber: new FormControl<boolean>(false, (c) => c.value === false ? { woNumber: true } : null),
      rmaNumber: new FormControl<boolean>(false, (c) => c.value === false ? { rmaNumber: true } : null),
    });
  }

  ngOnInit() {
    if (this.ticket.rmaTicketId === null) {
      this.checklistForm.patchValue({ rmaNumber: true })
    }
  }

  public checklistForm: FormGroup;

  public amountReceived = 0;

  public backorderDate = new FormControl<Date | null>(null);
  public receivedDate = new FormControl<Date | null>(new Date(), Validators.required);

  public lotNumbers: { lotNumber: string, qty: number, location: InventoryLocation, breadcrumbs?: string[] }[] = [];
  @ViewChild('lotNumbersForm', { static: true }) lotNumbersForm: NgForm;

  public get totalLotNumberQty() {
    return this.lotNumbers.reduce((acc, l) => acc + (l.qty || 0), 0)
  }

  public addLotNumber() {
    this.lotNumbers = [...this.lotNumbers, {
      lotNumber: "",
      qty: this.amountReceived - this.totalLotNumberQty,
      location: null
    }];
    this.lotNumbersForm.control.updateValueAndValidity();
  }

  public deleteLotNumber(index: number) { 
    this.lotNumbers = this.lotNumbers.filter((_, i) => i !== index);
  }

  public lotNumberStepReached = false;
  @ViewChild('lotNumberStep', { static: true }) lotNumberStep: MatStep;
  @ViewChild('finalizeStep', { static: true }) finalizeStep: MatStep;

  public onStepChange(event: StepperSelectionEvent) {
    if (event.previouslySelectedStep.label === this.finalizeStep.label && event.selectedStep.label !== this.finalizeStep.label) {
      this.printed = false;
    }
    if (event.selectedStep.label === this.lotNumberStep.label) {
      this.lotNumberStepReached = true;
    }
  }

  public onReceivedChange() {
    if (!this.lotNumberStepReached) {
      this.lotNumbers = [{
        lotNumber: "", qty: this.amountReceived, location: null
      }]
    }
  }

  public setBreadcrumbs(ln: ShippingCheckInComponent['lotNumbers'][0], its: InventoryTreeSelectComponent) {
    ln.breadcrumbs = its.locationBreadcrumbs.map(l => l.name);
  }

  public get itemTypeName() {
    const item = this.ticket.inventoryItem
    if (item.purchasedItemId) return 'item';
    else if (item.materialId) return 'material';
    else if (item.productId) return 'part';
  }

  public async abort() {
    const confirm = await this.utilityService.showConfirmationPromise(
      'Abort the check-in process?',
      'All your progress will be lost. Are you sure?'
    );
    if (confirm) {
      this.uploadedPhotos = [];
      this.savedPhotos = [];
      this.sidenav.close();
    }
  }

  public getTicketNumber = ShippingService.getTicketNumber;

  public get receivingFrom() {
    if (this.ticket.originBuilding) return this.ticket.originBuilding.name;
    else if (this.ticket.rmaTicket) return this.ticket.rmaTicket.customer.businessName;
    else if (this.ticket.purchaseOrder) return this.ticket.purchaseOrder.vendor && this.ticket.purchaseOrder.vendor.name;
    else if (this.ticket.workOrder) return this.ticket.workOrder.order && this.ticket.workOrder.order.customer && this.ticket.workOrder.order.customer.businessName;
  }

  
  public printed = false;
  public printLabel() {

    this.saving = true;
    this.lotNumbers.forEach((ln, i) => {
      this.shippingService.getInventoryLabel(this.ticket, ln.lotNumber, ln.qty.toString(), this.receivedDate.value).subscribe(doc => {
        this.saving = false;
        // TODO: print instead of opening
        // make some kind of check to see if connected to print station and just hide the print label button if not
        window.open(`/document/${doc.documentId}?inline=true`, "_blank")
      })
    })
    this.printed = true;
  }

  public printPO() {
    this.saving = true;
    this.purchasingService.getPOReport(this.ticket.purchaseOrder).subscribe(doc => {
      this.saving = false;
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public async finalizeAndCheckIn() {
    this.saving = true;

    if (this.uploadedPhotos.length > 0) {
      this.savePhotosAndCheckInTicket();
    } else {
      this.checkInTicket();
    }
  }

  private async checkInTicket() {
    const uploadedDocuments = await Promise.all(this.documents.map(d => this.documentService.upload((d.document as any).file as any).toPromise()));
    await this.shippingService.checkInTicket(this.ticket.shippingTicketId, {
      amountReceived: this.amountReceived,
      backorderDate: this.backorderDate.value,
      lots: this.lotNumbers,
      documents: [...this.savedPhotos, ...uploadedDocuments],
      receivedDate: this.receivedDate.value
    }).toPromise();

    this.uploadedPhotos = [];
    this.savedPhotos = [];
    this.saving = false;
    this.done.emit();
  }

  private savePhotosAndCheckInTicket() {
    if (this.uploadedPhotos.length > 0) {
      const photo = this.uploadedPhotos.pop();
      this.uploadDocument(photo).subscribe(d => {
        this.savedPhotos.push(d);
        this.savePhotosAndCheckInTicket();
      });
    } else {
      this.checkInTicket();
    }
  }

  private uploadDocument(document: any): Observable<VirtualDocument> {
    var subject = new Subject<VirtualDocument>();
    this.documentService.upload(document)
      .subscribe(r => {
        subject.next(r);
      });

    return subject;
  }

  public get now(): Date {
    return new Date();
  }

  public openPhotoSubSidenav(): void {
    this.subSidenavMode = 'photo';
    this.subSidenav.open();
  }

  public addPhotos(photos: VirtualDocument[]): void {
    if (photos.length > 0)
    this.uploadedPhotos = this.uploadedPhotos.concat(photos) || [];
  }

  public deletePhoto(photo: VirtualDocument): void {
    const index = this.uploadedPhotos.indexOf(photo, 0);
    if (index > -1) {
      this.uploadedPhotos.splice(index, 1);
    }
  }

  public async onBrowseDone(filelist: FileList) {
    this.documents = [...this.documents, ...Array.from(filelist).map(f => ({
      document: {
        name: f.name,
        mimeType: f.type,
        imageAsDataUrl: null,
        documentId: UtilityService.emptyGuid,
        file: f,
        tags: []
      }
    }))];
  }
  
}
