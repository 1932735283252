import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { OrderDetailService, fetchLatest } from '../../order-detail.service';
import { filter, map, scan, shareReplay, withLatestFrom } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Product } from '../../../../resources/product';
import { Change, ChangeType, CloneProductSubLevelChange, CreateBlankSubLevelProductChange, DeleteChange, RecordedChange, UpdateChange } from '@cots/common/autosaving/change';
import { MatMenuTrigger } from '@angular/material/menu';
import { UtilityService } from '../../../../../common/services/utility.service';
import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ClassifiedProduct } from '../../existing-product-select/existing-product-select.component';

interface ProductNode {
  loading: boolean;
  productId: string;
  parentAssemblyId: string;
  name: string;
  level: number;
  hasChildren: boolean;
  isAdding: false;
  partNumber: string,
  revision: string,
}
interface AddingNode {
  parentAssemblyId: string,
  isAdding: true,
  level: number
}

type TreeNode = ProductNode | AddingNode;

function isAdding(n: TreeNode): n is AddingNode {
  return n?.isAdding === true;
}

interface MemoAccumulator {
  addingId: string | null;
  products: Product[];
  topId: string;
  latestRelevantChangeId: string;
  willEmit: boolean;
  changes: RecordedChange[];
  baseLength: number;
}

const HIERARCHY_CHANGES: ChangeType[] = ['CREATE_BLANK_SUB_LEVEL_PRODUCT', 'CLONE_PRODUCT_SUB_LEVEL'];
const RELEVANT_FIELDS: (keyof Product)[] = ['parentAssemblyId', 'partNumber', 'revision', 'quantitiesMap', 'quantityAsChild'];
@Component({
  selector: 'product-hierarchy-new',
  templateUrl: './product-hierarchy-new.component.html',
  styleUrls: ['./product-hierarchy-new.component.less']
})
export class ProductHierarchyNewComponent implements OnInit {
  @ContentChild("postName") postNameTemplate: TemplateRef<any>;

  constructor(public service: OrderDetailService, private dialog: MatDialog) { }

  treeControl = new FlatTreeControl<TreeNode, string>(node => node.level, node => isAdding(node) ? false : node.hasChildren, {
    trackBy: node => isAdding(node) ? 'adding' : node.productId
  });

  public addingIdSubject = new BehaviorSubject<string | null>(null);
  
  public selectProduct(node: ProductNode) {
    if (this.sorting) return;
    this.service.selectedProductIdSubject.next(node.productId);
  }

  public data: Observable<TreeNode[]>;
  private setupHierarchyObservable() {
    return combineLatest([this.addingIdSubject, this.service.allProducts$, this.service.selectedProduct$, this.service.autosaver.changesSubject, this.service.autosaver.getPreChanges('productMap').pipe(
      map(pm => Object.keys(pm).length)
    )]).pipe(
      scan<[string, Product[], Product, RecordedChange[], number], MemoAccumulator>((previous, [addingId, products, selectedProduct, changes, baseLength]) => {
        const topId = selectedProduct?.topParentAssemblyId;
        const lastRelevantChangeId = changes.slice().reverse().find(change => (
          HIERARCHY_CHANGES.includes(change.changeType) ||
          (change.changeType === 'DELETE' && change.entity === 'Product') ||
          (change.changeType === 'UPDATE' && RELEVANT_FIELDS.includes(change.data.field as keyof Product))
        ))?.changeId;

        const willEmit = baseLength !== previous.baseLength || (addingId !== previous.addingId) || !topId || topId !== previous.topId || lastRelevantChangeId !== previous.latestRelevantChangeId;

        return {
          addingId,
          products,
          topId,
          latestRelevantChangeId: lastRelevantChangeId,
          willEmit,
          changes,
          baseLength
        };
      }, {
        addingId: null,
        latestRelevantChangeId: null,
        products: null,
        topId: null,
        willEmit: true,
        changes: [],
        baseLength: 0
      }),
      filter(({ willEmit }) => willEmit),
      map(({ products, topId, addingId, changes }) => {
        let nodes: ProductNode[] = products.filter(prod => prod.topParentAssemblyId === topId).map(prod => ({
          loading: false,
          parentAssemblyId: prod.parentAssemblyId,
          productId: prod.productId,
          name: `${prod.partNumber} Rev. ${prod.revision}`,
          hasChildren: products.filter(p => p.parentAssemblyId === prod.productId).length > 0,
          level: 0,
          isAdding: false,
          partNumber: prod.partNumber,
          revision: prod.revision,
        }));
        const cloneChanges = changes.filter(c => c.changeType === 'CLONE_PRODUCT_SUB_LEVEL') as CloneProductSubLevelChange[];
        let cloneChangesNodes: ProductNode[] = cloneChanges.map(({ data: changeData }) => ({
          loading: true,
          isAdding: false,
          parentAssemblyId: changeData.parentAssemblyId,
          productId: changeData.intoProductId,
          name: `${changeData.tempData.partNumber} Rev. ${changeData.tempData.revision}`,
          hasChildren: false,
          level: 0,
          partNumber: changeData.tempData.partNumber,
          revision: changeData.tempData.revision,
        }));
        nodes = [...nodes, ...cloneChangesNodes]
        // Weed out orphans as they will cause an infinite loop
        const allIds = nodes.map(n => n.productId);
        let orphans = nodes.filter(n => n.parentAssemblyId && !allIds.includes(n.parentAssemblyId));
        if (orphans.length > 0) {
          console.error('Found orphans in flat structure!', orphans.map(o => o.name));
          nodes = nodes.filter(n => !n.parentAssemblyId || allIds.includes(n.parentAssemblyId));
        }
        let sortedNodes: TreeNode[] = [nodes.find(x => x.productId === topId)];
        let sortLevel = 0;
        while (sortedNodes.length < nodes.length) {
          let nsn = sortedNodes.slice();
          for (const parentNode of sortedNodes) {
            if (parentNode.level == sortLevel) {
              const children = nodes.filter(n => n.parentAssemblyId === (parentNode as ProductNode).productId);
              children.forEach(n => n.level = sortLevel + 1);
              const spliceIndex = nsn.findIndex(n => !isAdding(n) && n.productId === (parentNode as ProductNode).productId) + 1;
              nsn.splice(spliceIndex, 0, ...children);
            };
          }
          sortedNodes = nsn;
          sortLevel += 1;
        }
        if (addingId) {
          // Ideally the field goes under all the part's children, but the logic to get the index for that is complicated so skipping it for now and just putting it right below the part
          // @ts-ignore
          // const addingIndex = sortedNodes.findLastIndex(n => !isAdding(n) && n.parentAssemblyId === addingId);
          const addingIndex = sortedNodes.findIndex(n => !isAdding(n) && n.productId === addingId);
          const addingNode = sortedNodes[addingIndex];
          sortedNodes.splice(addingIndex + 1, 0, {
            isAdding: true,
            parentAssemblyId: addingId,
            level: addingNode.level + 1
          });
        }
        return sortedNodes;
      }),
      shareReplay(1),
    );
  }

  public topProduct$: Observable<Product>;
  private setupTopProductObservable() {
    return this.service.selectedProduct$.pipe(
      withLatestFrom(this.service.autosaver.getPostChanges('productMap')),
      map(([selectedProduct, productsMap]) => selectedProduct?.topParentAssemblyId ? productsMap[selectedProduct.topParentAssemblyId] : null),
      filter(p => !!p)
    )
  }

  ngOnInit(): void {
    this.data = this.setupHierarchyObservable() as Observable<TreeNode[]>;
    this.topProduct$ = this.setupTopProductObservable();
    this.service.productHierarchy = this;
  }


  getParentNode(node: TreeNode, fullTree: TreeNode[]): ProductNode {
    return fullTree.find(n => !isAdding(n) && n.productId === node.parentAssemblyId) as ProductNode;
  }

  shouldRender(node: TreeNode, fullTree: TreeNode[]) {
    let parent = this.getParentNode(node, fullTree);
    while (parent) {
      if (!this.treeControl.isExpanded(parent)) {
        return false;
      }
      parent = this.getParentNode(parent, fullTree);
    }
    return true;
  }

  public sorting = false;
  public possibleParent: ProductNode | null = null;
  public isCreatingNewLevel = false;

  getTransform(x: number, y: number): string {
    return `translate3d(${Math.round(x)}px, ${Math.round(y)}px, 0)`;
  }

  private checkParent(draggingNode: ProductNode, index: number, data: ProductNode[]) {
    let parentDest = data[index - 1];

    this.possibleParent = parentDest;
  }

  public beforeDragStarted(node: TreeNode) {
    if (this.treeControl.isExpanded(node)) this.treeControl.collapse(node);
  }

  public dragStarted(event: CdkDragStart<ProductNode>, data: ProductNode[]) {
    const originalElement: HTMLElement = event.source.element.nativeElement;
    const rect = originalElement.getBoundingClientRect();
    const dragRef: DragRef = event.source._dragRef;
    // @ts-ignore because we want to edit a private property
    dragRef._pickupPositionOnPage = { x: rect.left + (event.source.data.level + 1) * 40, y: rect.top }
    setTimeout(() => {
      const previewElement: HTMLElement = document.querySelector('.cdk-drag-preview');
      previewElement.style.width = `${rect.width}px`;
      previewElement.style.transform = this.getTransform(rect.left, rect.top);
    });
    const index = data.findIndex(n => !isAdding(n) && n.productId === event.source.data.productId);
    this.checkParent(event.source.data, index, data);
  }

  public dragMoved(event: CdkDragStart<TreeNode>, data: ProductNode[]) {
    const previewElement: HTMLElement = document.querySelector('.cdk-drag-preview');
    if (!previewElement) return;
    const placeholder: HTMLElement = event.source.dropContainer.element.nativeElement.querySelector('.cdk-drag-placeholder').querySelector('.mat-tree-node');
    if (this.possibleParent) {
      const level = this.possibleParent.level;
      const hasOtherChildren = data.some(n => n.parentAssemblyId === this.possibleParent.productId);
      const threshold = (level + 1) * 40;
      const absoluteThreshold = (event.source.dropContainer.element.nativeElement.getBoundingClientRect().x) + threshold;
      if (!hasOtherChildren && previewElement.getBoundingClientRect().x > absoluteThreshold) {
        this.isCreatingNewLevel = true;
        placeholder.style.cssText = 'background-color: green !important;';
      } else {
        this.isCreatingNewLevel = false;
        placeholder.style.cssText = '';
      }
    } else {
      this.isCreatingNewLevel = false;
      placeholder.style.cssText = '';
    }
  }

  dragSorted(event: CdkDragDrop<ProductNode, ProductNode, ProductNode>, data: ProductNode[]) {
    this.checkParent(event.item.data, event.currentIndex, data);
    if (!this.possibleParent) return;
    const newSiblings = data.filter(n => !isAdding(n) && n?.parentAssemblyId === this.possibleParent?.parentAssemblyId) as ProductNode[];

    let level = !this.possibleParent ? 0 : data.find(node => (node as ProductNode).productId === (this.possibleParent as ProductNode).productId).level + 1;
    const hasOtherChildren = data.some(n => n.parentAssemblyId === this.possibleParent.productId);
    if (!hasOtherChildren) level -= 1;

    if (level === 0 || (newSiblings.map(x => x.parentAssemblyId).some(x => x === event.item.data.productId))) {
      const x: HTMLElement = event.container.element.nativeElement.querySelector('.cdk-drag-placeholder');
      x.style.display = 'none';
      x.style.height = '0px';
    } else {
      const x: HTMLElement = event.container.element.nativeElement.querySelector('.cdk-drag-placeholder');
      x.style.display = 'block';
      x.style.paddingLeft = `${(level * 40)}px`;
    }
  }

  public drop(event: CdkDragDrop<ProductNode, ProductNode, ProductNode>, data: ProductNode[]) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;
    if (!this.possibleParent) return;

    const draggingNode = event.item.data;
    let newParentId: string;

    const hasOtherChildren = data.some(n => n.parentAssemblyId === this.possibleParent.productId);
    if (!hasOtherChildren && !this.isCreatingNewLevel) {
      newParentId = this.possibleParent.parentAssemblyId;
    } else {
      newParentId = this.possibleParent.productId;
    }

    if (newParentId === draggingNode.productId) {
      console.error('Attempting to set product as its own parent!');
      return;
    }

    const parentNode = data.find(p => p.productId === newParentId);
    if (!this.treeControl.isExpanded(parentNode)) this.treeControl.expand(parentNode);

    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: draggingNode.productId,
        field: 'parentAssemblyId',
        oldValue: draggingNode.parentAssemblyId,
        newValue: newParentId,
      },
    };
    this.service.recordChanges(change);
    

    this.possibleParent = null;
    this.isCreatingNewLevel = false;
    
  }

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public menuPosition: { x: number; y: number } = { x: 0, y: 0 }
  public menuItem: TreeNode | null;
  public openMenu(event: MouseEvent, item: ProductNode | null) {
    if (!this.service.editing) return;
    event.preventDefault();
    if (item && item.level > 0) {
      event.stopPropagation();
      this.menuItem = item;
    } else {
      this.menuItem = null;
    }
    this.menuPosition = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    this.menuTrigger.openMenu();
  }

  public async menuAddChild(item: ProductNode | null, topProduct: Product) {
    const parentId = item?.productId ?? topProduct.productId;

    const allProducts = await fetchLatest(this.service.allProducts$);
    const { partNumber: parentPartNumber }= allProducts.find(p => p.productId === parentId);
    const children = allProducts.filter(p => p.parentAssemblyId === parentId);
    const regex = children.map(sa => 
      /-(\d+)$/.exec(sa.partNumber)
    );
    const highestExisting = regex.filter(r => !!r).map(r => r[1])
      .filter(r => !!r)
      .map(r => parseFloat(r))
      .sort((a, b) => b - a)
      .at(0)
    ;
    const subAssemblyNumber = (highestExisting ?? 0) + 1;
    this.addingName = `${parentPartNumber}-${subAssemblyNumber}`;
    this.addingIdSubject.next(parentId);
    setTimeout(() => {
      const addInput = document.getElementById('adding-text-field');
      addInput.focus();
    });
  }

  public isAdding(_: number, node: TreeNode) {
    return isAdding(node);
  }
  public addingName: string = null;
  public cancelAdding(event: FocusEvent, node: TreeNode) {
    // only cancel if newly focused event is not inside target
    const tgt = event.target as HTMLElement;
    const newTgt = event.relatedTarget as HTMLElement;
    if (
      newTgt &&
      (
        tgt === newTgt
        ||
        // newTgt.contains(tgt)
        // ||
        document.querySelector('#adding-text-field-container').contains(newTgt)
      )
    ) return;
    this.addingIdSubject.next(null);
  }

  public async saveAdding(partNumber: string, data: ProductNode[]) {
    const parentId = await fetchLatest(this.addingIdSubject);
    const parent = await fetchLatest(this.service.getProductObservable(parentId));
    this.addingIdSubject.next(null);
    const newId = UtilityService.newGuid();
    const change: CreateBlankSubLevelProductChange = {
      changeType: 'CREATE_BLANK_SUB_LEVEL_PRODUCT',
      entity: null,
      data: {
        productId: newId,
        partNumber: partNumber,
        revision: '-',
        parentAssemblyId: parentId,
        topParentAssemblyId: parent.topParentAssemblyId,
      },
    };
    this.service.recordChanges(change);
    this.treeControl.expand(data.find(p => p.productId === parentId));
    this.service.selectedProductIdSubject.next(newId);
  }

  @ViewChild('cloneChildDialog') cloneChildDialog: TemplateRef<any>;
  public async menuCloneChild(item: ProductNode | null, topProduct: Product) {
    const parentId = item?.productId ?? topProduct.productId;

    const dialogRef = this.dialog.open<any, { value: ClassifiedProduct }, ClassifiedProduct | null>(this.cloneChildDialog, {
      minWidth: '500px',
      data: { value: null },
    });
    
    const result = await dialogRef.afterClosed().toPromise();
    if (!result) return;

    const change: CloneProductSubLevelChange = {
      changeType: 'CLONE_PRODUCT_SUB_LEVEL',
      entity: null,
      data: {
        parentAssemblyId: parentId,
        topParentAssemblyId: topProduct.productId,
        productId: result.product.productId,
        intoProductId: UtilityService.newGuid(),
        tempData: { partNumber: result.product.partNumber, revision: result.product.revision },
        newRev: null,
      },
    };
    this.service.recordChanges(change);
    this.treeControl.expand(item);
  }

  public async menuDuplicateChild(item: TreeNode) {
    if (isAdding(item)) return;
    const topProduct = await fetchLatest(this.topProduct$);
    const change: CloneProductSubLevelChange = {
      changeType: 'CLONE_PRODUCT_SUB_LEVEL',
      entity: null,
      data: {
        productId: item.productId,
        intoProductId: UtilityService.newGuid(),
        parentAssemblyId: item.parentAssemblyId,
        topParentAssemblyId: topProduct.productId,
        newRev: null,
        tempData: { partNumber: item.partNumber, revision: item.revision },
      },
    };
    this.service.recordChanges(change);
  }

  public async menuDelete(item: TreeNode) {
    if (isAdding(item)) return;
    const product = await fetchLatest(this.service.getProductObservable(item.productId));
    const change: DeleteChange = {
      changeType: 'DELETE',
      entity: 'Product',
      data: {
        itemId: item.productId,
        oldValue: product,
      },
    };
    this.service.recordChanges(change);
  }

  public isLoading(_: number, node: TreeNode) {
    return !isAdding(node) && node.loading;
  }

  public async expandAll() {
    const data = await fetchLatest(this.data);
    for (const node of data) {
      this.treeControl.expand(node);
    }
  }

}
