import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { OrderDetailService } from "../order-detail.service";
import { Product, ProductQuantity } from "../../../resources/product";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Observable, ReplaySubject, combineLatest } from "rxjs";
import {
  filter,
  map,
  switchMap,
  take,
} from "rxjs/operators";
import { WorkflowStep } from "../../../resources/workflow";
import { MatDialog } from "@angular/material/dialog";
import { UpdateChange } from "@cots/common/autosaving/change";

@Component({
  selector: "order-detail-quantity-table",
  templateUrl: "./order-detail-quantity-table.component.html",
  styleUrls: ["./order-detail-quantity-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailQuantityTableComponent implements OnInit, OnChanges {
  constructor(public service: OrderDetailService, private dialog: MatDialog) {}

  public get editing() {
    return this.service.editing;
  }

  public dataSource: Observable<
    (ProductQuantity | { isStep: boolean; name: string; price: number })[]
  >;
  public isStep(_, item: ProductQuantity | WorkflowStep): item is WorkflowStep {
    return item.hasOwnProperty("isStep");
  }

  public preventNegative(e: KeyboardEvent) {
    if (e.key === "-" || e.key === ".") {
      return false;
    }
  }

  public readonly displayedColumns = [
    "dragHandle",
    "quantity",
    "cost",
    "markup",
    "calcPrice",
    "unitPrice",
    "extPrice",
    "delete",
  ] as const;

  private doChange(product: Product, newMap: ProductQuantity[]) {
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: product.productId,
        field: 'quantitiesMap',
        oldValue: product.quantitiesMap,
        newValue: newMap,
      },
    };
    this.service.recordChanges(change);
  }

  public addingQuantity: number = null;
  public addQuantity(product: Product) {
    if (!this.addingQuantity || this.addingQuantity < 1) return;
    if (product.quantitiesMap.some((q) => q.value === this.addingQuantity))
      return;
    const newValue = [
      ...product.quantitiesMap,
      {
        value: this.addingQuantity,
        markup: this.getMasterMarkup(product) ?? 18,
        showOnQuote: true,
      },
    ];
    this.doChange(product, newValue);
    this.addingQuantity = null;
  }

  public deleteQuantity(product: Product, qty: ProductQuantity) {
    const newValue = product.quantitiesMap.filter(
      (q) => q.value !== qty.value
    );
    this.doChange(product, newValue);
  }

  public drop(product: Product, event: CdkDragDrop<ProductQuantity[]>) {
    const newMap = structuredClone(product.quantitiesMap);
    moveItemInArray(
      newMap,
      event.previousIndex,
      event.currentIndex
    );
    this.doChange(product, newMap);
  }

  public product$: Observable<Product>;
  ngOnInit(): void {
    this.product$ = this.selectedProduct.pipe(
      filter((p) => !!p),
      map((p) => p.productId),
      switchMap((productId) => this.service.getProductObservable(productId))
    );
    this.dataSource = combineLatest([
      this.product$,
      this.service.stationService.stations,
    ]).pipe(
      map(([product, stations]) => {
        let data = [
          ...product.quantitiesMap,
          ...product.workflow.workflowSteps
            .filter((s) => s.isStandalone)
            .map((step) => ({
              isStep: true,
              name:
                stations.find((s) => s.stationId === step.stationId)?.name ??
                "Unknown Station",
              price: WorkflowStep.calculateCostForQty(step, 1),
            })),
        ];
        const progPrice = this.service.generateProgrammingPrice(product);
        if (progPrice > 0) {
          data = [
            ...data,
            {
              isStep: true,
              name: "Programming",
              price: progPrice,
            },
          ];
        }
        return data;
      })
    );
  }

  public getCostForQuantity(product: Product, item: ProductQuantity) {
    return this.service.getProductPriceObservable(product, item.value);
  }

  public getPriceForQuantity(product: Product, item: ProductQuantity) {
    return combineLatest([this.service.getProductObservable(product.productId), this.getCostForQuantity(product, item)]).pipe(
      map(([product, result]) => {
        const override = product.quantitiesMap.find(q => q.value === item.value)?.priceOverride;
        if (override) return override;
        else return result * (1 + item.markup / 100);
      })
    );
  }

  public getUnitPriceForQuantity(product: Product, item: ProductQuantity) {
    return this.getPriceForQuantity(product, item).pipe(
      map((result) => {
        return parseFloat((result / item.value).toFixed(2));
      })
    );
  }

  public getExtPriceForQuantity(product: Product, item: ProductQuantity) {
    return this.getUnitPriceForQuantity(product, item).pipe(
      map((result) => {
        return result * item.value;
      })
    );
  }

  public onEditMarkup(product: Product, quantity: number, newMarkup: number) {
    const newMap = structuredClone(product.quantitiesMap);
    const item = newMap.find(x => x.value === quantity);
    if (!item) return;
    item.markup = newMarkup;
    this.doChange(product, newMap);
  }

  private masterMarkupBackup = 0;
  public getMasterMarkup(product: Product) {
    if (product.quantitiesMap.length === 0) return this.masterMarkupBackup;
    if (
      product.quantitiesMap.every(
        (q) => q.markup === product.quantitiesMap[0].markup
      )
    ) {
      this.masterMarkupBackup = product.quantitiesMap[0].markup;
      return product.quantitiesMap[0].markup;
    } else return null;
  }
  public setMasterMarkup(product: Product, markup: number) {
    this.masterMarkupBackup = markup;
    const newMap = structuredClone(product.quantitiesMap);
    for (const item of newMap) {
      if (!item.priceOverride) item.markup = markup;
    }
    this.doChange(product, newMap);
  }

  @Input() product: Product;
  private selectedProduct = new ReplaySubject<Product>();
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.selectedProduct.next(changes.product.currentValue);
    }
  }


  @ViewChild('overridePriceDialog', { static: true }) overridePriceDialog: TemplateRef<any>;
  
  public async editPriceOverride(product: Product, qty: ProductQuantity) {

    const baseCost = await this.getCostForQuantity(product, qty).pipe(take(1)).toPromise();
    this.dialog.open(this.overridePriceDialog, {
      disableClose: true,
      data: {
        qty,
        product,
        basePrice: parseFloat((baseCost * (1 + qty.markup / 100)).toFixed(2)),
        newPrice: qty.priceOverride || null,
        note: qty.overrideNote || null,
      }
    });
  }

  public async setOverride(product: Product, val: number, price: number, note: string) {
    const newMap = structuredClone(product.quantitiesMap);
    const item = newMap.find(x => x.value === val);
    if (!item) return;
    item.priceOverride = price;
    item.overrideNote = note;
    // set markup based on override
    if (price !== null) {
      const basePrice = await this.getCostForQuantity(product, item).pipe(take(1)).toPromise();
      if (basePrice === 0) {
        item.markup = 0;
      } else {
        const diff = price - basePrice;
        const percentageDiff = (diff / basePrice) * 100;
        item.markup = Math.round(percentageDiff);
      }
    } else {
      item.markup = 18;
    }
    this.doChange(product, newMap);
  }

  public trackByFn = (_: null, n: ProductQuantity & { isStep: true }) => `${n.value}-${n.isStep}`;
}
