import { Material } from "../../order/resources/material";
import { Order } from "../../order/resources/order";
import { PurchasedItem } from "../../order/resources/purchased-item";
import { MaterialBid } from "../../purchasing/resources/materialBid";
import { OutsideProcessDescription } from "../../purchasing/resources/outsideProcessDescription";
import { PurchaseOrder } from "../../purchasing/resources/purchaseOrder";
import { User } from "./user";

export enum PurchasingRfqRequestStatus {
    NOTHING_SENT = 0,
    AWAITING_RFQS = 1,
    QUOTES_AVAILABLE = 2,
    QUOTE_SELECTED = 3,
}

export class PurchasingRfqRequest {
    purchasingRfqRequestId: string;
    quantityRequested: number;
    dueDate?: Date;

    status: number;

    materialId?: string;
    outsideProcessDescriptionId?: string;
    purchasedItemId?: string;


    sourcePurchaseOrderId?: string;
    sourceEstimateId?: string;

    outsideProcessDescription: OutsideProcessDescription;
    material: Material;
    purchasedItem: PurchasedItem;

    sourcePurchaseOrder: PurchaseOrder;
    sourceEstimate: Order;

    materialBids: MaterialBid[];

    selectedQuoteId: string;

    dateRequested: Date;
    requestedBy: User;

    public static getItemName(item: PurchasingRfqRequest) {
        if (item.material) return item.material.materialName;
        else if (item.purchasedItem) return item.purchasedItem.description;
        // TODO name other steps
        else if (item.outsideProcessDescription) return `${item.outsideProcessDescription.steps[0]?.station.name} for ${item.outsideProcessDescription.product.partNumber} Rev. ${item.outsideProcessDescription.product.revision}`;
        else return "Unknown Item";
    }
    
    public static getSourceTicketName(item: PurchasingRfqRequest) {
        if (item.sourceEstimate) return item.sourceEstimate.quoteNumber ?? item.sourceEstimate.orderNumber;
        else if (item.sourcePurchaseOrder) return item.sourcePurchaseOrder.purchaseOrderNumber;
        else return ""
    }


    public static getStatusText(status: PurchasingRfqRequestStatus) {
        switch (status) {
            case PurchasingRfqRequestStatus.NOTHING_SENT:
                return "RFQs Not Sent";
            case PurchasingRfqRequestStatus.AWAITING_RFQS:
                return "Awaiting RFQs";
            case PurchasingRfqRequestStatus.QUOTES_AVAILABLE:
                return "Awaiting Quote Selection";
            case PurchasingRfqRequestStatus.QUOTE_SELECTED:
                return "Pricing Available";
        }
    }

    public static getStatusChipClass(status: PurchasingRfqRequestStatus) {
        switch (status) {
            case PurchasingRfqRequestStatus.NOTHING_SENT:
                return "bg-warning text-dark";
            case PurchasingRfqRequestStatus.AWAITING_RFQS:
                return "bg-info text-white";
            case PurchasingRfqRequestStatus.QUOTES_AVAILABLE:
                return "bg-warning text-dark";
            case PurchasingRfqRequestStatus.QUOTE_SELECTED:
                return "bg-success text-white";
        }
    }
}
