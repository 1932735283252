import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../common/services/navigation.service';
import { PurchasingRfqRequestService } from '../../../common/services/purchasing-rfq-request.service';
import { Router } from '@angular/router';
import { SearchData } from '../../../common/components/search-list-new/search-list-new.component';
import { PurchaseOrder } from '../../resources/purchaseOrder';
import { UtilityService } from '../../../common/services/utility.service';
import { PurchasingRfqRequest } from '../../../common/resources/purchasing-rfq-request';

@Component({
  selector: 'purchasing-rfq-request-queue',
  templateUrl: './purchasing-rfq-request-queue.component.html',
  styleUrls: ['./purchasing-rfq-request-queue.component.less']
})
export class PurchasingRfqRequestQueueComponent {

  constructor(navService: NavigationService, private service: PurchasingRfqRequestService, private router: Router) {
    navService.setPageTitle("Procurement");
  }

  public search({ searchText, page, sort }: SearchData) {
    return this.service.search(searchText, page.pageIndex, sort.active, sort.direction);
  }

  onSelect(record: PurchasingRfqRequest): void {
    this.router.navigate(['/purchasing/procurement/', record.purchasingRfqRequestId]);
  }

  daysLeft(date: string, abs: boolean): number | null {
    if (date == null) return null;

    return UtilityService.getDaysRemaining(new Date(date), abs);
  }

  public getItemName = PurchasingRfqRequest.getItemName;
  public getSourceTicketName = PurchasingRfqRequest.getSourceTicketName;


}
