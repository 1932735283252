import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { take, map, startWith, shareReplay } from 'rxjs/operators';
import { MicroTicketService } from '../../services/microticket.service';
import { MicroTicket, MicroTicketStatus } from '../../resources/microticket';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { NewMicroticketComponent } from '../new-microticket/new-microticket.component';
import { VirtualDocument } from '../../../common/resources/virtual-document';
import { User } from '@sentry/angular-ivy';

@Component({
  selector: 'microticket-list',
  templateUrl: './microticket-list.component.html',
  styleUrls: ['./microticket-list.component.less']
})
export class MicroticketListComponent<TSubItem> implements AfterViewInit {


  constructor(private service: MicroTicketService, private userService: UserService, private utilityService: UtilityService, private cdr: ChangeDetectorRef) {}
  
  @Input() relatedTicketId: string;
  @Input() relatedTicketName: string;
  @Input() relatedTicketType: string;
  @Input() filterKey: string;

  @Input() subItemName: string = 'Subitem';
  @Input() subItems: Observable<TSubItem[]> = of(null);
  @Input() subItemDisplayWith: (i: TSubItem) => string = (x) => 'No String Function';
  @Input() subItemCompareWith: (i: TSubItem) => any = (x) => x;

  @Input() existingDocuments: Observable<VirtualDocument[]> | null = null;

  @ViewChild('filterModel') filterModel: NgModel;
  public sortOrder = new BehaviorSubject<"asc" | "desc">("asc");

  @Input() subject = new ReplaySubject<MicroTicket[]>(1);
  public filters = new BehaviorSubject<MicroTicketStatus[]>([0,1]);

  results$: Observable<MicroTicket[]>;

  public userFilter = 'assigned';
  @ViewChild('userFilterModel') userFilterModel: NgModel;

  private updateFromServer() {
    this.service.getTickets(this.relatedTicketId, this.filterKey ?? null).pipe(take(1)).subscribe(n => this.subject.next(n));
  }

  public userNames: Observable<User[]>;

  ngAfterViewInit(): void {
    this.updateFromServer();
    this.userNames = this.userService.listUserNames().pipe(shareReplay(1));

    this.results$ = combineLatest([
      this.subject,
      this.filterModel.valueChanges.pipe(startWith('')),
      this.userFilterModel.valueChanges.pipe(startWith('assigned')),
      this.filters,
      this.userService.user
    ]).pipe(
      map(([data, searchText, userFilter, filters, currentUser]) => {
        if (!data) return null;
        if (userFilter === 'assigned') data = data.filter(d => 
          d.assignedUserId === currentUser.userId ||
          d.carbonCopiesList.includes(currentUser.userId)
        );
        if (userFilter === 'created') data = data.filter(d => d.creatorId === currentUser.userId);
        // Then filter
        if (filters.length > 0) data = data.filter(d => filters.includes(d.status));
        // Then text
        if (typeof searchText === 'string' && !!searchText?.trim()) data = data.filter(d => JSON.stringify(d).toLowerCase().includes(searchText.toLowerCase()))
        // Then sort
        data.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
        return data;
      })
    )

    this.cdr.detectChanges();
  }
  
  public getStatusText(status: MicroTicketStatus) {
    return MicroTicketService.getStatusText(status);
  }

  public getStatusColorClass(status: MicroTicketStatus) {
    return MicroTicketService.getStatusColorClass(status);
  }

  public filterIsEnabled(filter: number, selected: number[]) {
    return selected.includes(filter);
  }

  public toggleFilter(filter: number) {
    this.filters.pipe(
      take(1),
      map(fs => {
        if (fs.includes(filter)) return fs.filter(ff => ff !== filter);
        else return [...fs, filter];
      })
    ).subscribe((v) => this.filters.next(v));
  }

  public getSubItemName(ticket: MicroTicket, subItems: TSubItem[]) {
    if (!ticket.relatedSubItem) return null;
    const item = subItems.find(i => this.subItemCompareWith(i) === ticket.relatedSubItem);
    if (!item) return 'Unknown or Deleted Item';
    try {
      return this.subItemDisplayWith(item);
    } catch (e) {
      return 'Unknown or Deleted Item';
    }
  }

  public editing = false;
  public editingId: 'new' | string | null = null;
  public create() {
    this.editing = true;
    this.editingId = 'new';
  }

  private microTicketForm: NewMicroticketComponent<TSubItem>;
  @ViewChild('microTicketForm', { static: false }) set microTicketFormSetter(content: NewMicroticketComponent<TSubItem>) {
    if (content) this.microTicketForm = content;
  }

  public edit(item: MicroTicket) {
    this.editing = true;
    this.editingId = item.microTicketId;
    setTimeout(() => {
      this.microTicketForm.initialize(item);
    })
  }

  @Output() loadingChange = new EventEmitter<boolean>();
  public async onSave(formData: MicroTicket) {
    this.editing = false;
    this.loadingChange.emit(true);
    const data = {
      microTicketId: this.editingId === 'new' ? UtilityService.emptyGuid : this.editingId,
      relatedTicketId: this.relatedTicketId,
      relatedTicketName: this.relatedTicketName,
      relatedTicketType: this.relatedTicketType,
      filterKey: this.filterKey,
      status: MicroTicketStatus.OPEN,
      creatorId: this.userService.userData.userId,
      createdDate: new Date(),
      ...formData
    };
    if (this.editingId === 'new') {
      await this.service.createTicket(data).toPromise();
    } else {
      await this.service.saveTicket(data).toPromise();
    }
    this.userFilter = 'created';
    this.updateFromServer();
    this.loadingChange.emit(false);
  }

  public onCancel() {
    this.editing = false;
    this.editingId = null;
  }

  public async close(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "Closing a ticket cannot be undone.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.closeTicket(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  public async markInProgress(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "The ticket will be marked as in progress.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.markTicketInProgress(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  public async markComplete(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "The ticket will be marked as completed.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.markTicketComplete(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  public async reopen(ticket: MicroTicket) {
    const c = await this.utilityService.showConfirmationPromise("Are you sure?", "The ticket will be reopened.");
    if (!c) return;
    this.loadingChange.emit(true);
    await this.service.reopen(ticket).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }
  
  @Output() jumpToSubitem = new EventEmitter<string>();

  public async newComment(ticket: MicroTicket, commentText: string) {
    this.loadingChange.emit(true);
    await this.service.createComment({
      microTicketCommentId: UtilityService.emptyGuid,
      microTicketId: ticket.microTicketId,
      createdDate: new Date(),
      creatorId: this.userService.userData.userId,
      text: commentText,
      creator: null
    }).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }

  public async addFiles(ticket: MicroTicket, files: VirtualDocument[]) {
    this.loadingChange.emit(true);
    await this.service.addDocuments(ticket, files).toPromise();
    this.updateFromServer();
    this.loadingChange.emit(false);
  }

  public trackByFn(index: number, item: MicroTicket) {
    return item.microTicketId;
  }

}
