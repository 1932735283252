<div class="d-flex flex-column h-100" *ngLet="service.postChangesOrder$ | async as record">
  <div class="row m-0 p-3" ngForm #orderForm="ngForm">
    <div class="row m-0 col-8 p-0">
      <div class="col-3 mb-2" *ngIf="record.discriminator !== 'Estimate' && record.discriminator !== 'RMAEstimate'">
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Order Number</mat-label>
          <input matInput readonly type="text" [ngModel]="record?.orderNumber ?? ''"
            [ngModelOptions]="{ standalone: true }" placeholder="-- Not Yet Assigned --" class="font-weight-bold"
            tabindex="-1">
        </mat-form-field>
      </div>
      <ng-container *ngIf="record.discriminator === 'Estimate' || record.discriminator === 'RMAEstimate'">
        <div class="col-5 mb-2">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Order Number</mat-label>
            <input matInput readonly type="text" [ngModel]="record?.quoteNumber ?? ''"
              [ngModelOptions]="{ standalone: true }" placeholder="-- Not Yet Assigned --" class="font-weight-bold"
              tabindex="-1">
          </mat-form-field>
        </div>
        <div class="col-4 mb-2">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-label>Estimate Number</mat-label>
            <input matInput readonly type="text" [ngModel]="record?.orderNumber ?? ''"
              [ngModelOptions]="{ standalone: true }" placeholder="-- Not Yet Assigned --" class="font-weight-bold"
              tabindex="-1">
          </mat-form-field>
        </div>
      </ng-container>
      <div class="col-3">
        <company-search [editing]="editing" [ngModel]="record.company"
          (ngModelChange)="onRelatedEntityChange('company', 'companyId', record.company, $event, record.company?.companyId, $event?.companyId)" name="company"></company-search>
      </div>
      <div class="col-3 mb-2">
        <customer-search [readonly]="!editing" label="Customer" [ngModel]="record.customer" name="customer"
          [required]="true" placeholder="Please select a customer..." floatLabel="always"
          (ngModelChange)="onCustomerSelected($event, record)"></customer-search>
      </div>
      <div class="col-3 mb-2">
        <clientside-search label="Customer Contact" noItemsText="No contacts found." [readonly]="!editing"
          [disabled]="!record.customer" [items]="record.customer?.customerContacts" [required]="false"
          [getSearchField]="getCustomerContactName" [ngModel]="record.customerContact" [item]="record.customerContact"
          (ngModelChange)="onRelatedEntityChange('customerContact', 'customerContactId', record.customerContact, $event, record.customerContact?.customerContactId, $event?.customerContactId)" [reqSel]="true" [canAdd]="true"
          [addConverter]="this.generateCustomerContact.bind(this)" (added)="customerContactAdded($event, record)"
          name="customerContact">
        </clientside-search>
      </div>
      <div class="col-12 px-3 mb-2" style="min-width: 50%;">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer RFQ/Quote Reference</mat-label>
          <input type="text" [readonly]="!editing" matInput [(ngModel)]="record.customerRfqnumber"
            name="customerRfqnumber" />
        </mat-form-field>
      </div>
      <!-- Need two different versions of this for RFQ and Estimate -->
      <ng-container *ngIf="isSales(record)">
        <div class="col-3">
          <employee-search [readonly]="true" label="Creator" [ngModel]="record.creator" name="creator"
            (ngModelChange)="onRelatedEntityChange('creator', 'creatorId', record.creator, $event, record.creator?.creatorId, $event?.creatorId)"
            [placeholder]="record.assignedUser ? undefined : 'Unknown'" floatLabel="always"
            (selectedItemChange)="record.creatorId = $event?.userId"></employee-search>
        </div>
        <div class="col-3">
          <employee-search [readonly]="!editing" label="Sales Person" [ngModel]="record.assignedUser"
            (ngModelChange)="onRelatedEntityChange('assignedUser', 'assignedTo', record.assignedUser, $event, record.assignedUser?.userId, $event?.userId)"
            name="assignedUser" [placeholder]="record.assignedUser ? undefined : 'Unassigned'" floatLabel="always"
            (selectedItemChange)="record.assignedTo = $event?.userId"></employee-search>
        </div>
        <div class="col-3">
          <employee-search [readonly]="!editing" label="Estimator" [ngModel]="record.estimator" name="estimator"
            (ngModelChange)="onRelatedEntityChange('estimator', 'estimatorId', record.estimator, $event, record.estimator?.estimatorId, $event?.userId)"
            [placeholder]="record.estimator ? undefined : 'Unassigned'" floatLabel="always"
            (selectedItemChange)="record.estimatorId = $event?.userId"></employee-search>
        </div>
      </ng-container>
      <ng-container *ngIf="isEstimating(record)">
        <div class="col-3">
          <employee-search [readonly]="true" label="Creator" [ngModel]="record.creator" name="creator"
            [placeholder]="record.assignedUser ? undefined : 'Unknown'" floatLabel="always"
            (selectedItemChange)="record.creatorId = $event?.userId"></employee-search>
        </div>
        <div class="col-3">
          <employee-search [readonly]="!editing" label="Sales Person" [ngModel]="record.salesPerson"
            (ngModelChange)="onRelatedEntityChange('salesPerson', 'salesPersonId', record.salesPerson, $event, record.salesPerson?.salesPersonId, $event?.userId)"
            name="salesPerson" [placeholder]="record.salesPerson ? undefined : 'Unassigned'" floatLabel="always"
            (selectedItemChange)="record.salesPersonId = $event?.userId"></employee-search>
        </div>
        <div class="col-3">
          <employee-search [readonly]="!editing" label="Estimator" [ngModel]="record.assignedUser" name="assignedUser"
            (ngModelChange)="onRelatedEntityChange('assignedUser', 'assignedTo', record.assignedUser, $event, record.assignedUser?.assignedTo, $event?.userId)"
            [placeholder]="record.assignedUser ? undefined : 'Unassigned'" floatLabel="always"
            (selectedItemChange)="record.assignedTo = $event?.userId"></employee-search>
        </div>
      </ng-container>
      <div class="col-3 mb-2">
        <mat-form-field appearance="outline" class="w-100 form-field-chip-size" tabindex="-1">
          <mat-label>Status</mat-label>
          <mat-chip-list #chipList [tabIndex]="-1">
            <mat-chip matPrefix style="pointer-events: none;" class="mat-standard-chip button-text"
              [ngClass]="getStatusColorClass(record.status)" tabindex="-1">{{ getStatusText(record.status) }}</mat-chip>
            <input tabindex="-1" matInput [matChipInputFor]="chipList" type="text" readonly>
          </mat-chip-list>
          <button mat-icon-button matSuffix class="text-muted" *ngIf="record.statusNote" matTooltip="View Status Note"
            (click)="viewStatusNote()">
            <mat-icon>info</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
          <mat-label>Date Created</mat-label>
          <input type="text" matInput readonly
            [value]="record.createdDate ? (record.createdDate | date: 'YYYY/MM/dd HH:mm a') : null"
            placeholder="Unknown">
        </mat-form-field>
      </div>
      <div class="col-3">
        <date-picker appearance="outline" class="w-100" placeholder="Date Received" [(ngModel)]="record.receivedDate"
          name="receivedDate" [editable]="true" [readonly]="!editing"></date-picker>
      </div>
      <div class="col-6 mb-2">
        <date-picker appearance="outline" class="w-100" placeholder="Date Required" [(ngModel)]="record.requiredDate"
          name="requiredDate" [editable]="true" [readonly]="!editing"></date-picker>
      </div>
      <div class="col-3 mb-2" *ngIf="record.discriminator == 'Estimate' || record.discriminator == 'RMAEstimate'">
        <date-picker appearance="outline" class="w-100" placeholder="Estd. Estimate Completion Date"
          [(ngModel)]="record.estimatedEstimateCompletionDate" name="estimatedEstimateCompletionDate" [editable]="true"
          [readonly]="!editing"></date-picker>
      </div>
      <div class="col-9 mb-2" *ngIf="record.discriminator == 'Estimate' || record.discriminator == 'RMAEstimate'">
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes for Date</mat-label>
          <input type="text" matInput [readonly]="!editing"
            [(ngModel)]="record.estimatePromiseDateNotes"
            placeholder="No notes entered"
            name="estimatePromiseDateNotes">
        </mat-form-field>
      </div>
    </div>
    <div class="row m-0 col-4 p-0" style="margin-bottom: 0.75em !important">
      <mat-form-field appearance="outline" class="w-100 mat-textarea-h-100" floatLabel="always">
        <mat-label>Project Description</mat-label>
        <textarea [readonly]="!editing" matInput [(ngModel)]="record.projectDescription" name="projectDescription"
          rows="2" placeholder="Project description (optional) - will appear on invoice."></textarea>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline" class="w-100" floatLabel="always">
        <mat-label>Public Notes</mat-label>
        <textarea [readonly]="!editing" matInput [(ngModel)]="record.publicNotes" name="publicNotes" rows="6"
          placeholder="Client visible notes can go here"></textarea>
      </mat-form-field>
    </div>
    <div class="col-4 mb-2">
      <mat-form-field appearance="outline" class="w-100" floatLabel="always">
        <mat-label>Private Notes</mat-label>
        <textarea [readonly]="!editing" matInput [(ngModel)]="record.privateNotes" name="privateNotes" rows="6"
          placeholder="Internal-only notes can go here"></textarea>
      </mat-form-field>
    </div>
    <div class="col-4 p-0" style="margin-bottom: .75em !important">
      <mat-form-field appearance="outline" class="w-100 mat-textarea-h-100" floatLabel="always">
        <mat-label>Additional Project Considerations</mat-label>
        <textarea [readonly]="!editing" matInput [(ngModel)]="record.quoteNotes" name="quoteNotes" rows="4"
          placeholder="Notes to show on the printed quote can go here"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="position-relative flex-grow-1 pb-4" style="
    padding-left: 2em;
    padding-right: 1em;
  ">
    <order-detail-products></order-detail-products>
  </div>
</div>

<ng-template #statusNoteDialogTemplate>
  <h2 mat-dialog-title>Status Note</h2>
  <mat-dialog-content class="mat-typography">
    <blockquote *ngIf="record.statusNote" class="border-left pl-2">
      {{ record.statusNote }}
    </blockquote>
    <div *ngIf="!record.statusNote">
      No rejection note was provided.
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="null">Close</button>
  </mat-dialog-actions>
</ng-template>