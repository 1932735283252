import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VirtualDocument, getMimeTypeIcon } from '../../../../common/resources/virtual-document';
import { UtilityService } from '../../../../common/services/utility.service';
import { OrderDetailService } from '../order-detail.service';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { fromMime } from 'human-filetypes'
import { DocumentService } from '../../../../common/services/document.service';

@Component({
  selector: 'order-detail-documents',
  templateUrl: './order-detail-documents.component.html',
  styleUrls: ['./order-detail-documents.component.less']
})
export class OrderDetailDocumentsComponent implements OnInit {

  @Input() documents: VirtualDocument[];
  @Output() documentsChange = new EventEmitter<VirtualDocument[]>()
  @Input() showTags = true;
  @Input() editing = false;
  @Input() uploadFunction: (files: File[]) => Promise<VirtualDocument[]> = (files) => Promise.all(files.map(f => this.documentService.upload(f as any).toPromise()));
;
  constructor(private utilitySvc: UtilityService, private documentService: DocumentService) { }

  ngOnInit(): void { }

  private checkEmptyFiles(files: File[]) {
    if (files.some(f => f.size === 0)) {
      this.utilitySvc.showAlert("Empty Files Detected", "<p>Some of the files you selected are empty and cannot be uploaded.</p><p>This is usually caused by trying to drag-and-drop files directly from a .zip folder. Please extract them first if so.</p><p>If this isn't what you're doing, please inspect the files; they may be corrupted.</p>")
      return true;
    } else {
      return false;
    }
  }

  @Output() documentsAdded = new EventEmitter<VirtualDocument[]>();
  async addDocuments(files: File[]) {
    if (this.checkEmptyFiles(files)) return;
    const vdocs = await this.uploadFunction(files);
    this.documentsAdded.emit(vdocs);
    this.documentsChange.emit([...this.documents, ...vdocs]);
    this.fileInput.nativeElement.value = null;
  }

  onBrowseDone(filelist: FileList) {
    const files = Array.from(filelist);
    this.addDocuments(files);
  }

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  public browse() {
    this.fileInput.nativeElement.click();
  }

  getFileIcon(doc: VirtualDocument): string {
    if (!doc) return null;
    return getMimeTypeIcon(doc.mimeType);
  }

  getFriendlyFiletype(doc: VirtualDocument): string {
    return fromMime(doc.mimeType);
  }

  readonly separatorKeyCodes = [ENTER, COMMA, SPACE] as const;
  @Output() tagsEdited = new EventEmitter<{document: VirtualDocument, newTags: string[]}>();
  removeTag(doc: VirtualDocument, index: number): void {
    const tags = doc.tags.slice();
    tags.splice(index, 1);
    this.tagsEdited.emit({ document: doc, newTags: tags });
  }

  addTag(doc: VirtualDocument, event: MatChipInputEvent): void {
    event.chipInput!.clear();
    if (!event.value?.trim()) return;

    this.tagsEdited.emit({ document: doc, newTags: [...doc.tags, event.value] });
  }

  @Output() documentDeleted = new EventEmitter<VirtualDocument>();
  public deleteDocument(docToDelete: VirtualDocument) {
    this.documentDeleted.emit(docToDelete);
    this.documentsChange.emit(this.documents.filter(doc => doc.documentId !== docToDelete.documentId));
  }

  public openDocument(doc: VirtualDocument) {
    window.open(`/document/${doc.documentId}` + ('/?inline=true'), "_blank")
  }

  public dragging = false;
  private enterTarget: EventTarget | null;
  public onDragEnter(event: DragEvent) {
    if (!this.editing) return;
    this.enterTarget = event.target;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = true;
  }

  public onDragLeave(event: DragEvent) {
    if (!this.editing) return;
    if (this.enterTarget === event.target){
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
    }
  }

  public async onDrop(event: DragEvent) {
    if (!this.editing) return;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = false;
    const files = Array.from(event.dataTransfer.files ?? []);
    if (files.length === 0) return;
    this.addDocuments(files);
  }

  public trackByFn(index: number, item: VirtualDocument) {
    return item.documentId;
  }

}
