<div class="sortable-container">
  <div>
    <h5 class="bg-secondary text-center p-2">Available Materials</h5>
  </div>
  <div class="px-2">
    <mat-form-field appearance="outline" dense class="w-100 dense-field">
      <mat-label>Filter Items</mat-label>
      <input matInput type="text" [(ngModel)]="filter" #filterModel="ngModel">
      <i matSuffix [class.invisible]="!searching" class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
    </mat-form-field>
  </div>
  <ul class="sortable-list" (scroll)="onListScroll($event)">
    <li *ngIf="!materialList">
      <p class="text-center">
        <i class="text-primary fas fa-spin fa-2x fa-circle-notch"></i>
      </p>
    </li>
    <li *ngFor="let material of materialList" (click)="select(material)">
      <div>
        <span [innerHTML]="getHighlightName(getGeneratedName(material))"></span>
      </div>
    </li>
  </ul>
</div>
