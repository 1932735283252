import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { NavigationService } from '../../../common/services/navigation.service';

type ApiData = {
  vendor: string;
  datePlaced: Date;
  daysLate: number;
}

type RowData = {
  vendor: string;
  onTime: number;
  total: number;
  percent: number;
}

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'on-time-processing-report',
  templateUrl: './on-time-processing-report.component.html',
  styleUrls: ['./on-time-processing-report.component.less'],
})

export class OnTimeProcessingReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private allData: ApiData[];
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  private GRACE_PERIOD_DAYS = 3;

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('On Time Processing');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<RowData>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'vendor', start: 'asc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getOnTimeProcessingData().subscribe(data => {
      this.allData = data;
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: ApiData[]): RowData[] {
    const rows: RowData[] = [];
    let grandTotalSales = 0;

    for (const data of apiData) {
      data.datePlaced = new Date(data.datePlaced);

      // If there's no row for this vendor, create one
      const row = rows.find(r => r.vendor === data.vendor);
      if (row === undefined) {
        rows.push({
          vendor: data.vendor,
          onTime: data.daysLate <= this.GRACE_PERIOD_DAYS ? 1 : 0,
          total: 1,
          percent: 0,
        });
      }

      // If there is a row, add to the on time values
      else {
        row.onTime += data.daysLate <= this.GRACE_PERIOD_DAYS ? 1 : 0;
        row.total += 1;
      }
    }

    // All in place, now calculate percent
    for (const row of rows) {
      row.percent = row.onTime / row.total;
    }

    return rows;
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    return pageData;
  }

  // Search data
  public searchText = '';
  search = (data: RowData, filterJson: string) => {
    return data.total > 0;
  }

  updateRowsWithFilter(filter: SearchFilter = null) {
    filter ??= {
      text: '',
      since: this.minDate,
      until: this.maxDate,
    };

    for (const row of this.dataSource.data) {
      const filteredData = this.allData
        .filter(d => d.vendor === row.vendor && d.vendor.toLowerCase().includes(filter.text.toLowerCase()))
        .filter(d => d.datePlaced >= filter.since && d.datePlaced <= filter.until);

      row.onTime = filteredData.reduce((sum, d) => sum += d.daysLate <= this.GRACE_PERIOD_DAYS ? 1 : 0, 0);
      row.total = filteredData.length;
      row.percent = row.onTime / row.total;
    }
  }

  onSearchChange() {
    const filter: SearchFilter = {
      text: this.searchText,
      since: this.since ?? this.minDate,
      until: this.until ?? this.maxDate,
    }

    const jsonFilter = JSON.stringify(filter);
    // Update row data to only include filtered orders
    this.updateRowsWithFilter(filter);

    // Only doing this once causes some of the values to lag behind
    this.dataSource.filter = jsonFilter;
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.updateRowsWithFilter();
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'vendor',
      'onTime',
      'percent',
    ];
  }

}
