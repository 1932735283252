<div *ngIf="!purchasingRfqRequest" class="w-100 d-flex align-items-center">
    <div class="small text-muted">No quote request for item</div>
    <button mat-flat-button color="primary" class="button-text ml-auto" [disabled]="loading" (click)="submitRequest()">
        <mat-spinner diameter="20" class="preview-spinner" *ngIf="loading"></mat-spinner>
        Request Quote for Item
    </button>
</div>
<div *ngIf="!!purchasingRfqRequest" class="w-100 d-flex align-items-center">
    <div class="small text-muted font-weight-medium">
        {{ purchasingRfqRequest.status == 3 ? "Quote request fulfilled" : "Quote request sent"}}
    </div>
    <div class="small text-muted ml-auto mr-2 font-weight-medium">Status:</div>
    <mat-chip class="button-text" disableRipple [ngClass]="getStatusChipClass(purchasingRfqRequest.status)">
        {{getStatusText(purchasingRfqRequest.status)}}
    </mat-chip>
</div>
<div *ngIf="purchasingRfqRequest?.status == 3 && this.detailService" class="w-100 d-flex align-items-center justify-content-end mt-2">
    <button mat-flat-button color="primary" class="button-text ml-auto"  (click)="viewQuote()">
        View Quote
    </button>
</div>

<ng-template #matSendRequestDialogTemplate let-data>
    <mat-card-title>
        Send Quote Request
    </mat-card-title>
    <div class="mat-dialog-content">
        <div class="row p-0 m-0">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Item</mat-label>
                    <input matInput type="text" readonly name="item" [value]="getItemName()">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label>Quantity Requested</mat-label>
                    <input matInput type="number" required min="1" name="quantityRequested" [(ngModel)]="data.quantityRequested">
                </mat-form-field>
            </div>
            <div class="col-6">
                <date-picker appearance="outline" class="w-100" placeholder="Due Date" [(ngModel)]="data.dueDate" [ngModelOptions]="{standalone: true}" name="dueDate" [editable]="true"></date-picker>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 justify-content-end">
        <button type="button" mat-stroked-button class="button-text" [matDialogClose]="null">
            <mat-icon svgIcon="close"></mat-icon>
            Cancel
        </button>
        <button mat-flat-button class="button-text ml-2" color="primary" type="submit"
            [disabled]="!data.quantityRequested"
            [matDialogClose]="data"
        >
            <mat-icon svgIcon="send"></mat-icon>
            Submit Quote Request
        </button>
    </div>
</ng-template>