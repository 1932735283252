import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';

type RowData = {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  name: string;
  totalCost: number;
  datePlaced: Date;
}

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'cost-report',
  templateUrl: './cost-report.component.html',
  styleUrls: ['./cost-report.component.less'],
})

export class CostReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  public $filteredSum = new BehaviorSubject(0);
  public $orderIdSet = new BehaviorSubject("");
  public searchText = '';
  public orderIdText = '';

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Cost');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<RowData>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'customer', start: 'asc', disableClear: false });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail(orderId: string) {
    this.loading = true;
    this.service.getCostReportData(orderId).subscribe(data => {
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
      this.$orderIdSet.next(orderId);
    })
  }

  dataToRows(apiData: RowData[]): RowData[] {
    return apiData.map(d => ({
      ...d,
      datePlaced: d.datePlaced == null ? this.maxDate : new Date(d.datePlaced),
    }));
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0) {
      this.$filteredSum.next(0);
      return [];
    }

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    this.$filteredSum.next(rowData.reduce((sum, row) => sum += row.totalCost, 0));

    return pageData;
  }

  // Search data
  search = (data: RowData, filterJson: string) => {
    const filter: SearchFilter = JSON.parse(filterJson);
    filter.since = new Date(filter.since ?? this.minDate);
    filter.until = new Date(filter.until ?? this.maxDate);

    if (!this.isInDateRange(data, filter.since, filter.until))
      return false;

    const rowText = JSON.stringify(data).toLowerCase();
    const tokens = filter.text.split(' ');
    return tokens.every(token => rowText.includes(token.toLowerCase()));
  }

  isInDateRange(data: RowData, since: Date, until: Date): boolean {
    return (
      data.datePlaced >= since &&
      data.datePlaced <= until
    );
  }

  onSearchChange() {
    this.dataSource.filter = JSON.stringify({
      text: this.searchText,
      since: this.since,
      until: this.until,
    });
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  onOrderChange() {
    this.getDetail(this.orderIdText);
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'purchaseOrderNumber',
      'datePlaced',
      'name',
      'totalCost',
    ];
  }

}
