<div class="rounded mat-elevation-z4 breakdown h-100-t" style="overflow-y: scroll;">
  <mat-list class="pt-0" *ngLet="$leadTimeDetail | async as leadTimeDetail">
    <!-- Material -->
    <mat-list-item *ngIf="product.material">
      <div class="list-item-inner item--material padding-noexpand">
        <mat-icon svgIcon="cube" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Material</span> ({{ product.material.materialName }})
        </div>
        <div class="ml-auto" *ngIf="!leadTimeDetail">
          <i class="fa fa-spin fa-circle-notch"></i>
        </div>
        <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="leadTimeDetail?.material.error !== null"></mat-icon>
        <div class="ml-auto font-weight-medium" *ngIf="leadTimeDetail">
          {{ leadTimeDetail.components.materialQuoteDays }} days
        </div>
      </div>
    </mat-list-item>
    <!-- Purchased -->
    <mat-list-item *ngIf="product.purchasedItems.length > 0">
      <div class="list-item-inner item--purchased">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('PURCHASED')">
          <mat-icon>
            {{ selectionModel.isSelected('PURCHASED') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="package-variant" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Purchased Items</span>
        </div>
        <ng-container *ngIf="leadTimeDetail != null; else loading">
          <div class="ml-auto font-weight-medium" *ngIf="!selectionModel.isSelected('PURCHASED')">
            {{ leadTimeDetail.components.purchasedItemDays }} days
          </div>
          <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('PURCHASED')"></mat-icon>
        </ng-container>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('PURCHASED')">
      <mat-list-item *ngFor="let productPurchasedItem of product.purchasedItems">
        <div class="list-item-inner item--purchased padding-subitem" *ngLet="getPurchasedItemData(productPurchasedItem.purchasedItemId, leadTimeDetail.purchasedItems) as data">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ productPurchasedItem.purchasedItem.description }}</span>
          </div>
          <div class="ml-auto" *ngIf="!leadTimeDetail">
            <i class="fa fa-spin fa-circle-notch"></i>
          </div>
          <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="data.error"></mat-icon>
          <div class="ml-auto font-weight-medium" *ngIf="data">
            {{ data.days }} days
          </div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Outsourced -->
    <mat-list-item *ngIf="outsourceSteps.length > 0">
      <div class="list-item-inner item--process">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('OUTSOURCED')">
          <mat-icon>
            {{ selectionModel.isSelected('OUTSOURCED') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="logout-variant" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Outside Processing</span>
        </div>
        <ng-container *ngIf="leadTimeDetail != null; else loading">
          <div class="ml-auto font-weight-medium" *ngIf="!selectionModel.isSelected('OUTSOURCED') && leadTimeDetail">
            {{ leadTimeDetail.components.outsourcedDays }} days
          </div>
          <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('OUTSOURCED')"></mat-icon>
        </ng-container>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('OUTSOURCED')">
      <mat-list-item *ngFor="let step of outsourceSteps">
        <div class="list-item-inner item--process padding-subitem" *ngLet="getWorkflowData(step.workflowStepId, leadTimeDetail.workflow) as data">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ service.getStation(step).name }}</span>
          </div>
          <div class="ml-auto" *ngIf="!leadTimeDetail">
            <i class="fa fa-spin fa-circle-notch"></i>
          </div>
          <mat-icon class="ml-auto" svgIcon="close-thick" *ngIf="data.error"></mat-icon>
          <div class="ml-auto font-weight-medium" *ngIf="data">
            {{ (data.setupHours + data.runHours) / 24 | number:'1.0-2' }} days
          </div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Labor -->
    <mat-list-item *ngIf="laborSteps.length > 0">
      <div class="list-item-inner item--labor">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('LABOR')">
          <mat-icon>
            {{ selectionModel.isSelected('LABOR') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="account-hard-hat" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Labor</span>
        </div>
        <div class="ml-auto" *ngIf="!leadTimeDetail">
          <i class="fa fa-spin fa-circle-notch"></i>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="leadTimeDetail && !selectionModel.isSelected('LABOR')">{{ leadTimeDetail.components.laborDays }} days</div>
        <mat-icon class="ml-auto" svgIcon="dots-horizontal" *ngIf="selectionModel.isSelected('LABOR')"></mat-icon>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('LABOR')">
      <mat-list-item *ngFor="let step of laborSteps">
        <div class="list-item-inner item--labor padding-subitem" *ngLet="getWorkflowData(step.workflowStepId, leadTimeDetail?.workflow) as data">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ service.getStation(step).name }}</span>
          </div>
          <div class="ml-auto font-weight-medium">{{ (data.setupHours + data.runHours) | number:'1.0-2' }} hours</div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Children -->
    <mat-list-item *ngIf="(service.getProductChildrenObservable(product.productId) | async).length > 0">
      <div class="list-item-inner">
        <button mat-icon-button class="mr-1" (click)="selectionModel.toggle('CHILDREN')">
          <mat-icon>
            {{ selectionModel.isSelected('CHILDREN') ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-icon svgIcon="list-box" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Subassemblies</span> (longest)
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="leadTimeDetail != null; else loading">
          {{ leadTimeDetail.components.subassemblyDays }} days
        </div>
      </div>
    </mat-list-item>
    <ng-container *ngIf="selectionModel.isSelected('CHILDREN')">
      <mat-list-item *ngFor="let child of ($subassemblyDetail | async)">
        <div class="list-item-inner padding-subitem">
          <div style="width: 65%" class="text-ellipsis">
            <span>{{ child.part }}</span>
          </div>
          <div class="ml-auto font-weight-medium">{{ child.days }} days</div>
        </div>
      </mat-list-item>
    </ng-container>
    <!-- Subtotal -->
    <mat-list-item style="
      position: sticky;
      bottom: 112px;
      background: white;
      /* border-top: 1px solid rgba(0,0,0,.12); */
    ">
      <div class="list-item-inner padding-noexpand">
        <mat-icon svgIcon="playlist-plus" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Subtotal</span>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="leadTimeDetail != null; else loading">
          {{ leadTimeDetail.totalDays - leadTimeDetail.components.bufferDays }} days
        </div>
      </div>
    </mat-list-item>
    <mat-list-item style="
      position: sticky;
      bottom: 56px;
      background: white;
    ">
      <div class="list-item-inner padding-noexpand">
        <mat-icon svgIcon="arrow-left-right-bold" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Buffer</span>
        </div>
        <mat-form-field appearance="outline" class="ml-auto dense-field mat-input-no-message buffer-field">
          <input placeholder="0" matInput [readonly]="!editing" type="number" step="1" class="text-right" [ngModel]="product.leadTimeBuffer" (ngModelChange)="setLeadTimeBuffer(product, $event)">
          <span class="text-muted" matSuffix>days</span>
        </mat-form-field>
      </div>
    </mat-list-item>
    <mat-list-item style="
      position: sticky;
      bottom: 0px;
      background: white;
      /* border-top: 1px solid rgba(0,0,0,.12); */
    ">
      <div class="list-item-inner padding-noexpand">
        <mat-icon svgIcon="calendar-clock" class="mr-1"></mat-icon>
        <div style="width: 65%" class="text-ellipsis">
          <span class="font-weight-medium">Total</span>
        </div>
        <div class="ml-auto font-weight-medium" *ngIf="leadTimeDetail != null; else loading">
          {{ leadTimeDetail.totalDays }} days
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>

<ng-template #loading>
  <div class="ml-auto">
    <i class="fa fa-spin fa-circle-notch"></i>
  </div>
</ng-template>