import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { exportCsv } from '../../../../../util/createCsv';
import { ReportService } from '@cots/report/services/report.service';
import { NavigationService } from '@cots/common/services/navigation.service';

type RowData = {
  isHeader: false;
  purchaseOrderId: string;
  workOrderNumber: string;
  purchaseOrderNumber: string;
  customer: string;
  vendor: string;
  dateExpected: Date;
  dueDate: string;
  shipDate: string;
  privateNote: string;
  partNumber: string;
  quantity: string;
  daysSince: string;
  totalPrice: string;
}

type HeaderData = {
  isHeader: true;
  groupName: string;
  total: number;
  lines: number;
  rows: RowData[];
}

type Data = HeaderData | RowData;

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'active-outsource-report',
  templateUrl: './active-outsource-report.component.html',
  styleUrls: ['./active-outsource-report.component.less'],
})

export class ActiveOutsourceReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  private now: Date = new Date();
  private lastFilteredData: RowData[];

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Active Outsource Report');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<Data>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'date', start: 'asc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getActiveOutsourceData().subscribe(data => {
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: RowData[]): Data[] {
    return apiData.map(d => ({
      ...d,
      isHeader: false,
      dateExpected: d.dateExpected == null ? this.minDate : new Date(d.dateExpected),
    }));
  }

  dataToPage = (rowData: RowData[]) => {
    this.lastFilteredData = rowData;

    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    let foundStart = false;
    let currentIndex = startIndex;
    let currentHeader: HeaderData = {
      isHeader: true,
      groupName: this.formatDate(pageData[0].dateExpected),
      total: 0,
      lines: 0,
      rows: [],
    };

    // Work backwards from startIndex until finding one with a different date, adding values to the header row
    // This makes the first header include previous page data that fits the category but is cut off
    while (!foundStart && currentIndex > 0) {
      currentIndex -= 1;
      const currentRow = rowData[currentIndex];
      const thisDate = this.formatDate(currentRow.dateExpected);

      if (thisDate === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += parseFloat(currentRow.totalPrice);
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    // Start real return object here, starting with the top header
    let finalRows: Data[] = [currentHeader];

    for (const row of pageData) {
      const thisDate = this.formatDate(row.dateExpected);

      // If a different date, set a new header
      if (thisDate !== currentHeader.groupName) {
        currentHeader = {
          isHeader: true,
          groupName: thisDate,
          total: 0,
          lines: 0,
          rows: [],
        }
        finalRows.push(currentHeader);
      }

      // Add stats and actual row to the most recent header
      currentHeader.lines += 1;
      currentHeader.total += parseFloat(row.totalPrice);
      currentHeader.rows.push(row);
      finalRows.push(row);
    }

    // Continue past the end until the date changes to add header info off the current page
    foundStart = false;
    currentIndex = endIndex - 1;
    while (!foundStart && currentIndex < (rowData.length - 1)) {
      currentIndex += 1;
      const currentRow = rowData[currentIndex];
      const thisDate = this.formatDate(currentRow.dateExpected);

      if (thisDate === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += parseFloat(currentRow.totalPrice);
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    return finalRows;
  }

  // Search data
  public searchText = '';
  search = (data: Data, filterJson: string) => {
    const filter: SearchFilter = JSON.parse(filterJson);
    filter.since = new Date(filter.since ?? this.minDate);
    filter.until = new Date(filter.until ?? this.maxDate);

    if (!this.isInDateRange(data, filter.since, filter.until))
      return false;

    const rowText = JSON.stringify(data).toLowerCase();
    const tokens = filter.text.split(' ');
    return tokens.every(token => rowText.includes(token.toLowerCase()));
  }

  isInDateRange(data: Data, since: Date, until: Date): boolean {
    if (data.isHeader && data.rows.length > 0) {
      return (
        data.rows[0].dateExpected >= since &&
        data.rows[data.rows.length-1].dateExpected <= until
      );
    } else if (data.isHeader === false) { // why this makes typescript behave and not (!data.isHeader) i do not know
      return (
        data.dateExpected >= since &&
        data.dateExpected <= until
      );
    }
  }

  onSearchChange() {
    this.dataSource.filter = JSON.stringify({
      text: this.searchText,
      since: this.since,
      until: this.until,
    });
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'date',
      'shipDate',
      'purchaseOrderNumber',
      'customer',
      'workOrderNumber',
      'partNumber',
      'quantity',
      'dueDate',
      'vendor',
      'privateNote',
      'daysSince',
    ];
  }

  public rowIsHeader(_: number, row: Data) {
    return row && row.isHeader;
  }

  private formatDate(date: Date): string {
    if (date === this.minDate)
      return "No Date";

    return new Date(date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

  public getStatusClass(date: string) {
    if (date === null)
      return "aor-row-bad";

    const days = Math.ceil(+new Date(date) - +this.now) / (1000 * 60 * 60 * 24);

    if (days >= 5)
      return "aor-row-good";
    else if (days >= 1)
      return "aor-row-warn";
    else
      return "aor-row-bad";
  }

  public export() {
    const now = this.now;
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = (now.getDay() + 1).toString().padStart(2, "0");

    const dateString = `${year}_${month}_${day}`;
    const title = `ActiveOutsourceReport_${dateString}`;

    const headers = [
      'Promise Date',
      'Ship Date',
      'PO Number',
      'Customer',
      'Work Order',
      'Part Number',
      'Qty',
      'Due Date',
      'Vendor',
      'Private Notes',
      'Days Since Placed',
      'Total Price',
    ];

    const data = [];

    for (let i = 0; i < this.lastFilteredData.length; i++) {
      const row = this.lastFilteredData[i];
      data.push([
        row.dateExpected !== this.minDate ? row.dateExpected : "",
        row.shipDate,
        row.purchaseOrderNumber,
        row.customer,
        row.workOrderNumber,
        row.partNumber,
        row.quantity,
        row.dueDate,
        row.vendor,
        row.privateNote,
        row.daysSince,
        parseFloat(row.totalPrice).toFixed(2),
      ])
    }

    exportCsv(title, data, headers);
  }

}
