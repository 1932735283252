<div class="position-relative rounded" style="
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div id="filter-bar">
    <mat-form-field appearance="outline" class="mat-input-no-message dense-field" id="search-field" #searchField>
      <mat-label>Text filter</mat-label>
      <mat-icon matPrefix class="text-muted">search</mat-icon>
      <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="onSearchChange()" #searchInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-input-no-message" id="daterange-field" #rangeField>
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="since" (keyup.enter)="onSearchChange()" placeholder="Start date">
        <input matEndDate [(ngModel)]="until" (keyup.enter)="onSearchChange()" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button
      (click)="onSearchChange()"
      mat-flat-button
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading"
    >
      Apply
    </button>
    <button
      mat-flat-button
      (click)="clearSearch()"
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading || (searchText === '' && since === null && until === null)"
    >
        Clear
      </button>
  </div>

  <div id="aggregate-total">
    Total: {{ $filteredSum | async | currency }}
  </div>

  <mat-table
    #dataTable
    [dataSource]="dataSource"
    matSort
    class="w-100 flex-grow-1"
    [class.loading]="loading"
  >
    <mat-header-row *matHeaderRowDef="rowDisplayedColumns"></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: rowDisplayedColumns;"
      class="oor-row oospr-data-row"
    ></mat-row>

    <div class="mat-row" *matNoDataRow>
      <div class="mat-cell"
        [attr.colspan]="rowDisplayedColumns.length"
        class="text-muted small font-italic text-center"
        *ngIf="!loading"
      >
        No sales matching the parameters were found.
      </div>
    </div>

    <ng-container matColumnDef="customer">
      <mat-header-cell *matHeaderCellDef mat-sort-header="customer" style="flex-basis: 50%"> Customer </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 50%">
        {{ row.customer }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalSales">
      <mat-header-cell *matHeaderCellDef mat-sort-header="totalSales" style="flex-basis: 25%; padding-left: 1em;"> Total Sales </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%; padding-left: 1em;">
        {{ row.totalSales | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalShare">
      <mat-header-cell *matHeaderCellDef mat-sort-header="totalShare" style="flex-basis: 25%; padding-left: 1em;"> Share of Total </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%; padding-left: 1em;">
        {{ row.totalShare | percent:'0.1-2' }}
      </mat-cell>
    </ng-container>

  </mat-table>

  <mat-paginator
    [pageSizeOptions]="[15]"
    [length]="dataLength"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    style="position: sticky; bottom: -1px;"
  ></mat-paginator>
</div>