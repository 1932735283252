import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { WorkOrderOverview } from '../../../planning/resources/work-order';
import { MatTableDataSource } from '@angular/material/table';
import { WorkOrderService } from '../../../planning/services/work-order.service';
import { SelectionModel } from '@angular/cdk/collections';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { WorkflowStepPlanningStatus } from '../../../order/resources/workflow';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerPurchaseOrderType } from '@cots/order/resources/customer-po';

type Data = WorkOrderOverview | { groupName: string, groupId: string };

function isOverview(data: Data): data is WorkOrderOverview {
  return data.hasOwnProperty('workOrderId');
}

function random() {
  return Math.random() > 0.5;
}

@Component({
  selector: 'open-order-report',
  templateUrl: './open-order-report.component.html',
  styleUrls: ['./open-order-report.component.less'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ 'max-height': 0 }),
            animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', 
                    style({ 'max-height': '65vh' }))
          ]
        ),
        // transition(
        //   ':leave', 
        //   [
        //     style({ height: '250px' }),
        //     animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)', 
        //             style({ height: 0 }))
        //   ]
        // )
      ]
    ),
    trigger(
      'triangleInOutAnimation', 
      [
        // transition(
        //   ':enter', 
        //   [
        //     style({ top: 0, 'clip-path': 'polygon(0 1%, 100% 20%, 100% 81%, 0% 81%)' }),
        //     animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'), 
        //     style({ top: '-17px', 'clip-path': 'polygon(0 1%, 100% 20%, 100% 62%, 0% 62%)' }),
        //   ]
        // ),
        // transition(
        //   ':leave', 
        //   [
        //     style({ top: '17px', 'clip-path': 'polygon(0 1%, 100% 20%, 100% 62%, 0% 62%)' }),
        //     animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)'), 
        //     style({ top: 0, 'clip-path': 'polygon(0 1%, 100% 20%, 100% 81%, 0% 81%)' }),
        //   ]
        // )
      ]
    ),
  ]
})
export class OpenOrderReportComponent implements OnInit, AfterViewInit {

  constructor(private service: WorkOrderService) { }

  public displayedColumns = [
    'wo',
    'customer',
    'po',
    'purchasing',
    'preplanning',
    'planning',
    'production',
    'qa',
    'shipping',
    'gaant',
    'dueDate',
  ]
  public get rowDisplayedColumns() {
    return [...this.displayedColumns, 'expansionPanel']
  }


  public loading = false;
  public dataSource = new MatTableDataSource<Data>();

  private getDateId(date: Date) {
    return (date.getMonth() + 1) + 
    "/" +  date.getDate() +
    "/" +  date.getFullYear();
  }

  public groupedData: {
    groupName: string;
    groupId: string;
    innerData: WorkOrderOverview[]
  }[] = [];
  public groupData(data: WorkOrderOverview[]) {
    return data.reduce((acc, x) => {
      const groupId = this.getDateId(new Date(x.dueDate));
      const existingGroup = acc.find(g => g.groupId === groupId);
      if (existingGroup) existingGroup.innerData.push(x);
      else acc.push({
        groupId,
        groupName: new Date(x.dueDate).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
        innerData: [x]
      });
      return acc;
    }, [] as typeof this['groupedData']);
  }
  public transformGroupedData(data: typeof this['groupedData']): Data[] {
    return data.flatMap(gd => [{ groupId: gd.groupId, groupName: gd.groupName }, ...gd.innerData]);
  }

  public groupSelectionModel = new SelectionModel<string>(true);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.dataSource.filterPredicate = (data, filter: string) => {
      // if (!isOverview(data)) {
      //   const container = this.groupedData.find(gd => gd.groupId === data.groupId);
      //   return container.innerData.some(d => JSON.stringify(d).toLowerCase().includes(filter.toLowerCase()));
      // } else {
      //   const groupId = this.getDateId(new Date(data.dueDate));
      //   if (!this.groupSelectionModel.isSelected(groupId)) return false;
      //   return JSON.stringify(data).toLowerCase().includes(filter.toLowerCase());
      // }
      return JSON.stringify(data).toLowerCase().includes(filter.toLowerCase());
    }
    this.dataSource._pageData = (data: WorkOrderOverview[]) => {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = this.paginator.pageSize;
      return this.transformGroupedData(this.groupData(data.slice().splice(startIndex, endIndex)));
    }
    this.getDetail();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  public originalData: WorkOrderOverview[];
  public getDetail() {
    this.loading = true;
    this.service.getOpenOrders().subscribe(d => {
      this.loading = false;
      this.groupedData = this.groupData(d);
      this.dataSource.data = d;
    })
  }

  public rowIsGroupHeader(_: number, row: Data) {
    return !isOverview(row);
  } 


  public searchText = '';
  onSearchChange() {
    this.dataSource.filter = this.searchText;
  }
  clearSearch() {
    this.searchText = '';
    this.dataSource.filter = '';
  }

  public expansionModel = new SelectionModel<string>(false);
  // public selectedIndex = 0;
  public selectedIndex = 7;
  private tabs = [
    'purchasing',
    'preplanning',
    'planning',
    'production',
    'qa',
    'shipping',
    'gaant'
  ]
  public selectSection(item: Data, tab: string) {
    if (!isOverview(item)) return;
    if (this.expansionModel.isSelected(item.workOrderId) && this.selectedIndex === this.tabs.findIndex(t => t === tab)) {
      this.expansionModel.clear();
      this.selectedIndex = 0;
    } else {
      this.expansionModel.select(item.workOrderId);
      this.selectedIndex = this.tabs.findIndex(t => t === tab);
    }
  }

  public isPurchasingComplete(item: WorkOrderOverview) {
    let items = item.purchasingSheet?.purchasingSheetItems ?? [];
    if (items.length === 0) return true;
    return items.every(i => i.assignments.reduce((acc, a) => acc + a.qtyAssigned, 0) >= i.qty);
  }
  public isPreplanningComplete(item: WorkOrderOverview) {
    return item.status !== 21;
  }
  public isPlanningComplete(item: WorkOrderOverview) {
    if (item.workflowSteps.length === 0) return true;
    return item.workflowSteps.every(s => s.planningStatus === WorkflowStepPlanningStatus.PLANNED);
  }
  public isProductionComplete(item: WorkOrderOverview) {
    if (!item.workflowSteps || item.workflowSteps.length === 0) return true;
    return item.workflowSteps.every(step => {
      return step.schedulingTicket?.executionStatus === 2
    });
  }
  public isInspectionComplete(item: WorkOrderOverview) {
    const inspectionTickets = item.workflowSteps.flatMap(s => (s.machineAssignments ?? [])
      .flatMap(a => a.inspectionTickets)
    );
    return (
      item.contractReviewTicket.status === 4 &&
      item.receivingTickets.every(t => t.status === 4) &&
      inspectionTickets.every(t => !!t.finishedTime)
    )
  }

  public isShippingComplete(item: WorkOrderOverview) {
    const tickets = item.shippingTickets.filter(st => !!st.inventoryItem.productId);
    if (tickets.length === 0) { return false };
    return tickets.every(st => !!st.departureDate);
  }

}
