import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../../order-detail.service';
import { Product } from '../../../../resources/product';
import { UpdateChange } from '@cots/common/autosaving/change';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID = 'dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e';
export const ESTIMATING_CONTRACT_REVIEW_QUESTIONS = [
  { contractReviewQuestionId: 'd8975684-aac9-4158-a117-ccdb3a2f6c0a', text: 'First Article Inspection Report', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID},
  { contractReviewQuestionId: '974b8b1b-0aa3-4d9d-8499-36eec9befa9e', text: 'PPAP Required', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
  { contractReviewQuestionId: 'a9bc3d37-25d3-4fa4-8d00-0479beecb5e3', text: 'MPS Plan', examples: 'UTAS/HDI with heat treat at 180KSI or above', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
  { contractReviewQuestionId: 'f2dbea6e-fb2f-4b06-b61b-d79d805ee688', text: 'Special Inspection Tooling Required', examples: 'Spline Gage, Thread Gage pre and post finish, etc.', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
  { contractReviewQuestionId: 'f82ddf92-0a11-40ed-a5b8-61cc1cc037a3', text: 'Long Lead Time Material', examples: 'Forgings, Castings, Etc.', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
  { contractReviewQuestionId: '509b36ea-78aa-4d1c-b4ca-fd745d8ba750', text: 'Long Lead Time Hardware', examples: 'Captive Screws, Card Guides, etc.',  contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
  { contractReviewQuestionId: 'dc476bd5-c872-4026-bf38-9f3596134230', text: 'Non Standard Processes Quoted', examples: 'Welding, Special Heat Treat, Special Finishing, etc.', contractReviewCategoryId: ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID },
];

@Component({
  selector: 'product-estimating-review',
  templateUrl: './product-estimating-review.component.html',
  styleUrls: ['./product-estimating-review.component.less'],
})
export class ProductEstimatingReviewComponent implements OnInit, OnChanges {

  constructor(public service: OrderDetailService) { }

  private async initIds() {
    const order = await fetchLatest(this.service.postChangesOrder$);
    let changed = false;
    let newAnswers = order.estimateReviewAnswers ? structuredClone(order.estimateReviewAnswers) : null;
    if (!newAnswers) { newAnswers = {}; changed = true }
    if (!newAnswers[this.product.productId]) { newAnswers[this.product.productId] = {}; changed = true; }
    if (!newAnswers[this.product.productId][ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID]) { newAnswers[this.product.productId][ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID] = {}; changed = true }
    if (changed) {
      const change: UpdateChange = {
        changeType: 'UPDATE',
        entity: 'OrderSegment',
        data: {
          itemId: null,
          field: 'estimateReviewAnswers',
          oldValue: order.estimateReviewAnswers,
          newValue: newAnswers,
        },
      };
      this.service.recordChanges(change);
    }
    this.dirty = false;
    this.internalState = newAnswers[this.product.productId][ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID];
  }

  private dirty = false;
  public internalState: { [ questionId: string ]: string | null };
  private updateSubject = new Subject<void>();

  ngOnInit(): void {
    this.initIds();
    this.updateSubject.subscribe(() => {
      this.flushInternalState(this.product.productId);
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.product && changes.product.previousValue && changes.product.previousValue.productId !== changes.product.currentValue?.productId) {
      if (changes.product.previousValue) await this.flushInternalState(changes.product.previousValue.productId);
      this.internalState = null;
      this.initIds();
    }
  }

  @Input() product: Product;

  public answers = ['Yes', 'No', 'N / A'];

  public questions = ESTIMATING_CONTRACT_REVIEW_QUESTIONS;

  private async flushInternalState(productId: string) {
    if (!this.dirty) return;
    const order = await fetchLatest(this.service.postChangesOrder$);
    const newAnswers = structuredClone(order.estimateReviewAnswers);
    newAnswers[productId][ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID] = this.internalState;
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        itemId: null,
        field: 'estimateReviewAnswers',
        oldValue: order.estimateReviewAnswers,
        newValue: newAnswers,
      },
    };
    this.service.recordChanges(change);
    this.dirty = false;
  }

  public update(questionId: string, value: string) {
    this.dirty = true;
    // Can't get this to reflect in UI properly
    // if (this.internalState[questionId] === value) this.internalState[questionId] = undefined;
    // else this.internalState[questionId] = value;
    this.internalState[questionId] = value;
    this.updateSubject.next();
  }

}
