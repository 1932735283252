<div class="position-relative rounded" style="
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  display: flex;
  flex-direction: column;
">
  <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;" [class.progress-bar-hidden]="!loading" mode="indeterminate"></mat-progress-bar>
  <div id="filter-bar">
    <mat-form-field appearance="outline" class="mat-input-no-message dense-field" id="search-field" #searchField>
      <mat-label>Monthly Sales Target</mat-label>
      <input matInput type="text" [(ngModel)]="targetSalesInput" (keyup.enter)="onSearchChange()" #searchInput />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-input-no-message" id="daterange-field" #rangeField>
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="since" (keyup.enter)="onSearchChange()" placeholder="Start date">
        <input matEndDate [(ngModel)]="until" (keyup.enter)="onSearchChange()" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button
      (click)="onSearchChange()"
      mat-flat-button
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading"
    >
      Apply
    </button>
    <button
      mat-flat-button
      (click)="clearSearch()"
      [class.bg-primary]="!loading"
      [class.text-white]="!loading"
      class="button-text"
      [disabled]="loading || (targetSalesInput === '' && since === null && until === null)"
    >
        Clear
      </button>
  </div>

  <div id="report-header-bar">
    <div id="aggregate-total">
      Total Booked:<br />{{ $filteredSum | async | currency }}
    </div>
    <div id="target-sales">
      Target Sales:<br />{{ $targetSales | async | currency }} in {{ $monthCount | async }} months
    </div>
    <div id="target-sales">
      Balance Available:<br />{{ $balanceAvailable | async | currency }}
    </div>
  </div>

  <mat-table
    #dataTable
    [dataSource]="dataSource"
    matSort
    class="w-100 flex-grow-1"
    [class.loading]="loading"
  >
    <mat-header-row *matHeaderRowDef="rowDisplayedColumns"></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: rowDisplayedColumns;"
      class="oor-row oospr-data-row"
    ></mat-row>

    <div class="mat-row" *matNoDataRow>
      <div class="mat-cell"
        [attr.colspan]="rowDisplayedColumns.length"
        class="text-muted small font-italic text-center"
        *ngIf="!loading"
      >
        No open orders matching the parameters were found.
      </div>
    </div>

    <ng-container matColumnDef="groupHeader">
      <mat-cell *matCellDef="let row" class="oospr-header-row">
        <div>
          <b>{{ row.groupName }}</b>
        </div><div>
          <b>{{ row.lines }}</b> orders
        </div><div>
          <b>{{ row.total | currency }}</b>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="month">
      <mat-header-cell *matHeaderCellDef mat-sort-header="monthKey" style="flex-basis: 25%"> Month </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%">
        {{ row.month }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="booked">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 25%"> Booked </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%">
        {{ row.booked | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="available">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 25%"> Available </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%">
        {{ row.available | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dailyTarget">
      <mat-header-cell *matHeaderCellDef style="flex-basis: 25%; padding-left: 1em;"> Daily Target </mat-header-cell>
      <mat-cell *matCellDef="let row" style="flex-basis: 25%; padding-left: 1em;">
        {{ row.dailyTarget | currency }} for {{ row.weekdays }} days
      </mat-cell>
    </ng-container>

  </mat-table>

  <mat-paginator
    [pageSizeOptions]="[12]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    style="position: sticky; bottom: -1px;"
  ></mat-paginator>
</div>