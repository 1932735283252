import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PurchasedItem } from '../../../../../resources/purchased-item';
import { Product, ProductPurchasedItem } from '../../../../../resources/product';
import { OrderDetailService, fetchLatest } from '../../../order-detail.service';
import { NgForm } from '@angular/forms';
import { map } from 'highcharts';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { startWith, distinctUntilChanged, pairwise, filter, withLatestFrom } from 'rxjs/operators';
import { UpdateChange } from '@cots/common/autosaving/change';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { PurchasingRfqRequest } from '../../../../../../common/resources/purchasing-rfq-request';

@Component({
  selector: 'product-purchased-items-form',
  templateUrl: './product-purchased-items-form.component.html',
  styleUrls: ['./product-purchased-items-form.component.less']
})
export class ProductPurchasedItemsFormComponent implements AfterViewInit, OnDestroy {

  @Input() product: Product;
  @Input() item: ProductPurchasedItem;

  public get editing() { return this.service.editing }

  constructor(public service: OrderDetailService) { }

  @ViewChild('form') form: NgForm;
  public selectedPurchasedItem$: Observable<ProductPurchasedItem>;
  private formSubs: Subscription[] = [];
  ngAfterViewInit() {
    setTimeout(() => {
      for (const field in this.form.controls) {
        if (field === 'purchasedItem') continue;
        const sub = this.form.controls[field].valueChanges
          .pipe(
            startWith(this.form.controls[field].value),
            distinctUntilChanged(),
            pairwise(),
            filter(() => this.editing),
            withLatestFrom(this.service.selectedPurchasedItemIdSubject)
          ).subscribe(([[oldValue, newValue], itemId]) => {
            this.recordSimpleUpdate(itemId, field, oldValue, newValue);
          })
          ;
        this.formSubs.push(sub);
      }
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.formSubs) {
      if (sub) sub.unsubscribe();
    }
  }
  
  private recordSimpleUpdate(itemId: string, field: string, oldValue: any, newValue: any) {
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'ProductPurchasedItem',
      data: {
        field,
        itemId,
        oldValue,
        newValue
      }
    };
    this.service.recordChanges(change);
  }

  public async setItemPrice(product: Product, purchasedItem: ProductPurchasedItem, quote: MaterialBid) {
    let newPrice: number;
    if (purchasedItem.isAmortized) {
      newPrice = quote.totalBid ? (quote.totalBid / this.service.getFirstQuantity(product)) : quote.perItemBid;
    } else {
      newPrice = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    }
    this.recordSimpleUpdate(purchasedItem.productPurchasedItemId, 'costPer', purchasedItem.costPer, newPrice);
  }

  public setRepetitionType(item: ProductPurchasedItem, type: 'amortized' | 'nonrecurring' | 'everytime') {
    switch (type) {
      case 'amortized':
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isAmortized', item.isAmortized, true);
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isNonRecurring', item.isNonRecurring, true);
        break;
      case 'nonrecurring':
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isAmortized', item.isAmortized, false);
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isNonRecurring', item.isNonRecurring, true);
        break;
      case 'everytime':
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isAmortized', item.isAmortized, false);
        this.recordSimpleUpdate(item.productPurchasedItemId, 'isNonRecurring', item.isNonRecurring, false);
        break;
      default:
        break;
    }
  }

  @Output() changeItem = new EventEmitter<ProductPurchasedItem>();

  public async onPurchasingRfqRequestCreated(request: PurchasingRfqRequest) {
    const productsMap = await fetchLatest(this.service.autosaver.getPreChanges('productMap'));
    const product = productsMap[this.product.productId];
    const item = product.purchasedItems.find(ppi => ppi.productPurchasedItemId === this.item.productPurchasedItemId);
    item.purchasingRfqRequest = request;
    item.purchasingRfqRequestId = request.purchasingRfqRequestId;
    this.service.autosaver.resetDomain('productMap', productsMap);
  }

}
