import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';

type RowData = {
  isHeader: false;
  workOrderNumber: string;
  date: Date;
  customer: string;
  lineItemNumber: string;
  productId: string;
  partNumber: string;
  revision: string;
  quantity: string;
  unitPrice: string;
  extPrice: string;
}

type HeaderData = {
  isHeader: true;
  groupName: string;
  total: number;
  lines: number;
  rows: RowData[];
}

type Data = HeaderData | RowData;

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'open-order-shipping-prices-report',
  templateUrl: './open-order-shipping-prices-report.component.html',
  styleUrls: ['./open-order-shipping-prices-report.component.less'],
})

export class OpenOrderShippingPricesReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  public $filteredSum = new BehaviorSubject(0);

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Open Order Shipping Prices');
  }

  public loading = false;
  public dataLength = 0;
  public dataSource = new MatTableDataSource<Data>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'date', start: 'asc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getOpenOrderShipmentPrices().subscribe(data => {
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: RowData[]): Data[] {
    return apiData.map(d => ({
      isHeader: false,
      ...d,
      date: d.date == null ? this.maxDate : new Date(d.date),
    }));
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    this.$filteredSum.next(rowData.reduce((sum, row) => sum += parseFloat(row.extPrice), 0));

    let foundStart = false;
    let currentIndex = startIndex;
    let currentHeader: HeaderData = {
      isHeader: true,
      groupName: this.formatDate(pageData[0].date),
      total: 0,
      lines: 0,
      rows: [],
    };

    // Work backwards from startIndex until finding one with a different date, adding values to the header row
    // This makes the first header include previous page data that fits the category but is cut off
    while (!foundStart && currentIndex > 0) {
      currentIndex -= 1;
      const currentRow = rowData[currentIndex];
      const thisDate = this.formatDate(currentRow.date);

      if (thisDate === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += parseFloat(currentRow.extPrice);
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    // Start real return object here, starting with the top header
    let finalRows: Data[] = [currentHeader];

    for (const row of pageData) {
      const thisDate = this.formatDate(row.date);

      // If a different date, set a new header
      if (thisDate !== currentHeader.groupName) {
        currentHeader = {
          isHeader: true,
          groupName: thisDate,
          total: 0,
          lines: 0,
          rows: [],
        }
        finalRows.push(currentHeader);
      }

      // Add stats and actual row to the most recent header
      currentHeader.lines += 1;
      currentHeader.total += parseFloat(row.extPrice);
      currentHeader.rows.push(row);
      finalRows.push(row);
    }

    // Continue past the end until the date changes to add header info off the current page
    foundStart = false;
    currentIndex = endIndex - 1;
    while (!foundStart && currentIndex < (rowData.length - 1)) {
      currentIndex += 1;
      const currentRow = rowData[currentIndex];
      const thisDate = this.formatDate(currentRow.date);

      if (thisDate === currentHeader.groupName) {
        currentHeader.lines += 1;
        currentHeader.total += parseFloat(currentRow.extPrice);
        currentHeader.rows.push(currentRow);
      } else {
        foundStart = true;
      }
    }

    return finalRows;
  }

  // Search data
  public searchText = '';
  search = (data: Data, filterJson: string) => {
    const filter: SearchFilter = JSON.parse(filterJson);
    filter.since = new Date(filter.since ?? this.minDate);
    filter.until = new Date(filter.until ?? this.maxDate);

    if (!this.isInDateRange(data, filter.since, filter.until))
      return false;

    const rowText = JSON.stringify(data).toLowerCase();
    const tokens = filter.text.split(' ');
    return tokens.every(token => rowText.includes(token.toLowerCase()));
  }

  isInDateRange(data: Data, since: Date, until: Date): boolean {
    if (data.isHeader && data.rows.length > 0) {
      return (
        data.rows[0].date >= since &&
        data.rows[data.rows.length-1].date <= until
      );
    } else if (data.isHeader === false) { // why this makes typescript behave and not (!data.isHeader) i do not know
      return (
        data.date >= since &&
        data.date <= until
      );
    }
  }

  onSearchChange() {
    this.dataSource.filter = JSON.stringify({
      text: this.searchText,
      since: this.since,
      until: this.until,
    });
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'date',
      'wo',
      'customer',
      'lineitem',
      'partno',
      'rev',
      'qty',
      'unitprice',
      'extprice',
    ];
  }

  public rowIsHeader(_: number, row: Data) {
    return row && row.isHeader;
  }

  private formatDate(date: Date): string {
    if (date === this.maxDate)
      return "No Date";

    return new Date(date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

}
