import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaterialBid } from '../../../../purchasing/resources/materialBid';
import { Product } from '../../../resources/product';
import { ProductTimingService, TimingData, TimingDataPurchasedItem, TimingDataWorkflow } from '../../../services/product-timing.service';
import { UpdateChange } from '@cots/common/autosaving/change';
import { OrderDetailService } from '../order-detail.service';

type SearchedQuote = MaterialBid | { error: 'UNQUOTED' | 'NOT_FOUND' }

@Component({
  selector: 'order-detail-lead-time-breakdown',
  templateUrl: './order-detail-lead-time-breakdown.component.html',
  styleUrls: ['./order-detail-lead-time-breakdown.component.less'],
})
export class OrderDetailLeadTimeBreakdownComponent implements OnInit, OnChanges {

  constructor(
    public service: OrderDetailService,
    public productTimingService: ProductTimingService,
  ) { }

  public get editing() { return this.service.editing }
  @Input() product: Product;

  public selectionModel = new SelectionModel<'PURCHASED' | 'OUTSOURCED' | 'LABOR' | 'CHILDREN'>(true);

  public get laborSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return !station.isOutsourceStep;
    })
  }

  public get outsourceSteps() {
    return this.product.workflow.workflowSteps.filter(s => {
      const station = this.service.getStation(s);
      return station.isOutsourceStep;
    })
  }

  public $leadTimeDetail: Observable<TimingData>
  public $subassemblyDetail: Observable<{part: string, days: number}[]>;

  public initObservables(): void {
    const $detail = this.service.getProductLeadTimeDetailObservable(this.product.productId);

    this.$leadTimeDetail = $detail.pipe(
      map(list => list.find(d => d.productId === this.product.productId)),
    );

    this.$subassemblyDetail = combineLatest([$detail, this.service.getProductChildrenObservable(this.product.productId)]).pipe(
      map(([list, children]) => {
        const subassemblyData: {part: string, days: number}[] = [];

        for (const child of children)
          subassemblyData.push({
            part: `${child.partNumber} Rev. ${child.revision}`,
            days: list.find(d => d.productId === child.productId)?.totalDays ?? 0,
          });

        return subassemblyData;
      })
    );
  }

  getPurchasedItemData(itemId: string, data: TimingDataPurchasedItem[]) {
    return data?.find(d => d.itemId === itemId);
  }

  getWorkflowData(stepId: string, data: TimingDataWorkflow[]) {
    return data?.find(d => d.stepId === stepId);
  }

  ngOnInit(): void {
    this.initObservables();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initObservables();
  }

  public setLeadTimeBuffer(product: Product, newValue: number) {
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: product.productId,
        field: 'leadTimeBuffer',
        oldValue: product.leadTimeBuffer,
        newValue,
      },
    };
    this.service.recordChanges(change);
  }

}
