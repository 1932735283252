import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../../order-detail.service';
import { OutsideProcessSpecification, Station } from '../../../../resources/station';
import { WorkflowStep } from '../../../../resources/workflow';
import { UtilityService } from '../../../../../common/services/utility.service';
import { TaskStatus } from '../../../../../common/resources/estimatingtask';
import { Observable, Subject, Subscription, combineLatest, merge } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { ProductWorkflowStepFormComponent } from './product-workflow-step-form/product-workflow-step-form.component';
import { StationService } from '../../../../services/station.service';
import { Product } from '../../../../resources/product';
import { EstimatingTaskStatus, Order } from '../../../../resources/order';
import { CreateChange, DeleteChange, SortWorkflowStepChange, UpdateChange } from '@cots/common/autosaving/change';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PurchasingRfqRequest } from '../../../../../common/resources/purchasing-rfq-request';

@Component({
  selector: 'product-workflow',
  templateUrl: './product-workflow.component.html',
  styleUrls: ['./product-workflow.component.less']
})
export class ProductWorkflowComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
    public utilityService: UtilityService,
    public stationService: StationService
  ) { }

  public get editing() { return this.service.editing }

  private updateProgress(product: Product) {
    if (product.workflowStatus === EstimatingTaskStatus.NOT_STARTED) this.service.setEstimatingTaskStatus(product.productId, 'workflow', EstimatingTaskStatus.IN_PROGRESS);
  }

  async addStep(product: Product, station: Station) {
    const newStep = WorkflowStep.newEmptyStep(station);
    newStep.workflowStepId = UtilityService.newGuid();
    newStep.workflowId = product.workflowWorkflowId;
    const change: CreateChange = {
             changeType: 'CREATE',
      entity: 'WorkflowStep',
      data: {
        itemId: newStep.workflowStepId,
        value: newStep
      },
    };
    this.service.recordChanges(change);
    this.service.selectedWorkflowStepIdSubject.next(newStep.workflowStepId);
    this.updateProgress(product);
  }

  public async deleteStep(step: WorkflowStep) {
    const r = await this.utilityService.showConfirmationPromise('Are you sure?', 'Really delete this step? This cannot be undone.');
    if (!r) return;
    const stepId = step.workflowStepId;
    const change: DeleteChange = {
             changeType: 'DELETE',
      entity: 'WorkflowStep',
      data: {
        itemId: stepId,
        oldValue: step,
      },
    };
    this.service.recordChanges(change); 
    this.service.selectedWorkflowStepIdSubject.next(null);
  }

  public onStepFieldChange(stepId: string, { field, oldValue, newValue }: { field: string, oldValue: any, newValue: any }) {
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'WorkflowStep',
      data: {
        field,
        itemId: stepId,
        oldValue,
        newValue
      }
    };
    this.service.recordChanges(change);
  }

  public onWorkflowSort(product: Product, event: CdkDragDrop<any, any, any>) {
    if (event.previousIndex === event.currentIndex) return;
    const change: SortWorkflowStepChange = {
      changeType: 'SORT_WORKFLOW_STEP',
      entity: null,
      data: {
        productId: product.productId,
        oldIndex: event.previousIndex,
        newIndex: event.currentIndex,
      },
    };
    this.service.recordChanges(change);
  }

  public onNewOPSpec(newSpec: OutsideProcessSpecification) {
    const change: CreateChange = {
             changeType: 'CREATE',
      entity: 'OutsideProcessSpecification',
      data: {
        itemId: newSpec.outsideProcessSpecificationId,
        value: newSpec,
      },
    };
    this.service.recordChanges(change);
  }
  
  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }
  public stationList: Station[]
  public step$: Observable<WorkflowStep>;
  ngOnInit(): void {
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
    this.step$ = combineLatest([this.service.selectedProduct$, this.service.selectedWorkflowStepIdSubject]).pipe(
      map(([product, stepId]) => product?.workflow?.workflowSteps?.find(step => step.workflowStepId === stepId))
    );
  }

  public getQuantityGetter = (allProducts: Product[]) => (p: Product) => this.service.getTotalQuantity(allProducts, p.productId, p.parentAssemblyId ? p.quantityAsChild : this.service.getFirstQuantity(p));

  public workflowView: 'list' | 'chart' = 'list';

  public async onPurchasingRfqRequestCreated(request: PurchasingRfqRequest, product: Product, workflowStep: WorkflowStep) {
    const productsMap = await fetchLatest(this.service.autosaver.getPreChanges('productMap'));
    const item = product.workflow.workflowSteps.find(ws => ws.workflowStepId === workflowStep.workflowStepId);
    item.purchasingRfqRequest = request;
    item.purchasingRfqRequestId = request.purchasingRfqRequestId;
    this.service.autosaver.resetDomain('productMap', productsMap);
  }

}
 