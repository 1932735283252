<search-list-new
  matSort
  [defaultSort]="{
    id: 'dueDate',
    start: 'desc'
  }"
  [showManagerToggle]="false"
  [resultGetter]="search.bind(this)"
  [displayedColumns]="['item', 'quantity', 'sourceTicket', 'dueDate']"
  (itemSelected)="onSelect($event)"
  localStorageId="purchasing-rfq-request-queue"
>
  <button mat-fab color="primary" routerLink="/purchasing/order/new" floatingButton><mat-icon>add</mat-icon></button>
  <ng-container matColumnDef="item">
    <mat-header-cell *matHeaderCellDef class="flex-grow-1">Item</mat-header-cell>
    <mat-cell *matCellDef="let row" class="flex-grow-1">
      <item-type-chip class="mr-2" [override]="'MATERIAL'" *ngIf="row.materialId" ></item-type-chip>
      <item-type-chip class="mr-2" [override]="'PROCESS'" *ngIf="row.outsideProcessDescriptionId"></item-type-chip>
      <item-type-chip class="mr-2" [override]="'PURCHASED'" *ngIf="row.purchasedItemId"></item-type-chip>
      <b>{{ getItemName(row) }} </b>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef class="flex-grow-0 flex-shrink-1" style="flex-basis: 8%">Quantity Requested</mat-header-cell>
    <mat-cell *matCellDef="let row" class="flex-grow-0 flex-shrink-1 text-align-right" style="flex-basis: 8%">
      {{row.quantityRequested}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sourceTicket" >
    <mat-header-cell *matHeaderCellDef mat-sort-header="sourceTicket" class="flex-grow-0" style="flex-basis: 18%">Source Ticket</mat-header-cell>
    <mat-cell *matCellDef="let row" class="flex-grow-0" style="flex-basis: 18%">
      {{ getSourceTicketName(row) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="dueDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="dueDate" style="flex-grow: 0; flex-basis: 12%;">Due Date</mat-header-cell>
    <mat-cell *matCellDef="let row" style="flex-grow: 0; flex-basis: 12%;">
      {{row.dueDate | date }}
    </mat-cell>
  </ng-container>
</search-list-new>