import { Component, OnInit, Input, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Order, OrderStatus } from '../../../resources/order';
import { ControlContainer, FormGroup, NgForm, NgModelGroup } from '@angular/forms';
import { OrderDetailService } from '../order-detail.service';
import { MatDialog } from '@angular/material/dialog';
import { Customer, CustomerContact } from '../../../../customer/resources/customer';
import { UtilityService } from '../../../../common/services/utility.service';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, forkJoin, interval, merge, timer } from 'rxjs';
import { CreateChange, UpdateChange } from '@cots/common/autosaving/change';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, take, tap, throttle, throttleTime, timeInterval } from 'rxjs/operators';

@Component({
  selector: 'order-detail-form',
  templateUrl: './order-detail-form.component.html',
  styleUrls: ['./order-detail-form.component.less'],
})
export class OrderDetailFormComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    private dialog: MatDialog
  ) { }

  public get editing() { return this.service.editing }

  ngOnInit(): void {
  }

  private readonly RELATED_ENTITY_FIELDS = ['company', 'customer', 'customerContact', 'creator', 'assignedUser', 'estimator', 'salesPerson'];


  @ViewChild('orderForm') orderForm: NgForm;
  private formSubs: Subscription[] = [];
  ngAfterViewInit(): void {
    // Hook up to change tracking
    setTimeout(() => {
      for (const field in this.orderForm.controls) {
        if (this.RELATED_ENTITY_FIELDS.includes(field)) continue;
        const sub = this.orderForm.controls[field].valueChanges
          .pipe(
            startWith(this.orderForm.controls[field].value),
            distinctUntilChanged((oldValue, newValue) => {
              if (newValue instanceof Date) {
                if (!oldValue) return false;
                return (new Date(oldValue)).getTime() === newValue.getTime();
              }
              return oldValue === newValue;
            }),
            pairwise(),
            filter(() => this.service.editing),
            // Causes bug
            // throttleTime(1000)
          ).subscribe(([oldValue, newValue]) => {
            console.log('recordSimpleUpdate', newValue);
            this.recordSimpleUpdate(field, oldValue, newValue);
          })
        ;
        this.formSubs.push(sub);
      }

    })
  }

  ngOnDestroy(): void {
    for (const sub of this.formSubs) {
      if (sub) sub.unsubscribe();
    }
  }

  public getStatusColorClass(status: number): string {
    return OrderStatus.getStatusColorClassChip(status);
  }

  public getStatusText(status: number): string {
    return OrderStatus.getStatusText(status);
  }

  @ViewChild('statusNoteDialogTemplate') statusNoteDialogTemplate: TemplateRef<any>;
  public viewStatusNote() {
    this.dialog.open(this.statusNoteDialogTemplate);
  }

  public getCustomerContactName = (c: CustomerContact | null) => c?.contact?.name ?? '';

  public generateCustomerContact(name: string): CustomerContact {
    return {
      customerContactId: null,
      contactId: null,
      customerId: null,
      contact: {
        contactId: null,
        name,
        title: '',
        cellNumber: '',
        email: '',
        personalEmail: '',
        faxNumber: '',
        note: '',
        phoneExtension: '',
        phoneNumber: ''
      },
    }
  }

  public customerContactAdded(custContact: CustomerContact, record: Order) {
    const customerContactId = UtilityService.newGuid();
    const contactId = UtilityService.newGuid();
    custContact.contactId = contactId;
    custContact.contact.contactId = contactId;
    custContact.customerContactId = customerContactId;
    custContact.customerId = record.customerId;
    const contactChange: CreateChange = {
      changeType: 'CREATE',
      entity: 'Contact',
      data: {
        itemId: contactId,
        value: custContact.contact
      }
    };
    this.service.recordChanges(contactChange);
    const custContactChange: CreateChange = {
             changeType: 'CREATE',
      entity: 'CustomerContact',
      data: {
        itemId: customerContactId,
        value: custContact
      }
    };
    this.service.recordChanges(custContactChange);
    this.onRelatedEntityChange('customerContact', 'customerContactId', record.customerContact, custContact, record.customerContact?.customerContactId, customerContactId);
  }

  public isSales(record: Order): boolean {
    return record.discriminator === 'RFQ' || record.discriminator === 'Quote';
  }

  public isEstimating(record: Order): boolean {
    return record.discriminator === 'Estimate' || record.discriminator === 'RMAEstimate';
  }

  public onCustomerSelected(newCustomer: Customer, record: Order) {
    if (record.customerContactId && record.customerId !== newCustomer?.customerId) {
      this.onRelatedEntityChange('customerContact', 'customerContactId', record.customerContact, null, record.customerContact?.customerContactId, null);
    }
    this.onRelatedEntityChange('customer', 'customerId', record.customer, newCustomer, record.customer?.customerId, newCustomer?.customerId);
    if (newCustomer?.salesPerson && this.isSales) {
      this.onRelatedEntityChange('assignedUser', 'assignedTo', record.assignedUser, newCustomer.salesPerson, record.assignedTo, newCustomer.salesPerson?.userId);
    } else if (newCustomer?.salesPerson && this.isEstimating) {
      this.onRelatedEntityChange('salesPerson', 'salesPersonId', record.salesPerson, newCustomer.salesPerson, record.assignedTo, newCustomer.salesPerson?.userId);
    }
  }

  private recordSimpleUpdate(field: string, oldValue: any, newValue: any) {
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        field,
        itemId: null,
        oldValue,
        newValue
      }
    };
    this.service.recordChanges(change);
  }

  public onRelatedEntityChange<T>(entityField: string, idField: string, oldEntity: T, newEntity: T, oldId: string, newId: string) {
    if (oldEntity === null && newEntity === null) return;
    if (oldId === newId) return;
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'OrderSegment',
      data: {
        field: idField,
        itemId: null,
        oldValue: oldId,
        newValue: newId,
        relatedEntityField: entityField,
        oldRelatedEntity: oldEntity,
        newRelatedEntity: newEntity
      }
    };
    this.service.recordChanges(change);
  }

}
