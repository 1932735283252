import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, pairwise, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrderService } from '../../../../services/order.service';
import { MakeProductCanonicalChange, UpdateChange } from '@cots/common/autosaving/change';
import { Product } from '../../../../resources/product';

@Component({
  selector: 'product-detail-form',
  templateUrl: './product-detail-form.component.html',
  styleUrls: ['./product-detail-form.component.less']
})
export class ProductDetailFormComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    private orderService: OrderService
  ) { }

  public get editing() { return this.service.editing }

  public leadTime$: Observable<number>;

  ngOnInit(): void {
    this.leadTime$ = this.service.selectedProductIdSubject.pipe(
      switchMap((id) => this.service.getProductLeadTimeObservable(id)),
    );
  }
  
  @ViewChild('form') form: NgForm;
  private formSubs: Subscription[] = [];
  ngAfterViewInit(): void {
    // Hook up to change tracking
    setTimeout(() => {
      for (const field in this.form.controls) {
        const sub = this.form.controls[field].valueChanges
          .pipe(
            startWith(this.form.controls[field].value),
            distinctUntilChanged((oldValue, newValue) => {
              if (newValue instanceof Date) {
                if (!oldValue) return false;
                return (new Date(oldValue)).getTime() === newValue.getTime();
              }
              if (field === 'quantitiesMap') return JSON.stringify(oldValue) === JSON.stringify(newValue);
              return oldValue === newValue;
            }),
            pairwise(),
            filter(() => this.service.editing),
            withLatestFrom(this.service.selectedProductIdSubject)
          ).subscribe(([[oldValue, newValue], selectedProductId]) => {
            if (!selectedProductId) return;
            this.recordSimpleUpdate(selectedProductId, field, oldValue, newValue);
          })
        ;
        this.formSubs.push(sub);
      }

    })
  }

  ngOnDestroy(): void {
    for (const sub of this.formSubs) {
      if (sub) sub.unsubscribe();
    }
  }

  public makePartCanonical(product: Product) {
    const change: MakeProductCanonicalChange = {
             changeType: 'MAKE_PRODUCT_CANONICAL',
      entity: null,
      data: {
        productId: product.productId,
      },
    };
    this.service.recordChanges(change);
  }
  
  private recordSimpleUpdate(productId: string, field: string, oldValue: any, newValue: any) {
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        field,
        itemId: productId,
        oldValue,
        newValue
      }
    };
    this.service.recordChanges(change);
  }


}
