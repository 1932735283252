import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { OrderService } from '../../../services/order.service';
import { Product } from '../../../resources/product';

export type ClassifiedProduct = { type: 'REPEAT' | 'NEWREV', product: Product };

@Component({
  selector: 'existing-product-select',
  templateUrl: './existing-product-select.component.html',
  styleUrls: ['./existing-product-select.component.less']
})
export class ExistingProductSelectComponent implements OnInit {

  @Input() allowNew = true;
  @Input() allowNewRev = true;
  @Output() cancel = new EventEmitter<void>();
  @Output() selected = new EventEmitter<ClassifiedProduct | string>();

  constructor(private orderService: OrderService) { }

  public searchControl = new FormControl<string>('');
  public productResults: Observable<ClassifiedProduct[]>;
  ngOnInit(): void {
    const searchedProducts = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((searchText) => this.orderService.searchProducts(searchText))
    );
    this.productResults = combineLatest([searchedProducts, this.searchControl.valueChanges]).pipe(
      // Clientside filtering
      map(([products, string]) => {
        if (typeof string !== 'string') return products;
        return products.filter(p => `${p.partNumber} Rev. ${p.productId}`.toLowerCase().includes(string.toLowerCase()))
      }),
      // Convert to format
      map(products => {
        let aggregated = products.reduce((acc, prod) => {
          if (!acc[prod.partNumber]) acc[prod.partNumber] = []
          acc[prod.partNumber].push({ type: 'REPEAT', product: prod })
          return acc;
        }, {} as { [key: string]: ClassifiedProduct[] })
        let preFlattened = Object.values(aggregated);
        // Add new rev option
        return preFlattened.flatMap(sa => [...sa, { type: 'NEWREV', product: sa[0].product }]);
      })
    );
  }

  public displayWith(item: ClassifiedProduct | string | null) {
    if (!item) return '';
    else if (typeof item === 'string') return item;
    else return `${item.product.partNumber} Rev. ${item.product.revision}`
  }

}
