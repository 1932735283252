import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../../common/services/navigation.service';

type ApiData = {
  workOrderNumber: string;
  orderDate: Date;
  dueDate: Date;
  customer: string;
  lineItemNumber: string;
  purchaseOrderNumber: string;
  productId: string;
  partNumber: string;
  revision: string;
  quantity: string;
  unitPrice: number;
  extPrice: number;
}

type RowData = {
  monthKey: number;
  month: string;
  booked: number;
  available: number;
  weekdays: number;
  dailyTarget: number;
}

type SearchFilter = {
  since: Date;
  until: Date;
}

type LocalStorageData = {
  monthlySalesTarget: number;
  since: string;
  until: string;
}

@Component({
  selector: 'bookings-monthly-report',
  templateUrl: './bookings-monthly-report.component.html',
  styleUrls: ['./bookings-monthly-report.component.less'],
})

export class BookingsMonthlyReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  private defaultSalesTarget = 12500000;
  public targetSalesInput = this.defaultSalesTarget.toString();
  public localStorageId = "report-booking-monthly";

  // The first and last month keys in the original data set
  public dataMonthCount: number;
  public dataSince: number;
  public dataUntil: number;

  // The current content of the filters
  public since: Date = null;
  public until: Date = null;

  // Observables rendered in the header row
  public $monthCount = new BehaviorSubject(0);
  public $filteredSum = new BehaviorSubject(0);
  public $targetSales = new BehaviorSubject(0);
  public $balanceAvailable: Observable<number>;

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Bookings (Monthly)');
  }

  public loading = false;
  public dataSource = new MatTableDataSource<RowData>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.$balanceAvailable = combineLatest([this.$targetSales, this.$filteredSum]).pipe(
      map(([target, sum]) => Math.max(0, target - sum))
    );

    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'monthKey', start: 'asc', disableClear: false });
    this.restoreSavedFilters();
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getBookingsData().subscribe(data => {
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: ApiData[]): RowData[] {
    const rows: RowData[] = [];
    let grandTotalSales = 0;

    for (const data of apiData) {
      const [monthName, monthKey] = this.getMonthData(data.dueDate);
      grandTotalSales += data.extPrice;

      const weekdays = this.getWeekdayCount(monthKey);
      const dailyTarget = this.defaultSalesTarget / weekdays

      // If there's no row for this month, create one
      const row = rows.find(r => r.monthKey === monthKey);
      if (row === undefined) {
        rows.push({
          monthKey,
          month: monthName,
          booked: data.extPrice,
          weekdays,
          available: Math.max(0, this.defaultSalesTarget - data.extPrice),
          dailyTarget,
        });
      }

      // If there is a row, add to the sales
      else {
        row.booked += data.extPrice;
        row.available = Math.max(0, this.defaultSalesTarget - row.booked);
      }
    }

    this.$filteredSum.next(grandTotalSales);

    // Now, a little weird: we want to generate rows that don't exist.
    // No jumps from January to March just because February is 0
    this.dataSince = Math.min(...rows.map(r => r.monthKey));
    this.dataUntil = Math.max(...rows.map(r => r.monthKey));
    let current = this.dataSince;

    // runs i.e. from 199912 to 200506, for Dec 1999 to Jun 2005
    // Skips the last month on purpose (since it's guaranteed populated)
    while (current < this.dataUntil) {
      const year = Math.floor(current / 100);
      const month = (current % 100);

      if (rows.find(r => r.monthKey === current) === undefined) {
        // A week into the month, so we don't get bitten by utc/dst pushing it over an edge
        const dateValue = new Date(`${year}-${month}-07`);
        const [monthName, monthKey] = this.getMonthData(dateValue);
        const weekdays = this.getWeekdayCount(monthKey);//, this.defaultSalesTarget);

        rows.push({
          monthKey,
          month: monthName,
          booked: 0,
          available: this.defaultSalesTarget,
          weekdays,
          dailyTarget: this.defaultSalesTarget / weekdays,
        });
      }

      // Get the next key
      const nextMonth = month >= 12 ? 1 : (month + 1);
      const nextYear = month >= 12 ? (year + 1) : year;

      current = (nextYear * 100) + nextMonth;
    }

    // rows.length is now the total number of months in the range
    this.dataMonthCount = rows.length;
    this.$monthCount.next(this.dataMonthCount);
    this.$targetSales.next(this.defaultSalesTarget * this.dataMonthCount);

    // Trigger the updates as needed from the filters from local storage
    this.onSearchChange();

    return rows;
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    this.$filteredSum.next(rowData.reduce((sum, row) => sum += row.booked, 0));

    return pageData;
  }

  // Search data
  search = (data: RowData, filterJson: string) => {
    const filter: SearchFilter = JSON.parse(filterJson);

    filter.since = new Date(filter.since ?? this.minDate);
    filter.until = new Date(filter.until ?? this.maxDate);

    const startKey = filter.since.getFullYear() * 100 + filter.since.getMonth() + 1;
    const endKey = filter.until.getFullYear() * 100 + filter.until.getMonth() + 1;

    return (data.monthKey >= startKey && data.monthKey <= endKey);
  }

  onSearchChange() {
    const salesTarget = parseInt(this.targetSalesInput);
    this.updateTargetSales(salesTarget, this.since, this.until)
    this.dataSource.filter = JSON.stringify({
      since: this.since,
      until: this.until,
    });
    this.paginator.pageIndex = 0;

    const savedData: LocalStorageData = {
      monthlySalesTarget: salesTarget,
      since: this.since?.toISOString(),
      until: this.until?.toISOString(),
    };
    
    localStorage.setItem(this.localStorageId, JSON.stringify(savedData));
  }

  updateTargetSales(monthlyTarget: number, since: Date, until: Date) {
    since ??= new Date(`${Math.floor(this.dataSince / 100)}-${this.dataSince % 100}-07`);
    until ??= new Date(`${Math.floor(this.dataUntil / 100)}-${this.dataUntil % 100}-07`);
    const monthCount = 1 + (until.getFullYear() * 12 + until.getMonth()) - (since.getFullYear() * 12 + since.getMonth());

    this.$monthCount.next(monthCount);
    this.$targetSales.next(monthlyTarget * monthCount);

    for (const row of this.dataSource.data) {
      row.weekdays = this.getWeekdayCount(row.monthKey);
      row.dailyTarget = monthlyTarget / row.weekdays;
      row.available = Math.max(0, monthlyTarget - row.booked);
    }
  }

  clearSearch() {
    this.targetSalesInput = this.defaultSalesTarget.toString();
    this.$targetSales.next(this.defaultSalesTarget * this.dataMonthCount);
    this.$monthCount.next(this.dataMonthCount);
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  restoreSavedFilters() {
    const existing = localStorage.getItem(this.localStorageId);
    try {
      if (existing) {
        const data: LocalStorageData = JSON.parse(existing);
        this.targetSalesInput = data.monthlySalesTarget.toString();
        if (data.since !== undefined)
          this.since = new Date(data.since);

        if (data.until !== undefined)
          this.until = new Date(data.until);

      }
    } catch (e) {
      localStorage.removeItem(this.localStorageId);
    }
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'month',
      'booked',
      'available',
      'dailyTarget',
    ];
  }

  private getMonthData(date: Date): [string, number] {
    if (date === this.maxDate)
      return ["No Date", 0];

    const realDate = new Date(date);

    // i.e. "December 1999" or "January 2000"
    const monthName = realDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long'});
    // Need a date that will sort the expcted way, so i.e. "199912" or "200001"
    const monthNumber = (realDate.getFullYear() * 100) + realDate.getMonth() + 1;

    return [monthName, monthNumber];
  }

  private getWeekdayCount(monthKey: number) {
    let weekdaysInMonth = 0;
    let checkDate = new Date(`${Math.floor(monthKey / 100)}-${monthKey % 100}-01 12:00:00`);
    let currentMonth = checkDate.getMonth();

    while (checkDate.getMonth() === currentMonth) {
      const day = checkDate.getDay();

      if (day > 0 && day < 6)
        weekdaysInMonth += 1;

      checkDate.setDate(checkDate.getDate() + 1);
    }

    return weekdaysInMonth;
  }

}
