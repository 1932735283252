import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportService } from '@cots/report/services/report.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../../common/services/navigation.service';

type ApiData = {
  customer: string;
  receivedDate: Date;
  purchaseOrderNumber: string;
  extPrice: number;
}

type RowData = {
  customer: string;
  totalSales: number;
  totalShare: number;
}

type SearchFilter = {
  text: string;
  since: Date;
  until: Date;
}

@Component({
  selector: 'customer-sales-share-report',
  templateUrl: './customer-sales-share-report.component.html',
  styleUrls: ['./customer-sales-share-report.component.less'],
})

export class CustomerSalesShareComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private allSales: ApiData[];
  private minDate = new Date(-8640000000000000);
  private maxDate = new Date(8640000000000000);
  public since: Date = null;
  public until: Date = null;
  public $filteredSum = new BehaviorSubject(0);

  constructor(
    navService: NavigationService,
    private service: ReportService
  ) {
    navService.setPageTitle("Reports");
    navService.pushBreadcrumb('Customer Sales Share');
  }

  public loading = false;
  public dataLength = 0;
  public dataSource = new MatTableDataSource<RowData>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource._pageData = this.dataToPage;
    this.dataSource.filterPredicate = this.search;
    this.sort.sort({ id: 'customer', start: 'asc', disableClear: false });
    this.getDetail();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getDetail() {
    this.loading = true;
    this.service.getCustomerSalesShares().subscribe(data => {
      this.allSales = data;
      this.dataSource.data = this.dataToRows(data);
      this.loading = false;
    })
  }

  dataToRows(apiData: ApiData[]): RowData[] {
    const rows: RowData[] = [];
    let grandTotalSales = 0;

    for (const data of apiData) {
      data.receivedDate = new Date(data.receivedDate);
      grandTotalSales += data.extPrice;

      // If there's no row for this customer, create one
      const row = rows.find(r => r.customer === data.customer);
      if (row === undefined) {
        rows.push({
          customer: data.customer,
          totalSales: data.extPrice,
          totalShare: 0,
        });
      }

      // If there is a row, add to the sales
      else {
        row.totalSales += data.extPrice;
      }
    }

    this.$filteredSum.next(grandTotalSales);

    // All in place, now calculate shares
    for (const row of rows) {
      row.totalShare = row.totalSales / grandTotalSales;
    }

    return rows;
  }

  dataToPage = (rowData: RowData[]) => {
    if (rowData.length === 0)
      return [];

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;

    const pageData = rowData.slice(startIndex, endIndex);

    return pageData;
  }

  // Search data
  public searchText = '';
  search = (data: RowData, filterJson: string) => {
    return data.totalSales > 0;
  }

  updateRowsWithFilter(filter: SearchFilter = null) {
    filter ??= {
      text: '',
      since: this.minDate,
      until: this.maxDate,
    };

    let grandTotal = 0;
    for (const row of this.dataSource.data) {
      row.totalSales = this.allSales
        .filter(d => d.customer === row.customer && d.customer.toLowerCase().includes(filter.text.toLowerCase()))
        .filter(d => d.receivedDate >= filter.since && d.receivedDate <= filter.until)
        .reduce((sum, d) => sum += d.extPrice, 0);
      grandTotal += row.totalSales;
    }

    for (const row of this.dataSource.data) {
      row.totalShare = row.totalSales / grandTotal;
    }

    this.$filteredSum.next(grandTotal);
  }

  onSearchChange() {
    const filter: SearchFilter = {
      text: this.searchText,
      since: this.since ?? this.minDate,
      until: this.until ?? this.maxDate,
    }

    const jsonFilter = JSON.stringify(filter);
    // Update row data to only include filtered orders
    this.updateRowsWithFilter(filter);

    // Only doing this once causes some of the values to lag behind
    this.dataSource.filter = jsonFilter;
    this.paginator.pageIndex = 0;
  }

  clearSearch() {
    this.updateRowsWithFilter();
    this.searchText = '';
    this.since = null;
    this.until = null;
    this.dataSource.filter = '';
  }

  // Utility
  public get rowDisplayedColumns() {
    return [
      'customer',
      'totalSales',
      'totalShare',
    ];
  }

}
