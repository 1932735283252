import { Material } from "../../order/resources/material";
import { Station } from "../../order/resources/station";
import { Vendor } from "../../supplier/resources/vendor";
import { VirtualDocument } from "../../common/resources/virtual-document";
import { PurchaseOrder } from "./purchaseOrder";
import { WorkOrder } from "../../planning/resources/work-order";
import { PurchasedItem } from "../../order/resources/purchased-item";
import { ProductPurchasedItem } from "../../order/resources/product";
import { Requisition } from "./requisition";
import { Company } from "../../common/resources/company";
import { Contact } from "../../common/resources/contact";

export interface MaterialBid {
  materialBidId: string;
  vendorId: string;
  orderId?: string;
  requisitionId?: string;
  workOrderId?: string;
  productId?: string;
  materialId?: string;
  stationId?: string;
  purchasedItemId?: string;
  bulkBidId?: string;
  note: string;
  leadTimeDays?: number;
  totalBid?: number;
  perItemBid?: number;
  qty?: number;
  requiredBy?: Date;
  asked?: Date;
  answered?: Date;
  accepted?: Date;
  rejected?: Date;
  acceptedBy?: string;
  rejectedBy?: string;
  purchaseOrderId?: string;
  contactId?: string;

  isVerbal: boolean;
  isPoPseudoBid: boolean;

  purchaseOrder: PurchaseOrder;
  vendor: Vendor;
  requisition?: Requisition;
  workOrder?: WorkOrder;
  material?: Material;
  station?: Station;
  purchasedItem?: PurchasedItem;
  contact?: Contact;
  materialBidDocuments?: MaterialBidDocument[];
  purchasingRfqRequestId?: string;
}

export interface MaterialBidDocument {
  materialBidId: string;
  documentId: string;
  document: VirtualDocument;
}

export class BulkBid {
  bulkBidId: string;
  vendorId: string;
  bidNumber: string;
  bids: MaterialBid[];
  vendor: Vendor;
  status: number;
  companyId: string;
  company: Company;
}

export class BulkBidStatus {
  static IN_PROCESS: number = 0;
  static AWAITING_REPLY: number = 1;
  static REPLY_RECEIVED: number = 2;
  static PO_CREATED: number = 3;

  static getStatusColorClass(status: number): string {
    switch (status) {
      case BulkBidStatus.IN_PROCESS: return 'badge-warning';
      case BulkBidStatus.AWAITING_REPLY: return 'badge-secondary';
      case BulkBidStatus.REPLY_RECEIVED: return 'badge-info';
      case BulkBidStatus.PO_CREATED: return 'badge-success';
      default: return null;
    }
  }

  static getStatusColorCode(status: number): string {
    switch (status) {
      case BulkBidStatus.IN_PROCESS: return '#28B463';
      case BulkBidStatus.AWAITING_REPLY: return '#2980B9';
      case BulkBidStatus.REPLY_RECEIVED: return '#616A6B';
      case BulkBidStatus.PO_CREATED: return '#28B463';
      default: return null;
    }
  }

  static getStatusText(status: number): string {
    switch (status) {
      case BulkBidStatus.IN_PROCESS: return 'In Process';
      case BulkBidStatus.AWAITING_REPLY: return 'Awaiting Reply';
      case BulkBidStatus.REPLY_RECEIVED: return 'Received';
      case BulkBidStatus.PO_CREATED: return 'PO Created';
      default: return null;
    }
  }
}

