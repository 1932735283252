import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../../../order-detail.service';
import { Product, ProductPurchasedItem } from '../../../../../resources/product';
import { MaterialBid } from '../../../../../../purchasing/resources/materialBid';
import { Material, MaterialTypeDimension } from '../../../../../resources/material';
import { UpdateChange } from '@cots/common/autosaving/change';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, distinctUntilChanged, pairwise, filter, withLatestFrom } from 'rxjs/operators';
import { PurchasingRfqRequest } from '../../../../../../common/resources/purchasing-rfq-request';

@Component({
  selector: 'product-material-form',
  templateUrl: './product-material-form.component.html',
  styleUrls: ['./product-material-form.component.less']
})
export class ProductMaterialFormComponent implements AfterViewInit {

  @Input() product: Product;

  public get editing() { return this.service.editing }
  constructor(public service: OrderDetailService) { }

  @ViewChild('form') form: NgForm;
  public selectedPurchasedItem$: Observable<ProductPurchasedItem>;
  private formSubs: Subscription[] = [];
  ngAfterViewInit() {
    setTimeout(() => {
      for (const field in this.form.controls) {
        if (field.includes('blank') || field.includes('finished')) return;
        const sub = this.form.controls[field].valueChanges
          .pipe(
            startWith(this.form.controls[field].value),
            distinctUntilChanged(),
            pairwise(),
            filter(() => this.editing),
            withLatestFrom(this.service.selectedProductIdSubject)
          ).subscribe(([[oldValue, newValue], productId]) => {
            if (!productId) return;
            this.recordSimpleUpdate(productId, field as any, oldValue, newValue);
          })
          ;
        this.formSubs.push(sub);
      }
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.formSubs) {
      if (sub) sub.unsubscribe();
    }
  }

  public setMaterialPrice(product: Product, quote: MaterialBid) {
    const newCost = quote.totalBid ? (quote.totalBid / quote.qty) : quote.perItemBid;
    this.recordSimpleUpdate(product.productId, 'materialLotCost', product.materialLotCost, parseFloat(newCost.toFixed(2)));
  }

  public togglePartsPerMaterialOverride(e: MouseEvent, product: Product) {
    e.preventDefault();
    this.recordSimpleUpdate(product.productId, 'partsPerMaterialOverride', product.partsPerMaterialOverride, !product.partsPerMaterialOverride);
  }

  public getRawWeight(product: Product): number {
    return Material.getVolume(product.material) * product.material.density;
  }

  public getRawWeightDisplay(product: Product): string {
    const rawWeight = this.getRawWeight(product);
    return rawWeight ? rawWeight.toFixed(2) + 'lb' : null;
  }

  public getBlankWeight(product: Product): number {
    return Material.getVolume(product.material) * product.material.density;
  }

  public getBlankWeightDisplay(product: Product): string {
    const blankWeight = this.getBlankWeight(product);
    return blankWeight ? blankWeight.toFixed(2) + 'lb' : null;
  }

  public getFinishedWeight(product: Product): number {
    return Material.getVolume(product.material) * product.material.density;
  }

  public getFinishedWeightDisplay(product: Product): string {
    const finishedWeight = this.getFinishedWeight(product);
    return finishedWeight ? finishedWeight.toFixed(2) + 'lb' : null;
  }

  public getMaterialRemoved(product: Product): number {
    return Product.getMaterialRemoved(product);
  }

  public getPiecesFromLot(product: Product): number {
    return Product.getPiecesFromLot(product);
  }

  public getPiecesFromLotAfterOverride(product: Product): number {
    return product.partsPerMaterialOverride ? product.partsPerMaterial : this.getPiecesFromLot(product);
  }

  public getMaterialMarkupFactor(product: Product): number {
    if (!product.materialMarkup) return 1;
    return (1 + ((product.materialMarkup || 0.0) / 100.0));
  }

  public getMaterialCost(product: Product): number {
    return Product.getMaterialCost(product, this.service.getFirstQuantity(product));
  }

  public getBlankDimension(product: Product, mtdId: string) {
    const dimensions = product.blankDimensions || [];
    const out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setBlankDimension(product: Product, mtdId: string, value: number) {
    const newDimensions = product.blankDimensions ? structuredClone(product.blankDimensions) : [];
    const dim = newDimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      newDimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
    this.recordSimpleUpdate(product.productId, 'blankDimensions', structuredClone(product.blankDimensions), newDimensions);
  }

  public getFinishedDimension(product: Product, mtdId: string) {
    const dimensions = product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setFinishedDimension(product: Product, mtdId: string, value: number) {
    const newDimensions = product.finishedDimensions ? structuredClone(product.finishedDimensions) : [];
    const dim = newDimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!dim) {
      newDimensions.push({ materialTypeDimensionId: mtdId, value })
    }
    else {
      dim.value = value;
    }
    this.recordSimpleUpdate(product.productId, 'finishedDimensions', structuredClone(product.finishedDimensions), newDimensions);
  }

  public getComplexity(product: Product) {
    if (product.complexity) return product.complexity;
    else return Math.round((this.getMaterialRemoved(product) || 0) / 10);
  }

  public setComplexity(product: Product, val: number) {
    this.recordSimpleUpdate(product.productId, 'complexity', product.complexity, val);
  }

  public resetComplexity(product: Product) {
    this.recordSimpleUpdate(product.productId, 'complexity', product.complexity, null);
  }

  private recordSimpleUpdate(productId: string, field: keyof Product, oldValue: any, newValue: any) {
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'Product',
      data: {
        field,
        itemId: productId,
        oldValue,
        newValue
      }
    };
    this.service.recordChanges(change);
  }

  public mtdTrackByFn(mtd: MaterialTypeDimension) {
    return mtd.materialTypeDimensionId;
  }

  public async onPurchasingRfqRequestCreated(request: PurchasingRfqRequest) {
    const productsMap = await fetchLatest(this.service.autosaver.getPreChanges('productMap'));
    const product = productsMap[this.product.productId];
    product.materialPurchasingRfqRequest = request;
    product.materialPurchasingRfqRequestId = request.purchasingRfqRequestId;
    this.service.autosaver.resetDomain('productMap', productsMap);
  }

}
