import { Component, OnInit } from '@angular/core';
import { OrderDetailService } from '../order-detail.service';
import { Observable, combineLatest } from 'rxjs';
import { Product, ProductDocument, ProductPurchasedItem } from '../../../resources/product';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { Material } from '../../../resources/material';
import { WorkflowStep } from '../../../resources/workflow';
import { PurchasedItem } from '../../../resources/purchased-item';
import { StationService } from '../../../services/station.service';
import { VirtualDocument } from '../../../../common/resources/virtual-document';

type SimpleProduct = Pick<Product, 'partNumber' | 'revision' | 'productId'> &
{ material?: boolean, workflowStep?: WorkflowStep, purchasedItem?: ProductPurchasedItem }

@Component({
  selector: 'order-detail-microticket-screen',
  templateUrl: './order-detail-microticket-screen.component.html',
  styleUrls: ['./order-detail-microticket-screen.component.less']
})
export class OrderDetailMicroticketScreenComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
    public stationService: StationService
  ) { }

  public partNumbers: Observable<SimpleProduct[]>
  public documents: Observable<VirtualDocument[]>

  ngOnInit(): void {
    this.partNumbers = this.service.allProducts$.pipe(
      shareReplay(1),
      map(products => products.map(p => ({ partNumber: p.partNumber, revision: p.revision, productId: p.productId })))
    );
    this.documents = combineLatest([this.service.postChangesOrder$, this.service.allProducts$]).pipe(
      shareReplay(1),
      map(([order, allProducts]) => [...order.documents.map(d => d.document), ...allProducts.flatMap(y => y.documents.map(d => d.document))]),
    );
  }

  public partNumberDisplayWith(item: SimpleProduct) {
    const base = `${item.partNumber} Rev. ${item.revision}`;
    if (item.material) {
      return `${base} - Material`;
    } else if (item.workflowStep) {
      const station = this.stationService?.stationList?.find(s => s.stationId === item.workflowStep.stationId);
      return `${base} - ${station?.name ?? 'Workflow Step'}`;
    } else if (item.purchasedItem) {
      return `${base} - Purchased Item - ${item.purchasedItem.purchasedItem?.description}`
    } else {
      return base;
    }
  }
  public partNumberCompareWith(item: SimpleProduct) {
    if (item.material) {
      return this.service.generateSubItemNavigationId(item.productId, "material");
    } else if (item.workflowStep) {
      return this.service.generateSubItemNavigationId(item.productId, "workflow", item.workflowStep.workflowStepId);
    } else if (item.purchasedItem) {
      return this.service.generateSubItemNavigationId(item.productId, "hardware", item.purchasedItem.productPurchasedItemId);
    } else {
      return item.productId;
    }
  }

  public jumpToSubitem(item: string) {
    this.service.navigateToSubItem(item);
  }
}
