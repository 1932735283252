import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { OrderDetailService } from '../../order-detail.service';
import { UtilityService } from '../../../../../common/services/utility.service';
import { Product, ProductPurchasedItem } from '../../../../resources/product';
import { OrderService } from '../../../../services/order.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel } from '@angular/forms';
import { debounceTime, filter, map, share, startWith, switchMap, tap } from 'rxjs/operators';
import { PurchasedItem } from '../../../../resources/purchased-item';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest, isObservable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EstimatingTaskStatus } from '../../../../resources/order';
import { CreateChange, DeleteChange, UpdateChange } from '@cots/common/autosaving/change';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'product-purchased-items',
  templateUrl: './product-purchased-items.component.html',
  styleUrls: ['./product-purchased-items.component.less']
})
export class ProductPurchasedItemsComponent implements AfterViewInit {

  constructor(
    public service: OrderDetailService,
    private dialog: MatDialog
  ) { }

  public selectedPurchasedItem$: Observable<ProductPurchasedItem>;
  ngAfterViewInit() {
    this.selectedPurchasedItem$ = combineLatest([this.service.selectedProduct$, this.service.selectedPurchasedItemIdSubject]).pipe(
      map(([product, itemId]) => product?.purchasedItems?.find(item => item.productPurchasedItemId === itemId))
    );
  }

  private updateProgress(product: Product) {
    if (product.hardwareStatus === EstimatingTaskStatus.NOT_STARTED) this.service.setEstimatingTaskStatus(product.productId, 'hardware', EstimatingTaskStatus.IN_PROGRESS);
  }

  public get editing() { return this.service.editing }

  private createPurchasedItem(purchasedItem: PurchasedItem) {
    if (purchasedItem.purchasedItemId === UtilityService.emptyGuid) {
      purchasedItem.purchasedItemId = UtilityService.newGuid();
      const change: CreateChange = {
                 changeType: 'CREATE',
        entity: 'PurchasedItem',
        data: {
          itemId: purchasedItem.purchasedItemId,
          value: purchasedItem,
        },
      };
      this.service.recordChanges(change);
    }
  }

  @ViewChild('newItemDialog') newItemDialog: TemplateRef<any>;
  public async createNew(product: Product) {
    const dialogRef = this.dialog.open(this.newItemDialog, { data: { item: null, isNew: true }, disableClose: true, width: "50vw" });
    const purchasedItem: PurchasedItem = await dialogRef.afterClosed().toPromise();
    if (!purchasedItem) return;
    this.createPurchasedItem(purchasedItem);
    const newItem = <ProductPurchasedItem>{
      productPurchasedItemId: UtilityService.newGuid(),
      purchasedItemId: purchasedItem.purchasedItemId,
      purchasedItem: purchasedItem,
      productId: product.productId,
      quantity: 1,
      isNonRecurring: false,
      isAmortized: false,
      markupPercent: 0,
    };
    const change: CreateChange = {
             changeType: 'CREATE',
      entity: 'ProductPurchasedItem',
      data: {
        itemId: newItem.productPurchasedItemId,
        value: newItem,
      },
    };
    this.service.recordChanges(change);
    this.service.selectedPurchasedItemIdSubject.next(newItem.productPurchasedItemId);
    this.updateProgress(product);
  }

  public async changeItem(productPurchasedItem: ProductPurchasedItem) {
    const dialogRef = this.dialog.open(this.newItemDialog, { data: { item: productPurchasedItem.purchasedItem, isNew: false }, disableClose: true, width: "50vw" });
    const purchasedItem: PurchasedItem = await dialogRef.afterClosed().toPromise();
    if (!purchasedItem) return;
    this.createPurchasedItem(purchasedItem);
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'ProductPurchasedItem',
      data: {
        itemId: productPurchasedItem.productPurchasedItemId,
        field: 'purchasedItemId',
        oldValue: productPurchasedItem.purchasedItemId,
        newValue: purchasedItem.purchasedItemId,
        relatedEntityField: 'purchasedItem',
        oldRelatedEntity: productPurchasedItem.purchasedItem,
        newRelatedEntity: purchasedItem,
      },
    };
    this.service.recordChanges(change);
  }

  public deleteItem(item: ProductPurchasedItem) {
    const change: DeleteChange = {
             changeType: 'DELETE',
      entity: 'ProductPurchasedItem',
      data: {
        itemId: item.productPurchasedItemId,
        oldValue: item,
      },
    };
    this.service.recordChanges(change);
  }

  public getCost(product: Product, item: ProductPurchasedItem) {
    let cost = item.costPer ?? 0;
    cost = cost * item.quantity;
    cost = cost * (1 + ((item.markupPercent ?? 0.0) / 100.0));
    if (item.isNonRecurring) cost = cost / this.service.getFirstQuantity(product);
    return cost;
  }

}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

@Component({
  selector: 'purchased-item-search',
  templateUrl: './purchased-item-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PurchasedItemSearch),
      multi: true
    }
  ]
})
export class PurchasedItemSearch implements AfterViewInit, ControlValueAccessor {

  constructor(private orderSvc: OrderService) { }

  @Input() dense: boolean = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() readonly = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() noItemsText: string;
  @Input() item: PurchasedItem;
  @Input() reqSel: boolean;
  @Input() nullOption: boolean;
  @Input() canAdd: boolean = true;
  @Input() fieldClass: string;

  private subscription: Subscription;
  @Input() set items(v: PurchasedItem[] | Observable<PurchasedItem[]>) {
    if (this.subscription) this.subscription.unsubscribe();
    if (isObservable(v)) {
      this.subscription = v.subscribe(this.items$);
    } else {
      this.items$.next(v);
    }
  }
  protected readonly items$ = new ReplaySubject<PurchasedItem[]>(1);
  public getSearchField: (item: PurchasedItem) => string = (i) => i?.description ?? '';

  public searchText = '';
  @ViewChild('searchModel') searchModel: NgModel;
  
  public searchedItems$: Observable<PurchasedItem[]>;
  public searchedStartWith: Observable<string>;
  public filteredItems$: Observable<PurchasedItem[]>;

  private currentItems$ = new BehaviorSubject<PurchasedItem[]>([]);
  public addValid: Observable<boolean>;
  public loading = false;
  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  @Input() clearAddedOnChange = true;
  ngAfterViewInit(): void {
    this.searchedStartWith = this.searchModel.valueChanges.pipe(startWith(''));
    this.searchedItems$ = this.searchModel.valueChanges.pipe(
        debounceTime(350),
        startWith(''),
        filter((val) => {
          return typeof val === 'string'
        }),
        tap(() => { this.loading = true; }),
        switchMap((val) => {
          return this.orderSvc.searchPurchasedItems(val);
        }),
        map((r) => r.results),
        tap(() => { this.loading = false; }),
        share()
    );

    this.filteredItems$ = combineLatest([
      this.searchedItems$,
      this.searchedStartWith
    ]).pipe(
      map(([results, filter]) => {
        if (!filter || typeof filter !== 'string') return results;
        else return results.filter(i => {
          const field = this.getSearchField(i).toLowerCase();
          const keywords = filter.toLowerCase().split(' ');
          return keywords.some(kw => field.includes(kw));
        })
      })
    )

    this.items$.subscribe(() => {
      if (this.clearAddedOnChange) this.added$.next(null);
    })

    this.addValid = combineLatest([this.filteredItems$, this.searchModel.valueChanges])
      .pipe(
        map(([items, search]) => {
          return typeof search === 'string' && !!search.trim() && (!this.value || search.trim() !== this.getSearchField(this.value)) && !(
            items?.length === 1 && (this.getSearchField(items?.[0]).toLowerCase() === search.toLowerCase())
          );
        })
    )
  }

  public value: PurchasedItem | null = null;
  onChange = (_: PurchasedItem) => {};
  onTouched = (_: PurchasedItem) => {};

  writeValue(value: PurchasedItem) {
    this.value = value;
    this.onChange && this.onChange(value);
    this.searchText = this.getSearchField(value) ?? '';
  }

  public registerOnChange(fn: (value: PurchasedItem) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: (value: PurchasedItem) => void): void {
    this.onTouched = fn;
  }

  public filterSearchText(item: any) {
    if (!item) return '';
    if (typeof item === 'string') return item;
    else {
      try {
        return this.getSearchField(item);
      } catch (e) {
        return '';
      }
    }
  }

  public getHighlitName(filter: string, str: string): string {
    if (!filter) { return str; }
    const split = filter.split(' ').sort((a, b) => b.length - a.length);
    let out = str;
    for (const keyword of split) {
      let kw = keyword.trim();
      if (!kw) continue;
      var re = new RegExp(escapeRegExp(keyword), 'gi');
      out = out.replace(re, "␚$&␚");
    }
    out = out.replace(/␚(.+?)␚/gi, "<b>$1</b>");
    return out;
  }

  public onBlur(event: FocusEvent, auto: MatAutocomplete) {
    const rt = event.relatedTarget as HTMLElement;
    if (rt && rt.classList?.contains('mat-option')) {
      return;
    }
    const value = this.searchText;

    let matchingOptions = (this.currentItems$.value ?? []).concat(this.added$.value ? this.added$.value : []).find(
      (option) => this.getSearchField(option) == value
    );
    if (!matchingOptions) {
      if (this.reqSel) {
        this.writeValue(null);
        this.searchText = '';
      }
    }
  }


  addConverter: (i: string) => PurchasedItem = (i: string) => ({
    purchasedItemId: UtilityService.emptyGuid,
    description: i,
    purchasedItemPartNumbers: [],
    purchasedItemCategoryId: UtilityService.emptyGuid,
    purchasedItemCategory: null
  });
  private added$ = new BehaviorSubject<PurchasedItem | null>(null);
  @Output() added = new EventEmitter<PurchasedItem>();
  public onAdd(item: PurchasedItem) {
    this.added$.next(item);
    this.added.emit(item);
  }

  public selected(event: MatAutocompleteSelectedEvent) {
    const { type, value } = event?.option?.value;
    this.onChange(value);
    this.searchField.nativeElement.value = this.getSearchField(value);
    if (type === 'new') this.onAdd(value);
  }


}