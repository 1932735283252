import { EventEmitter, Injectable } from "@angular/core";
import { EstimatingTaskStatus, Order, OrderSegmentProductReviewStatus, OrderStatus, QuoteLineItem } from "../../resources/order";
import { MaterialBid } from "../../../purchasing/resources/materialBid";
import { Product, ProductPurchasedItem } from "../../resources/product";
import { MaterialBidService } from "../../../purchasing/services/material-bid.service";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, concat, interval, merge } from "rxjs";
import { OutsideProcessSpecification, Station } from "../../resources/station";
import { StationService } from "../../services/station.service";
import { WorkflowStep } from "../../resources/workflow";
import { UtilityService } from "../../../common/services/utility.service";
import { AsyncPipe, Location } from "@angular/common";
import { debounce, debounceTime, distinctUntilChanged, filter, map, scan, shareReplay, skip, skipUntil, startWith, switchMap, take, tap, timestamp, withLatestFrom } from "rxjs/operators";
import { VirtualDocument } from "../../../common/resources/virtual-document";
import { DocumentService } from "../../../common/services/document.service";
import { UserService } from "../../../common/services/user.service";
import { OrderService } from "../../services/order.service";
import { MicroTicket } from "../../../microtickets/resources/microticket";
import { PricingBreakdown, PricingBreakdownItem } from "../../../../../util/pricing";
import { ProductTimingService, TimingData } from "../../services/product-timing.service";
import { MatSidenav } from "@angular/material/sidenav";
import { AddMaterialBidDocumentChange, Change, ChangeResult, ChangeType, CreateChange, RecordedChange, ReviewOrderProductChange, UpdateChange } from "../../../common/autosaving/change";
import { ProductMap, QuoteMap, applyChangeResultsToOrder, applyChangeResultsToProductMap, applyOrderSegmentChanges, applyProductsMapChanges, applyQuotesMapChanges, changeIsForQuotesMap } from "./order-detail-change-tracking";
import { eqSet } from "../../../../../util/eqSet";
import { MessageService } from "../../../common/services/message.service";
import { MessageType } from "../../../common/resources/message";
import { NavigationService } from "../../../common/services/navigation.service";
import { ProductHierarchyNewComponent } from "./product-detail-new/product-hierarchy-new/product-hierarchy-new.component";
import { OrderValidationError, ProductValidationError, validateOrder, validateProduct } from "./order-detail-validation";
import { Autosaver } from "../../../common/autosaving/auto-saver";

type SidenavMode = 'quoteDetail' | 'quoteHistory';

export function fetchLatest<T>(obs: Observable<T>): Promise<T> {
  return new Promise(resolve => {
    obs.pipe(take(1)).toPromise().then(s => resolve(s));
  })
}

@Injectable()
export class OrderDetailService {

  // Utility
  public getStation(item: WorkflowStep): Station {
    if (this.stationService.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationService.stationList.find(r => r.stationId == item.stationId);
  }

  public getFirstQuantity(product: Product): number {
    return Product.getFirstQuantity(product);
  }

  public async batchUploadDocuments(files: File[]): Promise<VirtualDocument[]> {
    this.loading = true;
    const docs = await Promise.all(files.map(f => this.documentService.upload(f as any).toPromise()));
    this.loading = false;
    return docs;
  }

  // Some navigation bindings so we can easily jump to certain parts of the app using the service
  public mainTab = 0;
  public productTab = 0;
  public selectedWorkflowStepIdSubject = new BehaviorSubject<string | null>(null);
  public selectedPurchasedItemIdSubject = new BehaviorSubject<string | null>(null);

  // State handling
  public editing = false;
  public async startEditing() {
    this.editing = true;
  }
  public async stopEditing() {
    const [selectedProductId, order] = await Promise.all([fetchLatest(this.selectedProductIdSubject), fetchLatest(this.autosaver.getPostChanges('order'))]);
    if (selectedProductId && !this.solo) {
      this.setSelectedProductId(null);
      return;
    }
    if (this.solo) {
      const product = await fetchLatest(this.selectedProduct$);
      const quotes = await fetchLatest(this.allQuotes);
      this.soloClose.emit({
        product,
        quotes,
      });
      return;
    } else if (order.orderSegmentId === UtilityService.emptyGuid) {
      this.location.back();
    } else {
      this.setSelectedProductId(null);
      this.editing = false;
    }
  }

  public loading$ = new BehaviorSubject(false);
  public set loading(value: boolean) {
    this.loading$.next(value);
  }

  public selectedProductIdSubject = new BehaviorSubject<string | null>(null);
  public setSelectedProductId(id: string | null) {
    this.selectedProductIdSubject.next(id);
  }

  public autosaver: Autosaver<{ 'order': Order, 'productMap': ProductMap, 'quoteMap': QuoteMap }>;
  private setupAutosaver() {
    this.autosaver = new Autosaver({
      changeMapper: (existingChanges, newChanges) => {
        let creatingOrderChangeI = existingChanges.findIndex(
          (change) =>
            change.changeType === "CREATE" && change.entity === "OrderSegment",
        );
        if (creatingOrderChangeI !== -1) {
          const creatingOrder = (
            existingChanges[creatingOrderChangeI] as CreateChange
          ).data.value as Order;
          for (const change of newChanges) {
            if (
              change.changeType === "UPDATE" &&
              change.entity === "OrderSegment"
            ) {
              const updateChange = change as UpdateChange;
              if (updateChange.data.field === "customerId") {
                creatingOrder.customerId = updateChange.data.newValue as string;
              } else if (updateChange.data.field === "companyId") {
                creatingOrder.companyId = updateChange.data.newValue as string;
              }
            }
          }
        }
        return { existingChanges, newChanges };
      },
    }).withDomain<Order, "order">("order", {
      changeApplier: (base, changes) => applyOrderSegmentChanges(base, changes),
      resultApplier: (base, changes, results) => applyChangeResultsToOrder(base, changes, results),
    })
    .withDomain<ProductMap, "productMap">("productMap", {
      changeApplier: (base, changes) => applyProductsMapChanges(base, changes),
      resultApplier: (base, changes, results) => applyChangeResultsToProductMap(base, changes, results),
    }).withDomain<QuoteMap, "quoteMap">("quoteMap", {
      changeFilter: c => changeIsForQuotesMap(c),
      changeApplier: (base, changes) => applyQuotesMapChanges(base, changes),
      // quotes do not have a results function
      resultApplier: (base) => base,
    });
    this.autosaver.setSaveFunction(async (serverChanges, data) => {
      return await this.orderService
        .incrementalSave(data["order"], serverChanges)
        .toPromise();
    });
    this.autosaver.onChangesSaved = (successfulChanges, data) => {
      // OSP
      const successfulOSPChanges = successfulChanges.filter(
        (c) =>
          c.changeType === "CREATE" && c.entity === "OutsideProcessSpecification",
      ) as CreateChange[];
      for (const ospChange of successfulOSPChanges) {
        this.burnInOPSpec(ospChange);
      }
      // Set ID
      if (this.id === "new") {
        const order = data["order"];
        this.id = order.orderSegmentId;
        history.replaceState(
          null,
          undefined,
          document.location.href.replace(/new$/, this.id),
        );
        this.setPageTitle(order);
      }
    };
    this.autosaver.handleErrors = async (failResults, allChanges) => { this.handleErrors(failResults, allChanges) };
    this.autosaver.changeFilter = (_changes, _preChangeData, postChangeData) => {
      return !!postChangeData["order"]?.customerId;
    };

  }

  public recordChanges(...changes: Change[]) {
    this.autosaver.recordChanges(...changes);
  }
  
  public resetProducts(allProducts: Product[]) {
    allProducts.forEach(p => p.workflow.workflowSteps.sort((a, b) => a.stepOrder - b.stepOrder));
    const mapped = Object.fromEntries(allProducts.map(p => [p.productId, p]));
    this.autosaver.resetDomain('productMap', mapped);
  }

  public get postChangesOrder$() {
    return this.autosaver.getPostChanges('order');
  }
  public get postChangesProducts$() {
    return this.autosaver.getPostChanges('productMap');
  }
  public get allQuotes() {
    return this.autosaver.getPostChanges('quoteMap');
  }
  
  public resetOrder(order: Order, isNew = false) {
    this.autosaver.clearChanges();
    if (isNew) {
      order.orderSegmentId = UtilityService.newGuid();
      const change: CreateChange = {
        changeType: "CREATE",
        entity: "OrderSegment",
        data: {
          itemId: order.orderSegmentId,
          value: order,
        },
      };
      this.autosaver.recordChanges(change);
    } else if (order) {
      order.products = order.products.sort((a, b) => a.sort - b.sort);
    }
    this.autosaver.resetDomain('order', order);
  }

  /*
  * Product data handling
  */
  public allProducts$: Observable<Product[]>;
  private setupAllProducts() {
    return this.autosaver.getPostChanges('productMap').pipe(map(m => Object.values(m)));
  }

  public productObservables: { [key: string]: Observable<Product> } = {}
  public getProductObservable(productId: string) {
    if (!this.productObservables[productId])
      this.productObservables[productId] = this.autosaver.getPostChanges('productMap').pipe(
          map((prods) => prods[productId]),
          // tap(p => { if (!p) console.log('Did not find product in map') }),
          filter(p => !!p),
          shareReplay(1)
        );
    return this.productObservables[productId];
  }

  private productChildrenObservableMap: { [productId: string]: Observable<Product[]> } = {};
  public getProductChildrenObservable(productId: string) {
    if (!this.productChildrenObservableMap[productId])
      this.productChildrenObservableMap[productId] = this.allProducts$
        .pipe(
          map((prods) => prods.filter(prod => prod.parentAssemblyId === productId)),
          shareReplay(1)
        );
    return this.productChildrenObservableMap[productId];
  }

  /*
  * OSP handling
  */
  public newOPSpecifications$: Observable<OutsideProcessSpecification[]>;
  private setupNewOPSpecificationsObservable() {
    return this.autosaver.changesSubject.pipe(
      map<Change[], OutsideProcessSpecification[]>(changes => changes.filter(ch => ch.entity === 'OutsideProcessSpecification' && ch.changeType === 'CREATE').map((ch: CreateChange) => ch.data.value as OutsideProcessSpecification))
    )
  }

  private burnInOPSpec(change: CreateChange) {
    if (change.entity !== "OutsideProcessSpecification") return console.error('Attempted to burn in OSP with a non-OSP change!');
    const osp = change.data.value as OutsideProcessSpecification;
    this.stationService.stationList = this.stationService.stationList.map(station => ({
      ...station,
      outsideProcessSpecifications: osp.stationId === station.stationId ? [...station.outsideProcessSpecifications, osp] : station.outsideProcessSpecifications
    }));
  }

  /*
  * Quote data handling
  */

  public quotesByItemObservableMap: { [itemId: string]: Observable<MaterialBid[]> } = {};
  public getItemQuotesObservable(itemId: string, isNewMaterial = false): Observable<MaterialBid[]> {
    if (this.quotesByItemObservableMap[itemId]) return this.quotesByItemObservableMap[itemId];
    const newObservable = this.autosaver.getPostChanges('quoteMap').pipe(
      map(all => {
        return all[itemId];
      }),
      filter(i => !!i),
      shareReplay(1)
    );
    this.quotesByItemObservableMap[itemId] = newObservable;

    fetchLatest(this.autosaver.getPostChanges('quoteMap')).then(all => {
      // If we haven't seen this item before, go fetch it from the API
      if (!all[itemId]) {
        this.quoteService.getAllQuotesForItem(itemId).pipe(withLatestFrom(this.autosaver.getPreChanges('quoteMap'))).subscribe(([results, all]) => {
          if (all[itemId]) all[itemId].push(...results);
          else all[itemId] = results;
          this.autosaver.resetDomain('quoteMap', all);
        });
      }
    });

    return newObservable;
  }

  public getQuoteObservable(quoteId: string): Observable<MaterialBid> {
    return this.autosaver.getPostChanges('quoteMap').pipe(
      map(all => {
        return Object.values(all).flat().find(q => q.materialBidId === quoteId);
      }),
      filter(i => !!i),
      shareReplay(1)
    );
  }

  public addQuote(quote: MaterialBid) {
    const change: CreateChange = {
      changeType: 'CREATE',
      entity: 'MaterialBid',
      data: {
        itemId: quote.materialBidId,
        value: quote,
      },
    };
    const fileChanges = (quote.materialBidDocuments ?? []).map<AddMaterialBidDocumentChange>(mbd => ({
      changeType: 'ADD_MATERIAL_BID_DOCUMENT',
      entity: null,
      data: {
        materialBidId: quote.materialBidId,
        documentId: mbd.document.documentId,
        documentData: mbd.document,
      },
    }));
    this.autosaver.recordChanges(change, ...fileChanges);
  }

  async quoteSearchCallback(itemId: string, quoteId: string) {
    return (
      await fetchLatest(this.getItemQuotesObservable(itemId))
    ).find(q => q.materialBidId === quoteId);
  }

  /*
  * Lead Time handling
  */

  public async resetProductLeadTime(product: Product): Promise<void> {
    // If this is a child, don't update it. The parent should be updating at the same time,
    // which will update the child along with it.
    if (product.parentAssemblyId)
      return;

    // For top-level products, remove existing data and calculate times for the whole tree again
    await this.productTimingService.updateDataForProduct(product, this.getFirstQuantity(product));
  }

  public getProductLeadTime(product: Product): number {
    let qty = null;

    // Top-level product, get the quantity ourselves
    // Otherwise, send null to get previously calculated amount for children
    if (!product.parentAssemblyId)
      qty = this.getFirstQuantity(product);

    const timingData = this.productTimingService.getDataForProduct(product, qty);

    return timingData?.totalDays ?? 0;
  }

  public getProductLeadTimeDetail(product: Product): TimingData[] {
    return this.productTimingService.getAllDataForProduct(product);
  }

  /*
  * Pricing calculation
  */

  private getProductPrice(product: Product, qty: number, allProducts: Product[], parentQty: number = null): number {
    if (parentQty) qty = qty * parentQty;
    let totalPrice = 0;
    let material = product.material;
    if (material && product.selectedMaterialQuote) {
      const materialCost = Product.getMaterialCost(product, qty, material);
      totalPrice += materialCost;
    }
    const stepCostList = product.workflow.workflowSteps.filter(s => !s.isStandalone).sort((a, b) => a.stepOrder - b.stepOrder).map(s => WorkflowStep.calculateCostForQty(s, qty));
    const stepCost = stepCostList.reduce((acc, x) => acc + x, 0);
    totalPrice += stepCost;
    let purchasedItemPrice = 0;
    for (const item of product.purchasedItems ?? []) {
      let itemPrice = item.costPer ?? 0;
      itemPrice = itemPrice * item.quantity;
      if (!item.isNonRecurring) itemPrice = itemPrice * qty;
      purchasedItemPrice += itemPrice * (1 + ((item.markupPercent ?? 0.0) / 100.0));
    }
    totalPrice += purchasedItemPrice;
    // children
    const children = allProducts.filter(p => p.parentAssemblyId === product.productId);
    const childCosts = children.map(c => this.getProductPrice(c, c.quantityAsChild, allProducts, qty));
    totalPrice += childCosts.reduce((acc, x) => acc + x, 0);
    return totalPrice;
  }

  private focusNameInput(name: string) {
    setTimeout(() => {
      (document.querySelector(`[name="${name}"]`) as HTMLElement)
        .focus()
    }, 50);
  }

  private handleOrderValidationError(order: Order, error: OrderValidationError): boolean {
    const issues = error.issues;
    if (issues.length === 0) return true;

    // we only care about showing the user the first issue
    const issue = issues[0];

    this.mainTab = 0;
    this.setSelectedProductId(null);
    // path should always match a field in the order form
    this.utilSvc.showMessage(`Error in quote data`, issue.message).then(() => {
      this.focusNameInput(issue.path.join('.'));
    });
    return false;
  }

  private handleProductValidationError(order: Order, product: Product, error: ProductValidationError) {
    const issues = error.issues.filter(i => {
      // we don't care about filling out child quantities in an rfq, that's estimating's job
      if (order.discriminator === 'RFQ' && i.path[0] === 'quantityAsChild') return false;
      else return true;
    });
    // bail and cancel erroring if we don't have any errors left after that
    if (issues.length === 0) return true;

    // we only care about showing the user the first issue
    const issue = issues[0];
    const { path } = issue;

    let focusName: string;

    // Handle navigation
    if (path[0] === 'quantitiesMap') {
      if (path.length === 1) {
        // If the path ends there, it's an issue with the quantity array rather than an individual
        this.mainTab = 0;
        if (order.discriminator === 'RFQ') {
          this.setSelectedProductId(null);
          focusName = `orderProducts['${product.productId}'].quantitiesMap`;
        } else {
          this.setSelectedProductId(product.productId);
          if (this.productHierarchy) this.productHierarchy.expandAll();
          focusName = "quantitiesMap";
        }
      } else {
        // Otherwise, go to the quantities tab map
        this.mainTab = 2;
        this.setSelectedProductId(null);
        this.selectedQuantityTableProductIdSubject.next(product.productId);
        const quantityIndex = path[1] as number;
        focusName = `orderProducts['${product.productId}].quantitiesMap[${quantityIndex}].markup`;
      }
    }
    // default case
    else {
      this.mainTab = 0;
      this.setSelectedProductId(product.productId);
      if (this.productHierarchy) this.productHierarchy.expandAll();
      focusName = path[0] as string;
    }
    this.utilSvc.showMessage(`Error in ${product.partNumber} Rev. ${product.revision}`, issue.message).then(() => {
      if (focusName) this.focusNameInput(focusName);
    });
    return false;
  }

  public async validate(): Promise<boolean> {
    const order = await fetchLatest(this.autosaver.getPostChanges('order'));
    const orderError = validateOrder(order);
    if (orderError) {
      return this.handleOrderValidationError(order, orderError);
    }
    const all = await fetchLatest(this.allProducts$);
    for (const product of all) {
      const productError = validateProduct(product);
      if (productError) {
        return this.handleProductValidationError(order, product, productError);
      }
    }
    return true;
  }


  public async initLeadTimes() {
    this.productTimingService.resetData();
    this.productTimingService.setCallback(this.quoteSearchCallback.bind(this));
    const order = await fetchLatest(this.autosaver.getPostChanges('order').pipe(filter(o => !!o)));
    const allProducts = await fetchLatest(this.allProducts$);
    const products = order.products.map(osp => allProducts.find(p => p.productId === osp.productProductId));
    for (const product of products) {
      await this.productTimingService.updateDataForProduct(product, this.getFirstQuantity(product));
    }
  }

  public async initStartingQuotes(order: Order) {
    this.quoteService.getAllQuotesForOrder(order).subscribe(x => this.autosaver.resetDomain('quoteMap', x));
  }

  public selectedProduct$: Observable<Product>;

  constructor(
    private documentService: DocumentService,
    private quoteService: MaterialBidService,
    public stationService: StationService,
    private utilSvc: UtilityService,
    private location: Location,
    private asyncPipe: AsyncPipe,
    private orderService: OrderService,
    private userSvc: UserService,
    private productTimingService: ProductTimingService,
    private messageService: MessageService,
    private navService: NavigationService,
  ) {
    this.setupAutosaver();


    this.selectedProduct$ = this.selectedProductIdSubject.pipe(
      filter(id => !!id),
      switchMap(id => this.getProductObservable(id)),
      shareReplay(1)
    );

    this.allProducts$ = this.setupAllProducts();
    this.newOPSpecifications$ = this.setupNewOPSpecificationsObservable();
    this.estimateIsApprovable = this.setupEstimateIsApprovable();
  }


  public leadTimeObservables: { [key: string]: Observable<number> } = {}
  public getProductLeadTimeObservable(productId: string): Observable<number> {
    if (!this.leadTimeObservables[productId])
      this.leadTimeObservables[productId] = this.getProductObservable(productId)
        .pipe(
          switchMap(async pr => {
            await this.resetProductLeadTime(pr);
            return this.getProductLeadTime(pr);
          }),
          shareReplay(1)
        );
    return this.leadTimeObservables[productId];
  }

  public leadTimeDetailObservables: { [key: string]: Observable<TimingData[]> } = {}
  public getProductLeadTimeDetailObservable(productId: string): Observable<TimingData[]> {
    if (!this.leadTimeDetailObservables[productId])
      this.leadTimeDetailObservables[productId] = this.getProductObservable(productId)
        .pipe(
          switchMap(async pr => {
            await this.resetProductLeadTime(pr);
            return this.getProductLeadTimeDetail(pr);
          }),
          shareReplay(1)
        );
    return this.leadTimeDetailObservables[productId];
  }

  public productPriceObservables: { [key: string]: { [key: number]: Observable<number> } } = {}
  public getProductPriceObservable(product: Product, qty: number): Observable<number> {
    let qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap) this.productPriceObservables[product.productId] = {};
    qtyMap = this.productPriceObservables[product.productId];
    if (!qtyMap[product.productId]) qtyMap[qty] = this.autosaver.getPostChanges('productMap').pipe(
      map(productMap => [productMap, productMap[product.productId]] as const),
      map(([productsMap, p]) => {
        if (!p) return 0;
        return this.getProductPrice(p, qty, Object.values(productsMap));
      }),
      shareReplay(1)
    );
    return qtyMap[qty];
  }

  public productUnitPriceObservables: { [key: string]: Observable<number> } = {}
  public getProductUnitPriceObservable(productId: string, qty: number = null): Observable<number> {
    if (!this.productUnitPriceObservables[productId])
      this.productUnitPriceObservables[productId] = this.autosaver.getPostChanges('productMap').pipe(
        map(productMap => [productMap, productMap[productId]] as const),
        filter(([_, pr]) => !!pr),
        map(([productsMap, pr]) => {
          const qty = this.getFirstQuantity(pr);
          const markup = (pr.quantitiesMap ?? []).find(q => q.value === qty)?.markup ?? 0;
          const total = this.getProductPrice(pr, qty, Object.values(productsMap)) * (1 + markup / 100);
          return parseFloat((total / qty).toFixed(2));
        }),
        shareReplay(1)
      );
    return this.productUnitPriceObservables[productId];
  }

  public generateProgrammingPrice(product: Product) {
    return product.workflow.workflowSteps.filter(s => s.hasProgramming).map(s => WorkflowStep.getProgrammingCost(s)).reduce((acc, x) => acc + x, 0);
  }

  public async generateLineItems(): Promise<QuoteLineItem[]> {
    if (!this.stationService.loaded) {
      await this.stationService.stationsLoaded.toPromise();
    }
    const order = await fetchLatest(this.autosaver.getPostChanges("order"));
    const allProducts = await fetchLatest(this.allProducts$);
    const topLevelProducts = await Promise.all(order.products.map(osp => fetchLatest(this.getProductObservable(osp.productProductId))));
    const output = await Promise.all(topLevelProducts.map(async (product, i) => {
      // All quantities
      const leadTimeDays = this.getProductLeadTime(product);
      let output: QuoteLineItem[] = [];
      const quantityItems = await Promise.all(product.quantitiesMap.map(async (qty) => {
        let cost = this.getProductPrice(product, qty.value, allProducts);
        const price = cost * (1 + qty.markup / 100);
        const unitPrice = parseFloat((price / qty.value).toFixed(2));
        let extPrice = unitPrice * qty.value;
        return <QuoteLineItem>{
          lineItemNumber: (i + 1).toString(),
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: null,
          station: null,
          quantity: qty.value,
          unitPrice,
          extPrice,
          leadTimeDays,
        };
      }));
      output = output.concat(quantityItems);
      const standaloneStepItems = product.workflow.workflowSteps.filter((s) => s.isStandalone).map(step => {
        const qty = this.getFirstQuantity(product);
        let stepPrice = WorkflowStep.calculateCostForQty(step, qty);
        return <QuoteLineItem>{
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: step.stationId,
          station: this.getStation(step),
          quantity: step.runIsPerPart ? qty : 1,
          unitPrice: step.runIsPerPart ? (stepPrice / qty) : stepPrice,
          extPrice: stepPrice,
          leadTimeDays,
        };
      });
      output = output.concat(standaloneStepItems);
      const programmingCost = this.generateProgrammingPrice(product);
      if (programmingCost > 0) {
        const progStation = this.stationService.stationList.find((s) => s.name === 'Programming');
        output.push({
          quoteLineItemId: UtilityService.emptyGuid,
          quoteId: UtilityService.emptyGuid,
          productId: product.productId,
          product: product,
          stationId: progStation.stationId,
          station: progStation,
          quantity: 1,
          unitPrice: programmingCost,
          extPrice: programmingCost,
          leadTimeDays,
        })
      }
      return output;
    }));
    return output.flat();
  }

  // Code for live updating estimating quote view
  public quotePreviewWindow: Window | null;
  public quotePreviewLoading = false;
  private quotePreviewSubscription: Subscription;
  private quotePreviewWindowInterval: any = null;

  public async updateQuotePreview() {
    this.quotePreviewLoading = true;
    const lineItems = await this.generateLineItems();
    const order = await fetchLatest(this.autosaver.getPostChanges('order'));
    const res = await fetch(`/api/orderSegment/getQuoteReportClientside/${order.orderSegmentId}`, {
      method: 'POST',
      body: JSON.stringify(lineItems),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const buffer = await res.arrayBuffer();
    const file = new Blob([buffer], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const w = this.quotePreviewWindow;
    this.quotePreviewLoading = false;
    w.open(fileURL, '_self');
    setTimeout(() => w.document.title = `Quote Preview - ${order.orderSegmentId}`, 50);
  }

  private onPreviewWindowUnload() {
    if (this.quotePreviewWindow.closed) {
      this.quotePreviewWindow = null;
      this.quotePreviewLoading = false;
      clearInterval(this.quotePreviewWindowInterval);
      this.quotePreviewWindowInterval = null;
      if (this.quotePreviewSubscription) this.quotePreviewSubscription.unsubscribe();
    }
  }

  public closeQuotePreviewWindow() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.close();
    }
  }

  public startQuotePreview() {
    if (this.quotePreviewWindow) {
      this.quotePreviewWindow.focus();
      return;
    };
    this.quotePreviewWindow = window.open('about:blank', '_blank', 'menubar=no,status=no,toolbar=no');
    // only reliable way to check if window was closed seems to be constant interval
    this.quotePreviewWindowInterval = setInterval(() => {
      if (this.quotePreviewWindow.closed) this.onPreviewWindowUnload()
    }, 500)
    // this.quotePreviewWindow.addEventListener('beforeunload', this.onPreviewWindowUnload.bind(this));
    this.updateQuotePreview();
    this.quotePreviewSubscription = this.autosaver.changesSubject
      .pipe(
        debounceTime(1000)
      )
      .subscribe(() => {
        this.updateQuotePreview();
      });
    window.addEventListener('beforeunload', this.closeQuotePreviewWindow.bind(this));
    return this.quotePreviewWindow;
  }

  public userIsManager(): boolean {
    return this.userSvc.canAccess('QuoteManager');
  }

  public canApproveRFQ(order: Order) {
    return order && order.discriminator === 'RFQ' && order.status === OrderStatus.AWAITING_REVIEW && this.userSvc.canAccess("RFQReviewer");
  }

  public getIncompleteAssemblyInfo(product: Product, order: Order) {
    const top = order.products.find(p => p.productProductId === product.topParentAssemblyId);
    if (!top) return null;
    if (top.reviewStatus !== 0 && top.reviewStatus !== 1 && top.reviewStatus !== 3) return null;
    else return { note: top.reviewStatusNote, status: top.reviewStatus };
  }

  public async markAsCorrected(product: Product) {
    const order = await fetchLatest(this.autosaver.getPostChanges('order'));
    const top = order.products.find(p => p.productProductId === product.topParentAssemblyId);
    const change: ReviewOrderProductChange = {
        changeType: 'REVIEW_ORDER_PRODUCT',
        entity: null,
        data: {
          productId: top.productProductId,
          reviewStatus: 3,
          reviewStatusNote: top.reviewStatusNote,
        }
      }
    this.recordChanges(change);
  }

  public getTotalQuantity(allProducts: Product[], productId: string, topLevelQuantity: number) {
    const product = allProducts.find(p => p.productId === productId);
    if (!product) return 0;
    let currentParent = allProducts.find(p => p.productId === product.parentAssemblyId);
    let totalQuantity = topLevelQuantity;
    if (!currentParent) return totalQuantity;

    totalQuantity *= product.quantityAsChild;

    // NOTE: this will multiply by all parent quantities, but exclude its own
    while (currentParent?.parentAssemblyId !== null) {
      totalQuantity *= currentParent.quantityAsChild ?? 0;
      currentParent = allProducts.find(p => p.productId === currentParent.parentAssemblyId);
    }

    return totalQuantity;
  }

  public getPriceBreakdown(allProducts: Product[], productId: string, quantity: number) {

    let product = allProducts.find(p => p.productId === productId);
    const totalQuantity = this.getTotalQuantity(allProducts, productId, quantity);

    const material = Product.getMaterialBreakdown(product, totalQuantity, product.material);

    let purchasedItems: PricingBreakdownItem[] = null;
    if (product.purchasedItems?.length > 0) {
      purchasedItems = [];
      for (const item of product.purchasedItems) {
        purchasedItems.push(ProductPurchasedItem.getBreakdown(item, totalQuantity));
      }
    }

    const outsourcingSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && s.outsourceMarkup).sort((a, b) => a.stepOrder - b.stepOrder);
    let process: PricingBreakdownItem[] = null;
    if (outsourcingSteps.length > 0) {
      process = [];
      for (const step of outsourcingSteps) {
        const name = this.getStation(step).name;
        process.push(WorkflowStep.getOutsideProcessBreakdown(step, totalQuantity, name));
      }
    }

    const laborSteps = product.workflow.workflowSteps.filter(s => !s.isStandalone && !s.outsourceMarkup).sort((a, b) => a.stepOrder - b.stepOrder);
    let labor: PricingBreakdownItem[] = null;
    if (laborSteps.length > 0) {
      labor = [];
      for (const step of laborSteps) {
        const name = this.getStation(step).name;
        if (step.paint)
          labor.push(WorkflowStep.getPaintStepBreakdown(step, totalQuantity, name));
        else
          labor.push(...WorkflowStep.getLaborStepBreakdown(step, totalQuantity, name));
      }
    }

    return <PricingBreakdown>{
      material,
      purchasedItems,
      process,
      labor,
    }
  }

  public generateSubItemNavigationId(productId: string, type: 'product' | 'material' | 'workflow' | 'hardware', subItemId?: string) {
    if (type === 'product') {
      return `${productId}`;
    } else if (type === 'material') {
      return `${productId}--material`;
    } else if (type === 'workflow') {
      if (!subItemId) return `${productId}--workflow`;
      return `${productId}--workflow--${subItemId}`;
    } else if (type === 'hardware') {
      if (!subItemId) return `${productId}--hardware`;
      return `${productId}--hardware--${subItemId}`;
    }
  }

  public focusedMicroticketId: string = null;
  public focusedPurchasedItemId: string = null;
  public navigateToSubItem(navigationString: string) {
    const [left, right, subItemId] = navigationString.split('--');
    if (left === 'microticket') {
      const microticketId = right;
      if (!microticketId) console.warn('Attempted to navigate to microticket with invalid ID')
      else {
        // Microtickets tab
        this.focusedMicroticketId = microticketId;
        this.mainTab = 5;
        this.setSelectedProductId(null);
      }
      return;
    }
    const productId = left;
    if (!productId) {
      console.warn(`Invalid navigation string ${navigationString}`);
      return;
    }
    this.mainTab = 0;
    this.setSelectedProductId(productId);
    if (!right) this.productTab = 0;
    else if (right === 'material') this.productTab = 2;
    else if (right === 'workflow') {
      this.selectedWorkflowStepIdSubject.next(subItemId ?? null);
      this.productTab = 3;
    } else if (right === 'hardware') {
      this.selectedPurchasedItemIdSubject.next(subItemId ?? null);
      this.productTab = 4;
    }
  }

  public getNameForMicrotickets(order: Order): string {
    if (!order) return '';
    else if (order.discriminator === 'RFQ' || order.discriminator === 'Quote') return order.orderNumber;
    else return order.quoteNumber ?? order.orderNumber;
  }

  public getMicroticketFilterKey(order: Order): string {
    if (order.discriminator === 'RFQ' && order.status === 1) return 'Estimating';
    if (order.discriminator === 'RFQ' || order.discriminator === 'Quote') return 'Sales';
    if (order.discriminator === 'Estimate' || order.discriminator === 'RMAEstimate') return 'Estimating';
    else return 'Other';
  }

  public sharedMicroticketSubject = new ReplaySubject<MicroTicket[]>(1);
  public mainTabBodyElement: HTMLDivElement;
  private lastMainTabScroll: number;

  public partListPageIndex = 0;

  //
  private soloClose: EventEmitter<{ product: Product, quotes: { [key: string]: MaterialBid[] } }>;
  public initSolo(product: Product, order: Order, soloClose: typeof this.soloClose) {
    this.solo = true;
    this.soloClose = soloClose;
    order = JSON.parse(JSON.stringify(order));
    order.products = [{
      orderSegmentOrderSegmentId: order.orderSegmentId,
      productProductId: product.productId,
      reviewStatus: OrderSegmentProductReviewStatus.Complete,
      reviewStatusNote: '',
      product,
    }]
    this.autosaver.resetDomain('order', order);
    this.autosaver.resetDomain('productMap', { [product.productId]: product });
    this.setSelectedProductId(product.productId);
    this.editing = true;
    this.initStartingQuotes(order);
  }
  public solo: boolean = false;

  public canApproveEstimate() {
    return this.userSvc.canAccess('EstimateManager');
  }

  public estimateIsApprovable: Observable<boolean>;
  public setupEstimateIsApprovable() {
    return combineLatest([this.autosaver.getPostChanges('productMap'), this.autosaver.getPostChanges('order')]).pipe(
      filter(([productMap, order]) => !!productMap && !!order),
      map(([productMap, order]) => {
        const allProductIds = Object.keys(productMap);
        return allProductIds.every(id => {
          const answers = order.estimateReviewAnswers?.[id]?.['dca3e5ce-6c5f-4b0d-aeff-901ada3cc92e'];
          if (!answers) return false;
          const answerValues = Object.values(answers);
          return answerValues.length === 7 && answerValues.every(v => v !== undefined && v !== null);
        });
      })
    )
  }

  public sidenav: MatSidenav;
  public sidenavMode = new BehaviorSubject<SidenavMode | null>(null);
  public sidenavData = new BehaviorSubject<any | null>(null);
  public openSidenav(mode: SidenavMode, data: any | null) {
    if (mode === 'quoteDetail' && this.editing) {
      // Clone the quote object so that the quote-detail component can keep its own state without ruining our immutability
      let { itemId, quote }: { itemId: string, quote: MaterialBid } = data;
      quote = structuredClone(quote);
      data = { itemId, quote };
    }
    this.sidenavData.next(data);
    this.sidenavMode.next(mode);
    this.sidenav.open();
  }

  public async resetSidenav() {
    const [mode, data] = await fetchLatest(combineLatest([this.sidenavMode, this.sidenavData]));
    if (mode === 'quoteDetail' && this.editing) {
      const { itemId, quote }: { itemId: string, quote: MaterialBid } = data;
      // this.editQuote(itemId, JSON.parse(JSON.stringify(quote)));
    }
    this.sidenavMode.next(null);
    this.sidenavData.next(null);
  }

  private PRODUCT_CREATION_CHANGE_TYPES: ChangeType[] = ['CLONE_PRODUCT_TOP_LEVEL', 'CLONE_PRODUCT_SUB_LEVEL', 'CREATE_BLANK_TOP_LEVEL_PRODUCT', 'CREATE_BLANK_SUB_LEVEL_PRODUCT'];
  private async handleErrors(failResults: ChangeResult[], changes: RecordedChange[]) {
    const failedChanges = failResults
      .map(r => changes.find(c => c.changeId === r.changeId))
      ;
    // If any creation changes failed, we need to deselect those items to avoid errors in the UI
    const failedProductIds = failedChanges
      .filter(c => this.PRODUCT_CREATION_CHANGE_TYPES.includes(c.changeType))
      .map((c) => (c as { data: { productId: string } }).data.productId)
      ;
    const selectedProductId = await fetchLatest(this.selectedProductIdSubject);
    if (failedProductIds.includes(selectedProductId)) this.selectedProductIdSubject.next(null);

    const failedCreateEntityIds = failedChanges
      .filter(c => c.changeType === 'CREATE')
      .map((c) => (c as CreateChange).data.itemId)
      ;
    const selectedWorkflowStepId = await fetchLatest(this.selectedWorkflowStepIdSubject);
    if (failedCreateEntityIds.includes(selectedWorkflowStepId)) this.selectedWorkflowStepIdSubject.next(null);
    const selectedPurchasedItemId = await fetchLatest(this.selectedPurchasedItemIdSubject);
    if (failedCreateEntityIds.includes(selectedPurchasedItemId)) this.selectedPurchasedItemIdSubject.next(null);
		
    const message = this.messageService.add(
			"There was an error while autosaving. Some changes were reverted.",
			MessageType.ERROR,
		);
		message.messagePayload = { error: JSON.stringify(failResults, null, 2) };
  }

  public setPageTitle(record: Order): void {
    if (this.solo) return;
    this.navService.setPageTitle(record.discriminator + " Detail");
    this.navService.pushBreadcrumb(
      record.orderNumber || "New " + record.discriminator
    );
  }

  private DEBOUNCE_TIME = 500;
  private DEBOUNCE_MAX_WAIT = 5_000;

  private debounceOverride = new Subject<void>();
  public lastSaveSuccessful = true;
  private saving = new BehaviorSubject(false);
  public untilSaved() {
    // Fires either if not currently saving, or if we are, when it finishes
    return this.saving.pipe(filter(s => s === false), take(1)).toPromise();
  }
  public untilNextSave() {
    // Does not fire until a save starts and then finishes
    return concat(this.saving.pipe(filter(s => s === true), take(1)), this.saving.pipe(filter(s => s === false), take(1))).pipe(skip(1)).toPromise();
  }
  private maxDebounceWaitTimerSubscription: Subscription;
  private resetMaxDebounceWaitTimer() {
    // console.log('resetting max wait timer');
    if (this.maxDebounceWaitTimerSubscription) this.maxDebounceWaitTimerSubscription.unsubscribe();
    this.maxDebounceWaitTimerSubscription = interval(this.DEBOUNCE_MAX_WAIT).pipe(take(1)).subscribe(() => {
      // console.log('Overriding debounce due to timeout!');
      this.debounceOverride.next();
    });
  }

  public id: string;

  public productHierarchy: ProductHierarchyNewComponent;

  public getAllDescendants(productId: string, allProducts: Product[]) {
    const output: Product[] = [];
    let childrenToAdd: Product[] = allProducts.filter(y => y.parentAssemblyId === productId);
    do {
      output.push(...childrenToAdd);
      childrenToAdd = childrenToAdd.flatMap(x => allProducts.filter(y => y.parentAssemblyId === x.productId));
    } while (childrenToAdd.length > 0);
    return output;
  }

  public async getAllDescendantsObservable(productId: string) {
    return this.allProducts$.pipe(
      map(allProducts => {
        return this.getAllDescendants(productId, allProducts);
      }),
    )
  }

  public getAllIncompleteSimpleTasksByProduct(products: Product[]): { productName: string, missing: string, productId: string }[] {
    return products.map(pr => {
      let missing = [];
      if (!EstimatingTaskStatus.countsAsDone(pr.materialStatus)) missing.push('Material');
      if (!EstimatingTaskStatus.countsAsDone(pr.workflowStatus)) missing.push('Workflow');
      if (!EstimatingTaskStatus.countsAsDone(pr.hardwareStatus)) missing.push('Hardware');
      if (missing.length > 0) {
        return {
          productName: `${pr.partNumber} Rev. ${pr.revision}`,
          missing: missing.join(','),
          productId: pr.productId
        }
      } else return null;
    }).filter(x => x !== null);
  }

  public shouldShowIndicators(order: Order) {
    return (order.discriminator === 'Estimate' || order.discriminator === 'RMAEstimate') ||
      (order.discriminator === 'RFQ' && order.status === 1);
  }

  public getEstimatingTaskStatus(product: Product, type: 'material' | 'workflow' | 'hardware') {
    let field: keyof Product;
    switch (type) {
      case 'material':
        field = 'materialStatus';
        break;
      case 'workflow':
        field = 'workflowStatus';
        break;
      case 'hardware':
        field = 'hardwareStatus';
        break;
    }
    return product[field];
  }

  public async setEstimatingTaskStatus(productId: string, type: 'material' | 'workflow' | 'hardware', status: EstimatingTaskStatus) {
    let field: keyof Product;
    switch (type) {
      case 'material':
        field = 'materialStatus';
        break;
      case 'workflow':
        field = 'workflowStatus';
        break;
      case 'hardware':
        field = 'hardwareStatus';
        break;
    }
    const product = await fetchLatest(this.getProductObservable(productId));
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: productId,
        field,
        oldValue: product[field],
        newValue: status,
      },
    };
    this.autosaver.recordChanges(change);
  }

  public selectedQuantityTableProductIdSubject = new BehaviorSubject<string>(null);

}