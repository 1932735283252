import { Component, EventEmitter, Inject, Injector, OnChanges, Output } from "@angular/core";
import { GenericSearchComponent } from "../generic-search/generic-search.component";
import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, SimpleChanges } from "@angular/core";
import { Input } from "@angular/core";
import { WorkOrder } from "../../../planning/resources/work-order";
import { WorkOrderService } from "../../../planning/services/work-order.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'work-order-search',
  templateUrl: '../../../common/components/generic-search/generic-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderSearchComponent extends GenericSearchComponent<WorkOrder> implements OnChanges {


  @Input() declare label: string;
  @Input() selectedItem: WorkOrder | null = null;
  @Output() selectedItemChange = new EventEmitter<WorkOrder | null>();

  public get placeholder() {
    if (this.label) return this.label;
    else return 'Work Order Number';
  }

  constructor(private workOrderSvc: WorkOrderService, @Inject(Injector) injector: Injector) {
    super(injector);
  }

  doSearch(searchText: string) {
    return this.workOrderSvc.search(true, searchText, 0, null, null).pipe(
      map(r => r.results)
    )
  }

  public canAdd(): boolean { return false }

  public get addItemText(): string {
     return '';
  }

  public get noItemsText(): string {
    return 'No matching work orders found.';
  }

  public getSearchField(c: WorkOrder): string {
    return c && c.workOrderNumber;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) {
      const change = changes.selectedItem;
      if (change.firstChange) return;
      this.searchValue = this.getSearchField(change.currentValue);
    }
  }

}