<mat-sidenav-container class="drawer-container">
  <mat-sidenav mode="over" position="end" #insetnav style="width: 80%; padding-top: 2em; padding-right: 4em;">
    <quote-detail #newQuoteDetail [forceVendor]="newQuoteData?.vendor" [data]="newQuoteData" *ngIf="sidenav=='newQuote'" [sidenav]="insetnav"
      (approved)="newQuoteApproved.next($event)"
      (cancelled)="newQuoteCancelled.next()"
      [binaryOptions]="true"
    ></quote-detail>
    <quote-detail #viewQuoteDetail [forceVendor]="newQuoteData?.vendor" [data]="newQuoteData" *ngIf="sidenav=='viewQuote'" [sidenav]="insetnav"
      (saved)="newQuoteApproved.next($event)"
      (cancelled)="newQuoteCancelled.next()"
      [noAccept]="true"
    ></quote-detail>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="mat-elevation-z6 rounded overflow-hidden pt-2 position-relative d-flex flex-column" style="height: 90vh; position: relative;">
      <mat-progress-bar class="w-100 progress-bar" style="position: absolute; top: 0; z-index: 999;"
        [class.invisible]="record" mode="indeterminate">
      </mat-progress-bar>
      <div class="row px-3 py-2 mb-3" style="width: 75%" *ngIf="record">
        <div class="col-10 mb-2">
          <mat-form-field class="w-100 mat-input-no-message form-field-chip-size form-field-chip-text"
            floatLabel="always">
            <mat-label>Item</mat-label>
            <mat-chip-list #chipList [tabIndex]="-1">
              <item-type-chip matPrefix class="mr-2" [override]="'MATERIAL'" *ngIf="record.materialId"></item-type-chip>
              <item-type-chip matPrefix class="mr-2" [override]="'PROCESS'"
                *ngIf="record.outsideProcessDescriptionId"></item-type-chip>
              <item-type-chip matPrefix class="mr-2" [override]="'PURCHASED'"
                *ngIf="record.purchasedItemId"></item-type-chip>
              <input tabindex="-1" matInput [matChipInputFor]="chipList" type="text" readonly [value]="getItemName()">
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field class="w-100 mat-input-no-message">
            <mat-label>Quantity Requested</mat-label>
            <input matInput type="number" class="text-right" readonly [value]="record.quantityRequested">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="w-100 mat-input-no-message">
            <mat-label>Source Ticket</mat-label>
            <input matInput type="text" readonly [value]="getSourceTicketName()">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="w-100 mat-input-no-message">
            <mat-label>Requested By</mat-label>
            <input matInput type="text" readonly [value]="record.requestedBy?.fullName ?? 'Unknown User'">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="w-100 mat-input-no-message">
            <mat-label>Date Requested</mat-label>
            <input matInput type="text" readonly [value]="record.dateRequested ? (record.dateRequested | date: 'YYYY/MM/dd') : null" >
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="w-100 mat-input-no-message">
            <mat-label>Due Date</mat-label>
            <input matInput type="text" readonly
              [value]="record.dueDate ? (record.dueDate | date: 'YYYY/MM/dd') : null">
          </mat-form-field>
        </div>
      </div>
      <mat-divider class="w-100"></mat-divider>
      <mat-progress-bar class="w-100 progress-bar" [class.invisible]="!record || !loadingVendors" mode="indeterminate">
      </mat-progress-bar>
      <div class="px-3">
        <mat-form-field class="w-100 dense-field mat-input-no-message">
          <mat-icon matPrefix class="text-muted">search</mat-icon>
          <input matInput type="text" [formControl]="searchControl">
        </mat-form-field>
      </div>
      <div class="w-100 d-block flex-spread" style="overflow-y: scroll">
        <table *ngIf="record" mat-table [trackBy]="trackByFn" #matTable [dataSource]="vendors$" class="w-100" 
        matSort
        (matSortChange)="sortChange.next($event)"
        [matSortDisableClear]="true"
      >
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor">Supplier</th>
          <td mat-cell *matCellDef="let vendor" class="pr-1 vendor-name" style="width: 18%">
            {{ vendor.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quotingStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
          <td mat-cell *matCellDef="let vendor" class="pr-1" style="width: 15%">
            <mat-chip *ngLet="getVendorStatus(vendor) as status" class="status button-text" disableRipple
              [ngClass]="getVendorStatusChipClass(status)">
              {{getVendorStatusText(status)}}
            </mat-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="pricing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="pricing">Pricing</th>
          <td mat-cell *matCellDef="let vendor" class="pl-1">
            <ng-container *ngIf="getVendorStatus(vendor) == 2">
              <div *ngLet="getQuoteForVendor(vendor) as quote" class="text-muted">
                <ng-container *ngIf="quote.totalBid"><b>{{ quote.totalBid | currency }}</b> total</ng-container><ng-container *ngIf="quote.perItemBid"><b>{{ quote.perItemBid | currency }}</b>/item</ng-container> for <b>{{quote.qty}}</b> items
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="leadTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="leadTime">Lead Time</th>
          <td mat-cell *matCellDef="let vendor" class="pl-1">
            <ng-container *ngIf="getVendorStatus(vendor) == 2">
              <div *ngLet="getQuoteForVendor(vendor) as quote" class="text-muted">
                <b>{{ quote.leadTimeDays }}</b> day lead time
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let vendor" class="pr-3">
            <div class="d-flex align-items-center justify-content-end" *ngLet="getVendorStatus(vendor) as status">
              <button mat-stroked-button class="ml-2" (click)="generateRFQ(vendor)">
                <mat-icon matPrefix svgIcon="file-document"></mat-icon>
                Generate RFQ
              </button>
              <!-- Buttons for unsent quotes -->
              <ng-container *ngIf="status === 0">
                <button mat-stroked-button class="ml-2" (click)="markAsSent(vendor)">
                  <mat-icon matPrefix svgIcon="send"></mat-icon>
                  Mark as Sent
                </button>
                <button mat-stroked-button class="ml-2" (click)="manuallyInputPricing(vendor)">
                  <mat-icon matPrefix svgIcon="cursor-text"></mat-icon>
                  Manually Input Pricing
                </button>
              </ng-container>
              <!-- Buttons for quotes awaiting response -->
              <ng-container *ngIf="status === 1">
                <button mat-stroked-button class="ml-2" (click)="markAsReceived(vendor)">
                  <mat-icon matPrefix svgIcon="inbox"></mat-icon>
                  Mark as Received
                </button>
              </ng-container>
              <ng-container *ngIf="status === 2 || status === 3">
                <button mat-stroked-button class="ml-2" (click)="viewEditQuote(vendor)">
                  <mat-icon matPrefix svgIcon="open-in-app"></mat-icon>
                  View/Edit Quote
                </button>
                <button mat-flat-button color="primary" class="ml-2" *ngIf="!record.selectedQuoteId" (click)="acceptQuote(vendor)">
                  <mat-icon matPrefix svgIcon="check"></mat-icon>
                  Accept Quote
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns">

        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0" [class.vendor-loading]="loadingMap[row.vendorId]">
        </tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length"
            class="text-muted small font-italic text-center py-3">
            <span *ngIf="!editing && !loadingVendors">There are no approved vendors for this item. You can add one
              below.</span>
          </td>
        </tr>
      </table>
     <div class="w-100 d-flex align-items-center justify-content-center py-2">
        <button mat-flat-button color="primary" class="button-text" *ngIf="!loadingVendors" (click)="addCapableVendor()">
          <mat-icon matPrefix>add</mat-icon>
          Add Approved Supplier for Item
        </button>
      </div> </div> 
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #findVendorDialogTemplate let-data>
  <h2 mat-dialog-title>All Vendors</h2>
  <div mat-dialog-content>
    <div><vendor-search [hint]="data.hint" [(selectedItem)]="data.vendor"></vendor-search></div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data.vendor" [disabled]="!data.vendor">Select Vendor</button>
  </mat-dialog-actions>
</ng-template>