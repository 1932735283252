import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  concat,
  interval,
  merge,
  Observable,
  OperatorFunction,
  Subject,
  Subscription,
} from "rxjs";
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  scan,
  shareReplay,
  skip,
  skipUntil,
  startWith,
  take,
  tap,
  timestamp,
  withLatestFrom,
} from "rxjs/operators";
import { eqSet } from "../../../../util/eqSet";
import { changeIsRedundant } from "../../order/components/order-detail-new/order-detail-change-tracking";
import { Order } from "../../order/resources/order";
import { OrderService } from "../../order/services/order.service";
import { MessageType } from "../resources/message";
import { UtilityService } from "../services/utility.service";
import {
  Change,
  ChangeResult,
  ChangeType,
  CreateChange,
  RecordedChange,
  ServerChange,
  UpdateChange,
} from "./change";
import { Timestamp } from "rxjs/internal/operators/timestamp";

interface DomainOptions<T> {
  changesObservable: Observable<RecordedChange[]>;
  changeFilter?: (c: RecordedChange) => boolean,
  changeApplier: (base: T, changes: RecordedChange[]) => T;
  resultApplier: (
    base: T,
    results: ChangeResult[],
    changes: RecordedChange[],
  ) => T;
}

export class AutosaverDomain<T> {
  private baseSubject: BehaviorSubject<T | null>;
  private changesObservable: Observable<RecordedChange[]>
  public preChanges$: Observable<T>;
  public postChanges$: Observable<T>;

  private changeFilter: DomainOptions<T>["changeFilter"] = () => true;
  private changeApplier: DomainOptions<T>["changeApplier"];
  private resultApplier: DomainOptions<T>["resultApplier"];
  public staticApplyChanges(data: T, changes: RecordedChange[]) {
    return this.changeApplier(data, changes.filter(this.changeFilter));
  }
  public staticApplyResults(
    data: T,
    results: ChangeResult[],
    changes: RecordedChange[],
  ) {
    const validChanges = changes.filter(this.changeFilter);
    const validChangeIds = validChanges.map(c => c.changeId);
    const validResults = results.filter(r => validChangeIds.includes(r.changeId));
    return this.resultApplier(data, validResults, validChanges);
  }

  public updateIgnored = new Subject<void>();

  constructor(private name: string, options: DomainOptions<T>) {
    this.changesObservable = options.changesObservable;
    if (options.changeFilter) this.changeFilter = options.changeFilter;
    this.changeApplier = options.changeApplier;
    this.resultApplier = options.resultApplier;
    this.baseSubject = new BehaviorSubject(null);
    this.preChanges$ = this.baseSubject.pipe(filter((x) => !!x));
    const filteredChanges$ = this.changesObservable.pipe(
      map(changes => changes.filter(this.changeFilter)),
      distinctUntilChanged((oldChanges, newChanges) =>
        eqSet(
          new Set(oldChanges.map((c) => c.changeId)),
          new Set(newChanges.map((c) => c.changeId)),
        ),
      ),
    );
    this.postChanges$ = combineLatest([
      this.preChanges$.pipe(timestamp()),
      filteredChanges$.pipe(timestamp()),
    ]).pipe(
      scan<
        [Timestamp<T>, Timestamp<RecordedChange[]>],
        { cached: T, result: T, changes: RecordedChange[] }>(
      ({ cached }, [{ value: preChanges, timestamp: preChangesTimestamp }, { value: changes, timestamp: changesTimestamp }]) => {
        if (!cached || preChangesTimestamp > changesTimestamp) {
          cached = structuredClone(preChanges);
        }
        return { cached, result: options.changeApplier(cached, changes), changes };
      }, { cached: null, result: {} as T, changes: [] }),
      map(({ result }) => result),
      filter(r => !!r),
      shareReplay(1),
    );
  }

  public reset(newData: T) {
    this.baseSubject.next(newData);
  }
}

const IMMEDIATE_SAVE_TYPES: ChangeType[] = [
  "CLONE_PRODUCT_TOP_LEVEL",
  "BATCH_IMPORT",
  "CLONE_PRODUCT_SUB_LEVEL",
];

interface AutosaverOptions {
  changeMapper?: (
    existingChanges: RecordedChange[],
    newChanges: Change[],
  ) => { existingChanges: RecordedChange[]; newChanges: Change[] };
}

type AutosaverSaveFunction<T> = (
  changes: ServerChange[],
  data: T,
) => Promise<ChangeResult[]>;
export class Autosaver<T extends object = {}> {
  //@ts-ignore
  private domains: { [I in keyof T]: AutosaverDomain<T[I]> } = {};
  private domainDataUpdater = new Subject<[keyof T, { preChanges: any, postChanges: any }]>();
  private domainPreChangeData$: Observable<T>;
  private domainPostChangeData$: Observable<T>;

  private saveFunction: AutosaverSaveFunction<T>;
  public setSaveFunction(fn: AutosaverSaveFunction<T>) {
    this.saveFunction = fn;
  }

  private changeMapper: AutosaverOptions["changeMapper"] = (
    existingChanges,
    newChanges,
  ) => ({ existingChanges, newChanges });

  constructor(options: AutosaverOptions) {
    if (options.changeMapper) this.changeMapper = options.changeMapper;
    this.domainPreChangeData$ = this.domainDataUpdater.pipe(
      scan((acc, [key, datum]) => {
        acc[key] = datum.preChanges;
        return acc;
      }, {} as T),
    );
    this.domainPostChangeData$ = this.domainDataUpdater.pipe(
      scan((acc, [key, datum]) => {
        acc[key] = datum.postChanges;
        return acc;
      }, {} as T),
    );
    this.setupAutosaveSubscription();
  }

  public withDomain<V, N extends string>(name: Exclude<N, keyof T>, options: Omit<DomainOptions<V>, 'changesObservable'>) {
    type NewT = T & { [key in N]: V };
    const newThis = this as Autosaver<NewT>;
    const newDomain = new AutosaverDomain<V>(name, {...options, changesObservable: this.changesSubject });
    combineLatest([newDomain.preChanges$, newDomain.postChanges$]).subscribe(([preChanges, postChanges]) => {
      newThis.domainDataUpdater.next([name, { preChanges, postChanges }]);
    });
    // @ts-ignore 
    newThis.domains[name] = newDomain;

    return newThis;
  }

  public handleErrors: (
    failResults: ChangeResult[],
    allChanges: RecordedChange[],
  ) => Promise<void> = async () => { };
  public onChangesSaved: (
    successfulChanges: RecordedChange[],
    postChangesData: T,
  ) => void = () => { };

  public changesSubject = new BehaviorSubject<RecordedChange[]>([]);
  public recordChanges(...changes: Change[]) {
    this.changesSubject.pipe(take(1)).subscribe((val) => {
      let newVal = val.slice();
      const { existingChanges, newChanges } = this.changeMapper(
        newVal,
        changes,
      );
      newVal = existingChanges;
      changes = newChanges;
      for (const change of changes) {
        const lastChange = newVal[newVal.length - 1];
        if (lastChange && changeIsRedundant(lastChange, change)) {
          if (
            lastChange.changeType === "UPDATE" &&
            change.changeType === "UPDATE"
          )
            change.data.oldValue = lastChange.data.oldValue;
          newVal.splice(newVal.length - 1, 1);
        }
        const newChange: RecordedChange = {
          ...change,
          changeId: UtilityService.newGuid(),
        };
        newVal = [...newVal, newChange];
      }
      this.changesSubject.next(newVal);
      // We override the debounce and save ASAP if the changes array has somehow gotten really big, or if we just recorded a change that needs an immediate response from the server
      // We also override if we've been debouncing for too long - that's handled elsewhere with a timer
      let shouldOverride =
        newVal.length > 30 ||
        changes.some((c) => IMMEDIATE_SAVE_TYPES.includes(c.changeType));
      if (shouldOverride) {
        this.debounceOverride.next();
      }
    });
  }

  private async doSave(data: T, changes: RecordedChange[]) {
    // Massage changes for server
    const serverChanges: ServerChange[] = changes.map((c) => ({
      ...c,
      data: <any>{
        ...c.data,
        relatedEntityField: undefined,
        newRelatedEntity: undefined,
        oldRelatedEntity: undefined,
      },
    }));
    let results: ChangeResult[];
    try {
      results = await this.saveFunction(serverChanges, data);
    } catch (e) {
      let errorText: string;
      try {
        errorText = e?.error;
        if (!errorText) errorText = JSON.stringify(e);
      } catch (_) {
        errorText = "Could not coerce error text";
      }
      results = serverChanges.map((sc) => ({
        changeId: sc.changeId,
        success: false,
        error: `Unhandled error caused non-200 server response: ${errorText}`,
      }));
    }
    const successResults = results.filter((r) => r.success === true);
    const successfulChanges = changes.filter(
      (change) =>
        successResults.findIndex((r) => r.changeId === change.changeId) !== -1,
    );

    // First, commit successful changes

    let appliedChangesData: Partial<T> = {};
    for (const domainName in data) {
      let domainData = data[domainName];
      // apply changes
      domainData = this.getDomain(
        domainName,
      ).staticApplyChanges(domainData, successfulChanges);
      // then apply server results
      domainData = this.getDomain(
        domainName,
      ).staticApplyResults(domainData, results, successfulChanges);
      appliedChangesData[domainName] = domainData;
    }
    
    const failResults = results.filter((r) => !r.success);
    if (failResults.length > 0) {
      await this.handleErrors(failResults, changes);
      // Blank out the changes array, including anything that hasn't been queued for save yet, as if we had errors we can't guarantee those will go through correctly
      this.changesSubject.next([]);
    } else {
      // Blank out only the changes that we saved, preserving pending ones
      const savedChangeIds = changes.map((c) => c.changeId);
      this.changesSubject.pipe(take(1)).subscribe((newestChanges) => {
        this.changesSubject.next(
          newestChanges.filter((c) => !savedChangeIds.includes(c.changeId)),
        );
      });
    }
    
    setTimeout(() => {
      for (const domainName in appliedChangesData) {
        if (domainName === 'order') {
          const x = appliedChangesData['order'] as Order;
          console.log('resetting', x.publicNotes);
        }
        this.getDomain(domainName).reset(appliedChangesData[domainName]);
      }
    }, 50);
    
    this.onChangesSaved(successfulChanges, appliedChangesData as T);

    setTimeout(() => {
      this.changesCommittedSubject.next();
    });
    return appliedChangesData;
  }

  public changesCommittedSubject = new Subject<void>();

  private DEBOUNCE_TIME = 500;
  private DEBOUNCE_MAX_WAIT = 5_000;
  private debounceOverride = new Subject<void>();

  public lastSaveSuccessful = true;
  private saving = new BehaviorSubject(false);
  public untilSaved() {
    // Fires either if not currently saving, or if we are, when it finishes
    return this.saving
      .pipe(
        filter((s) => s === false),
        take(1),
      )
      .toPromise();
  }
  public untilNextSave() {
    // Does not fire until a save starts and then finishes
    return concat(
      this.saving.pipe(
        filter((s) => s === true),
        take(1),
      ),
      this.saving.pipe(
        filter((s) => s === false),
        take(1),
      ),
    )
      .pipe(skip(1))
      .toPromise();
  }
  private maxDebounceWaitTimerSubscription: Subscription;
  private resetMaxDebounceWaitTimer() {
    if (this.maxDebounceWaitTimerSubscription)
      this.maxDebounceWaitTimerSubscription.unsubscribe();
    this.maxDebounceWaitTimerSubscription = interval(this.DEBOUNCE_MAX_WAIT)
      .pipe(take(1))
      .subscribe(() => {
        this.debounceOverride.next();
      });
  }

  private autosaveSubscription: Subscription;

  public changeFilter: (changes: RecordedChange[], preChangeData: T, postChangeData: T) => boolean = () =>
    true;

  private setupAutosaveSubscription() {
    this.autosaveSubscription = this.changesSubject
      .pipe(
        withLatestFrom(combineLatest([this.domainPreChangeData$, this.domainPostChangeData$])),
        filter(([changes, [preChangeData, postChangeData]]) => this.changeFilter(changes, preChangeData, postChangeData)),
        map(([changes, _]) => changes),
        // No reason to do anything if there aren't actually any changes
        filter((c) => c.length > 0),
        // We ignore the first 500ms of changes as (especially in new orders) some changes are set automatically and we don't want to send them until we get real user input
        skipUntil(interval(500).pipe(take(1))),
        // Complex debounce code
        // Basically, this will never fire until we're done with any existing save operations.
        // Then, the user has to not have recorded any changes for Xms to fire, but if the override is called, we skip that timer
        debounce(() => {
          const doneSaving = this.saving.pipe(
            filter((s) => s === false),
            take(1),
          );
          const debounceTimer = merge(
            interval(this.DEBOUNCE_TIME).pipe(take(1)),
            this.debounceOverride,
          ).pipe(take(1));
          // If we don't already have a max-wait timer running, start one
          if (!this.maxDebounceWaitTimerSubscription)
            this.resetMaxDebounceWaitTimer();
          return concat(doneSaving, debounceTimer).pipe(skip(1));
        }),
        // If we're here that means we got past the debounce and are going to save, so clear out the max-wait timer
        tap(() => {
          if (this.maxDebounceWaitTimerSubscription) {
            this.maxDebounceWaitTimerSubscription.unsubscribe();
            this.maxDebounceWaitTimerSubscription = null;
          }
        }),
        withLatestFrom(this.domainPreChangeData$),
        map(([changes, data]) => [changes, structuredClone(data)] as const)
      )
      .subscribe(([changes, data]) => {
        this.saving.next(true);
        this.doSave(data, changes).then(() => {
          this.saving.next(false);
        });
      });
  }
  private getDomain<N extends keyof T>(name: N) {
    return this.domains[name] as AutosaverDomain<T[N]>;
  }
  public getPreChanges<N extends keyof T>(name: N) {
    return this.getDomain(name).preChanges$;
  }
  public getPostChanges<N extends keyof T>(name: N) {
    return this.getDomain(name).postChanges$;
  }
  public resetDomain<N extends keyof T>(name: N, data: T[N]) {
    this.getDomain(name).reset(data);
  }
  public transformDomain<N extends keyof T>(name: N, transformer: (current: T[N]) => T[N]) {
    this.getPreChanges(name).pipe(take(1)).subscribe(original => { 
      this.resetDomain(name, transformer(original));
    });
  }

  public clearChanges() {
    this.changesSubject.next([]);
  }
}