import { CustomerContact } from "../../customer/resources/customer";
import {
	EstimateReviewAnswersMap,
	EstimatingTaskStatus,
	Order,
	OrderProduct,
	OrderSegmentProductReviewStatus,
} from "../../order/resources/order";
import {
	Product,
	ProductPurchasedItem,
	ProductQuantity,
	ProductMaterialDimension,
} from "../../order/resources/product";
import { PurchasedItem } from "../../order/resources/purchased-item";
import { OutsideProcessSpecification } from "../../order/resources/station";
import { WorkflowStep } from "../../order/resources/workflow";
import { MaterialBid } from "../../purchasing/resources/materialBid";
import { Contact } from "../resources/contact";
import { TaskList } from "../resources/estimatingtask";
import { VirtualDocument } from "../resources/virtual-document";

export function assertUnreachable(x: never): never {
	throw new Error("This shouldn't even have compiled!");
}

type Create = "CREATE";
type Update = "UPDATE";
type Delete = "DELETE";
type AddOrderDocument = "ADD_ORDER_DOCUMENT";
type DeleteOrderDocument = "DELETE_ORDER_DOCUMENT";
type AddProductDocument = "ADD_PRODUCT_DOCUMENT";
type DeleteProductDocument = "DELETE_PRODUCT_DOCUMENT";
type AddMaterialBidDocument = "ADD_MATERIAL_BID_DOCUMENT";
type DeleteMaterialBidDocument = "DELETE_MATERIAL_BID_DOCUMENT";
type ReviewOrderProduct = "REVIEW_ORDER_PRODUCT";
type CreateBlankTopLevelProduct = "CREATE_BLANK_TOP_LEVEL_PRODUCT";
type CloneProductTopLevel = "CLONE_PRODUCT_TOP_LEVEL";
type BatchImport = "BATCH_IMPORT";
type DeleteOrderProduct = "DELETE_ORDER_PRODUCT";
type CreateBlankSubLevelProduct = "CREATE_BLANK_SUB_LEVEL_PRODUCT";
type CloneProductSubLevel = "CLONE_PRODUCT_SUB_LEVEL";
type SortOrderProduct = "SORT_ORDER_PRODUCT";
type SortWorkflowStep = "SORT_WORKFLOW_STEP";
type MakeProductCanonical = "MAKE_PRODUCT_CANONICAL";

export type ChangeType =
	| Create
	| Update
	| Delete
	| AddOrderDocument
	| DeleteOrderDocument
	| AddProductDocument
	| DeleteProductDocument
	| AddMaterialBidDocument
	| DeleteMaterialBidDocument
	| ReviewOrderProduct
	| CreateBlankTopLevelProduct
	| CloneProductTopLevel
	| BatchImport
	| DeleteOrderProduct
	| CreateBlankSubLevelProduct
	| CloneProductSubLevel
	| SortWorkflowStep
	| SortOrderProduct
	| MakeProductCanonical;

interface ChangeBase {
	changeType: ChangeType;
	// backend expects this
	entity?: CrudChangeEntityName;
}

export type CrudChangeEntityName =
	| "OrderSegment"
	| "Document"
	| "Product"
	| "WorkflowStep"
	| "ProductPurchasedItem"
	| "PurchasedItem"
	| "MaterialBid"
	| "Contact"
	| "CustomerContact"
	| "OutsideProcessSpecification";

export type CrudChangeEntityType =
	| Order
	| Document
	| Product
	| WorkflowStep
	| ProductPurchasedItem
	| PurchasedItem
	| MaterialBid
	| Contact
	| CustomerContact
	| OutsideProcessSpecification;

type CrudChangeValueType =
	| string
	| number
	| Date
	| ProductQuantity[]
	| ProductMaterialDimension[]
	| TaskList
	| EstimateReviewAnswersMap
	| string[]
	| EstimatingTaskStatus;

type UpdateChangeBasicFields = {
	itemId: string;
	field: string;
	oldValue: CrudChangeValueType;
	newValue: CrudChangeValueType;
};

type UpdateChangeRelatedEntityFields = {
	relatedEntityField?: string;
	oldRelatedEntity?: any;
	newRelatedEntity?: any;
};

export type UpdateChange = ChangeBase & {
	changeType: Update;
	entity: CrudChangeEntityName;
	data: UpdateChangeBasicFields & UpdateChangeRelatedEntityFields;
};

export type CreateChange = ChangeBase & {
	changeType: Create;
	entity: CrudChangeEntityName;
	data: {
		itemId: string;
		value: CrudChangeEntityType;
	};
};

export type DeleteChange = ChangeBase & {
	changeType: Delete;
	entity: CrudChangeEntityName;
	data: {
		itemId: string;
		oldValue: CrudChangeEntityType;
	};
};

export type AddOrderDocumentChange = ChangeBase & {
	changeType: AddOrderDocument;
	entity: null;
	data: {
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type DeleteOrderDocumentChange = ChangeBase & {
	changeType: DeleteOrderDocument;
	entity: null;
	data: {
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type AddProductDocumentChange = ChangeBase & {
	changeType: AddProductDocument;
	entity: null;
	data: {
		productId: string;
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type DeleteProductDocumentChange = ChangeBase & {
	changeType: DeleteProductDocument;
	entity: null;
	data: {
		productId: string;
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type AddMaterialBidDocumentChange = ChangeBase & {
	changeType: AddMaterialBidDocument;
	entity: null;
	data: {
		materialBidId: string;
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type DeleteMaterialBidDocumentChange = ChangeBase & {
	changeType: DeleteMaterialBidDocument;
	entity: null;
	data: {
		materialBidId: string;
		documentId: string;
		// For client use only
		documentData: VirtualDocument;
	};
};

export type ReviewOrderProductChange = ChangeBase & {
	changeType: ReviewOrderProduct;
	entity: null;
	data: {
		productId: string;
		reviewStatus: OrderSegmentProductReviewStatus;
		reviewStatusNote: string;
	};
};

export type CreateBlankTopLevelProductChange = ChangeBase & {
	changeType: CreateBlankTopLevelProduct;
	entity: null;
	data: {
		productId: string;
		partNumber: string;
		revision?: string;
		sort: number;
	};
};

export type CloneProductTopLevelChange = ChangeBase & {
	changeType: CloneProductTopLevel;
	entity: null;
	data: {
		productId: string;
		newRev?: string;
		intoProductId: string;
		sort: number;
		tempData: { partNumber: string; revision: string };
	};
};

export type BatchImportChange = ChangeBase & {
	changeType: BatchImport;
	entity: null;
	data: {
		items: {
			partNumber: string;
			revision: string;
			quantities: number[];
			intoProductId: string;
			sort: number;
		}[];
	};
};

export type DeleteOrderProductChange = ChangeBase & {
	changeType: DeleteOrderProduct;
	entity: null;
	data: {
		productId: string;
	};
};

export type CreateBlankSubLevelProductChange = ChangeBase & {
	changeType: CreateBlankSubLevelProduct;
	entity: null;
	data: {
		productId: string;
		partNumber: string;
		revision?: string;
		parentAssemblyId: string;
		topParentAssemblyId: string;
	};
};

export type CloneProductSubLevelChange = ChangeBase & {
	changeType: CloneProductSubLevel;
	entity: null;
	data: {
		productId: string;
		newRev?: string;
		intoProductId: string;
		parentAssemblyId: string;
		topParentAssemblyId: string;
		tempData: { partNumber: string; revision: string };
	};
};

export type SortOrderProductChange = ChangeBase & {
	changeType: SortOrderProduct;
	entity: null;
	data: {
		oldIndex: number;
		newIndex: number;
	};
};

export type SortWorkflowStepChange = ChangeBase & {
	changeType: SortWorkflowStep;
	entity: null;
	data: {
		productId: string;
		oldIndex: number;
		newIndex: number;
	};
};

export type MakeProductCanonicalChange = ChangeBase & {
	changeType: MakeProductCanonical;
	entity: null;
	data: {
		productId: string;
	};
};

export type Change =
	| CreateChange
	| UpdateChange
	| DeleteChange
	| ReviewOrderProductChange
	| AddOrderDocumentChange
	| DeleteOrderDocumentChange
	| AddProductDocumentChange
	| DeleteProductDocumentChange
	| AddMaterialBidDocumentChange
	| DeleteMaterialBidDocumentChange
	| CreateBlankTopLevelProductChange
	| CloneProductTopLevelChange
	| BatchImportChange
	| DeleteOrderProductChange
	| CreateBlankSubLevelProductChange
	| CloneProductSubLevelChange
	| SortOrderProductChange
	| SortWorkflowStepChange
	| MakeProductCanonicalChange;

type RecordedChangeProperties = { changeId: string };
export type RecordedChange = Change & RecordedChangeProperties;

type ServerChangeBase = Exclude<RecordedChange, UpdateChange> & {
	entity: CrudChangeEntityName | null;
};
type ServerUpdateChange = Omit<
	UpdateChange & RecordedChangeProperties,
	"data"
> & { data: UpdateChangeBasicFields };
export type ServerChange = ServerChangeBase | ServerUpdateChange;

function changeIsRedundant(oldChange: Change, newChange: Change) {
	if (oldChange.changeType !== newChange.changeType) return false;
	const { changeType } = newChange;
	switch (changeType) {
		case "CREATE":
		case "DELETE":
		case "ADD_ORDER_DOCUMENT":
		case "DELETE_ORDER_DOCUMENT":
		case "ADD_PRODUCT_DOCUMENT":
		case "DELETE_PRODUCT_DOCUMENT":
		case "ADD_MATERIAL_BID_DOCUMENT":
		case "DELETE_MATERIAL_BID_DOCUMENT":
		case "CREATE_BLANK_TOP_LEVEL_PRODUCT":
		case "CLONE_PRODUCT_TOP_LEVEL":
		case "BATCH_IMPORT":
		case "CREATE_BLANK_SUB_LEVEL_PRODUCT":
		case "CLONE_PRODUCT_SUB_LEVEL":
		case "DELETE_ORDER_PRODUCT":
			// None of these should ever be redundant
			return false;
		case "SORT_ORDER_PRODUCT":
		case "SORT_WORKFLOW_STEP": // These can't be redundant because the effects of the sort depend on previous sorts
			return false;
		case "UPDATE": {
			const oldData = (oldChange as UpdateChange).data;
			return (
				oldData.itemId === newChange.data.itemId &&
				oldChange.entity === newChange.entity &&
				oldData.field === newChange.data.field
			);
		}
		case "REVIEW_ORDER_PRODUCT":
		case "MAKE_PRODUCT_CANONICAL": {
			const oldData = oldChange.data as { productId: string };
			return oldData.productId === newChange.data.productId;
		}
		default:
			assertUnreachable(changeType);
	}
}

export interface CreateOrderSegmentResultData {
	orderNumber: string;
	salesProcessId: string;
	createdDate: Date;
}
export interface BatchImportResultData {
	orderSegmentProducts: OrderProduct[];
	products: Product[];
}
export interface CloneProductTopLevelResultData {
	orderSegmentProduct: OrderProduct;
	products: Product[];
}
export interface CloneProductSubLevelResultData {
	products: Product[];
}

export type ChangeResultData =
	| CreateOrderSegmentResultData
	| BatchImportResultData
	| CloneProductTopLevelResultData
	| CloneProductSubLevelResultData;

export interface ChangeResult {
	changeId: string;
	success: boolean;
	error?: string;
	data?: ChangeResultData;
}
