<mat-card class="mat-elevation-z3 py-2">
    <div class="d-flex w-100 align-items-center mb-2">
        <mat-chip [ngClass]="getStatusColorClass(ticket.status)" [attr.data-testid]="'status-' + ticket.status"
            class="button-text mr-2">
            {{ getStatusText(ticket.status) }}
        </mat-chip>
        <div class="top-line">Assigned to <b data-testid="assignedUserName">{{getUserName(ticket.assignedUser)}}</b> by
            <b data-testid="creatorName">{{ getUserName(ticket.creator) ?? 'Unknown User' }}</b> on <b>{{
                ticket.createdDate| date }}</b> at <b>{{ ticket.createdDate | date: "hh:mm a" }}</b></div>
        <div class="d-flex align-items-center ml-auto" data-testid="creator-buttons">
            <button mat-flat-button color="accent" class="button-text" (click)="reopen.emit()"
                *ngIf="ticket.status === 2 || ticket.status === 3" data-testid="reopen-button">
                <mat-icon matPrefix svgIcon="lock-open"></mat-icon>
                Reopen
            </button>
            <button mat-flat-button (click)="close.emit()" *ngIf="ticket.status !== 2 && ticket.status !== 3"
                data-testid="close-button">
                <mat-icon matPrefix svgIcon="close-octagon"></mat-icon>
                Close
            </button>
            <button mat-flat-button (click)="edit.emit()" *ngIf="ticket.status !== 2 && ticket.status !== 3"
                data-testid="edit-button">
                <mat-icon matPrefix>edit</mat-icon>
                Edit
            </button>
        </div>
    </div>
    <ng-container *ngIf="ticket.carbonCopiesList.length > 0">
        <mat-divider></mat-divider>
        <div class="top-line d-inline" data-testid="cc-section">
            <b>CCs: </b> <span *ngFor="let userId of ticket.carbonCopiesList; let last = last">{{
                this.getCCUserName(userId) | async }}<ng-container *ngIf="!last">, </ng-container></span>
        </div>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="note-content my-4" [innerHTML]="ticket.text"></div>
    <mat-divider></mat-divider>
    <div class="d-flex w-100 align-items-center py-2" *ngIf="this.subItemName" data-testid="subitem-line">
        <div class="top-line">
            <b>{{ subItemTitle ?? 'Subitem' }}:</b> {{ subItemName }}
        </div>
        <button mat-flat-button color="accent" class="button-text mat-button-small ml-2" (click)="jumpToSubitem.emit()"
            *ngIf="ticket.status === 0">
            <mat-icon matPrefix>open_in_new</mat-icon>
            Go To {{ subItemTitle ?? 'Subitem' }}
        </button>
    </div>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0 comments-panel" togglePosition="before" #documentsPanel>
        <mat-expansion-panel-header>
            <ng-container>
                <mat-icon svgIcon="file-multiple" class="mr-2"></mat-icon>
                <span class="small font-weight-medium">{{ ticket.documents.length }} file{{ ticket.documents.length ===
                    1 ? '' : 's' }} </span>
                <button mat-flat-button class="button-text ml-auto bg-success" color="accent"
                    (click)="$event.stopPropagation(); uploadFilesToTicket()" data-testid="upload-button">
                    <mat-icon svgIcon="file-upload" matPrefix></mat-icon>
                    Upload Documents
                </button>
                <button *ngIf="existingDocuments" mat-flat-button class="button-text ml-2" color="accent" (click)="$event.stopPropagation(); linkFilesToTicket()"
                    data-testid="link-existing-button">
                    <mat-icon svgIcon="file-link" matPrefix></mat-icon>
                    Link Existing Document
                </button>
            </ng-container>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let document of ticket.documents">
            <mat-divider class="comment-divider"></mat-divider>
            <div class="p-2 d-flex align-items-center cursor-pointer" data-testid="document" (click)="openDocument(document.document)">
                <mat-icon svgIcon="file-multiple" class="mr-2"></mat-icon>
                <div class="small file-text">{{ document.document.name }}</div>
            </div>
        </ng-container>
    </mat-expansion-panel> <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0 comments-panel" togglePosition="before" #commentsPanel>
        <mat-expansion-panel-header>
            <ng-container *ngIf="!isAddingComment">
                <mat-icon svgIcon="forum" class="mr-2"></mat-icon>
                <span class="small font-weight-medium">{{ ticket.comments.length }} comment{{ ticket.comments.length ===
                    1 ? '' : 's' }} </span>
                <button mat-flat-button class="button-text ml-auto" color="accent"
                    (click)="$event.stopPropagation(); isAddingComment = true;" data-testid="add-comment-button">
                    <mat-icon svgIcon="message-plus" matPrefix></mat-icon>
                    Add Comment
                </button>
            </ng-container>
            <ng-container *ngIf="isAddingComment">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="w-100">
                        <mat-form-field class="w-100" (click)="$event.stopPropagation()">
                            <textarea matInput [(ngModel)]="newCommentText" autofocus data-testid="new-comment-textarea"
                                (keydown)="$event.stopPropagation()"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="w-100 d-flex justify-content-end mb-1">
                        <button mat-flat-button class="button-text" (click)="newCommentCancel($event)"
                            data-testid="cancel-comment-button">
                            Cancel
                        </button>
                        <button mat-flat-button color="primary" class="button-text bg-success ml-2"
                            (click)="newCommentSubmit($event)" data-testid="submit-comment-button">
                            Submit
                        </button>
                    </div>
                </div>
            </ng-container>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let comment of ticket.comments">
            <mat-divider class="comment-divider"></mat-divider>
            <div class="p-2 d-flex align-items-center" data-testid="comment"
                [attr.data-test-commentid]="comment.microTicketCommentId">
                <mat-chip class="small mr-2 commenter-chip" color="accent" selected>
                    <mat-icon matPrefix svgIcon="account-circle"></mat-icon>
                    {{ comment.creator.fullName }}
                </mat-chip>
                <div class="small comment-text">{{ comment.text }}</div>
                <div class="ml-auto small">
                    Posted on <b>{{ comment.createdDate | date: 'YYYY/MM/dd HH:mm a' }}</b>
                </div>
            </div>
        </ng-container>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <div class="d-flex w-100 align-items-center pt-2">
        <mat-icon svgIcon="calendar-clock" class="mr-2"></mat-icon>
        <div class="top-line" *ngIf="ticket.dueDate">
            Due on <b>{{ ticket.dueDate | date }}</b>
        </div>
        <div class="top-line" *ngIf="!ticket.dueDate">
            No due date
        </div>
        <div class="d-flex align-items-center ml-auto" *ngIf="isAssignedToMe()" data-testid="assignedUser-buttons">
            <button mat-flat-button color="accent" class="button-text" (click)="markInProgress.emit()"
                *ngIf="ticket.status === 0" data-testid="in-progress-button">
                Mark as In Progress
            </button>
            <button mat-flat-button color="accent" class="bg-success button-text" (click)="markComplete.emit()"
                *ngIf="ticket.status === 1" data-testid="completed-button">
                Mark as Completed
            </button>
        </div>
    </div>
</mat-card>


<ng-template #newFileUploadDialog let-data>
    <h2 mat-dialog-title>Upload Files to Microticket</h2>
    <mat-dialog-content style="height: 500px;">
        <order-detail-documents [editing]="true" [(documents)]="data.documents" [showTags]="false"></order-detail-documents>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button class="button-text" [mat-dialog-close]="null">Cancel</button>
        <button mat-flat-button color="primary" class="button-text" [disabled]="data.documents.length === 0"
            [mat-dialog-close]="data.documents">Upload</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #existingFileLinkDialog let-data>
    <h2 mat-dialog-title>Link Existing Files to Microticket</h2>
    <mat-dialog-content style="height: 500px;">
          <mat-form-field appearance="outline" class="w-100" floatLabel="always">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let doc of data.documents; let i = index"
                [removable]="true"
                (removed)="removeLinkedDoc(data, doc)"
              >
                {{ doc.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                matInput
                [formControl]="docFilterControl"
                [matAutocomplete]="auto"
                #autoTrigger="matAutocompleteTrigger"
                [matAutocompleteNoDisplay]="autoTrigger"
              />
            </mat-chip-list>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="addLinkedDoc(data, $event.option.value)"
            >
              <mat-option *ngFor="let fd of (filteredDocuments | async)" [value]="fd">
                {{ fd.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button class="button-text" [mat-dialog-close]="null">Cancel</button>
        <button mat-flat-button color="primary" class="button-text" [disabled]="data.documents.length === 0"
            [mat-dialog-close]="data.documents">Upload</button>
    </mat-dialog-actions>
</ng-template>