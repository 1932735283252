import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../order-detail.service';
import { EstimatingTaskStatus, Order, OrderProduct, OrderSegmentProductReviewStatus, OrderSegmentProductType } from '../../../resources/order';
import { Product, ProductStandardHistory } from '../../../resources/product';
import { UtilityService } from '../../../../common/services/utility.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { OrderService } from '../../../services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessageService } from '../../../../common/services/message.service';
import * as XLSX from 'xlsx';
import { FilePartAssignment, OrderDetailProductsUploadComponent } from './order-detail-products-upload/order-detail-products-upload.component';
import { MessageType } from '../../../../common/resources/message';
import { Task, TaskStatus } from '../../../../common/resources/estimatingtask';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AddOrderDocumentChange, AddProductDocumentChange, BatchImportChange, Change, CloneProductTopLevelChange, CreateBlankTopLevelProductChange, DeleteOrderProductChange, ReviewOrderProductChange, SortOrderProductChange, UpdateChange } from '@cots/common/autosaving/change';
import { ClassifiedProduct } from '../existing-product-select/existing-product-select.component';
import { ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID, ESTIMATING_CONTRACT_REVIEW_QUESTIONS } from '../product-detail-new/product-estimating-review/product-estimating-review.component';

const parseMap = {
  'Name': 'partNumber',
  'Part Number': 'partNumber',
  'Part': 'partNumber',
  'PN': 'partNumber',
  'Part #': 'partNumber',
  'Description': 'description',
  'Notes': 'description',
  'Revision': 'revision',
  'Rev': 'revision'
}

const sheetTypes = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet'
]

function tableToJson(tableHtml: string): { [key: string]: string }[] {

  const div = document.createElement('div');
  div.innerHTML = tableHtml.trim();
  const table = div.querySelector('table') as HTMLTableElement;

  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerText.trim().replace(/\n/gi, ' ');
  }

  // go through cells
  for (var i = 1; i < table.rows.length; i++) {

    var tableRow = table.rows[i];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {

      rowData[headers[j]] = tableRow.cells[j].innerText;

    }

    data.push(rowData);
  }

  return data;
}


type ParsedSpreadsheetProduct = { 'partNumber': string, 'revision': string, quantities: number[] }


type OrderProductMod = (OrderProduct & {
  unitPriceObservable: Observable<number>,
  leadTimeObservable: Observable<number>,
  loading: false,
  estimateReviewAnswered: number,
  estimateReviewTotal: number,
  materialStatus: EstimatingTaskStatus,
  workflowStatus: EstimatingTaskStatus,
  hardwareStatus: EstimatingTaskStatus
}) | { loading: true, productProductId: string, product: { partNumber: string, revision: string } };

@Component({
  selector: 'order-detail-products',
  templateUrl: './order-detail-products.component.html',
  styleUrls: ['./order-detail-products.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailProductsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public service: OrderDetailService,
    public orderService: OrderService,
    private dialog: MatDialog,
    private utilityService: UtilityService,
    private messageService: MessageService
  ) { }

  public get editing() { return this.service.editing }

  public rawData$: Observable<OrderProductMod[]>;
  public data$: Observable<OrderProductMod[]>;

  private generateProductsObservable(): Observable<OrderProductMod[]> {
    // We need make sure to update when we get new products, as just hoping the order observable fires after results in a race condition where sometimes getProductObservable fails to get the newest product map
    const productsMapObservable = this.service.autosaver.getPostChanges('productMap').pipe(
      distinctUntilChanged((oldMap, newMap) => Object.values(oldMap).length === Object.values(newMap).length)
    );
    // This is the actual observable that returns the list for the table
    const inner = combineLatest([this.service.postChangesOrder$.pipe(filter(o => !!o)), productsMapObservable, this.service.autosaver.changesCommittedSubject.pipe(startWith(null))])
      .pipe(
        // The hope for this was to avoid the in-between state when adding parts where the PN/rev columns don't render, but instead it just makes it never fire when new parts are added
        // filter(([order, productsMap]) => {
        //   const nonLoading = (order.products as (OrderProduct & { isLoading: boolean})[]).filter(osp => !osp.isLoading);
        //   return nonLoading.every(osp => productsMap[osp.productProductId]);
        // }),
        map(([order, prodsMap]) => {
          const allProducts = Object.values(prodsMap);
          return (order.products as (OrderProduct & { isLoading: boolean})[]).map<OrderProductMod>(osp => (osp.isLoading ? { loading: true, productProductId: osp.productProductId, product: osp.product } : {
            ...osp,
            loading: false,
            productObservable: this.service.getProductObservable(osp.productProductId),
            leadTimeObservable: this.service.getProductLeadTimeObservable(osp.productProductId),
            unitPriceObservable: this.service.getProductUnitPriceObservable(osp.productProductId),
            estimateReviewAnswered: this.countAnsweredEstimateReviewQuestions(order, Object.values(prodsMap), osp.productProductId),
            estimateReviewTotal: this.countTotalEstimateReviewQuestions(Object.values(prodsMap), osp.productProductId),
            materialStatus: this.getSimpleTaskStatus(osp, 'material', allProducts),
            workflowStatus: this.getSimpleTaskStatus(osp, 'workflow', allProducts),
            hardwareStatus: this.getSimpleTaskStatus(osp, 'hardware', allProducts),
          }))
        }),
        shareReplay(1)
      );
    this.rawData$ = combineLatest([inner, this.searchSubject])
        .pipe(map(([products, search]) => {
          if (search.trim()) products = products.filter(p => `${p.product.partNumber} Rev. ${p.product.revision}`.toLowerCase().includes(search.trim().toLowerCase()))
          return products;
        }));
    return combineLatest([this.rawData$, this.pageSubject])
        .pipe(map(([products, pageEvent]) => {
          const { pageSize, pageIndex } = pageEvent;
          const start = pageIndex * pageSize;
          const end = start + pageSize;
          return products.slice(start, end);
        }));
  }

  public getDisplayedColumns(record: Order) 
  {
    let displayed = [];
    if (this.editing) displayed.push('dragHandle');
    if (this.service.canApproveRFQ(record)) displayed.push('review');
    if (record.discriminator === 'Estimate' || record.discriminator === 'RMAEstimate' || record.discriminator === 'Quote') displayed.push('reviewEstimate');
    displayed = [...displayed, 'partNumber', 'rev', 'quantities', 'unitPrice', 'leadTime', 'cloneData', 'quoteHistory'];
    if (this.service.shouldShowIndicators(record)) displayed.push('microtasks');
    if (record.discriminator === 'Estimate' && record.status === 1 && this.service.canApproveEstimate()) displayed.push('estimatingContractReview');
    displayed.push('files');
    if (this.editing) displayed.push('filesUpload');
    displayed.push('open');
    if (this.editing) displayed.push('delete');
    return displayed;
  }

  public async drop(event: CdkDragDrop<OrderProduct[]>) {
    const page = await fetchLatest(this.pageSubject);
    const oldIndex = event.previousIndex + (page.pageIndex * page.pageSize);
    const newIndex = event.currentIndex + (page.pageIndex * page.pageSize);
    const change: SortOrderProductChange = {
      changeType: 'SORT_ORDER_PRODUCT',
      entity: null,
      data: {
        oldIndex,
        newIndex,
      },
    };
    this.service.recordChanges(change);
  }
  
  private async checkCustomer(): Promise<boolean> {
      const order = await fetchLatest(this.service.postChangesOrder$);
      if (!order.customer) {
        this.utilityService.showAlert('No Customer Selected', 'Please select a customer before adding parts.');
        return false;
      }
      else return true;
  }

  @ViewChild('partSearcher', { static: false }) set partSearcherSetter(content: ElementRef<HTMLInputElement>) {
    if (content) {
      content.nativeElement.focus();
    }
  }
  @ViewChild('container') container: ElementRef<HTMLDivElement>; 
  @ViewChild('paginator') paginator: MatPaginator;
  public addingPart = false;
  public async addProduct() {
    const r = await this.checkCustomer();
    if (!r) return;
    this.addingPart = true;
    this.searchSubject.next('');
    this.paginator.lastPage();
  }

  public openProduct(product: OrderProduct) {
    this.service.selectedProductIdSubject.next(product.productProductId);
  }

  ngOnInit(): void {
    this.data$ = this.generateProductsObservable();
    this.pageSubject.next({ length: 0, pageIndex: this.service.partListPageIndex, pageSize: this.PAGE_SIZE });

    // combineLatest([this.searchSubject, this.pageSubject]).subscribe(([search, page]) => {
    //   if (page.pageIndex != 0 || !!search.trim()) this.addingPart = false;
    // })
  }

  private newBlankProduct(order: Order, partNumber: string, newRev: string = null) {
    const newId = UtilityService.newGuid();
    const change: CreateBlankTopLevelProductChange = {
      changeType: 'CREATE_BLANK_TOP_LEVEL_PRODUCT',
      entity: null,
      data: {
        partNumber,
        revision: newRev,
        productId: newId,
        sort: order.products.length,
      },
    };
    this.service.recordChanges(change);
  }

  private cloneProduct(order: Order, product: Product, newRev: string = null) {
    const newId = UtilityService.newGuid();
    const change: CloneProductTopLevelChange = {
      changeType: 'CLONE_PRODUCT_TOP_LEVEL',
      entity: null,
      data: {
        productId: product.productId,
        intoProductId: newId,
        newRev,
        sort: order.products.length,
        tempData: { partNumber: product.partNumber, revision: product.revision },
      },
    };
    this.service.recordChanges(change);
  }

  public duplicateProduct(order: Order, product: Product) {
    const newId = UtilityService.newGuid();
    const change: CloneProductTopLevelChange = {
      changeType: 'CLONE_PRODUCT_TOP_LEVEL',
      entity: null,
      data: {
        productId: product.productId,
        intoProductId: newId,
        sort: order.products.length,
        tempData: { partNumber: product.partNumber, revision: product.revision },
      },
    };
    this.service.recordChanges(change);
  }

  @ViewChild('newRevDialogTemplate') newRevDialogTemplate: TemplateRef<any>;
  public async partDropdownSelected(order: Order, value: ClassifiedProduct | string) {
    if (typeof value === 'string') {
      this.newBlankProduct(order, value);
    } else if (value.type === 'REPEAT') {
      this.cloneProduct(order, value.product);
    } else if (value.type === 'NEWREV') {
      const data = {
        availableProducts: null,
        newRev: '',
        productToClone: null
      }
      this.orderService.searchProducts(value.product.partNumber).subscribe(s => {
        data.availableProducts = s;
        if (data.availableProducts.length === 1) data.productToClone = data.availableProducts[0]
      });
      const dialogRef = this.dialog.open(this.newRevDialogTemplate, {
        disableClose: true,
        maxWidth: '30vw',
        data
      });
      dialogRef.afterClosed().subscribe((result: { newRev: string, productToClone: Product | null }) => {
        if (!result) return;
        if (result.productToClone) {
          this.cloneProduct(order, result.productToClone, result.newRev);
        } else {
          this.newBlankProduct(order, value.product.partNumber, result.newRev);
        }
      })
    }
    this.addingPart = false;
    setTimeout(() => {
      this.container.nativeElement && this.container.nativeElement.focus();
    })
  }

  public isLoading(_index: number, row: OrderProductMod) {
    return row.loading;
  }

  public async deleteProduct(row: OrderProduct, order: Order) {
    const r = await this.utilityService.showConfirmationPromise('Delete part?', `<p>Really delete part <b>${row.product.partNumber} Rev. ${row.product.revision}</b>?</p><p class="font-weight-bold text-danger">This cannot be undone and may result in severe data loss.</p>`)
    if (!r) return;
    const change: DeleteOrderProductChange = {
      changeType: 'DELETE_ORDER_PRODUCT',
      entity: null,
      data: {
        productId: row.productProductId,
      },
    };
    this.service.recordChanges(change);
  }

  private parseKey(parseString: string, target: string) {
    const sanitized = target
        .replace(/[^a-zA-Z0-9\s]+/gi, '')
        .replace(/\s+/gi, ' ')
        .trim();
      const keywords = parseString.split(' ');
        // check that all keywords are in the sanitized table header name
        if (keywords.every(kw => sanitized.toUpperCase().includes(kw.toUpperCase())))
          return true;
      return false;
  }

  public async parseTable(items: { [key: string]: string }[]): Promise<ParsedSpreadsheetProduct[]> {
    let unmappedKeys = [];
    return items.flat().map(r => {
      let out = { partNumber: null, revision: null, description: null };
      let qties: number[] = [];
      for (const objectKey in r) {
        if (Object.prototype.hasOwnProperty.call(r, objectKey)) {
          if (/(qty|quantity)/gi.test(objectKey)) {
            const parsed = parseInt(r[objectKey]);
            if (!isNaN(parsed)) qties.push(parsed);
            continue;
          }
          let couldParse = false;
          for (const parseString in parseMap) {
            if (this.parseKey(parseString, objectKey)) {
              out[parseMap[parseString]] = r[objectKey];
              couldParse = true;
            }
          }
          if (!couldParse) unmappedKeys.push(objectKey);
        }
      }
      return {
        partNumber: out.partNumber,
        revision: out.revision || '-',
        description: out.description || '',
        quantities: qties,
      }
    });
  }

  public async addParsedProducts(order: Order, parsedProducts: ParsedSpreadsheetProduct[]) {
    const r = await this.checkCustomer();
    if (!r) return;
    // Merge matching PN/REV combinations, adding up all their quantities
    const pnrevMap: { [key: string]: ParsedSpreadsheetProduct } = parsedProducts.reduce((acc, pp) => {
      const key = `${pp.partNumber}-${pp.revision}`;
      if (!acc[key]) acc[key] = pp;
      else acc[key].quantities = Array.from(new Set([...acc[key].quantities, ...pp.quantities]));
      return acc;
    }, <{[key:string]: ParsedSpreadsheetProduct}>{});
    const finalProducts = Object.values(pnrevMap);
    const newIds = finalProducts.map(() => UtilityService.newGuid());
    const change: BatchImportChange = {
      changeType: 'BATCH_IMPORT',
      entity: null,
      data: {
        items: finalProducts.map((pp, i) => ({
          partNumber: pp.partNumber,
          revision: pp.revision,
          quantities: pp.quantities,
          intoProductId: newIds[i],
          sort: order.products.length + i,
        })),
      },
    };
    this.service.recordChanges(change);
  }

  public async parseClipboard(): Promise<{ [key: string]: string }[]> {
    // @ts-ignore
    const items = await navigator.clipboard.read();
    if (!items[0]) return;
    const item = items[0];
    if (!item) return;
    if (!item.types.includes('text/html')) return;
    const blob = await item.getType('text/html')
    const tableString = await blob.text();
    return tableToJson(tableString);
  }

  public async onPaste(order: Order) {
    if (!this.editing) return;
    try {
      const rawData = await this.parseClipboard();
      const parsed = await this.parseTable(rawData);
      this.addParsedProducts(order, parsed);
    } catch (e) {
      console.error('Failed to parse clipboard:', e);
    }
  }

  private async parseFile(file: File): Promise<{ [key: string]: string }[]> {
    // @ts-ignore
    const buffer = await file.arrayBuffer();
    const parsed = XLSX.read(buffer);
    return Object.values(parsed.Sheets).map(s => XLSX.utils.sheet_to_json(s, { defval: null })) as any;
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  
  public async onFileChange(order: Order) {
    const files = this.fileInput && this.fileInput.nativeElement.files;
    if (files.length > 0) {
      const file = files[0];
      try {
        const data = await this.parseFile(file);
        const parsed = await this.parseTable(data);
        this.addParsedProducts(order, parsed);
      } catch (e) {
        this.messageService.add(`Could not parse file`, 1, true);
        console.error('Failed to parse file:', e);
      }
    }
  }

  public clipboardIsValid = false;
  public checkClipboard() {
    this.parseClipboard().then(() => this.clipboardIsValid = true).catch(() => this.clipboardIsValid = false);
  }


  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public menuPosition: { x: number; y: number } = { x: 0, y: 0 }
  public menuItem: OrderProduct | null = null;
  public openMenu(event: MouseEvent, item: OrderProduct | null) {
    this.checkClipboard();
    if (item) {
      this.menuItem = item;
    } else {
      this.menuItem = null;
    }
    this.menuPosition = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    if (!this.menuItem) return;
    event.preventDefault();
    this.menuTrigger.openMenu();
  }

  public ngAfterViewInit() {
    this.paginator.pageIndex = this.service.partListPageIndex;
  }

  public ngOnDestroy(): void {
  }

  public dragging = false;
  private enterTarget: EventTarget | null;
  public onDragEnter(event: DragEvent) {
    if (!this.editing) return;
    this.enterTarget = event.target;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = true;
  }

  public onDragLeave(event: DragEvent) {
    if (!this.editing) return;
    if (this.enterTarget === event.target){
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
    }
  }

  private async assignDocuments(assignments: FilePartAssignment[]) {
    const uploaded = await this.service.batchUploadDocuments(assignments.map(a => a.file));
    let i = 0;
    let changes: Change[] = [];
    for (const assign of assignments) {
      const doc = uploaded[i];
      const docId = doc.documentId;
      if (assign.assignedPart === 'NONE') {
        const change: AddOrderDocumentChange = {
                     changeType: 'ADD_ORDER_DOCUMENT',
          entity: null,
          data: {
            documentId: doc.documentId,
            documentData: doc
          }
        };
        changes.push(change);
      } else {
        const change: AddProductDocumentChange = {
                     changeType: 'ADD_PRODUCT_DOCUMENT',
          entity: null,
          data: {
            productId: assign.assignedPart.productId,
            documentId: doc.documentId,
            documentData: doc
          }
        };
        changes.push(change);
      }
      if (assign.tags.length > 0) {
        const tagChange: UpdateChange = {
          changeType: 'UPDATE',
          entity: 'Document',
          data: {
            itemId: doc.documentId,
            field: 'tags',
            oldValue: [],
            newValue: assign.tags,
          },
        };
        changes.push(tagChange);
      }
      i++;
    }
    this.service.recordChanges(...changes);
  }

  public async onDrop(order: Order, event: DragEvent) {
    if (!this.editing) return;
    event.stopPropagation();
    event.preventDefault();
    this.dragging = false;
    const record = await fetchLatest(this.service.postChangesOrder$);
    const files = Array.from(event.dataTransfer.files ?? []);
    if (files.length === 0) return;
    else if (files.length === 1 && sheetTypes.includes(files[0].type)) {
      const file = files[0];
      try {
        const data = await this.parseFile(file);
        const parsed = await this.parseTable(data);
        this.addParsedProducts(order, parsed);
      } catch (e) {
        this.messageService.add(`Could not parse file`, 1, true);
        console.error('Failed to parse file:', e);
      }
    } else {
      const prodMap = await fetchLatest(this.service.autosaver.getPostChanges('productMap'));
      const topProducts = record.products.map(osp => prodMap[osp.productProductId]);
      const dialogRef = this.dialog.open<any, { files: File[], products: Product[] }, FilePartAssignment[] | null>(OrderDetailProductsUploadComponent, {
        data: {
          files: files,
          products: topProducts,
        },
        minWidth: '50vw',
        minHeight: '70vh',
        disableClose: true
      });
      const result = await dialogRef.afterClosed().toPromise();
      if (!result) return;
      await this.assignDocuments(result);
      this.messageService.add(`${result.length} files assigned.`, MessageType.GENERAL, true)
    }
  }

  public canGetBinder(record: Order) {
    const allDocs = record.products.flatMap(p => p.product.documents).filter(d => d.document.tags.includes('Drawing'));
    return allDocs.length > 0;
  }

  public getBinder(record: Order) {
    window.open(`/api/orderSegment/getBinder/${record.orderSegmentId}`, '_blank');
  }

  public getCloneDataChipClass(product: OrderProduct) {
    switch (product.type) {
      case OrderSegmentProductType.NEW:
        return 'chip-clone-blank'
      case OrderSegmentProductType.CLONED_NEWREV:
        return 'chip-clone-newrev'
      case OrderSegmentProductType.CLONED:
        return 'chip-clone-repeat'
      default:
        return '';
    }
  }
  public getCloneDataText(product: OrderProduct) {
    switch (product.type) {
      case OrderSegmentProductType.NEW:
        return 'New Part'
      case OrderSegmentProductType.CLONED_NEWREV:
        return 'Cloned – New Rev'
      case OrderSegmentProductType.CLONED:
        return 'Cloned'
      default:
        return '';
    }
  }

  
  @ViewChild('productFileInput', { static: true }) productFileInput: ElementRef<HTMLInputElement>;
  private addingFilesProduct: OrderProduct = null;
  public onClickFiles(op: OrderProduct) {
    this.service.selectedProductIdSubject.next(op.productProductId);
    this.service.productTab = 1;
  }
  public onClickUpload(op: OrderProduct) {
    this.addingFilesProduct = op;
    this.productFileInput.nativeElement.click();
  }

  public async onProductFileChange() {
    const files = this.productFileInput && this.productFileInput.nativeElement.files;
    const part = this.addingFilesProduct?.product;
    if (!part) return;
    if (files.length > 0) {
      const vdocs = await this.service.batchUploadDocuments(Array.from(files));
      const changes: AddProductDocumentChange[] = vdocs.map(c => ({
               changeType: 'ADD_PRODUCT_DOCUMENT',
        entity: null,
        data: {
          productId: part.productId,
          documentId: c.documentId,
          documentData: c
        }
      }));
      this.service.recordChanges(...changes);
      this.messageService.add(`${files.length} files assigned to ${part.partNumber} Rev. ${part.revision}.`, MessageType.GENERAL, true)
    }
    this.productFileInput.nativeElement.value = '';
    this.addingFilesProduct = null;
  }

  public getQuoteHistoryText(h: ProductStandardHistory) {
    switch (h) {
      case ProductStandardHistory.NEW:
        return 'New Part';
      case ProductStandardHistory.RFQ:
        return 'Previously in RFQ';
      case ProductStandardHistory.ESTIMATED:
        return 'Previously Estimated';
      case ProductStandardHistory.QUOTED:
        return 'Previously Quoted';
      case ProductStandardHistory.PURCHASED:
        return 'Previously Purchased';
      case ProductStandardHistory.GOLD:
        return 'Gold Part';
    }
  }

  public getQuoteHistoryChipClass(h: ProductStandardHistory) {
    switch (h) {
      case ProductStandardHistory.NEW:
        return 'history-chip-new';
      case ProductStandardHistory.RFQ:
        return 'history-chip-new';
      case ProductStandardHistory.ESTIMATED:
        return 'history-chip-estimated';
      case ProductStandardHistory.QUOTED:
        return 'history-chip-quoted';
      case ProductStandardHistory.PURCHASED:
        return 'history-chip-purchased';
      case ProductStandardHistory.GOLD:
        return 'history-chip-gold';
    }
  }

  @ViewChild('reviewProductDialogTemplate') reviewProductDialogTemplate: TemplateRef<any>;
  public async reviewProduct(op: OrderProduct, product: Product) {
    const diagRef = this.dialog.open(this.reviewProductDialogTemplate, {
      disableClose: true,
      width: '450px',
      data: {
        op,
        product,
        reviewStatus: op.reviewStatus,
        reviewStatusNote: op.reviewStatusNote
      }
    });
    const result: Pick<OrderProduct, 'reviewStatus' | 'reviewStatusNote'> | null = await diagRef.afterClosed().toPromise();
    if (!result) return;
    const { reviewStatus, reviewStatusNote } = result;
    const change: ReviewOrderProductChange = {
             changeType: 'REVIEW_ORDER_PRODUCT',
      entity: null,
      data: {
        productId: op.productProductId,
        reviewStatus,
        reviewStatusNote
      }
    };
    this.service.recordChanges(change);
  }
  
  @ViewChild('viewProductReviewTemplate') viewProductReviewTemplate: TemplateRef<any>;
  public async viewReviewData(op: OrderProduct) {
    if (op.reviewStatus === OrderSegmentProductReviewStatus.NotReviewed) return;
    const record = await fetchLatest(this.service.postChangesOrder$);
    const product = await fetchLatest(this.service.getProductObservable(op.productProductId));
    this.dialog.open(this.viewProductReviewTemplate, {
      width: '450px',
      data: {
        op, product, record
      }
    });
  }

  private getAllTasksRecursive(taskList: Task[]): Task[] {
    return [...taskList, ...taskList.flatMap((t) => this.getAllTasksRecursive(t.subtasks))]
  }

  public getSimpleTaskStatus(op: OrderProduct, taskType: 'material' | 'workflow' | 'hardware', allProducts: Product[]) {
    const allAssemblies = allProducts.filter(p => p.topParentAssemblyId === op.productProductId);
    const allStatuses = allAssemblies.map(p => {
      switch (taskType) {
        case 'material':
          return p.materialStatus;
        case 'workflow':
          return p.workflowStatus;
        case 'hardware':
          return p.hardwareStatus;
      };
    });
    if (allStatuses.some(s => EstimatingTaskStatus.countsAsNotStarted(s))) return TaskStatus.NOT_STARTED;
    if (allStatuses.some(s => EstimatingTaskStatus.countsAsInProgress(s))) return TaskStatus.IN_PROGRESS;
    return TaskStatus.DONE;
  }

  public getStatusColorClass(status: TaskStatus) {
    return EstimatingTaskStatus.getStatusColorClass(status);
  }
  
  public getStatusText(status: TaskStatus) {
    return EstimatingTaskStatus.getStatusText(status);
  }

  public readonly PAGE_SIZE = 9 as const;
  private pageSubject = new ReplaySubject<PageEvent>(1);
  public onPage(event: PageEvent) {
    this.service.partListPageIndex = event.pageIndex;
    this.pageSubject.next(event);
  }
  public searchSubject = new BehaviorSubject<string>('');

  public trackByFn(index: number, item: OrderProductMod) {
    return `${item.productProductId}-${item.loading}`;
  }

  public productFieldEdited(productId: string, field: string, oldValue: string, newValue: string) {
    if (!productId) return;
    const change: UpdateChange = {
      changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: productId,
        field,
        oldValue,
        newValue,
      },
    };
    this.service.recordChanges(change);
  }

  public openEstimateReview(productId: string) {
    this.service.productTab = 5;
    this.service.selectedProductIdSubject.next(productId);
    setTimeout(() => {
      if (this.service.productHierarchy) this.service.productHierarchy.expandAll();
    });
  }

  public countTotalEstimateReviewQuestions(allProducts: Product[], topProductId: string) {
    let allProductIds = allProducts.filter(p => p.topParentAssemblyId === topProductId).map(p => p.productId);
    return allProductIds.length * ESTIMATING_CONTRACT_REVIEW_QUESTIONS.length;
  }

  public countAnsweredEstimateReviewQuestions(order: Order, allProducts: Product[], topProductId: string) {
    let allProductIds = allProducts.filter(p => p.topParentAssemblyId === topProductId).map(p => p.productId);
    const validAnswers = Object.entries(order.estimateReviewAnswers ?? {})
      .filter(([prodId, _]) => allProductIds.includes(prodId))
      .flatMap(([_, prodData]) => Object.values(prodData[ESTIMATING_CONTRACT_REVIEW_CATEGORY_ID] ?? {}))
      .filter(ans => !!ans);
    return validAnswers.length;
  }
}
