import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PurchasingRfqRequest, PurchasingRfqRequestStatus } from '../../../common/resources/purchasing-rfq-request';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchasingRfqRequestService } from '../../../common/services/purchasing-rfq-request.service';
import { NavigationService } from '../../../common/services/navigation.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, merge, of } from 'rxjs';
import { Vendor } from '../../../supplier/resources/vendor';
import { VendorService } from '../../../supplier/services/vendor.service';
import { map, startWith, take, tap } from 'rxjs/operators';
import { MaterialBidService } from '../../services/material-bid.service';
import { MaterialBid } from '../../resources/materialBid';
import { UtilityService } from '../../../common/services/utility.service';
import { MatSidenav } from '@angular/material/sidenav';
import { QuoteDetailComponent } from '../quote-detail/quote-detail.component';
import { nextTick } from 'process';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

enum VendorQuoteStatus {
  NOT_SENT = 0,
  AWAIT_RESPONSE = 1,
  RECEIVED = 2,
  ACCEPTED = 3,
}

const enCollator = new Intl.Collator('en');

@Component({
  selector: 'purchasing-rfq-request-detail',
  templateUrl: './purchasing-rfq-request-detail.component.html',
  styleUrls: ['./purchasing-rfq-request-detail.component.less']
})
export class PurchasingRfqRequestDetailComponent implements OnInit {
  public record: PurchasingRfqRequest;
  public id: string;
  public sidenav: 'newQuote' | 'viewQuote';
  public newQuoteData: MaterialBid = null;

  constructor(
    navService: NavigationService,
    private dialog: MatDialog,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private router: Router,
    private service: PurchasingRfqRequestService,
    private materialBidService: MaterialBidService,
    private utilitySvc: UtilityService
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
    navService.clearBreadCrumbs();
    navService.setPageTitle("Purchasing");
    navService.pushBreadcrumb("Procurement");
    navService.pushBreadcrumb("Procurement Ticket");
  }

  public loadingVendors = true;
  public sortChange = new BehaviorSubject<Sort>({
    active: "status",
    direction: "desc",
  })
  public allVendorsSubject = new ReplaySubject<Vendor[]>(1);
  public vendors$: Observable<Vendor[]>;

  private updateStatus(newStatus: PurchasingRfqRequestStatus) {
    this.record.status = newStatus;
    this.service.updateStatus(this.record, newStatus).subscribe(() => {});
  }

  private getFilterId() {
    if (this.record.purchasedItemId) return "purchased";
    else if (this.record.material) return this.record.material.materialGroupId;
    // TODO: Outside process descriptions will require filtering by multiple stations at once
    else if (this.record.outsideProcessDescriptionId) return this.record.outsideProcessDescription.steps[0]?.stationId ?? "";
    else return "";
  }
  
  private getFilterName() {
    if (this.record.purchasedItemId) return "Hardware";
    else if (this.record.material) return this.record.material.materialGroup?.groupName;
    // TODO: Outside process descriptions will require filtering by multiple stations at once
    else if (this.record.outsideProcessDescriptionId) return this.record.outsideProcessDescription.steps[0]?.station?.name;
    else return "";
  }

  private loadVendors() {
    this.loadingVendors = true;
    this.vendorService.getCapableVendors(this.getFilterId()).pipe(
      tap(() => this.loadingVendors = false),
      startWith([] as Vendor[]),
    ).subscribe((v) => this.allVendorsSubject.next(v));
  }

  public searchControl = new FormControl<string>("");
  async ngOnInit() {
    this.record = await this.service.get(this.id).toPromise();
    const vendorsAlreadyQuoted = this.record.materialBids.map(b => b.vendor);
    this.vendors$ = combineLatest([
      of(vendorsAlreadyQuoted),
      this.allVendorsSubject,
      this.searchControl.valueChanges.pipe(startWith('')),
      this.sortChange
    ]).pipe(map(([alreadyQuoted, others, filter, sort]) => [
      ...alreadyQuoted,
      ...others.filter(o => alreadyQuoted.findIndex(aqv => aqv.vendorId === o.vendorId) === -1),
    ].filter((v) => {
      if (!filter.trim()) return true;
      else return v.name.toLowerCase().includes(filter.toLowerCase());
    })
      .sort((a, b) => {
      const quoteA = this.getQuoteForVendor(a);
      const quoteB = this.getQuoteForVendor(b);
      switch (sort.active) {
        case "vendor":
          if (sort.direction === "desc")return enCollator.compare(b.name, a.name);
          else return enCollator.compare(a.name, b.name);
        case "status":
          if (sort.direction === "desc") return this.getVendorStatus(b) - this.getVendorStatus(a);
          else return this.getVendorStatus(a) - this.getVendorStatus(b);
        case "pricing":
          const priceA = quoteA ? (quoteA.perItemBid ? quoteA.perItemBid * quoteA.qty : quoteA.totalBid ?? 0) : 0;
          const priceB = quoteB ? (quoteB.perItemBid ? quoteB.perItemBid * quoteB.qty : quoteB.totalBid ?? 0) : 0;
          if (sort.direction === "desc") return priceB - priceA;
          else return priceA - priceB;
        case "leadTime":
          if (sort.direction === "desc") return (quoteB?.leadTimeDays ?? 0) - (quoteA?.leadTimeDays ?? 0);
          else return (quoteA?.leadTimeDays ?? 0) - (quoteB?.leadTimeDays ?? 0);
        default:
          return 0;
      }
    })));
    this.loadVendors();
  }

  public getQuoteForVendor(vendor: Vendor) {
    return this.record.materialBids.find(b => b.vendorId === vendor.vendorId);
  }

  public getVendorStatus(vendor: Vendor): VendorQuoteStatus {
    const quote = this.getQuoteForVendor(vendor);
    if (!quote) return VendorQuoteStatus.NOT_SENT;
    if (!quote.answered) return VendorQuoteStatus.AWAIT_RESPONSE;
    if (!(this.record.selectedQuoteId === quote.materialBidId)) return VendorQuoteStatus.RECEIVED;
    return VendorQuoteStatus.ACCEPTED;
  }

  public getVendorStatusText(status: VendorQuoteStatus) {
    switch (status) {
      case VendorQuoteStatus.NOT_SENT:
        return 'Not Sent';
      case VendorQuoteStatus.AWAIT_RESPONSE:
        return 'Awaiting Response'
      case VendorQuoteStatus.RECEIVED:
        return 'Quote Received';
      case VendorQuoteStatus.ACCEPTED:
        return 'Quote Accepted';
    }
  }

  public getVendorStatusChipClass(status: VendorQuoteStatus) {
    switch (status) {
      case VendorQuoteStatus.NOT_SENT:
        return 'bg-danger text-white';
      case VendorQuoteStatus.AWAIT_RESPONSE:
        return 'bg-warning text-dark'
      case VendorQuoteStatus.RECEIVED:
        return 'bg-info text-white';
      case VendorQuoteStatus.ACCEPTED:
        return 'bg-success text-white';
    }
  }

  public getItemName() {
    return PurchasingRfqRequest.getItemName(this.record);
  }

  public getSourceTicketName() {
    return PurchasingRfqRequest.getSourceTicketName(this.record);
  }

  public displayedColumns = ["vendorName", "quotingStatus", "pricing", "leadTime", "buttons"];
  public trackByFn(_: any, vendor: Vendor) {
    return vendor.vendorId;
  }

  public loadingMap: { [vendorId: string]: true } = {};

  public async markAsSent(vendor: Vendor) {
    const r = await this.utilitySvc.showConfirmationPromise("Mark quote as sent?", "The RFQ will be marked as sent. This cannot be undone.");
    if (!r) return;
    this.loadingMap[vendor.vendorId] = true;
    const bid = await this.materialBidService.newQuoteForRfqRequestDetail(
      this.record.purchasingRfqRequestId,
      vendor.vendorId,
      this.record.purchasedItemId, this.record.outsideProcessDescription?.steps?.[0]?.stationId, this.record.materialId,
      this.record.dueDate,
    ).toPromise();
    delete this.loadingMap[vendor.vendorId];
    this.record.materialBids.push(bid);
    if (this.record.status == 0) this.updateStatus(PurchasingRfqRequestStatus.AWAITING_RFQS);
  }

  public async markAsReceived(vendor: Vendor) {
    const quote = structuredClone(this.getQuoteForVendor(vendor));
    this.newQuoteData = quote;
    this.sidenav = 'newQuote';
    this.insetnav.open();
    const res: MaterialBid | null = await merge(
      this.newQuoteApproved,
      this.newQuoteCancelled.pipe(map(() => null))
    ).pipe(take(1)).toPromise();
    this.sidenav = null;
    if (!res) return;
    const index = this.record.materialBids.findIndex(q => q.materialBidId === res.materialBidId);
    if (index !== -1) this.record.materialBids.splice(index, 1, res);
    if (this.record.status <= 1) this.updateStatus(PurchasingRfqRequestStatus.QUOTES_AVAILABLE);
  }

  @ViewChild('insetnav') insetnav: MatSidenav;
  public newQuoteApproved = new Subject<MaterialBid>();
  public newQuoteCancelled = new Subject<void>();
  public async manuallyInputPricing(vendor: Vendor) {
    this.newQuoteData = <MaterialBid>{
      materialBidId: UtilityService.emptyGuid,
      purchasingRfqRequestId: this.record.purchasingRfqRequestId,
      isVerbal: true,
      vendor,
      vendorId: vendor.vendorId,
      materialId: this.record.materialId,
      purchasedItemId: this.record.purchasedItemId,
      stationId: this.record.outsideProcessDescription?.steps?.[0]?.stationId,
      isPoPseudoBid: false,
      asked: new Date(),
      answered: new Date(),
      // TODO user
      acceptedBy: null,
    };
    this.sidenav = 'newQuote';
    this.insetnav.open();
    const res: MaterialBid | null = await merge(
      this.newQuoteApproved,
      this.newQuoteCancelled.pipe(map(() => null))
    ).pipe(take(1)).toPromise();
    this.sidenav = null;
    if (!res) return;
    this.record.materialBids.push(res);
    if (this.record.status <= 1) this.updateStatus(PurchasingRfqRequestStatus.QUOTES_AVAILABLE);
  }

  public async viewEditQuote(vendor: Vendor) {
    const quote = structuredClone(this.getQuoteForVendor(vendor));
    this.newQuoteData = quote;
    this.sidenav = 'viewQuote';
    this.insetnav.open();
    const res: MaterialBid | null = await merge(
      this.newQuoteApproved,
      this.newQuoteCancelled.pipe(map(() => null))
    ).pipe(take(1)).toPromise();
    this.sidenav = null;
    if (!res) return;
    const index = this.record.materialBids.findIndex(q => q.materialBidId === res.materialBidId);
    if (index !== -1) this.record.materialBids.splice(index, 1, res);
  }

  public generateRFQ(vendor: Vendor) {
    this.loadingMap[vendor.vendorId] = true;
    this.service.getRFQDocument(this.record, vendor).subscribe(doc => {
      delete this.loadingMap[vendor.vendorId];
      window.open(`/document/${doc.documentId}?inline=true`, "_blank")
    })
  }

  public async acceptQuote(vendor: Vendor) {
    const r = await this.utilitySvc.showConfirmationPromise("Accept this quote?", "This quote will be accepted and used for pricing in the estimate. This cannot be undone.");
    if (!r) return;
    const quote = this.getQuoteForVendor(vendor);
    this.record.selectedQuoteId = quote.materialBidId;
    this.loadingMap[vendor.vendorId] = true;
    await this.service.acceptQuote(this.record, quote).toPromise();
    delete this.loadingMap[vendor.vendorId];
    this.router.navigate(["/purchasing/procurement"]);
    if (this.record.status <= 1) this.updateStatus(PurchasingRfqRequestStatus.QUOTE_SELECTED);
  }

  @ViewChild('findVendorDialogTemplate', { static: true }) findVendorDialogTemplate: TemplateRef<any>;
  public async addCapableVendor() {
    const ref = this.dialog.open<any, any, Vendor | null>(this.findVendorDialogTemplate, {
      minWidth: 500,
      data: { hint: '', vendor: null }
    })
    const vendor = await ref.afterClosed().toPromise();
    if (!vendor) return;

    const filterId = this.getFilterId();

    const notCapable = filterId && (
      (vendor.vendorServiceOfferings.findIndex(vso => vso.stationStationId === filterId) === -1) &&
      (vendor.vendorMaterialGroups.findIndex(vso => vso.materialGroupMaterialGroupId === filterId) === -1) &&
      (
        filterId !== 'purchased' ||
        vendor.vendorMaterialGroups.findIndex(vso => vso.materialGroup.groupName === "Hardware") === -1
      )
    );
    if (notCapable) {
      let confirmText = `This will mark <b>${ vendor.name }</b> as as capable of supplying <b>${this.getFilterName()}</b>.`;;

      const confirm = await this.utilitySvc.showConfirmationPromise(
        'Add Other Vendor',
        `<p>${confirmText}</p>
        <p>Are you sure you want to do this?</p>`
      );
  
      if (!confirm) return;
      
      this.loadingVendors = true;
      await this.vendorService.markVendorForItem(vendor, filterId).toPromise();
      this.allVendorsSubject.pipe(take(1)).subscribe(list => {
        this.allVendorsSubject.next([...list, vendor]);
        this.loadingVendors = false;
      });

    }

    }
}
