<mat-form-field appearance="outline" class="flex-grow-1 dense-field mat-input-no-message w-100">
  <input placeholder="Search Part Number..." #partSearcher matInput [matAutocomplete]="auto" type="text" name="partSearcher" [formControl]="searchControl" (keydown)="$event.stopPropagation()"
  (keydown.escape)="cancel.emit()"
  >
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected.emit($event.option.value)" [displayWith]="displayWith.bind(this)">
    <ng-container *ngFor="let item of (productResults | async)">
      <mat-option *ngIf="(item.type === 'REPEAT') || (allowNewRev && item.type === 'NEWREV')"  [value]="item">
        <ng-container *ngIf="item.type === 'REPEAT'">
          <mat-icon matPrefix svgIcon="content-copy"></mat-icon>
          <span [innerHTML]="displayWith(item) | highlight: searchControl.value"></span>
        </ng-container>
        <ng-container *ngIf="item.type === 'NEWREV'">
          <mat-icon matPrefix svgIcon="content-duplicate" class="text-success"></mat-icon>
          <span class="text-success">New Revision For Existing PN <span [innerHTML]="item.product.partNumber | highlight: searchControl.value"></span></span>
        </ng-container>
      </mat-option>
    </ng-container>
    <mat-option *ngIf="allowNew && !!searchControl.value" [value]="searchControl.value" class="text-muted">
      <mat-icon matPrefix svgIcon="plus-box"></mat-icon>
      New Blank Part <b>"{{searchControl.value}}"</b>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>