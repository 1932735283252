import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrderDetailService, fetchLatest } from '../order-detail.service';
import { Product } from '../../../resources/product';
import { Order, OrderProduct } from '../../../resources/order';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { ChangeType } from '@cots/common/autosaving/change';
import { eqSet } from '../../../../../../util/eqSet';

const TOP_LEVEL_CHANGES: ChangeType[] = ['CREATE_BLANK_TOP_LEVEL_PRODUCT', 'CLONE_PRODUCT_TOP_LEVEL'];
const RELEVANT_PRODUCT_FIELDS: (keyof Product)[] = ['partNumber', 'revision', 'quantitiesMap'];
@Component({
  selector: 'order-detail-estimating-screen',
  templateUrl: './order-detail-estimating-screen.component.html',
  styleUrls: ['./order-detail-estimating-screen.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailEstimatingScreenComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
  ) { }

  public get editing() { return this.service.editing }


  public topLevelProducts$: Observable<Product[]>;
  public selectedProduct$: Observable<Product>;
  ngOnInit() {
    this.selectedProduct$ = this.service.selectedQuantityTableProductIdSubject.pipe(
      switchMap(id => this.service.getProductObservable(id))
    );
    const changesObservable = this.service.autosaver.changesSubject.pipe(
      map(changes => changes
        .filter(ch => 
          TOP_LEVEL_CHANGES.includes(ch.changeType) ||
          (
            ch.entity === 'Product' &&
            (
              ch.changeType === 'DELETE' ||
              (ch.changeType === 'UPDATE' && RELEVANT_PRODUCT_FIELDS.includes(ch.data.field as keyof Product))
            )
          )
        )
      ),
      debounceTime(500),
      distinctUntilChanged((oldChanges, newChanges) => eqSet(new Set(oldChanges.map(c => c.changeId)), new Set(newChanges.map(c => c.changeId)))),
    );
    const productsMapObservable = combineLatest([changesObservable, this.service.autosaver.changesCommittedSubject.pipe(startWith(null))]).pipe(
      switchMap(() => this.service.autosaver.getPostChanges('productMap')),
    );
    this.topLevelProducts$ = combineLatest([this.service.postChangesOrder$, productsMapObservable])
      .pipe(
        map<any, Product[]>(
          ([order, productsMap]) => (order.products as (OrderProduct & { isLoading: boolean})[]).filter(osp => !osp.isLoading).map<Product>(osp => productsMap[osp.productProductId])
        ),
        shareReplay(1)
      );
    combineLatest([this.topLevelProducts$, this.service.selectedPurchasedItemIdSubject]).pipe(take(1)).subscribe(([prods, oldId]) => { if (oldId === null && prods.length > 0) this.service.selectedPurchasedItemIdSubject.next(prods[0].productId) });
  }


  public openProduct(product: Product) {
    this.service.setSelectedProductId(product.productId);
    this.service.mainTab = 0;
  }

}
