import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../../common/services/navigation.service';
import { Material } from '../../../order/resources/material';
import { Order } from '../../../order/resources/order';
import { Product, ProductPurchasedItem, ProductQuantity } from '../../../order/resources/product';
import { Station } from '../../../order/resources/station';
import { WorkflowStep } from '../../../order/resources/workflow';
import { OrderService } from '../../../order/services/order.service';
import { StationService } from '../../../order/services/station.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { ContractReviewCategory } from '../../resources/contract-review-questions';
import { QualityTicket } from '../../resources/quality-ticket';
import { QualityService } from '../../service/quality.service';
import { UtilityService } from '../../../common/services/utility.service';
import { User } from '../../../common/resources/user';
import { UserService } from '../../../common/services/user.service';


@Component({
  selector: 'app-contract-review',
  templateUrl: './contract-review.component.html',
  styleUrls: ['./contract-review.component.less'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
})
export class ContractReviewComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public router: Router,
    private orderService: OrderService,
    private userSvc: UserService,
    private qualityService: QualityService,
    private quoteService: MaterialBidService,
    private stationService: StationService,
    private utilityService: UtilityService,
    navService: NavigationService,
    route: ActivatedRoute
  ) {
    navService.setPageTitle("Quality");
    navService.pushBreadcrumb("Contract Review");
    this.id = route.snapshot.paramMap.get('id');
  }

  public loading = false;
  public saving = false;
  public id: string;
  public ticket: QualityTicket;
  public record: Order;

  public selectedProductId = "";


  public pricingBreakdowns: {
    [productId: string]: {
      description: string,
      price: number
    }[]
  } = {};
  public materialQuotes: {
    [productId: string]: MaterialBid
  } = {};
  public purchasedItemQuotes: {
    [productPurchasedItemId: string]: MaterialBid
  } = {};

  public questionCategories: ContractReviewCategory[];
  public selectedCategoryId: string;
  public infoTabs = ['Details', 'Material', 'Workflow', 'Purchased Items', 'Checklist'];
  public selectedTab = 'Details';
  private stationList: Station[] = null;

  ngOnInit() {
    this.getDetail();
    if (this.stationService.loaded) {
      this.stationList = this.stationService.stationList;
    }
    else {
      this.stationService.stationsLoaded.subscribe(
        _ => this.stationList = this.stationService.stationList
      );
    }
  }


  public async getDetail() {
    this.loading = true;
    const detail = await this.qualityService.getDetail(this.id).toPromise();
    this.ticket = detail;
    const record = await this.orderService.getDetail(this.ticket.orderSegmentId).toPromise();
    this.record = record;
    const products = await this.orderService.getProductTree(this.ticket.orderSegmentId).toPromise();
    this.record.products.forEach(op => op.product = products.find(p => p.productId === op.productProductId));
    const checklist = await this.qualityService.getContractReviewChecklist().toPromise();
    this.questionCategories = checklist.results;
    if (this.questionCategories.length > 0) this.selectedCategoryId = this.questionCategories[0].contractReviewCategoryId;
    this.setupAnswers();
    this.loading = false;
    this.allProductsFlat().forEach(p => {
      this.orderService.getSingleProductCostBreakdown(p).subscribe(breakdown => this.pricingBreakdowns[p.productId] = breakdown);
      if (p.materialId && p.selectedMaterialQuote) {
        this.quoteService.get(p.selectedMaterialQuote).subscribe(q => this.materialQuotes[p.productId] = q);
      }
      p.purchasedItems.forEach(ppi => {
        if (ppi.purchasedItem && ppi.selectedQuote) {
          this.quoteService.get(ppi.selectedQuote).subscribe(q => this.purchasedItemQuotes[ppi.productPurchasedItemId] = q);
        }
      });
    });
  }

  public async save(reload = true) {
    this.saving = true;
    await this.qualityService.save(this.ticket).toPromise();
    this.saving = false;
    if (reload) this.getDetail();
  }

  public get products() {
    if (!this.ticket) return [];
    return this.record.products.filter(p => p.product).map(p => p.product);
  }
  public pricingIsLoaded(product: Product) {
    return this.pricingBreakdowns[product.productId];
  }

  public getProductQuantity(product: Product): number {
    if (!product.parentAssemblyId) return product.orderQuantity;
    else {
      const products = this.allProductsFlat();
      const parent = products.find(p => p.productId === product.parentAssemblyId);
      return product.quantityAsChild * this.getProductQuantity(parent);
    }
  }

  private item(b: ContractReviewComponent['pricingBreakdowns']['any'], name: string): number {
    const i = b.find(x => x.description === name)
    if (i) return i.price
    else return 0
  }


  public getProductCost(product: Product) {
    const b = this.pricingBreakdowns[product.productId];
    if (!b) return 0;
    const qty = this.getProductQuantity(product);
    return (this.item(b, 'Labor - Repeating') + this.item(b, 'Outsourcing - Repeating') + this.item(b, 'Purchased Items - Repeating')) * qty +
      this.item(b, 'Labor - One Time') + this.item(b, 'Outsourcing - One Time') + this.item(b, 'Purchased Items - One Time') + Product.getMaterialCost(product, qty);
  }

  public getProductPricingData(product: Product): ProductQuantity {
    if (!product.parentAssemblyId) {
      const quantites = product.quantitiesMap;
      const pricingData = quantites.find(q => q.value === product.orderQuantity);
      if (!pricingData) return { value: 1, markup: 0, showOnQuote: true, overrideNote: null, priceOverride: null };
      else return pricingData;
    }
    else return null;
  }

  public priceIsOverriden(product: Product) {
    if (product.parentAssemblyId) {
      return false;
    }
    else {
      const pricingData = this.getProductPricingData(product);
      if (!pricingData) return false;
      else return pricingData.priceOverride !== null && pricingData.priceOverride !== undefined;
    }
  }

  public getProductMarkup(product: Product) {
    if (!product.parentAssemblyId) {
      const pricingData = this.getProductPricingData(product);
      if (!pricingData) return 0;
      else return pricingData.markup;
    } else {
      const products = this.allProductsFlat();
      const parent = products.find(p => p.productId === product.parentAssemblyId);
      return this.getProductMarkup(parent);
    }
  }

  public getProductPrice(product: Product) {
    if (!product.parentAssemblyId) {
      const pricingData = this.getProductPricingData(product);
      if (pricingData.priceOverride) return pricingData.priceOverride;
    }
    const cost = this.getProductCost(product);
    const markup = this.getProductMarkup(product);
    return cost * ( 1 + markup / 100);
  }


  public getTotalMaterialCost(product: Product) {
    const quote = this.materialQuotes[product.productId];
    if (!quote) return null;
    const qty = this.getProductQuantity(product);
    let perItem: number;
    if (quote.perItemBid) perItem = quote.perItemBid;
    else perItem = quote.totalBid / qty;
    return perItem * qty;
  }

  public getTotalPurchasedItemCost(product: Product, ppi: ProductPurchasedItem) {
    const quote = this.purchasedItemQuotes[ppi.productPurchasedItemId];
    if (!quote) return null;


    const { quantity } = ppi;
    let totalQuantity = quantity;
    if (!ppi.isNonRecurring) {
      const productQty = this.getProductQuantity(product);
      totalQuantity = totalQuantity * productQty;
    }

    let perItem: number;
    if (quote.perItemBid) perItem = quote.perItemBid;
    else perItem = quote.totalBid / totalQuantity;
    return perItem * totalQuantity;
  }
  public allProductsFlat(product?: Product): Product[] {
    if (!product) return this.products.flatMap(p => this.allProductsFlat(p))
    else return [product, ...product.childAssemblies.flatMap(childProduct => this.allProductsFlat(childProduct))]
  }
  public get selectedProduct(): Product {
    return this.allProductsFlat().find(p => p.productId === this.selectedProductId)
  }
  public get selectedProductDeliveryTimes() {
    if (!this.record || !this.selectedProductId || this.selectedProduct.parentAssemblyId) return []
    return this.record.shippingAssignments[this.selectedProductId] || [];
  }

  public unassignedAmount(product: Product) {
    if (!product.orderQuantity) return 0;
    if (!this.record.shippingAssignments[product.productId]) return 0;
    const assignedAmount = this.record.shippingAssignments[product.productId].reduce((acc, a) => acc + a.quantity, 0);
  
    return product.orderQuantity - assignedAmount;
  }

  public get selectedCategory() {
    return this.questionCategories.find(c => c.contractReviewCategoryId === this.selectedCategoryId)
  }



  public setupAnswers() {

    if (!this.ticket.contractReviewAnswers) this.ticket.contractReviewAnswers = {};

    const setupAnswersForProduct = (pr: Product) => {
      const {productId, topParentAssemblyId} = pr;
      // check if we have defaults from PO review
      if (!this.ticket.contractReviewAnswers[productId]) {
        this.ticket.contractReviewAnswers[productId] = this.ticket.topLevelPOReviewAnswers?.[topParentAssemblyId] ?? {};
      }
      this.questionCategories.forEach(questionCat => {
        if (!this.ticket.contractReviewAnswers[productId][questionCat.contractReviewCategoryId]) this.ticket.contractReviewAnswers[productId][questionCat.contractReviewCategoryId] = {};
        questionCat.questions.forEach((q) => {
          let existingAnswer = this.ticket.contractReviewAnswers[productId][questionCat.contractReviewCategoryId][q.contractReviewQuestionId];
          if (!existingAnswer) {
            existingAnswer = this.ticket.topLevelPOReviewAnswers?.[topParentAssemblyId]?.[questionCat.contractReviewCategoryId]?.[q.contractReviewQuestionId];
            if (existingAnswer) this.ticket.contractReviewAnswers[productId][questionCat.contractReviewCategoryId][q.contractReviewQuestionId] = existingAnswer;
          }
          if (!existingAnswer || !questionCat.answers.includes(existingAnswer)) {
            this.ticket.contractReviewAnswers[productId][questionCat.contractReviewCategoryId][q.contractReviewQuestionId] = null;
          }
        })
      })
      pr.childAssemblies.forEach(setupAnswersForProduct);
    }

    this.products.forEach(setupAnswersForProduct);

  }

  public countProductAnswers(pr: Product, categoryId?: string) {
    const productAnswersIndex = this.ticket.contractReviewAnswers[pr.productId];
    let productAnswers: string[];
    if (categoryId) {
      productAnswers = Object.values(productAnswersIndex[categoryId])
    }
    else {
      productAnswers = Object.values(productAnswersIndex).map(y => Object.values(y)).flat()
    }
    
    const total = productAnswers.length;
    const answered = productAnswers.filter(x => x !== null).length;
    return { answered, total }
  }

  public selectProduct(pr: Product) {
    this.selectedProductId = pr.productId;
  }

  public materialName = Material.generatedName;
  public getRawWeight = Product.getRawWeight;


  public willApprove: boolean
  public rejectNote = ""
  public get canFinalize(): boolean {
    return this.willApprove !== null && 
    ( this.willApprove === true || !!this.rejectNote )
  }

  @ViewChild('finalizeReviewDialog', { static: true }) finalizeReviewDialog: TemplateRef<any>;
  @ViewChild('doneDialog', { static: true }) doneDialog: TemplateRef<any>;

  public async finalizeReview() {
    this.rejectNote = "";
    this.willApprove = null;

    const doneRef = this.dialog.open(this.doneDialog, { disableClose: true, minWidth: 500 });

    const doneStatus = await doneRef.afterClosed().toPromise();
    if (doneStatus === "cancel") return;


    const isFinalizing = doneStatus === 'done';

    if (isFinalizing) {
      const reviewDialog = this.dialog.open(this.finalizeReviewDialog, { disableClose: true, minWidth: 500 });
      const cancel = await reviewDialog.afterClosed().toPromise();
      if (cancel === true) return;
    }

    await this.save(false);
    if (isFinalizing) {
      this.saving = true;
      if (this.willApprove === true) {
        await this.qualityService.approveTicket(this.ticket).toPromise();
      } else {
        await this.qualityService.rejectTicket(this.ticket, this.rejectNote).toPromise();
      }
      this.saving = false;
      this.router.navigate(['/quality']);
    }

  }

  

  public get uncheckedItems() {
    return this.allProductsFlat().reduce((acc, product) => {
      const { answered, total } = this.countProductAnswers(product);
      return acc + (total - answered);
    }, 0)
  }

  public getStation(item: WorkflowStep): Station {
    if (this.stationList == null || item == null || item.stationId == null)
      return null;

    return this.stationList.find(r => r.stationId == item.stationId);
  }

  public assigningEmployee: User = null;

  public userIsManager(): boolean {
    return (
      this.userSvc.canAccess("QAManager") ||
      this.userSvc.canAccess("Developer")
    );
  }
  public userIsAssigned(): boolean {
    return this.userSvc.userData.userId === this.ticket.assignedUserId;
  }

  public async saveAssignment() {
    if (!this.record) return;
    this.loading = true;
    await this.qualityService.save(this.ticket).toPromise();
    await this.qualityService.assign(this.ticket, this.assigningEmployee).toPromise();
    this.ticket.assignedUser = this.assigningEmployee;
    this.ticket.assignedUserId = this.assigningEmployee.userId;
    this.loading = false;
  }


}