import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, ViewChildren, ViewContainerRef, QueryList, Query } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import { MatStep } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { User } from '../../../common/resources/user';
import { NavigationService } from '../../../common/services/navigation.service';
import { UserService } from '../../../common/services/user.service';
import { UtilityService } from '../../../common/services/utility.service';
import { Station } from '../../../order/resources/station';
import { Workflow, WorkflowStep, WorkflowStepType } from '../../../order/resources/workflow';
import { StationService } from '../../../order/services/station.service';
import { MaterialBid } from '../../../purchasing/resources/materialBid';
import { MaterialBidService } from '../../../purchasing/services/material-bid.service';
import { WorkOrder } from '../../resources/work-order';
import { PreplanningData, PreplanningMaterialParameters, WorkOrderService } from '../../services/work-order.service';
import { PlanningDetailComponent } from '../planning-detail/planning-detail.component';
import { Product, ProductMaterialDimension, ProductPurchasedItem } from '../../../order/resources/product';
import { NgForm } from '@angular/forms';
import { PurchasedItem } from '../../../order/resources/purchased-item';
import { Paint } from '../../../order/resources/paint';
import { Material, MaterialDimension, MaterialSpecification, MaterialType } from '../../../order/resources/material';
import { MaterialSelectComponent, MaterialSelectParameters } from '../../../order/components/material-select/material-select.component';
import { MaterialService } from '../../../order/services/material.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'preplanning-detail',
  templateUrl: './preplanning-detail.component.html',
  styleUrls: ['./preplanning-detail.component.less']
})
export class PreplanningDetailComponent implements OnInit, AfterViewInit {

  @Input('workOrder') workOrder: WorkOrder;
  @Input('product') product: Product;
  @Input('parentComponent') parentComponent: PlanningDetailComponent;

  @Output() workOrderChange = new EventEmitter<WorkOrder>();
  @Output() save = new EventEmitter<void>();
  @Output() stopSave = new EventEmitter<void>();
  
  
  public loading = false;
  public showEditor = null;
  public dirty = false;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('form') form: NgForm;

  public openWorkflow(): void {
    this.showEditor = "part";
    this.sidenav.open();
  }

  public async closeSideNav() {
    console.log('closeSidenav');
    this.showEditor = null;

    
    this.getDetail();
  }


  public openPODocument() {
    window.open('/document/' + this.workOrder.order.orderPurchaseOrder.documentId + '?inline=true', '_blank')
  }

  constructor(private matService: MaterialService, private woService: WorkOrderService, private userService: UserService, private navService: NavigationService, public stationService: StationService, private materialBidService: MaterialBidService, private utilityService: UtilityService, private dialog: MatDialog, private router: Router, private _viewContainerRef: ViewContainerRef) { }

  public paintItems: Paint[] = [];
  public altMaterials: Material[] = [];
  public cmmStepNames: string[] = [];
  public cncStepNames: string[] = [];
  @ViewChild('programList') programList: MatSelectionList;
  public async getDetail() {
    console.log('getDetail');
    this.product.workflow.workflowSteps = this.product.workflow.workflowSteps.sort((a, b) => { return a.stepOrder - b.stepOrder; });
  
    if (this.product.material) {
      this.lastMaterialType = this.product.material.materialType;
      this.initializeWorkingDimensions(this.product.material.materialType, this.product.material.materialDimensions);
      const { params, density} = MaterialSelectComponent.existingMaterialToParams(this.product.material);
      this.primaryMaterialParams = { ...params, density };
    }

    this.paintItems = this.product.workflow.workflowSteps.filter(s => this.getStation(s.stationId).isPainting).filter(s => !!s.paint).map(p => p.paint);

    if (this.product.materialNeed) {
      this.altMaterials = this.product.materialNeed.materialNeedMaterials.filter(mnm => !mnm.isPrimary).map(mnm => mnm.material);
      this.alternateMaterialParams = this.altMaterials.map(mat => {
        const { params, density } = MaterialSelectComponent.existingMaterialToParams(mat);
        return { ...params, density };
      });
    }

    // Programming auto-check
    const cmmSteps = this.product.workflow.workflowSteps.filter(step => 
      (step.hasFirstPartInspection || step.hasInspection) && step.inspectionIsCMM
    );
    if (cmmSteps.length > 0) {
      this.createProgrammingCMM = true;
      this.cmmStepNames = cmmSteps.map(s => this.getStation(s.stationId)?.name).filter(x => !!x);
    }
    const cncSteps = this.product.workflow.workflowSteps.map(s => this.getStation(s.stationId)?.name).filter(x => !!x).filter(stepName => 
      stepName.includes('CNC')
    );
    const programmingStep = this.product.workflow.workflowSteps.map(s => this.getStation(s.stationId)?.name).filter(x => !!x).find(s => s.toLowerCase().includes('program'));
    if (programmingStep && cncSteps.length > 0) {
      this.createProgrammingCNC = true;
      this.cncStepNames = cncSteps;
    }
  }


  ngOnInit() {
    this.getDetail();
  }

  ngAfterViewInit() {
    this.primaryMaterialParams.density = this.primaryMaterialSelect.materialDensity;

    setTimeout(() => {
      if (this.altMaterials.length > 0) {
        this.altMaterialSelects.forEach((component, i) => {
          const mat = this.altMaterials[i];
          component.loadExistingMaterial(mat);
        });
      }
    });
  }

  public initializeWorkingDimensions(type: MaterialType, dimensions: MaterialDimension[] = [], reset = false) {
    const defaultDimensions = type.materialTypeDimensions.map<ProductMaterialDimension>(mtd => {
      const dim = dimensions.find(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)
      if (!dim) return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: 0 };
      else return { materialTypeDimensionId: mtd.materialTypeDimensionId, value: dim.value };
    })
    if (!this.materialDimensions || this.dimensionsDiffer(type.materialTypeDimensions, this.materialDimensions)) this.materialDimensions = JSON.parse(JSON.stringify(defaultDimensions));
    if (!this.product.blankDimensions || this.dimensionsDiffer(type.materialTypeDimensions, this.product.blankDimensions)) this.product.blankDimensions = JSON.parse(JSON.stringify(defaultDimensions));
    if (!this.product.finishedDimensions || this.dimensionsDiffer(type.materialTypeDimensions, this.product.finishedDimensions)) this.product.finishedDimensions = JSON.parse(JSON.stringify(defaultDimensions));
  }

  public materialDimensions: MaterialDimension[] = [];

  public getDimensionValue(materialTypeDimensionId: string) {
    const d = this.materialDimensions.find(md => md.materialTypeDimensionId === materialTypeDimensionId)
    return d ? d.value : 0;
  }

  public setDimensionValue(materialTypeDimensionId: string, value: number) {
    const d = this.materialDimensions.find(md => md.materialTypeDimensionId === materialTypeDimensionId)
    if (!d) return;
    else d.value = value;
  }

  public getBlankDimension(mtdId: string) {
    const dimensions = this.product.blankDimensions || [];
    const out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setBlankDimension(mtdId: string, value: number) {
    const dimensions = this.product.blankDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return;
    else {
      out.value = value;
    }
  }

  public getFinishedDimension(mtdId: string) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return 0;
    else return out.value;
  }

  public setFinishedDimension(mtdId: string, value: number) {
    const dimensions = this.product.finishedDimensions || [];
    let out = dimensions.find(d => d.materialTypeDimensionId === mtdId);
    if (!out) return;
    else {
      out.value = value;
    }
  }


  public getStation(stationId: string): Station {
    if (!this.stationService.stationList) return null;

    return this.stationService.stationList.find(s => s.stationId == stationId);
  }

  public outsideProcessQuotes: { [key: string]: MaterialBid } = {}

  @ViewChild('outsideProcessTabGroup') outsideProcessTabGroup: MatTabGroup;
  @ViewChild('insideProcessTabGroup') insideProcessTabGroup: MatTabGroup;

  @Output() setPortal = new EventEmitter<Portal<any>>();

  public getScrollParent(node) {
    if (node == null) {
      return null;
    }
  
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return this.getScrollParent(node.parentNode);
    }
  }

  @ViewChildren('anchor') stepScrollAnchors: QueryList<ElementRef>;
  public onSelectedStep(index: number) {
    // setTimeout(() => {
    //   const step = this.stepScrollAnchors.toArray()[index];
    //   const anchorElement: HTMLElement = step.nativeElement;
    //   const parent: HTMLElement = document.querySelector('#planning-tab-group .mat-tab-body-content');
    //   const y = anchorElement.getBoundingClientRect().y - parent.getBoundingClientRect().y - 48;
    //   parent.scrollTo({ top: y, left: 0, behavior: 'smooth' })
    // }, 150)
  }

  public createProgrammingCNC = false;
  public assignedProgrammerCNC: User;
  public createProgrammingNC = false;
  public assignedProgrammerNC: User;
  public createProgrammingCMM = false;
  public assignedProgrammerCMM: User;

  public newPurchasedItem: PurchasedItem | null;
  @ViewChild('newPurchasedItemDialog', { static: true }) newPurchasedItemDialog: TemplateRef<any>;
  public async addPurchasedItem() {
    this.newPurchasedItem = null;
    const ref = this.dialog.open(this.newPurchasedItemDialog, {
      disableClose: true,
    });
    const r: boolean = await ref.afterClosed().toPromise();
    if (!r) return;
    else this.product.purchasedItems.push(<ProductPurchasedItem>{
      productPurchasedItemId: UtilityService.emptyGuid,
      purchasedItemId: this.newPurchasedItem.purchasedItemId,
      isAmortized: false,
      isNonRecurring: false,
      itemRepairType: WorkflowStepType.Standard,
      markupPercent: 18,
      productId: this.product.productId,
      quantity: 1,
      purchasedItem: this.newPurchasedItem
    });
  }

  public deletePurchasedItem(index: number) {
    this.product.purchasedItems = this.product.purchasedItems.filter((p, i) => i !== index);
  }



  public newPaint: Paint | null;
  @ViewChild('newPaintDialog', { static: true }) newPaintDialog: TemplateRef<any>;
  public async addPaint() {
    this.newPaint = null;
    const ref = this.dialog.open(this.newPaintDialog, {
      disableClose: true,
      data: null
    });
    const r: boolean = await ref.afterClosed().toPromise();
    if (!r) return;
    this.paintItems = [...this.paintItems, this.newPaint];
  }

  public deletePaint(index: number) {
    this.paintItems = this.paintItems.filter((p, i) => i !== index);
  }

  public assignedPlanner: User;

  public get preplanningData(): PreplanningData {
    return {
      product: this.product,
      paintList: this.paintItems,
      primaryMaterial: this.primaryMaterialSelect.shouldShowPlaceholder ? null : { 
        ...this.primaryMaterialSelect.parameterIds,
        density: this.primaryMaterialSelect.materialDensity,
          materialDimensions: this.materialDimensions.map(d => ({
            ...d, materialId: UtilityService.emptyGuid, materialTypeDimension: this.primaryMaterialSelect.materialParameters.type.materialTypeDimensions.find(mtd => mtd.materialTypeDimensionId === d.materialTypeDimensionId)
          }))
      },
      alternateMaterials: this.alternateMaterialParams.map((params, i) => {
        const component = this.altMaterialSelects.get(i);
        return {
          ...component.parameterIds,
          density: component.materialDensity,
          materialDimensions: this.materialDimensions.map(d => ({
            ...d, materialId: UtilityService.emptyGuid, materialTypeDimension: component.materialParameters.type.materialTypeDimensions.find(mtd => mtd.materialTypeDimensionId === d.materialTypeDimensionId)
          }))
        }
      }),
      createProgrammingCNC: this.createProgrammingCNC,
      assignedProgrammerCNC: this.assignedProgrammerCNC && this.assignedProgrammerCNC.userId,
      createProgrammingNC: this.createProgrammingNC,
      assignedProgrammerNC: this.assignedProgrammerNC && this.assignedProgrammerNC.userId,
      createProgrammingCMM: this.createProgrammingCMM,
      assignedProgrammerCMM: this.assignedProgrammerCMM && this.assignedProgrammerCMM.userId,
      assignedPlanner: this.assignedPlanner.userId,
    }
  }

  public togglePartsPerMaterialOverride(e) {
    e.preventDefault();
    this.product.partsPerMaterialOverride = !this.product.partsPerMaterialOverride;
    this.dirty = true;
  }
  public get blanksPerMaterial(): number {
    if (Product.getVolume(this.product) === 0) return 0;
    return Math.floor(Product.getVolume(this.product) / Product.getBlankVolume(this.product))
  }

  public togglePartsPerBlankOverride(e) {
    e.preventDefault();
    this.product.partsPerBlankOverride = !this.product.partsPerBlankOverride;
    this.dirty = true;
  }
  public get partsPerBlank(): number {
    if (Product.getFinishedVolume(this.product) === 0) return 0;
    return Math.floor(Product.getBlankVolume(this.product) / Product.getFinishedVolume(this.product))
  }

  public get mv() {
    return Product.getVolume(this.product);
  }

  public get bv() {
    return Product.getBlankVolume(this.product);
  }

  public get fv() {
    return Product.getFinishedVolume(this.product);
  }

  // Material editor stuff
  public primaryMaterialParams: PreplanningMaterialParameters = {
    group: undefined,
    alloy: undefined,
    type: undefined,
    castnumber: undefined,
    hardness: undefined,
    specs: [],
    density: 0,
  };
  public paramsAreMissing(params: PreplanningMaterialParameters) {
    return 
      !params.group
      || !params.alloy
      || !params.type
      || !params.castnumber
      || !params.hardness
  }
  public alternateMaterialParams: PreplanningMaterialParameters [] = [];

  @ViewChild('materialTabGroup') materialTabGroup: MatTabGroup;
  @ViewChild('primaryMaterialSelect') primaryMaterialSelect: MaterialSelectComponent;
  @ViewChildren('altMaterialSelect') altMaterialSelects: QueryList<MaterialSelectComponent>;

  public addAltMaterial(event: MouseEvent) {
    let paramsToAdd: PreplanningMaterialParameters;
    let stateToSet: MaterialSelectComponent['_state'] = 'GROUP';
    let isFullyInput = false;
    let density = 0;
    if (!event.ctrlKey || !this.primaryMaterialParams) {
      paramsToAdd = {
        group: undefined,
        alloy: undefined,
        type: undefined,
        castnumber: undefined,
        hardness: undefined,
        specs: [],
        density,
      };
      stateToSet = 'GROUP';
      isFullyInput = false;
    } else {
      paramsToAdd = JSON.parse(JSON.stringify(this.primaryMaterialParams));
      stateToSet = this.primaryMaterialSelect._state;
      isFullyInput = this.primaryMaterialSelect.materialFullyInput;
      density = this.primaryMaterialSelect.materialDensity;
    }
    this.alternateMaterialParams = [...this.alternateMaterialParams, paramsToAdd];
    const index = this.alternateMaterialParams.length - 1;
    setTimeout(() => {
      const select = this.altMaterialSelects.get(index);
      select.materialParameters = paramsToAdd;
      select._state = stateToSet;
      select.hasFinishedSpecs = isFullyInput;
      select.materialDensity = density;
      this.materialTabGroup.selectedIndex = index + 1;
    });
  }

  public deleteAltMaterial(index: number) {
    this.alternateMaterialParams.splice(index, 1);
    this.alternateMaterialParams = this.alternateMaterialParams;
      this.materialTabGroup.selectedIndex = 0;
  }
  
  public dimensionsDiffer(t1: { materialTypeDimensionId: string }[], t2: { materialTypeDimensionId: string }[]): boolean {
    if (!t1 || !t2) return false;
    const eqSet = (xs: Set<any>, ys: Set<any>) =>
      xs.size === ys.size &&
        [...xs].every((x) => ys.has(x));
    const t1ids = new Set(t1.map(mtd => mtd.materialTypeDimensionId));
    const t2ids = new Set(t2.map(mtd => mtd.materialTypeDimensionId));
    return !eqSet(t1ids, t2ids);
  }

  public materialDimensionsDiffer(t1: MaterialType, t2: MaterialType): boolean {
    if (!t1 || !t2) return false;
    if (t1.materialTypeId === t2.materialTypeId) return false;
    return this.dimensionsDiffer(t1.materialTypeDimensions, t2.materialTypeDimensions);
  }

  public validatePrimaryMaterial(): string | undefined {
    if (!this.primaryMaterialSelect) return;
    if (!this.primaryMaterialSelect.materialFullyInput) {
      if (!this.primaryMaterialSelect.shouldShowPlaceholder) return 'Primary material should either be empty or completely filled out.';
    };
  }

  public validateSecondaryMaterial(component: MaterialSelectComponent): string | undefined {
    if (!this.primaryMaterialSelect || !component) return;
    if (!this.primaryMaterialSelect?.materialFullyInput && this.primaryMaterialSelect?.shouldShowPlaceholder) return 'Cannot have alternate materials if primary material is empty.'
    if (!component.materialFullyInput) return 'Alternate material is not fully input.';
    if (this.materialDimensionsDiffer(this.primaryMaterialParams.type, component.materialParameters.type)) return 'Alternate material has a different set of dimensions from the primary material.'
  }

  public canContinueFromMaterial() {
    if (!this.primaryMaterialSelect || !this.altMaterialSelects) return true;
    return (!this.validatePrimaryMaterial() && this.altMaterialSelects.map(c => this.validateSecondaryMaterial(c)).every(x => !x));
  }

  public lastMaterialType: MaterialType;
  public onPrimaryMaterialChange() {
    this.primaryMaterialParams = {
      ...this.primaryMaterialSelect.materialParameters,
      density: this.primaryMaterialSelect.materialDensity
    };
    if (this.primaryMaterialParams && this.primaryMaterialParams.type?.materialTypeId !== this.lastMaterialType?.materialTypeId) {
      this.initializeWorkingDimensions(this.primaryMaterialParams.type);
      this.lastMaterialType = this.primaryMaterialParams.type;
    }
  }

}
