import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Product, ProductMaterialDimension } from '../../../../resources/product';
import { MaterialSelectComponent } from '../../../material-select/material-select.component';
import { UtilityService } from '../../../../../common/services/utility.service';
import { EstimatingTaskStatus, Order } from '../../../../resources/order';
import { MaterialBid } from '../../../../../purchasing/resources/materialBid';
import { Material, MaterialDimension, MaterialType, MaterialTypeDimension } from '../../../../resources/material';
import { OrderDetailService, fetchLatest } from '../../order-detail.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TaskStatus } from '../../../../../common/resources/estimatingtask';
import { UpdateChange } from '@cots/common/autosaving/change';
import { switchMap, take } from 'rxjs/operators';
import { MaterialService } from '../../../../services/material.service';

@Component({
  selector: 'product-material',
  templateUrl: './product-material.component.html',
  styleUrls: ['./product-material.component.less']
})
export class ProductMaterialComponent implements OnInit {

  constructor(
    public service: OrderDetailService,
    public matService: MaterialService,
  ) { }

  public get editing() { return this.service.editing }


  public newMaterialType: 'new' | 'search' = 'new';

  public initializeMaterial(product: Product) {
    if (product?.material) {
      if (!product?.blankDimensions || !product?.finishedDimensions) {
        this.initializeWorkingDimensions(product, product?.material.materialType, product?.material.materialDimensions);
      }
    } else if (this.materialSelect) {
      this.materialSelect.resetMaterialParameters();
    }
  }

  private resetSubscription: Subscription;
  ngOnInit(): void {
    if (this.resetSubscription) this.resetSubscription.unsubscribe();
    this.resetSubscription = this.service.selectedProductIdSubject.pipe(
      // Not using take(1) here would cause the subscription to fire whenever the product is edited
      // We only want to fire on the selected ID changing and get the latest data
      switchMap(id => this.service.getProductObservable(id).pipe(take(1)))
    ).subscribe(sp => {
      this.initializeMaterial(sp);
    });
  }

  ngOnDestroy(): void {
    this.resetSubscription && this.resetSubscription.unsubscribe();
  }

  private updateProgress(product: Product) {
    if (product.materialStatus === EstimatingTaskStatus.NOT_STARTED) this.service.setEstimatingTaskStatus(product.productId, 'material', EstimatingTaskStatus.IN_PROGRESS);
  }

  @ViewChild('materialSelect') materialSelect: MaterialSelectComponent;
  public async onMaterialSearchSelect(product: Product, mat: Material) {
    const change: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: product.productId,
        field: 'materialId',
        oldValue: product.material?.materialId,
        newValue: mat.materialId,
        relatedEntityField: 'material',
        oldRelatedEntity: product.material,
        newRelatedEntity: mat,
      }
    };
    this.service.recordChanges(change);
    this.initializeWorkingDimensions(product, mat.materialType, mat.materialDimensions);
    this.updateProgress(product);
  }

  public clearMaterial(product: Product) {
      const matChange: UpdateChange = {
                 changeType: 'UPDATE',
        entity: 'Product',
        data: {
          itemId: product.productId,
          field: 'materialId',
          oldValue: product.material?.materialId,
          newValue: null,
          relatedEntityField: 'material',
          oldRelatedEntity: product.material,
          newRelatedEntity: null,
        },
      };
      const blankChange: UpdateChange = {
                 changeType: 'UPDATE',
        entity: 'Product',
        data: {
          itemId: product.productId,
          field: 'blankDimensions',
          oldValue: product.blankDimensions,
          newValue: [],
        },
      };
      const finishedChange: UpdateChange = {
                 changeType: 'UPDATE',
        entity: 'Product',
        data: {
          itemId: product.productId,
          field: 'finishedDimensions',
          oldValue: product.finishedDimensions,
          newValue: [],
        },
      };
      this.service.recordChanges(matChange, blankChange, finishedChange);
  }

  public editMaterial(product: Product) {
    const mat = product.material;
    this.newMaterialType = 'new';
    this.clearMaterial(product);
    setTimeout(() => {
      if (this.materialSelect) this.materialSelect.loadExistingMaterial(mat);
    });
  }

  public onNewMaterialTypeChange(product: Product, value: 'new' | 'search') {
    const oldValue = this.newMaterialType;
    if (oldValue === value) return;
    this.newMaterialType =  value;
    if (!!product.materialId) {
      this.clearMaterial(product);
    }
    if (value === 'search') {
      this.materialSelect && this.materialSelect.resetMaterialParameters();
    }
  }

  private dimensionsDiffer(t1: { materialTypeDimensionId: string }[], t2: { materialTypeDimensionId: string }[]): boolean {
    if (!t1 || !t2) return false;
    const eqSet = (xs: Set<any>, ys: Set<any>) =>
      xs.size === ys.size &&
        [...xs].every((x) => ys.has(x));
    const t1ids = new Set(t1.map(mtd => mtd.materialTypeDimensionId));
    const t2ids = new Set(t2.map(mtd => mtd.materialTypeDimensionId));
    return !eqSet(t1ids, t2ids);
  }

  private generateBaseDimensions(type: MaterialType, dimensions: MaterialDimension[]): ProductMaterialDimension[] {
    return type.materialTypeDimensions.map<ProductMaterialDimension>(mtd => ({
      materialTypeDimensionId: mtd.materialTypeDimensionId,
      value: dimensions.find(md => md.materialTypeDimensionId === mtd.materialTypeDimensionId)?.value ?? 0,
    }));
  }

  public initializeWorkingDimensions(product: Product, type: MaterialType, dimensions: MaterialDimension[]) {
    if (this.dimensionsDiffer(type.materialTypeDimensions, product.blankDimensions)) {
      const change: UpdateChange = {
        changeType: 'UPDATE',
        entity: 'Product',
        data: {
          itemId: product.productId,
          field: 'blankDimensions',
          oldValue: structuredClone(product.blankDimensions),
          newValue: this.generateBaseDimensions(type, dimensions),
        }
      };
      this.service.recordChanges(change);
    }
    if (this.dimensionsDiffer(type.materialTypeDimensions, product.finishedDimensions)) {
      const change: UpdateChange = {
                 changeType: 'UPDATE',
        entity: 'Product',
        data: {
          itemId: product.productId,
          field: 'finishedDimensions',
          oldValue: structuredClone(product.finishedDimensions),
          newValue: this.generateBaseDimensions(type, dimensions),
        }
      };
      this.service.recordChanges(change);
    }
  }

  public matLoading = false;
  public async submitMaterial(product: Product, matSelect: MaterialSelectComponent) {
    this.matLoading = true;
    const material = await this.matService.findOrCreateMaterial(matSelect.parameterIds).toPromise();
    const matChange: UpdateChange = {
             changeType: 'UPDATE',
      entity: 'Product',
      data: {
        itemId: product.productId,
        field: 'materialId',
        oldValue: product.material?.materialId,
        newValue: material.materialId,
        relatedEntityField: 'material',
        oldRelatedEntity: product.material,
        newRelatedEntity: material,
      },
    };
    this.service.recordChanges(matChange);
    this.initializeWorkingDimensions(product, material.materialType, material.materialDimensions);
    this.matLoading = false;
  }

}
